import { administrationAvailableSubdomainLocalesService } from '@/services';

export const subdomainLocales = {
    namespaced: true,
    state: {
        all: [],
        baseLanguages: [],
        loading: false
    },
    actions: {
        getAll({ commit }, filters) {
            commit('getAllRequest');

            administrationAvailableSubdomainLocalesService.getAll(filters)
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },

        getBaseLanguages({ commit }, filters) {
            commit('getBaseLanguagesRequest');

            administrationAvailableSubdomainLocalesService.getAll(filters)
                .then(
                    data => commit('getBaseLanguagesSuccess', data),
                    error => commit('getBaseLanguagesFailure', error)
                );
        },
    },
    mutations: {
        getAllRequest(state) {
            state.all = []
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data.data.results;
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.all = [];
            console.dir(error);
        },
        getBaseLanguagesRequest(state) {
            state.baseLanguages = []
            state.loading = true;
        },
        getBaseLanguagesSuccess(state, data) {
            state.baseLanguages = data.data.results;
            state.loading = false;
        },
        getBaseLanguagesFailure(state, error) {
            state.baseLanguages = [];
            console.dir(error);
        },
    }
}
