<style scoped>
.isDone,
.isDone * {
  opacity: 0.5;
}
</style>
<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <section class="section is-main-section">

      <b-tabs v-model="activeTab" type="is-boxed">

        <b-tab-item label="External Agency & Agency Feed (Daily)" value="1">

          <dependant-multi-filter
              v-model="selectedMultiFilter"
              :default-range-date="defaultDateRange"
              range-date-enabled
              agency-feeds-enabled
              @filter="filterPartnerRevenue"
          ></dependant-multi-filter>

          <b-table
              :loading="partnerRevenueIsLoading"
              :striped="true"
              :hoverable="true"
              default-sort="external_agency"
              :data="partnerRevenueData"
          >
            <b-table-column label="Date" field="date" sortable v-slot="props">
              {{ props.row.date }}
            </b-table-column>
            <b-table-column label="Name" field="external_agency" sortable v-slot="props">
              {{ props.row.external_agency.organization.name }}
            </b-table-column>
            <b-table-column label="Agency Feed" field="partner.agency_feed.code" sortable v-slot="props">
              {{ props.row.external_agency.partner.agency_feed.code }}
            </b-table-column>
            <b-table-column label="Gross" field="revenue_amount_gross" sortable v-slot="props" :custom-sort="sortRevenueAmountGross">
              {{ renderCurrency(props.row.revenue_amount_gross, 2) }}
            </b-table-column>
            <b-table-column label="Feed (rev share)" field="revenue_amount_feed" sortable v-slot="props" :custom-sort="sortRevenueAmountFeed">
              {{ renderCurrency(props.row.revenue_amount_feed, 2) }}
            </b-table-column>
            <b-table-column label="Partner (rev share)" field="revenue_amount_partner" sortable v-slot="props" :custom-sort="sortRevenueAmountPartner">
              {{ renderCurrency(props.row.revenue_amount_partner, 2) }}
            </b-table-column>
            <b-table-column label="Partner Agent (rev share)" field="revenue_amount_partner_agent" sortable v-slot="props" :custom-sort="sortRevenueAmountPartnerAgent">
              {{ renderCurrency(props.row.revenue_amount_partner_agent, 2) }}
            </b-table-column>
            <b-table-column label="Agency (rev share)" field="revenue_amount_agency" sortable v-slot="props" :custom-sort="sortRevenueAmountAgency">
              {{ renderCurrency(props.row.revenue_amount_agency, 2) }}
            </b-table-column>
            <b-table-column label="Agency Agent (rev share)" field="revenue_amount_agency_agent" sortable v-slot="props" :custom-sort="sortRevenueAmountAgencyAgency">
              {{ renderCurrency(props.row.revenue_amount_agency_agent, 2) }}
            </b-table-column>
            <b-table-column label="Net" field="revenue_amount_net" sortable v-slot="props" :custom-sort="sortRevenueAmountNet">
              {{ renderCurrency(props.row.revenue_amount_net, 2) }}
            </b-table-column>
            <b-table-column label="Net(Us)" field="revenue_amount_net" sortable v-slot="props" :custom-sort="sortRevenueAmountNetUs">
              {{ renderCurrency(props.row.revenue_amount_net * props.row.monthly_profit_share, 2) }} ({{ renderPercentage(props.row.monthly_profit_share, 0) }})
            </b-table-column>
            <b-table-column label="Clicks(in)" field="clicks_in" sortable v-slot="props" :custom-sort="sortClicksIn">
              {{ renderNumber(props.row.clicks_in, 0) }}
            </b-table-column>
            <b-table-column label="Clicks(out)" field="clicks_out" sortable v-slot="props" :custom-sort="sortClicksOut">
              {{ renderNumber(props.row.clicks_out, 0) }}
            </b-table-column>
            <b-table-column label="CTR" field="ctr" sortable v-slot="props">
              {{ renderPercentage(props.row.ctr, 2) }}
            </b-table-column>
          </b-table>

        </b-tab-item>


        <b-tab-item label="External Agency & Agency Feed" value="2">

          <dependant-multi-filter
              v-model="selectedMultiFilter"
              :default-range-date="defaultDateRange"
              range-date-enabled
              agency-feeds-enabled
              @filter="filterPartnerRevenueExternalAgency"
          ></dependant-multi-filter>

          <b-table
              :loading="partnerRevenueExternalAgencyIsLoading"
              :striped="true"
              :hoverable="true"
              default-sort="external_agency"
              :data="partnerRevenueExternalAgencyData"
          >
            <b-table-column label="Date" field="date" sortable v-slot="props">
              {{ props.row.min_date }}/{{ props.row.max_date }}
            </b-table-column>
            <b-table-column label="Name" field="external_agency" sortable v-slot="props">
              {{ props.row.external_agency.organization.name }}
            </b-table-column>
            <b-table-column label="Agency Feed" field="partner.agency_feed.code" sortable v-slot="props">
              {{ props.row.external_agency.partner.agency_feed.code }}
            </b-table-column>
            <b-table-column label="Gross" field="revenue_amount_gross" sortable v-slot="props" :custom-sort="sortRevenueAmountGross">
              {{ renderCurrency(props.row.revenue_amount_gross, 2) }}
            </b-table-column>
            <b-table-column label="Feed (rev share)" field="revenue_amount_feed" sortable v-slot="props" :custom-sort="sortRevenueAmountFeed">
              {{ renderCurrency(props.row.revenue_amount_feed, 2) }}
            </b-table-column>
            <b-table-column label="Partner (rev share)" field="revenue_amount_partner" sortable v-slot="props" :custom-sort="sortRevenueAmountPartner">
              {{ renderCurrency(props.row.revenue_amount_partner, 2) }}
            </b-table-column>
            <b-table-column label="Partner Agent (rev share)" field="revenue_amount_partner_agent" sortable v-slot="props" :custom-sort="sortRevenueAmountPartnerAgent">
              {{ renderCurrency(props.row.revenue_amount_partner_agent, 2) }}
            </b-table-column>
            <b-table-column label="Agency (rev share)" field="revenue_amount_agency" sortable v-slot="props" :custom-sort="sortRevenueAmountAgency">
              {{ renderCurrency(props.row.revenue_amount_agency, 2) }}
            </b-table-column>
            <b-table-column label="Agency Agent (rev share)" field="revenue_amount_agency_agent" sortable v-slot="props" :custom-sort="sortRevenueAmountAgencyAgency">
              {{ renderCurrency(props.row.revenue_amount_agency_agent, 2) }}
            </b-table-column>
            <b-table-column label="Net" field="revenue_amount_net" sortable v-slot="props" :custom-sort="sortRevenueAmountNet">
              {{ renderCurrency(props.row.revenue_amount_net, 2) }}
            </b-table-column>
            <b-table-column label="Net(Us)" field="revenue_amount_net" sortable v-slot="props" :custom-sort="sortRevenueAmountNetUs">
              {{ renderCurrency(props.row.revenue_amount_net * props.row.monthly_profit_share, 2) }} ({{ renderPercentage(props.row.monthly_profit_share, 0) }})
            </b-table-column>
            <b-table-column label="Clicks(in)" field="clicks_in" sortable v-slot="props" :custom-sort="sortClicksIn">
              {{ renderNumber(props.row.clicks_in, 0) }}
            </b-table-column>
            <b-table-column label="Clicks(out)" field="clicks_out" sortable v-slot="props" :custom-sort="sortClicksOut">
              {{ renderNumber(props.row.clicks_out, 0) }}
            </b-table-column>
            <b-table-column label="CTR" field="ctr" sortable v-slot="props">
              {{ renderPercentage(props.row.ctr, 2) }}
            </b-table-column>
          </b-table>

        </b-tab-item>


        <b-tab-item label="Agency Feed" value="3">

          <dependant-multi-filter
              v-model="selectedMultiFilter"
              :default-range-date="defaultDateRange"
              range-date-enabled
              agency-feeds-enabled
              @filter="filterPartnerRevenuePartnerAgency"
          ></dependant-multi-filter>

          <b-table
              :loading="partnerRevenuePartnerAgencyIsLoading"
              :striped="true"
              :hoverable="true"
              default-sort="external_agency"
              :data="partnerRevenuePartnerAgencyData"
          >
            <b-table-column label="Date" field="date" sortable v-slot="props">
              {{ props.row.min_date }}/{{ props.row.max_date }}
            </b-table-column>
            <b-table-column label="Name" field="agency_feed.code" sortable v-slot="props">
              {{ props.row.agency_feed.code }}
            </b-table-column>
            <b-table-column label="Gross" field="revenue_amount_gross" sortable v-slot="props" :custom-sort="sortRevenueAmountGross">
              {{ renderCurrency(props.row.revenue_amount_gross, 2) }}
            </b-table-column>
            <b-table-column label="Feed (rev share)" field="revenue_amount_feed" sortable v-slot="props" :custom-sort="sortRevenueAmountFeed">
              {{ renderCurrency(props.row.revenue_amount_feed, 2) }}
            </b-table-column>
            <b-table-column label="Partner (rev share)" field="revenue_amount_partner" sortable v-slot="props" :custom-sort="sortRevenueAmountPartner">
              {{ renderCurrency(props.row.revenue_amount_partner, 2) }}
            </b-table-column>
            <b-table-column label="Partner Agent (rev share)" field="revenue_amount_partner_agent" sortable v-slot="props" :custom-sort="sortRevenueAmountPartnerAgent">
              {{ renderCurrency(props.row.revenue_amount_partner_agent, 2) }}
            </b-table-column>
            <b-table-column label="Agency (rev share)" field="revenue_amount_agency" sortable v-slot="props" :custom-sort="sortRevenueAmountAgency">
              {{ renderCurrency(props.row.revenue_amount_agency, 2) }}
            </b-table-column>
            <b-table-column label="Agency Agent (rev share)" field="revenue_amount_agency_agent" sortable v-slot="props" :custom-sort="sortRevenueAmountAgencyAgency">
              {{ renderCurrency(props.row.revenue_amount_agency_agent, 2) }}
            </b-table-column>
            <b-table-column label="Net" field="revenue_amount_net" sortable v-slot="props" :custom-sort="sortRevenueAmountNet">
              {{ renderCurrency(props.row.revenue_amount_net, 2) }}
            </b-table-column>
            <b-table-column label="Net(Us)" field="revenue_amount_net" sortable v-slot="props" :custom-sort="sortRevenueAmountNetUs">
              {{ renderCurrency(props.row.revenue_amount_net * props.row.monthly_profit_share, 2) }} ({{ renderPercentage(props.row.monthly_profit_share, 0) }})
            </b-table-column>
            <b-table-column label="Clicks(in)" field="clicks_in" sortable v-slot="props" :custom-sort="sortClicksIn">
              {{ renderNumber(props.row.clicks_in, 0) }}
            </b-table-column>
            <b-table-column label="Clicks(out)" field="clicks_out" sortable v-slot="props" :custom-sort="sortClicksOut">
              {{ renderNumber(props.row.clicks_out, 0) }}
            </b-table-column>
            <b-table-column label="CTR" field="ctr" sortable v-slot="props">
              {{ renderPercentage(props.row.ctr, 2) }}
            </b-table-column>
          </b-table>

        </b-tab-item>

        <b-tab-item label="External Agency (AdUnits)" value="4">

          <dependant-multi-filter
              v-model="selectedMultiFilter"
              :default-range-date="defaultDateRange"
              range-date-enabled
              agency-feeds-enabled
              @filter="filterExternalAgencyAdUnitAgency"
          ></dependant-multi-filter>

          <b-table
              :loading="externalAgencyAdUnitIsLoading"
              :striped="true"
              :hoverable="true"
              default-sort="external_agency"
              :data="externalAgencyAdUnitData"
          >
            <b-table-column label="Date" field="date" sortable v-slot="props">
              {{ props.row.date }}
            </b-table-column>
            <b-table-column label="Name" field="external_agency.organization.name" sortable v-slot="props">
              {{ props.row.external_agency.organization.name }}
            </b-table-column>
            <b-table-column label="AdUnit" field="adunit" sortable v-slot="props">
              {{ props.row.adunit.code }}
            </b-table-column>
            <b-table-column label="Agency Feed" field="partner.agency_feed.code" sortable v-slot="props">
              {{ props.row.external_agency.partner.agency_feed.code }}
            </b-table-column>
            <b-table-column label="Gross" field="revenue_amount_gross" sortable v-slot="props" :custom-sort="sortRevenueAmountGross">
              {{ renderCurrency(props.row.revenue_amount_gross, 2) }}
            </b-table-column>
            <b-table-column label="Feed (rev share)" field="revenue_amount_feed" sortable v-slot="props" :custom-sort="sortRevenueAmountFeed">
              {{ renderCurrency(props.row.revenue_amount_feed, 2) }}
            </b-table-column>
            <b-table-column label="Partner (rev share)" field="revenue_amount_partner" sortable v-slot="props" :custom-sort="sortRevenueAmountPartner">
              {{ renderCurrency(props.row.revenue_amount_partner, 2) }}
            </b-table-column>
            <b-table-column label="Partner Agent (rev share)" field="revenue_amount_partner_agent" sortable v-slot="props" :custom-sort="sortRevenueAmountPartnerAgent">
              {{ renderCurrency(props.row.revenue_amount_partner_agent, 2) }}
            </b-table-column>
            <b-table-column label="Agency (rev share)" field="revenue_amount_agency" sortable v-slot="props" :custom-sort="sortRevenueAmountAgency">
              {{ renderCurrency(props.row.revenue_amount_agency, 2) }}
            </b-table-column>
            <b-table-column label="Agency Agent (rev share)" field="revenue_amount_agency_agent" sortable v-slot="props" :custom-sort="sortRevenueAmountAgencyAgency">
              {{ renderCurrency(props.row.revenue_amount_agency_agent, 2) }}
            </b-table-column>
            <b-table-column label="Net" field="revenue_amount_net" sortable v-slot="props" :custom-sort="sortRevenueAmountNet">
              {{ renderCurrency(props.row.revenue_amount_net, 2) }}
            </b-table-column>
            <b-table-column label="Clicks(in)" field="clicks_in" sortable v-slot="props" :custom-sort="sortClicksIn">
              {{ renderNumber(props.row.clicks_in, 0) }}
            </b-table-column>
            <b-table-column label="Clicks(out)" field="clicks_out" sortable v-slot="props" :custom-sort="sortClicksOut">
              {{ renderNumber(props.row.clicks_out, 0) }}
            </b-table-column>
            <b-table-column label="CTR" field="ctr" sortable v-slot="props">
              {{ renderPercentage(props.row.clicks_out / props.row.clicks_in, 2) }}
            </b-table-column>
          </b-table>

        </b-tab-item>
      </b-tabs>

    </section>
  </div>
</template>

<script>

import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {performancePartnerRevenuesService} from "@/services/performance-partner-revenues.service";
import moment from "moment/moment";
import {numberUtils} from "@/helpers/number-utils";
import {
  performancePartnerRevenuesExternalAgencyService
} from "@/services/performance-partner-revenues-external-agency.service";
import numeral from 'numeral';
import {
  performancePartnerRevenuesPartnerAgencyService
} from "@/services/performance-partner-revenues-partner-agency.service";
import {performanceAdunitRevenuesService} from "@/services/performance-adunit-revenues.service";

export default {
  name: 'ExternalAgenciesRevenue',

  mounted() {
    // performancePartnerRevenuesExternalAgencyService.get()
    // performancePartnerRevenuesExternalPartnerService.get()
    // performancePartnerRevenuesPartnerAgencyService.get()
  },

  mixins: [
      numberUtils,
  ],
  components: {
    DependantMultiFilter
  },

  data() {
    return {

      activeTab: 1,

      isLoading: false,

      selectedMultiFilter: {
        'agencyFeeds': [],
        'countries': [],
        'accounts': [],
        'marketingUsers': [],
      },

      partnerRevenueData: [],
      partnerRevenueIsLoading: false,

      partnerRevenueExternalAgencyData: [],
      partnerRevenueExternalAgencyIsLoading: false,

      partnerRevenuePartnerAgencyData: [],
      partnerRevenuePartnerAgencyIsLoading: false,


      externalAgencyAdUnitData: [],
      externalAgencyAdUnitIsLoading: false,

      defaultDateRange: [
          moment().subtract('1 day').toDate(),
          moment().subtract('1 day').toDate(),
      ]

    }
  },

  computed: {

  },

  methods: {

    filterPartnerRevenue() {

      let dates = this.selectedMultiFilter['rangeDate'];

      this.partnerRevenueIsLoading = true;

      performancePartnerRevenuesService.get({
        'partner__agency_feed__in': this.selectedMultiFilter['agencyFeeds'].map((e) => e.id).join(','),
        'external_agency__isnull': false,
        'date__range': moment(dates[0]).format('YYYY-MM-DD') + ',' + moment(dates[1]).format('YYYY-MM-DD'),
      }).then((e) => {
        this.partnerRevenueIsLoading = false;
        this.partnerRevenueData = e.data.results;
      })

    },
    filterPartnerRevenueExternalAgency() {

      let dates = this.selectedMultiFilter['rangeDate'];

      this.partnerRevenueExternalAgencyIsLoading = true;

      performancePartnerRevenuesExternalAgencyService.get({
        'partner__agency_feed__in': this.selectedMultiFilter['agencyFeeds'].map((e) => e.id).join(','),
        'external_agency__isnull': false,
        'date__range': moment(dates[0]).format('YYYY-MM-DD') + ',' + moment(dates[1]).format('YYYY-MM-DD'),
      }).then((e) => {
        this.partnerRevenueExternalAgencyIsLoading = false;
        this.partnerRevenueExternalAgencyData = e.data.results;
      })

    },

    filterPartnerRevenuePartnerAgency() {

      let dates = this.selectedMultiFilter['rangeDate'];

      this.partnerRevenuePartnerAgencyIsLoading = true;

      performancePartnerRevenuesPartnerAgencyService.get({
        'partner__agency_feed__in': this.selectedMultiFilter['agencyFeeds'].map((e) => e.id).join(','),
        'external_agency__isnull': false,
        'date__range': moment(dates[0]).format('YYYY-MM-DD') + ',' + moment(dates[1]).format('YYYY-MM-DD'),
      }).then((e) => {
        this.partnerRevenuePartnerAgencyIsLoading = false;
        this.partnerRevenuePartnerAgencyData = e.data.results;
      })

    },

    filterExternalAgencyAdUnitAgency() {

      let dates = this.selectedMultiFilter['rangeDate'];

      this.externalAgencyAdUnitIsLoading = true;

      performanceAdunitRevenuesService.get({
        'adunit__partner__agency_feed__in': this.selectedMultiFilter['agencyFeeds'].map((e) => e.id).join(','),
        'external_agency__isnull': false,
        'date__range': moment(dates[0]).format('YYYY-MM-DD') + ',' + moment(dates[1]).format('YYYY-MM-DD'),
      }).then((e) => {
        this.externalAgencyAdUnitIsLoading = false;
        this.externalAgencyAdUnitData = e.data.results;
      })

    },

    sortRevenueAmountGross(a, b, isAsc){
      return this.sortNumber('revenue_amount_gross', a, b, isAsc);
    },

    sortRevenueAmountFeed(a, b, isAsc){
      return this.sortNumber('revenue_amount_feed', a, b, isAsc);
    },

    sortRevenueAmountPartner(a, b, isAsc){
      return this.sortNumber('revenue_amount_partner', a, b, isAsc);
    },

    sortRevenueAmountPartnerAgent(a, b, isAsc){
      return this.sortNumber('revenue_amount_partner_agent', a, b, isAsc);
    },

    sortRevenueAmountAgency(a, b, isAsc){
      return this.sortNumber('revenue_amount_agency', a, b, isAsc);
    },

    sortRevenueAmountAgencyAgency(a, b, isAsc){
      return this.sortNumber('revenue_amount_agency_agent', a, b, isAsc);
    },

    sortRevenueAmountNet(a, b, isAsc){
      return this.sortNumber('revenue_amount_net', a, b, isAsc);
    },

    sortRevenueAmountNetUs(a, b, isAsc){

      a = numeral(a['revenue_amount_net']).value() * numeral(a['monthly_profit_share']).value()
      b = numeral(b['revenue_amount_net']).value() * numeral(b['monthly_profit_share']).value()

      return this.sortNumber(null, a, b, isAsc);
    },

    sortClicksIn(a, b, isAsc){
      return this.sortNumber('clicks_in', a, b, isAsc);
    },

    sortClicksOut(a, b, isAsc){
      return this.sortNumber('clicks_out', a, b, isAsc);
    },

    sortNumber(fieldName, a, b, isAsc){

      if(fieldName){
        a = numeral(a[fieldName]).value();
        b = numeral(b[fieldName]).value();
      }

      if(isAsc){
        return a < b;
      }else{
        return a > b;
      }

    }

  }
}
</script>
