import { config } from './config';
import axios from "axios";


export const administrationBiddingStrategyService = {
    getAll,
    config
};


export function getAll(filters) {

    let axiosConfig = {}

    filters = filters || null;

    if(filters){
        let params = {};

        params['is_active'] = true;

        if('dealer' in filters){
            params['dealer'] = filters['dealer'];
        }

        // Append to request.
        axiosConfig['params'] = params;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/bidding-strategies/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}