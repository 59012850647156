<template>
    <section class="has-text-centered mx-auto p-3">
        <img :src="require('@/assets/systems-under-maintenance.gif')" alt="Focused developer troubleshooting system maintenance on laptop and monitor" />
        <p>Maintenance in progress. Come back {{ maintenanceStopTimeInUTC ? "in:" : "latter."}}</p>
        <div v-if="maintenanceStopTimeInUTC" class="timer">
            <code>{{ timer }}</code>
        </div>
    </section>
</template>

<script>
import moment from 'moment';
export default {
    name: 'SystemUnderMaintenance',

    data() {
        return {
            timer: null,
        }
    },

    created() {
        if(!this.maintenanceStopTimeInUTC) return;

        const endTime = moment.utc(this.maintenanceStopTimeInUTC)
        setInterval(() => {
            if (endTime <= moment().utc()) return this.timer = "00:00:00"
            this.timer = moment.utc(moment(endTime, "YYYY-MM-DD HH:mm:ss").diff(moment(moment().utc(), "YYYY-MM-DD HH:mm:ss"))).format("HH:mm:ss")
        }, 1000)

    },

    computed: {
        maintenanceStopTimeInUTC() {
            return this.$store.state.theme.maintenanceStopTimeInUTC
        }
    },

    beforeDestroy() {
        clearInterval(this.timer) 
    },
}
</script>

<style lang="scss" scoped>
section {
    max-width: 600px;
    min-height: 400px;
    background-color: #fff;
    box-shadow: 1px 1px 10px 0px #ccc;
    border-radius: 7px;
    margin-top: 10%;
    font-family: serif;
    p {
        font-size: 1.2rem;
    }
    code {
        font-size: 2.5rem;
        background-color: transparent;
    }
}
</style>