import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { theme } from './theme.module';
import { users } from './users.module';
import { networks } from './administration-networks.module';
import { toolsImageSearcher } from './tools-image-searcher.module';
import { products } from "./administration-products.module";
import { facebookPages } from "./administration-facebook-pages.module";
import { tqScores } from "./administration-tq-scores.module";
import { accounts } from "./administration-accounts.module";
import { biddingStrategies } from "./administration-bidding-strategy.module";
import { agencyFeeds } from "./administration-agency-feeds.module";
import { internalAgencyManager } from './internal-agency-manager.module';
import { subdomainLocales } from './administration-subdomain-locales.module';
import { partners } from "./administration-partners.module";
import { accessGroups } from './administration-access-groups.module'
import { adunits } from './administration-available-adunit.module'

import { hourlyPerformances } from "./optimization-hourly-performances.module";
import { dashboardsOverviewPerformances } from "./optimization-dashboards-overview-performances.module";
import { dashboardsChartPerformances } from "./optimization-dashboards-chart-performances.module";
import { dashboardsChartKeywords } from "./optimization-dashboards-chart-keywords.module";
import { dashboardsReportPerformances } from "./optimization-dashboards-report-performances.module";
import { dashboardsReportKeywordsPerformances } from "./optimization-dashboards-report-keywords-performances.module";

import { foundKeywordImages } from "./creation-found-keyword-images.module";

import { logOptimizationGroups } from "./optimization-log-optimization-groups.module";
import { logDeletionGroups } from "./optimization-log-deletion-groups.module";

import { hemingwayDomainKeywords } from "./hemingway-domain-keyword.module";

import { countries } from "./dev-countries.module";
import { currencies } from "./administration-account-currencies.module";
import { languages } from "./dev-languages.module";
import {dashboardsKpi} from "@/store/optimization-dashboards-kpi.module";
import {dailyPerformances} from "@/store/daily-optimization-performances.module";

import { optimizationAutomation } from "@/store/optimization-automation.module";
import { myProfile } from '@/store/my-profile.module'

import { userStorage } from '@/store/user-storage.module'
import {foundKeywordVideos} from "@/store/creation-found-keyword-videos.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        authentication,
        theme,
        users,
        networks,
        toolsImageSearcher,
        products,
        facebookPages,
        tqScores,
        accounts,
        biddingStrategies,
        agencyFeeds,
        internalAgencyManager,
        dailyPerformances,
        hourlyPerformances,
        dashboardsOverviewPerformances,
        dashboardsChartKeywords,
        dashboardsKpi,
        dashboardsChartPerformances,
        dashboardsReportPerformances,
        dashboardsReportKeywordsPerformances,
        foundKeywordImages,
        foundKeywordVideos,
        logOptimizationGroups,
        logDeletionGroups,
        hemingwayDomainKeywords,
        countries,
        languages,
        optimizationAutomation,
        myProfile,
        userStorage,
        subdomainLocales,
        partners,
        accessGroups,
        currencies,
        adunits,
    }
});
