<template>
    <div>
        <form @submit.prevent>
            <b-field @input.native="val => isValidLength(val)">
                <b-input
                    type="password"
                    placeholder="Old Password"
                    v-model="oldPassword"
                    minlength="6"
                    required>
                </b-input>
            </b-field>

            <b-field @input.native="val => isValidLength(val)">
                <b-input
                    type="password"
                    placeholder="New Password"
                    v-model="newPassword"
                    minlength="6"
                    required>
                </b-input>
            </b-field>

            <b-field
                @input.native="val => isValidLength(val)"
                :type="errorMsg.type === 'match' ? 'is-danger' : null"
                :message="errorMsg.type === 'match' ? errorMsg.content : ''" 
            >
                <b-input
                    type="password"
                    placeholder="Confirm New Password"
                    v-model="repeatNewPassword"
                    minlength="6"
                    required>
                </b-input>
            </b-field>
            <p v-if="errorMsg.type === 'general'" class="notification is-danger is-light py-2 px-2 my-2">
                <ul class="m-0 pl-4" style="list-style: circle;">
                    <li v-for="(er, i) in Object.entries(errorMsg.content)" :key="i">
                        <h4><strong>{{ capitalizeStr(er[0]) }}:</strong></h4>
                        <ul class="m-0 pl-4" style="list-style: square;">
                            <li v-for="(e, ii) in er[1]" :key="ii">{{ e }}</li>
                        </ul>
                    </li>
                </ul>
            </p>
            <b-button size="is-medium" type="is-info" @click="submit" :disabled="disableSave" :loading="myProfile.loading">Save</b-button>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'ProfilePagePassword',

    data() {
        return {
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            disableSave: true,
            errorMsg: {
                type: '',
                content: '',
            },
        }
    },

    computed: {
        ...mapState(['myProfile']),

        isMatchedPassword() {
            const {  newPassword, repeatNewPassword } = this

            if(newPassword && repeatNewPassword) {
                if(newPassword === repeatNewPassword) {
                    return { empty: false, error: false }
                }
                return { empty: false, error: true }
            }
            return { empty: true, error: true }
        }
    },

    methods: {
        submit() {
            if(this.disableSave) return
            if(this.newPassword !== this.repeatNewPassword) {
                this.errorMsg.type = 'match'
                this.errorMsg.content = 'Please make sure passwords match'
                return;
            } else {
                this.errorMsg.type = ''
                this.errorMsg.content = ''
            }

            this.$store.dispatch('myProfile/changeUserInfo', { old_password: this.oldPassword, new_password: this.newPassword })
                .then(() => {
                    this.$emit('customDestroy')
                    this.$buefy.snackbar.open({
                        message: 'Password updated Successfully!',
                        queue: false,
                    })

                    this.$store.dispatch('authentication/logout');
                    window.location.reload();
                })
                .catch(er => {
                    this.errorMsg.type = 'general'
                    this.errorMsg.content = er.response.data
                })
        },

        isValidLength(val) {
            if (val.target.value.length >= 6) {
                if(this.oldPassword.length >= 6 && this.newPassword.length >= 6 && this.repeatNewPassword.length >= 6) {
                    this.disableSave = false
                }
            } else {
                this.disableSave = true
            }
        },

        capitalizeStr(str) {
            str = str.split('_')
            let a = []
            for(const s in str) {
                a.push(str[s].charAt(0).toUpperCase() + str[s].slice(1))
            }
            return a.join(' ')
        },
    }
}
</script>