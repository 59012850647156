import { config } from './config';
import axios from "axios";

export const creationFoundKeywordImagesService = {
    post,
    get,
    patch,
    config
};

function get(params){

    let axiosConfig = {}

    let endpoint;

    // Append to request.
    axiosConfig['params'] = params;

    endpoint = '/api/creation/found-keyword-images/';

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function post(params){

    let endpoint;

    endpoint = '/api/creation/found-keyword-images/';

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}${endpoint}`, params)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function patch(id, params){

    let endpoint = '/api/creation/found-keyword-images/' + id + '/';

    return new Promise((resolve, reject) => {
      axios
        .patch(`${config.apiUrl}${endpoint}`, params)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

