<template>
    <b-modal
        v-model="show"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        :aria-label="title"
        close-button-aria-label="Close"
        :class="['custom-modal-wrapper', classes]"
        :can-cancel="canCancel"
        aria-modal>
        
        <header class="modal-card-head">
                <p class="modal-card-title">{{ title }}</p>
        </header>
        <div class="modal-card" :style="customStyle">
            <slot></slot>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'CustomModalWrapper',

    props: {
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        customStyle: {
            type: String,
        },
        classes: {
            type: String,
        },
        canCancel: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        show(val) {
            this.aa = val
            this.$emit('close', val)
        }
    },
}
</script>

<style lang="scss">
.modal.custom-modal-wrapper {
    z-index: 999 !important;
    .animation-content {
        max-width: unset !important;
        .modal-card {
            overflow: unset !important;
                .modal-card-body {
                overflow: unset;
            }
        }
    }
}

.modal.custom-modal-wrapper.ads_plus {
    .modal-background {
        background-color: rgba(10, 10, 10, 0.7) !important
    }
}

@media screen and (max-width: 768px){
    .modal .animation-content {
        .modal-card-head,
        .modal-card {
            width: 90vw;
            margin: 0 auto;
        }
    }
    
}
</style>
