<template>
  <div>
    <b-loading v-model="loading" />
    <section class="section is-main-section">
      <b-field label="New configuration">
        <b-select v-model="network">
          <option v-for="s in networks['all']" :value="s.id" v-bind:key="`networks-${s.id}`">
            {{ s.name }}
          </option>
        </b-select>
        <p class="control">
          <b-button @click="createNewConfiguration" class="is-primary mb-5" icon-left="plus-box">
            Continue
          </b-button>
        </p>
      </b-field>

      <dependant-multi-filter
          v-model="selectedMultiFilter"
          networks-enabled
          agency-feeds-enabled
          marketing-users-enabled
          @filter="fetchSettings"
      ></dependant-multi-filter>

      <b-table class="table" striped>

        <thead>

          <tr>

            <th></th>
            <th>Description</th>
            <th>User</th>
            <th>Network</th>
            <th>Agency Feed</th>
            <th>Type</th>

          </tr>

        </thead>

        <tbody>

          <tr v-for="item in items" v-bind:key="`items-${item.id}`">

            <td><b-button @click="openItemForm(item.id, item.network)">Edit</b-button></td>
            <td>{{ item.description }}</td>
            <td>{{ item.user.username }}</td>
            <td>{{ getNetworkById(item.network).name }}</td>
            <td>{{ getAgencyFeedById(item.agency_feed)[getFieldCodeLabel()] }}</td>
            <td>{{ item.launching_type || '-'  }}</td>

          </tr>

        </tbody>

      </b-table>

      <b-modal :active.sync="isModalActive" has-modal-card full-screen can-cancel>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Settings</p>
          </header>
          <section class="modal-card-body">

            <creating-setting-facebook-form v-if="networkCode === 'FBA'" @cancel="cancelModal" @saved="savedModal" :network="network" :id="itemId"></creating-setting-facebook-form>
            <creating-setting-google-display-form v-else-if="networkCode === 'GDN'" @cancel="cancelModal" @saved="savedModal" :network="network" :id="itemId"></creating-setting-google-display-form>
            <creating-setting-google-discovery-form v-else-if="networkCode === 'GD'" @cancel="cancelModal" @saved="savedModal" :network="network" :id="itemId"></creating-setting-google-discovery-form>
            <creating-setting-outbrain-form v-else-if="networkCode === 'OBN'" @cancel="cancelModal" @saved="savedModal" :network="network" :id="itemId"></creating-setting-outbrain-form>
            <div v-else>
              Network not supported yet.
            </div>

          </section>
        </div>
      </b-modal>

    </section>
  </div>
</template>

<script>

import CreatingSettingGoogleDisplayForm from "@/pages/admin/form/CreationSettingGoogleDisplayForm";
import CreatingSettingFacebookForm from "@/pages/admin/form/CreationSettingFacebookForm";

import CreatingSettingGoogleDiscoveryForm from "@/pages/admin/form/CreationSettingGoogleDiscoveryForm";
import {creationSettingsService} from "@/services/creation-creation-settings.service";
import {mapState} from "vuex";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {permissionsUtils} from "@/helpers/permissions-utils";
import CreatingSettingOutbrainForm from "@/pages/admin/form/CreationSettingOutbrainForm.vue";

export default {
  name: 'LaunchingSettingsPage',

  created() {
    this.fetchSettings()
    if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll');
    if(!this.networks.all.length) this.$store.dispatch('networks/getAll');
    if(!this.countries.all.length) this.$store.dispatch('countries/getAll');
  },

  components: {
    CreatingSettingOutbrainForm,
    DependantMultiFilter,
    CreatingSettingGoogleDiscoveryForm,
    CreatingSettingGoogleDisplayForm,
    CreatingSettingFacebookForm,
  },

  mixins: [
    permissionsUtils,
  ],

  data () {

    return {
      loading: false,
      isModalActive: false,
      items: [],
      itemId: null,
      networkCode: null,
      network: null,

      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'marketingUsers': []
      }
    }
  },

  computed: {
    ...mapState([
      'agencyFeeds',
      'networks',
      'countries',
    ])
  },
  methods: {

    openItemForm(id, network) {

      id = id || null;

      this.itemId = id;
      this.isModalActive = true;
      this.network = network;
      if(network){
        this.networkCode = this.networks['all'].filter((e) => e.id === network)[0].code;
      }

    },

    createNewConfiguration() {
      if(!this.network){
        return alert('Please select a network in order to continue');
      }

      this.openItemForm(null, this.network);
    },

    fetchSettings() {
      let that = this;
      this.loading = true;

      creationSettingsService.get({
        'network__in': this.selectedMultiFilter['networks'].map((element) => {
          return element.id
        }).join(','),
        'agency_feed__in': this.selectedMultiFilter['agencyFeeds'].map((element) => {
          return element.id
        }).join(','),
        'user__in': this.selectedMultiFilter['marketingUsers'].map((element) => {
          return element.id
        }).join(','),
      }).then(response => {
        that.items = response.data['results']
        this.loading = false
      }).catch(r => {
        alert('An Error Occurred.')
        this.loading = false
        console.dir(r);
      })
    },

    cancelModal(){
      this.isModalActive = false;
    },

    savedModal(){
      this.isModalActive = false;
      this.$buefy.snackbar.open('Saved!');
      this.fetchSettings() //reload
    },

    getAgencyFeedById(id){

      let agencyFeed = this.agencyFeeds['all'].filter((e) => e.id === id);

      if(agencyFeed.length === 0){
        return {}
      }

      return agencyFeed[0]
    },

    getNetworkById(id){

      let network = this.networks['all'].filter((e) => e.id === id);

      if(network.length === 0){
        return {}
      }

      return network[0]
    },

  }
}
</script>
