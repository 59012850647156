import { config } from './config';
import axios from "axios";

export const optimizationDashboardsReportKeywordsPerformancesService = {
    getByDateRange,
    config
};

export function getByDateRange(fromDate, toDate, pageNumber, ordering){

    let axiosConfig = {}, params = {};

    params['date__range'] = fromDate + ',' + toDate;
    params['page'] = pageNumber;
    if(ordering){
        params['ordering'] = ordering;
    }

    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/performance/dashboards-report-keywords-performances/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}