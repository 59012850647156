<template>
  <div>
    <section>
      <b-steps
          v-model="activeStep"
          :animated="true"
          :rounded="true"
          :has-navigation="true"
          @input="stepChanged"
          label-position="bottom"
          mobile-mode="minimalist">

          <b-step-item step="1" label="Main Details" :clickable="false">
            <card-component>
            <h3 class="title has-text-centered">Main Details</h3>
            <b-field label="Agency Feed">
              <b-select v-model="agencyFeed">
                <option value="CBSI">CBSI</option>
              </b-select>
            </b-field>
            <b-field label="Domain">
              <b-input v-model="productDomain"></b-input> *Include http and path: https://www.website.com/search
            </b-field>
            <b-field label="Name">
              <b-input v-model="productName" @input="productName=makeTitle($event)"></b-input>
            </b-field>
            <b-field label="Code">
              <b-input v-model="productCode"></b-input>
            </b-field>
            <h2>Layout</h2>
            <div class="block">
              <b-radio v-model="layoutType"
                  name="layoutType"
                  native-value="CBSI_BING">
                  CBSI Bing
              </b-radio>
              <b-radio v-model="layoutType"
                  name="layoutType"
                  native-value="IAC_BING">
                  IAC Bing
              </b-radio>
            </div>
            </card-component>
            <card-component v-if="layoutType === 'CBSI_BING'">

            <div>
              <div class="block">
                <h3>CBSI Layout Options:</h3>
                <b-radio v-model="domainStructure"
                    name="domainStructure"
                    native-value="LOCALE_SUBDOMAIN">
                    Locale Subdomain
                </b-radio>
                <b-radio v-model="domainStructure"
                    name="domainStructure"
                    native-value="LOCALE_FOLDER_BASED">
                    Locale Folder Based
                </b-radio>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Type Tag">
                    <b-input v-model="typeTagInit"></b-input>
                    <b-input v-model="typeTagNumeric"></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Range">
                    <b-input v-model="rangeStarts"></b-input>
                    <b-input v-model="rangeEnds"></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Networks">
                    <div>
                      <b-select v-model="selectedNetworks" multiple>
                        <option
                          v-for="option in networks"
                          :value="option.id"
                          :key="option.id">
                          {{ option.name }}
                        </option>
                      </b-select>
                    </div>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Url Parameters">
                    <div>
                      <b-select v-model="selectedUrlParameters" multiple>
                        <option
                          v-for="option in urlParameters"
                          :value="option.id"
                          :key="option.id">
                          {{ option.name }}
                        </option>
                      </b-select>
                    </div>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Devices">
                    <div>
                      <b-select v-model="selectedDevices" multiple>
                        <option
                          v-for="option in devices"
                          :value="option.id"
                          :key="option.id">
                          {{ option.name }}
                        </option>
                      </b-select>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
            </card-component>
          </b-step-item>

          <b-step-item step="2" label="Domains" :clickable="false" :type="{'is-success': true}">
            <card-component>
              <h1 class="title has-text-centered">Domains</h1>
              <b-table
                  :data="subdomainList"
                  :striped="true"
                  :hoverable="true">

                <b-table-column field="domain" label="domain"   v-slot="props">
                  {{ props.row.domain }}
                </b-table-column>

                <b-table-column field="locale" label="Locale" v-slot="props">
                  {{ props.row.locale }}
                </b-table-column>

                <b-table-column field="domain" label="View" v-slot="props">
                  <a :href="props.row.domain" target="_blank">Open</a>
                </b-table-column>

                <template #empty>
                  <div class="has-text-centered">No records</div>
                </template>
              </b-table>

              <b-button @click="openAllLandingPages()">Open All Landing Pages</b-button>
            </card-component>
        </b-step-item>

        <b-step-item step="3" label="Ad Units" :clickable="false" :type="{'is-success': adUnitsImported.isValid, 'is-danger': !adUnitsImported.isValid}">
          <card-component>
            <h1 class="title has-text-centered">Ad Units</h1>
            <flex-table
              label="AdUnits"
              :options="[
                { id: 'AdUnitCode', name: 'Ad Unit Code'},
                { id: 'AdUnitName', name: 'AdUnit Name'},
                { id: 'RangeStart', name: 'Range Start'},
                { id: 'RangeEnd', name: 'Range End'},
              ]"
              v-model="adUnitsImported"
            ></flex-table>
          </card-component>
        </b-step-item>
        <b-step-item step="4" label="Confirmation" :clickable="false" :type="{'is-success': true}">
          <card-component>
            <h1 class="title has-text-centered">Confirmation</h1>
          </card-component>
        </b-step-item>
          <template
              v-if="customNavigation"
              #navigation="{previous, next}">
            <b-button
                outlined
                type="is-danger"
                icon-pack="fas"
                icon-left="backward"
                :disabled="previous.disabled"
                @click.prevent="previous.action">
                Previous
            </b-button>
            <b-button
                outlined
                type="is-success"
                icon-pack="fas"
                icon-right="forward"
                :disabled="next.disabled"
                @click.prevent="next.action">
                Next
            </b-button>
          </template>

      </b-steps>
    </section>
  </div>
</template>

<script>

import FlexTable from "@/components/FlexTable";
// import Tiles from '@/components/Tiles';
import CardComponent from '@/components/CardComponent';

export default {
  name: 'TrackingCodesPage',
  created() {
    this.setDevelopmentData();
  },
  components: {
    FlexTable,
    CardComponent
  },
  data () {

    return {
      activeStep: 0,

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,

      networks: [
        {id: 'FBA', name: 'FBA'}
      ],

      devices: [
        {id: 'DESKTOP', name: 'DESKTOP'},
        {id: 'MOBILE', name: 'MOBILE'},
        {id: 'TABLET', name: 'TABLET'},
      ],

      urlParameters: [
        {id: 'src=nf', name: 'src=nf'}
      ],

      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',

      agencyFeed: '',

      productDomain: '',
      productName: '',
      productCode: '',

      layoutType: 'CBSI_BING',

      domainStructure: '',

      typeTagInit: '',
      typeTagNumeric: null,

      rangeStarts: null,
      rangeEnds: null,

      selectedNetworks: [],
      selectedDevices: [],
      selectedUrlParameters: [],

      cbsiLocales: [
        'fr-CA',
        'en-CA',
        'de-DE',
        'es-ES',
        'fr-FR',
        'it-IT',
        'es-MX',
        'en-GB',
        'es-US',
        'en-US',
        'en-AU',
      ],

      subdomainList: [],

      adUnitsImported: {}
    }
  },
  computed: {

  },
  methods: {
    setDevelopmentData () {

      this.agencyFeed = 'CBSI';

      this.layoutType = 'CBSI_BING';

      this.productDomain = 'https://www.ezsearcher.com/seek';
      this.productName = 'Ezsearcher';
      this.productCode = 'ezsearcher';

      this.domainStructure = 'LOCALE_SUBDOMAIN';

      this.typeTagInit = 'T';
      this.typeTagNumeric = 7;

      this.rangeStarts = 0;
      this.rangeEnds = 48500;

      this.selectedNetworks = ['FBA'];
      this.selectedDevices = ['DESKTOP', 'MOBILE', 'TABLET'];
      this.selectedUrlParameters = ['src=nf'];

    },

    makeTitle (value) {
      return value.replace(
        /(\w*\W*|\w*)\s*/g,
        function(txt) {
        return(txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
        }
      );
    },

    stepChanged (stepIndex){

      if(stepIndex === 1){
        if(this.subdomainList.length === 0){
          this.createSubdomainList();
        }
      }else if(stepIndex === 3){
        if(this.adUnitsImported.isValid !== true){
          this.$buefy.snackbar.open({
            message: 'Select All indexes on the adunits screen',
            queue: false,
            type: 'is-danger'
          })
          this.activeStep = 2;
        }
      }
    },

    createSubdomainList () {
      if(this.layoutType === 'CBSI_BING'){
        for(let i in this.cbsiLocales){
          let locale = this.cbsiLocales[i],
              domainPieces = this.productDomain.split('/'),
              localeGeo = locale.split('-')[1],
              localeLanguage = locale.split('-')[0],
              domainProtocol = domainPieces[0],
              domainDomain = domainPieces[2],
              newDomainDomain = domainDomain,
              domainPath = domainPieces[3],
              domainLocale;

          if(localeGeo === 'US'){
            localeGeo = 'www';
          }else if(localeGeo === 'GB'){
            localeGeo = 'uk';
          }

          if(this.domainStructure === 'LOCALE_SUBDOMAIN'){

            if(domainDomain.indexOf('www') > -1){
              newDomainDomain = domainDomain.replace('www', localeGeo);
            }else{
              newDomainDomain = localeGeo + '.' + domainDomain;
            }

            if(localeLanguage !== 'en'  && localeGeo.toLowerCase() !== localeLanguage.toLowerCase() && ['MX'].indexOf(localeGeo) === -1) {
              domainLocale = domainProtocol + '//' + newDomainDomain + '/' + localeLanguage + '/' + domainPath;
            }else {
              domainLocale = domainProtocol + '//' + newDomainDomain + '/' + domainPath;
            }
          }else if(this.domainStructure === 'LOCALE_FOLDER_BASED') {

            if(locale.toLowerCase() === 'fr-CA'){
              localeGeo = 'fr';
            }else {
              localeGeo = locale;
            }

            if(localeLanguage === 'en'){
              domainLocale = domainProtocol + '//' + newDomainDomain + '/' + domainPath;
            }else{
              domainLocale = domainProtocol + '//' + newDomainDomain + '/' + localeLanguage + '/' + domainPath;
            }
          }

          this.subdomainList.push({
            domain: domainLocale.toLowerCase(),
            locale: locale
          });
        }
      }
    },

    openAllLandingPages (){
      let urls = [];

      for(let i in this.subdomainList){
        if(urls.indexOf(this.subdomainList[i].domain) > -1){
          continue;
        }
        urls.push(this.subdomainList[i].domain)
      }

      for(let i in urls){
        window.open(urls[i]);
      }

    }
  },

  watch: {
    productDomain: function (val){
      let parsed = val;

      parsed = parsed.split('.');

      if(parsed.length === 2){
        parsed = parsed[0].replace('https://', '').replace('http://', '');
      }else if(parsed.length === 3){
        parsed = parsed[1];
      }else{
        parsed = parsed[0];
      }

      this.productName = this.makeTitle(parsed);
      this.productCode = parsed.toLowerCase()
    },
  }
}
</script>
