import { Bar, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  name: 'bar-chart',
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    extraOptions: Object
  },
  data () {
    return {
      ctx: null
    }
  },
  watch: {
    chartData () {
    let that = this;
      setTimeout(function (){
        that.renderChart(that.chartData, that.extraOptions)
      }, 200);
    }
  },
  mounted () {
    let that = this;
    this.$nextTick(function() {
      // Workround for refresh.
      setTimeout(function (){
        that.renderChart(that.chartData, that.extraOptions)
      }, 200);

    })
  }
}
