<template>
  <card-component
      title="Image Picker"
      icon="finance"
  >
    <template v-slot:toolbar-right>
      <b-field class="mt-3 mr-6">
        <b-datepicker
            size="is-small"
            placeholder="Date..."
            v-model="selectedDate"
            class="mr-6"
            range>
          <b-button
              label="This Month"
              type="is-primary"
              class="is-small"
              icon-left="calendar-today"
              @click="setSelectedDates('THIS_MONTH', 'selectedDate')"/>
          <b-button
              label="Previous Month"
              type="is-primary"
              class="is-small"
              icon-left="calendar"
              @click="setSelectedDates('PREVIOUS_MONTH', 'selectedDate')"/>
          <b-button
              label="Yesterday"
              type="is-primary"
              class="is-small"
              icon-left="calendar-today"
              @click="setSelectedDates('YESTERDAY', 'selectedDate')"/>
        </b-datepicker>
      </b-field>
      <a
          href="#"
          class="card-header-icon"
          aria-label="more options"
          @click.prevent="loadData"
      >
        <b-icon icon="reload" custom-size="default"/>

      </a>
    </template>
    <div v-if="dataNormalized" class="chart-area" style="min-height: 20rem; overflow: auto;">
      <b-table
        :loading="isLoading"
        :striped="true"
        :hoverable="true"
        default-sort="total_count"
        :data="dataNormalized"

        pagination-position="top"
        :pagination-rounded="false"
        sort-icon="chevron-up"
        sort-icon-size="is-medium"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >

        <b-table-column label="Images Added" field="total_count" sortable v-slot="props">
          {{ props.row.total_count }}
        </b-table-column>

        <b-table-column label="User" field="username" sortable v-slot="props">
          {{ props.row.username }}
        </b-table-column>

      </b-table>
    </div>

  </card-component>
</template>
<script>
import CardComponent from '@/components/CardComponent'
import moment from "moment-timezone";
import {numberUtils} from "@/helpers/number-utils";
import {timeUtils} from "@/helpers/time-utils";
import {creationDashboardsFoundKeywordImagesService} from "@/services";

export default {
  name: 'DashboardImagePicker',
  components: {
    CardComponent,
  },
  mixins: [
    numberUtils,
    timeUtils
  ],

  mounted() {
    this.setSelectedDates('THIS_MONTH', 'selectedDate');
    this.loadData();
  },
  data() {
    return {
      selectedDate: [],
      dataNormalized: [],
      isLoading: false,
    }
  },

  methods: {
    loadData() {
      let that = this;

      this.isLoading = true;

      creationDashboardsFoundKeywordImagesService.getByDateTimeRange(
          moment(this.selectedDate[0]).format('YYYY-MM-DD 00:00:00'),
          moment(this.selectedDate[1]).format('YYYY-MM-DD 23:59:59')
      ).then(response => {
        that.dataNormalized = that.normalizeData(response.data.results);
        this.isLoading = false;
      })
    },

    normalizeData(data) {
      return data;
    }
  }
}
</script>