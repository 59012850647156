<template>
  <div>

    <b-field v-if="normalizedData.length === 0">
      <template #label>
        {{ label }} <slot name="besideLabel"></slot>
      </template>
<!--      <div class="control is-clearfix">-->
        <textarea class="textarea" @keydown.tab.prevent="captureTab" v-model="data" :placeholder="placeHolder"></textarea>
<!--      </div>-->

    </b-field>

    <b-button @click="parse" v-if="normalizedData.length === 0">Parse</b-button>

    <table class="table is-hoverable is-striped" v-if="normalizedData.length > 0">
      <thead>
      <tr>
        <th v-for="(rr, index) in normalizedData[0]" v-bind:key="`t-th-rr-${index}`">
          <div class="input-group">
            <b-field>
              <b-select
                placeholder="Index"
                v-model="header['index_' + index]"
                @input="emitModel"
              >
                <option v-for="(o, oi) in options" v-bind:key="`t-th-rr-options-${index}-${oi}`" :value="o.id">
                  {{ o.name }}
                </option>
              </b-select>
              <b-button
              type="is-danger"
              @click="removeColumn(index);"
              icon-left="close" />
            </b-field>
          </div>
        </th>
      </tr>
      </thead>

      <tbody>
        <tr v-for="(r, i) in normalizedData" v-bind:key="`t-r-${i}`">
          <td v-for="(rr, ii) in r" v-bind:key="`t-rr-${ii}`">{{ rr }}</td>
        </tr>
      </tbody>
    </table>

    <b-button @click="cancelParse" class="is-warning" v-if="normalizedData.length > 0">Cancel</b-button>
  </div>
</template>

<script>
import Vue from "vue";

export default {

  name: 'FlexTable',

  components: {},

  props: {
    label: {
      type: String,
      default: 'Data'
    },

    placeHolder: {
      type: String,
      default: '...paste from Excel...'
    },

    options: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      data: '',
      normalizedData: [],
      header: {}
    }
  },

  computed: {

  },

  methods: {
    cancelParse(){
      this.normalizedData = [];
      this.emitModel();
    },
    removeColumn (remIdx)
    {
      this.normalizedData = this.normalizedData.map(function(arr) {
        return arr.filter(function(el,idx){
          return idx !== remIdx
        });
      });

      this.emitModel();
    },
    normalizeData: function (data) {
      let numberOfColumns = data[0].length,
          row,
          column,
          areAllColumnsEmpty;

      for (let i = 0, len = data.length; i < len; i++) {
        row = data[i];

        areAllColumnsEmpty = true;

        for (let ii = 0; ii < numberOfColumns; ii++) {
          column = row[ii];

          if (column === 'null' || column === 'NULL' || column == null) {
            row[ii] = '';
            column = row[ii];
          }

          if (column !== '') {
            areAllColumnsEmpty = false;
          }

          //Last iteration
          if (ii === (numberOfColumns - 1) && areAllColumnsEmpty) {
            //remove row
            delete data[i];
          }
        }
      }

      data = data.filter(function (item) {
        return item !== undefined
      });

      return data;
    },

    parse() {
      let d = this.data.split("\n");

      for (let i in d) {
        d[i] = d[i].split("\t");
      }

      this.normalizedData = this.normalizeData(d);

      this.preselect();
    },

    isValid (){
      if(this.normalizedData.length === 0){
        return false;
      }

      // ... === Unique headers names
      return this.normalizedData[0].length === [...new Set(Object.values(this.header))].length;


    },

    saveData() {
      let data = [],
          row = {};

      if(!this.isValid()){
        return data;
      }

      for(let i in this.normalizedData){
        row = {};
        for(let ii in this.normalizedData[i]){
          row[this.header['index_' + ii]] = this.normalizedData[i][ii].trim();
        }

        data.push(row);
      }

      return data;
    },

    emitModel (){
      this.$emit('input', {
        data: this.saveData(),
        isValid: this.isValid()
      })
    },

    preselect(){

      let hasPreselect = false;

      for(let i in this.options){
        let row = this.options[i];

        if(row.preselect){
          Vue.set(this.header, 'index_' + i, row.id);
          hasPreselect = true;
        }
      }

      if(hasPreselect){
        this.emitModel();
      }

    },

    captureTab(e){

      let target = e.target,
          char = "\t";

      if (target.setRangeText) {
          //if setRangeText function is supported by current browser
          target.setRangeText(char,
                              target.selectionStart,
                              target.selectionEnd,
                              'end');
      } else {
          // Deprecated, so only used when setRangeText is not available.
          target.focus()
          document.execCommand('insertText', false /*no UI*/, char);
      }

    }

  },
}
</script>