import { myProfileService } from "@/services/my-profile.service";

export const myProfile = {
    namespaced: true,
    state: {
        validOTP: null,
        loading: false,
        error: null,
    },
    actions: {
        sendOTPToAgent({ commit }, payload) {
            commit('sendOTPToAgentRequest');

            myProfileService.sendOTPToAgent(payload)
                .then(
                    data    => commit('sendOTPToAgentSuccess', data),
                    error   => commit('sendOTPToAgentFailure', error)
                );
        },

        validateOTP({ commit }, payload) {
            commit('validateOTPRequest');

           return new Promise((resolve, reject) => {               
               myProfileService.validateOTP(payload)
                    .then(
                        data    => {commit('validateOTPSuccess', data); resolve(data)},
                        error   => {commit('validateOTPFailure', error); reject(error)}
                    );
           }) 
        },

        saveAgent({ commit }, payload) {
            commit('saveAgentRequest');

           return new Promise((resolve, reject) => {               
               myProfileService.saveAgent(payload)
                    .then(
                        data    => {commit('saveAgentSuccess', data); commit('theme/user', { results : data.data.results }, { root: true }); resolve(data)},
                        error   => {commit('saveAgentFailure', error); reject(error)}
                    );
           }) 
        },

        changeUserInfo({ commit }, payload) {
            commit('changeUserInfoRequest');

           return new Promise((resolve, reject) => {               
               myProfileService.changeUserInfo(payload)
                    .then(
                        data    => {commit('changeUserInfoSuccess', data); resolve(data)},
                        error   => {commit('changeUserInfoFailure', error); reject(error)}
                    );
           }) 
        },

    },
    mutations: {
        sendOTPToAgentRequest(state) {
            state.loading = true;
        },
        sendOTPToAgentSuccess(state) {
            state.loading = false;
        },
        sendOTPToAgentFailure(state, error) {
            state.loading = false;
            state.error = error;
        },
        validateOTPRequest(state) {
            state.validOTP = null;
            state.loading = true;
        },
        validateOTPSuccess(state, data) {
            state.validOTP = data.data.results.validOTP;
            state.loading = false;
        },
        validateOTPFailure(state, error) {
            state.validOTP = null;
            state.loading = false;
            state.error = error;
        },
        saveAgentRequest(state) {
            state.loading = true;
        },
        saveAgentSuccess(state, data) {
            console.log(data)
            state.loading = false;
        },
        saveAgentFailure(state, error) {
            state.loading = false;
            state.error = error;
        },
        changeUserInfoRequest(state) {
            state.loading = true;
        },
        changeUserInfoSuccess(state) {
            state.loading = false;
        },
        changeUserInfoFailure(state, error) {
            state.loading = false;
            state.error = error;
        },
    }
}