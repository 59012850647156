<template>
  <div>
    <section>
      <b-loading v-model="isLoading"></b-loading>
      <div>
        <div class="columns">
          <div class="column">
            <b-field
              label="Agency Feed"
            >
              <b-select v-model="formData.agencyFeed.value" :disabled="formData.id.value">
                <option v-for="s in agencyFeeds['all']" :value="s.id" v-bind:key="`agency-feeds-gd-${s.id}`">
                  {{ s.code }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Network"
            >
              <b-select v-model="formData.network.value" disabled>
                <option v-for="s in networks['all']" :value="s.id" v-bind:key="`networks-gd-${s.id}`">
                  {{ s.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <div v-if="formData.id.value === null && users['all'].length > 0">

        <b-field
          label="Pre-populate by user"
        >
          <b-select v-model="cloneFromUser">
            <option v-for="s in users['all']" :value="s" v-bind:key="`clone-users-gdn-${s.id}`">
              {{ s.username }}
            </option>
          </b-select>
          <p class="control">
            <b-button @click="prePopulateByUser" class="is-info">Pre-Populate!</b-button>
          </p>
        </b-field>
      </div>
      <div>
        <b-field
            label="Description"
            :type="{ 'is-danger': formData.description.hasError }"
            :message="{ 'Please provide a descriptive description': formData.description.hasError }">
            <b-input v-model="formData.description.value" type="textarea"></b-input>
        </b-field>
      </div>
      <div>
        <b-field
            label="User"
            v-if="users['all'].length > 0"
            >
          <b-select v-model="formData.user.value">
            <option v-for="s in users['all']" :value="s" v-bind:key="`users-${s.id}`">
              {{ s.username }}
            </option>
          </b-select>
        </b-field>
        <b-field
            label="User"
            v-else
            >
            {{ formData.user.value.username }}
        </b-field>
      </div>
      <div class="columns">
        <div class="column" v-if="visibleTo('IsSuperUser')">
          <b-field label="Allow Auto Queue">
              <b-checkbox v-model="formData.allowAutoQueue.value"></b-checkbox>
          </b-field>
        </div>
        <div class="column" v-if="visibleTo('IsSuperUser')">
          <b-field label="Subdomain Multiplier"
                   :type="{ 'is-danger': formData.subdomainMultiplier.hasError }"
                   :message="{ 'Please provide a value for this item': formData.subdomainMultiplier.hasError }"
          >
            <b-input v-model="formData.subdomainMultiplier.value" type="number" step="1"
            ></b-input>
          </b-field>
        </div>
        <div class="column" v-if="visibleTo('IsSuperUser')">
          <b-field label="Max Clones"
                   :type="{ 'is-danger': formData.maxClones.hasError }"
                   :message="{ 'Please provide a value for this item': formData.maxClones.hasError }"
          >
            <b-input v-model="formData.maxClones.value" type="number" step="1"
            ></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Ads Multiplier"
                   :type="{ 'is-danger': formData.adMultiplier.hasError }"
                   :message="{ 'Please provide a value for this item': formData.adMultiplier.hasError }"
          >
            <b-input v-model="formData.adMultiplier.value" type="number" step="1"
            ></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Campaign Status">
              <b-select v-model="formData.campaignStatus.value">
                <option v-for="s in status" :value="s.value" v-bind:key="`campaign-status-${s.value}`">
                  {{ s.label }}
                </option>
              </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="AdGroup Status">
              <b-select v-model="formData.adGroupStatus.value">
                <option v-for="s in status" :value="s.value" v-bind:key="`campaign-status-${s.value}`">
                  {{ s.label }}
                </option>
              </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Ad Status">
              <b-select v-model="formData.adStatus.value">
                <option v-for="s in status" :value="s.value" v-bind:key="`campaign-status-${s.value}`">
                  {{ s.label }}
                </option>
              </b-select>
          </b-field>
        </div>
      </div>

        <b-field label="Is Active">
            <b-checkbox v-model="formData.isActive.value"></b-checkbox>
        </b-field>


          <h2 class="subtitle">
            <b>
              Account Managers
            </b>
          </h2>

          <multiselect
            v-model="formData.accountManagers.value"
            :options="accountManagers"
            :multiple="true"
            placeholder="Account Managers"
            label="name"
            track-by="id"
          ></multiselect>

          <h2 class="subtitle">
            <b>
              Country Settings:
            </b>
          </h2>

          <div class="columns" v-for="(countrySetting, rowId) in formData.countrySettings"
          v-bind:key="`country_settings-${rowId}`">
          <div class="column">
              <b-field label="Country">
                {{ countrySetting.country.value }}
              </b-field>
            </div>
            <div class="column">
              <b-field label="Account Currency"
                :type="{ 'is-danger': countrySetting.accountCurrencyCode.hasError }"
                :message="{ 'Please provide a value': countrySetting.accountCurrencyCode.hasError }"
              >
                <b-select v-model="countrySetting.accountCurrencyCode.value">
                  <option :value="c" v-for="c in currencies" v-bind:key="`currencies-${c}`">
                    {{ c }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Bid Amount"
                :type="{ 'is-danger': countrySetting.bidAmount.hasError }"
                :message="{ 'Please provide a value': countrySetting.bidAmount.hasError }">
                <decimal-input v-model="countrySetting.bidAmount.value"
                               step="0.01"
                               prefix="$"
                               controls-alignment="right"></decimal-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Budget Amount"
                :type="{ 'is-danger': countrySetting.budgetAmount.hasError }"
                :message="{ 'Please provide a value': countrySetting.budgetAmount.hasError }">
                <decimal-input v-model="countrySetting.budgetAmount.value"
                               step="1"
                               prefix="$"
                               controls-alignment="right"></decimal-input>
              </b-field>
            </div>
            <div class="column" v-if="visibleTo('IsSuperUser')">
              <b-field label="Max Budget Amount"
                       :type="{ 'is-danger': countrySetting.maxBudgetAmount.hasError }"
                       :message="{ 'Please provide a value': countrySetting.maxBudgetAmount.hasError }">
                <decimal-input v-model="countrySetting.maxBudgetAmount.value"
                               step="1"
                               prefix="$"
                               controls-alignment="right"></decimal-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Daily Limit"
                :type="{ 'is-danger': countrySetting.dailyLimit.hasError }"
                :message="{ 'Please provide a value': countrySetting.dailyLimit.hasError }">
                <decimal-input v-model="countrySetting.dailyLimit.value"
                               step="1"
                               controls-alignment="right"></decimal-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Bidding Strategy"
                :type="{ 'is-danger': countrySetting.biddingStrategy.hasError }"
                :message="{ 'Please provide a value': countrySetting.biddingStrategy.hasError }">
                <b-select v-model="countrySetting.biddingStrategy.value">
                  <option
                    v-for="option in biddingStrategies['allGDN']"
                    :key="option.code"
                    :value="option.code">
                    {{ option.code }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Languages"
                :type="{ 'is-danger': countrySetting.languages.hasError }"
                :message="{ 'Please provide at least one value': countrySetting.languages.hasError }">
                <multiselect v-model="countrySetting.languages.value"
                         :options="languages"
                         :multiple="true"
                         placeholder="Languages"
                         ></multiselect>
              </b-field>
            </div>
          </div>

        <b-button class="is-primary is-pulled-right" @click="save">Save!</b-button>
        <b-button class="is-text is-pulled-left" @click="cancel">Cancel</b-button>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import Multiselect from "vue-multiselect";
import DecimalInput from "@/components/Element/DecimalInput";
import {mapState} from "vuex";
import {administrationAccountManagerService, devCountriesService, devLanguagesService} from "@/services";
import {creationSettingsService} from "@/services/creation-creation-settings.service";

export default {
  name: 'CreatingSettingGoogleDiscoveryForm',
  created() {
    let id = this.id;

    if(!this.biddingStrategies.allGDN.length) this.$store.dispatch('biddingStrategies/getAllGDN');
    if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll');
    if(!this.networks.all.length) this.$store.dispatch('networks/getAll');

    if(id){
      this.fetchSettingsById(id)
    }else{
      console.log('populate settings etc.')
    }

    this.formData.network.value = this.network;
  },

  mounted() {
    if(!this.users.all.length) this.$store.dispatch('users/getAll');
  },

  props: [
    'id',
    'network'
  ],
  components: {
    DecimalInput,
    Multiselect,
  },
  data () {
    return {
      isLoading: false,
      cloneFromUser: null,

      formData: {
        id: {
          value: null,
        },
        description: {
          value: '',
          hasError: false
        },
        user: {
          value: {},
          hasError: false
        },
        allowAutoQueue: {
          value: true,
          hasError: false
        },
        adMultiplier: {
          value: 1,
          hasError: false
        },
        subdomainMultiplier: {
          value: 1,
          hasError: false
        },
        maxClones: {
          value: 1,
          hasError: false
        },
        campaignStatus: {
          value: 'ENABLED',
          hasError: false
        },
        adGroupStatus: {
          value: 'ENABLED',
          hasError: false
        },
        adStatus: {
          value: 'ENABLED',
          hasError: false
        },
        isActive: {
          value: true,
          hasError: false
        },

        agencyFeed: {
          value: null,
          hasError: false
        },

        network: {
          value: null,
          hasError: false
        },

        accountManagers: {
          value: [],
          hasError: false,
        },

        countrySettings: []
      },

      status: [
        {'label': 'ACTIVE', 'value': 'ENABLED'},
        {'label': 'PAUSED', 'value': 'PAUSED'},
      ],

      currencies: ['USD', 'HKD'],
      languages: [],
      accountManagers: [],
      configuredAgencyFeedsCode: [],
    }
  },
  computed: {
    ...mapState([
      'biddingStrategies',
      'agencyFeeds',
      'networks',
      'users',
      'theme',
    ])
  },
  methods: {

      visibleTo (groupName){
        return this.theme.userGroups.indexOf(groupName) > -1;
      },

      removeAllIdsFromFormData(){
        this.formData.id.value = null;
        this.formData.user.value = null;
      },

      fetchSettingsById(id) {
        let that = this;

        this.isLoading = true;
        creationSettingsService.getById(id).then(response => {
          that.populateForm(response.data)
          this.isLoading = false
        }).catch(r => {
          alert('An Error Occurred.')
          console.dir(r);
        })
      },

      fetchSettingsByUserId(userId) {
        let that = this;
        this.isLoading = true;
        creationSettingsService.get({
          'user': userId,
          'network': this.formData.network.value,
          'agency_feed': this.formData.agencyFeed.value
        }).then(response => {
          that.isLoading = false;
          if (response.data['results'].length === 0) {
            return alert('No configurations found for this user, please try another one.')
          }
          that.populateForm(response.data['results'][0])
          that.removeAllIdsFromFormData()

        }).catch(r => {
          alert('An Error Occurred.')
          console.dir(r);
        })
      },

      fetchAccountManagers(agencyFeed){
        let that = this;

        administrationAccountManagerService.getAll({
          'agency_feed__code': agencyFeed,
          'dealer': 1,
        }).then(response => {
          that.accountManagers = response.data['results'];
          that.setObjectMultiselectAccountManagers()
        })

      },

      isValid(){
        let isValid = true;

        if(this.formData.description.value.length === 0){
          isValid = false;
          this.formData.description.hasError = !isValid;
        }else{
          this.formData.description.hasError = false;
        }

        if(this.isEmpty(this.formData.agencyFeed.value)){
          isValid = false;
          this.formData.agencyFeed.hasError = !isValid;
        }else{
          this.formData.agencyFeed.hasError = false;
        }

        if(this.isEmpty(this.formData.network.value)){
          isValid = false;
          this.formData.network.hasError = !isValid;
        }else{
          this.formData.network.hasError = false;
        }

        if(this.isEmpty(this.formData.user.value)){
          isValid = false;
          this.formData.user.hasError = !isValid;
        }else{
          this.formData.user.hasError = false;
        }

        if(this.formData.adMultiplier.value.length === 0){
          isValid = false;
          this.formData.adMultiplier.hasError = !isValid;
        }else{
          this.formData.adMultiplier.hasError = false;
        }

        if(this.visibleTo('IsSuperUser')){
          if (this.formData.subdomainMultiplier.value.length === 0) {
            isValid = false;
            this.formData.subdomainMultiplier.hasError = !isValid;
          } else {
            this.formData.subdomainMultiplier.hasError = false;
          }

          if (this.formData.maxClones.value.length === 0) {
            isValid = false;
            this.formData.maxClones.hasError = !isValid;
          } else {
            this.formData.maxClones.hasError = false;
          }
        }

        for (let i in this.formData.countrySettings) {

          let countrySetting = this.formData.countrySettings[i];

          if (this.isEmpty(countrySetting.accountCurrencyCode.value)) {
            isValid = false;
            countrySetting.accountCurrencyCode.hasError = !isValid;
          } else {
            countrySetting.accountCurrencyCode.hasError = false;
          }

          if (countrySetting.biddingStrategy.value) {
            let biddingStrategy = this.biddingStrategyDetails(countrySetting.biddingStrategy.value);
            if (biddingStrategy['is_bid_handled']) {
              if (this.isEmpty(countrySetting.bidAmount.value)) {
                isValid = false;
                countrySetting.bidAmount.hasError = !isValid;
              } else {
                countrySetting.bidAmount.hasError = false;
              }
              let bidAmountParts = countrySetting.bidAmount.value.toString().split('.');
              if (bidAmountParts.length > 1 && bidAmountParts[1].length > 2) {
                this.$buefy.snackbar.open({'message':'Bid amount decimals must be maximum 2 places, for example: 0.01', 'position': 'is-bottom'});
                isValid = false;
                countrySetting.bidAmount.hasError = !isValid;
              } else {
                countrySetting.bidAmount.hasError = false;
              }
            } else {
              countrySetting.bidAmount.value = null;
              countrySetting.bidAmount.hasError = false;
            }
          } else {
            countrySetting.bidAmount.hasError = false;
          }

          if(this.visibleTo('IsSuperUser')) {
            if (this.isEmpty(countrySetting.maxBudgetAmount.value)) {
              isValid = false;
              countrySetting.maxBudgetAmount.hasError = !isValid;
            } else {
              countrySetting.maxBudgetAmount.hasError = false;
            }
          }

          if (this.isEmpty(countrySetting.budgetAmount.value)) {
            isValid = false;
            countrySetting.budgetAmount.hasError = !isValid;
          } else {
            countrySetting.budgetAmount.hasError = false;
          }

          if (this.isEmptyAllowZero(countrySetting.dailyLimit.value)) {
            isValid = false;
            countrySetting.dailyLimit.hasError = !isValid;
          } else {
            countrySetting.dailyLimit.hasError = false;
          }

          if (countrySetting.biddingStrategy.value === null) {
            isValid = false;
            countrySetting.biddingStrategy.hasError = !isValid;
          } else {
            countrySetting.biddingStrategy.hasError = false;
          }

          if (this.isEmpty(countrySetting.languages.value) || countrySetting.languages.value.length === 0) {
            isValid = false;
            countrySetting.languages.hasError = !isValid;
          } else {
            countrySetting.languages.hasError = false;
          }
        }

        return isValid;
      },

      isEmpty(val){
        return !val || val === '';
      },

      isEmptyAllowZero(val){
        if(val === 0 || val === '0'){
          return false;
        }
        return !val || val === '';
      },

      populateForm(data){
        this.formData.id.value = data['id'];
        this.formData.description.value = data['description'];
        this.formData.allowAutoQueue.value = data['allow_auto_queue'];
        this.formData.adMultiplier.value = data['json_value']['ad_multiplier'];
        this.formData.subdomainMultiplier.value = data['subdomain_multiplier'];
        this.formData.maxClones.value = data['max_clones'];
        this.formData.campaignStatus.value = data['json_value']['campaign_status'];
        this.formData.adGroupStatus.value = data['json_value']['adgroup_status'];
        this.formData.adStatus.value = data['json_value']['ad_status'];
        this.formData.isActive.value = data['is_active'];
        this.formData.user.value = data['user'];
        this.formData.accountManagers.value = data['account_managers'];
        this.formData.agencyFeed.value = data['agency_feed'];
        this.formData.network.value = data['network'];

        this.formData.countrySettings = [];

        for(let i in data['json_value']['country_settings']) {
          let countrySettings = data['json_value']['country_settings'][i];

          this.formData.countrySettings.push({
            country: {
              value: i,
            },
            accountCurrencyCode: {
              value: countrySettings['account_currency_code'],
              hasError: false
            },
            bidAmount: {
              value: countrySettings['bid_amount'],
              hasError: false
            },
            budgetAmount: {
              value: countrySettings['budget_amount'],
              hasError: false
            },
            maxBudgetAmount: {
              value: countrySettings['max_budget'],
              hasError: false
            },
            dailyLimit: {
              value: countrySettings['daily_limit'],
              hasError: false
            },
            biddingStrategy: {
              value: countrySettings['bidding_strategy_code'],
              hasError: false
            },
            languages: {
              value: countrySettings['language_codes'],
              hasError: false
            }
          });
        }
        this.fetchAccountManagers()
        this.fillNewCountries()
      },

      prePopulateByUser(){

        if(!this.cloneFromUser){
          return alert('Please select a user.');
        }

        if (!this.formData.agencyFeed.value) {
          return alert('Please select an agency feed to copy from.');
        }

        if (!this.formData.network.value) {
          return alert('Please select a network to copy from.');
        }

        this.fetchSettingsByUserId(this.cloneFromUser.id);

      },

      setObjectMultiselectAccountManagers(){

        let indexed = {};

        for(let i in this.accountManagers) {
          indexed['id_' + this.accountManagers[i]['id']] = this.accountManagers[i];
        }

        let formAccountManagers = this.formData.accountManagers.value;

        for(let i in formAccountManagers) {
            let row = formAccountManagers[i];

            if(typeof row !== "number"){
              continue
            }

            if(!('id_' + row in indexed)){
              // alert('Attention, the account manager: ' + row + ' is not available in list, do not save!' + JSON.stringify(this.accountManagers));
              continue;
            }

            Vue.set(
                this.formData.accountManagers.value,
                i,
                indexed['id_' + row]
            );
        }

      },

      biddingStrategyDetails: function (biddingStrategyCode){
         let r = this.$store.state.biddingStrategies.allGDN.filter((row) => row.code === biddingStrategyCode);
         return r[0];
      },


      fillNewCountries(){
        this.fillNewCountry()
      },

    fillNewCountry(agencyFeed){
        agencyFeed = agencyFeed || this.formData.agencyFeed.value;

        devCountriesService.getAll({
          'agency_feed': agencyFeed
        }).then(response => {

          let newCountries = response.data['results'],
              newCountriesIndexed = {};

          for(let i in newCountries){
            newCountriesIndexed[newCountries[i]['code']] = newCountries[i];
          }

          for(let i in this.formData['countrySettings']){
            let countryCode = this.formData['countrySettings'][i]['country']['value'];
            delete newCountriesIndexed[countryCode];
          }

          for(let countryCode in newCountriesIndexed){

            Vue.set(this.formData.countrySettings, this.formData.countrySettings.length, {
              country: {
                value: countryCode,
              },
              accountCurrencyCode: {
                value: null,
                hasError: false,
              },
              bidAmount: {
                value: null,
                hasError: false,
              },
              biddingStrategy: {
                value: null,
                hasError: false,
              },
              maxBudgetAmount: {
                value: 35,
                hasError: false,
              },
              budgetAmount: {
                value: 0,
                hasError: false,
              },
              dailyLimit: {
                value: 0,
                hasError: false,
              },
              languages: {
                value: [],
                hasError: false,
              },
            })
          }
        })
      },

      getNormalizedFormData(){
        let data = data = {
          'id': this.formData.id.value,
          'json_value': {
            "ad_status": this.formData.adStatus.value,
            "adgroup_status": this.formData.adGroupStatus.value,
            "ad_multiplier": parseInt(this.formData.adMultiplier.value),
            "campaign_status": this.formData.campaignStatus.value,
            "country_settings": {},
          },
          'description': this.formData.description.value,
          'allow_auto_queue': this.formData.allowAutoQueue.value,
          'subdomain_multiplier': this.formData.subdomainMultiplier.value,
          'max_clones': this.formData.maxClones.value,
          'is_active': this.formData.isActive.value,
          'user': this.formData.user.value.id,
          'network': this.formData.network.value,
          'agency_feed': this.formData.agencyFeed.value,
          'account_managers': this.formData.accountManagers.value.map((e) => {
            return typeof e === 'number' ? e : e.id
          }),
        };

        for (let i in this.formData.countrySettings) {
          let countrySetting = this.formData.countrySettings[i],
              languages = [];

          if (countrySetting.languages.value) {
            languages = countrySetting.languages.value.map((e) => {
              return typeof e === 'string' ? e : e.id
            })
          }

          data['json_value']['country_settings'][countrySetting.country.value] = {
            'account_currency_code': countrySetting.accountCurrencyCode.value,
            'bid_amount': countrySetting.bidAmount.value,
            'budget_amount': countrySetting.budgetAmount.value,
            'max_budget': countrySetting.maxBudgetAmount.value,
            'daily_limit': countrySetting.dailyLimit.value,
            'bidding_strategy_code': countrySetting.biddingStrategy.value,
            'language_codes': languages,
          };
        }

        return data;
      },

      save() {

        if(!this.isValid()){
          return alert('Please resolve the errors and try again.');
        }

        let data = this.getNormalizedFormData(),
          that = this;

        this.isLoading = true;

        creationSettingsService.save(data).then((e) => {
          that.isLoading = false;
          console.dir(e);
          this.$emit('saved');
        }).catch((e) => {
          that.isLoading = false;
          alert('An error occurred while saving. Error from server: ' + JSON.stringify(e, null, '\t'));
          console.dir(e);
        });

      },

      cancel(){
        this.$emit('cancel');
      },
      fetchLanguages() {
        let that = this;

        devLanguagesService.getAll().then(response => {

          let languages = [];

          for(let i in response.data['results']){
            let row = response.data['results'][i];
            languages.push(row.code);
          }

          that.languages = languages;
        })
      },
  },
  watch: {
    'formData.agencyFeed.value': function (oldValue, newValue){
      this.fetchAccountManagers(newValue);
      this.fillNewCountry(newValue);
      this.fetchLanguages();
    }
  }
}
</script>