<style scoped>
table.table td .image {
  width: unset !important;
  height: unset !important;
}
</style>
<template>
  <div>
    <section class="section is-main-section">

      <card-component v-if="!isCheckingKeywords" custom-class="p-3">
        <h1 class="title has-text-centered">Manual Import</h1>

        <dependant-multi-filter
          v-model="selectedMultiFilter"
          partners-available-enabled
          products-with-available-url-codes-enabled
          subdomain-locales-enabled
          customize-for-plus-modal
          available-networks-enabled
          languageFromSubdomainLocalesEnabled
          single-network
          optional-language
          optional-product
          filter-disabled />

        <b-field label="Keywords" v-if="isContent">
          <multiselect
              v-model="keywordsImported"
              :close-on-select="false"
              :options="contents"
              :multiple="true"
              :loading="contentsIsLoading"
              placeholder="Select Content"
              label="title"
              track-by="resource_name">

            <template slot="singleLabel" slot-scope="{ option }">
              <strong>{{ option.title }}</strong>
              <span style="color:red">&nbsp;{{ option }}</span>
            </template>

            <template slot="option" slot-scope="{ option }">
              <div class="option__desc">
                <span class="option__title">
                  <strong>{{option.title}}&nbsp;</strong>
                </span>
                <span class="option__small" style="color:darkgoldenrod">{{ option.is_queued ? 'Queued' : '' }}</span>
                <span class="option__small" style="color:darkgoldenrod">{{ option.is_launched ? ' - Launched' : '' }}</span>
                <span class="option__small" style="color:red">{{ option.ever_live ? ' - Live' : '' }}</span>
              </div>
            </template>

          </multiselect>
        </b-field>
        <b-field label="Keywords" v-else-if="isParkedDomain">
          <keywords-selector :required-keywords="5" v-model="domainKeywords"></keywords-selector>
        </b-field>
        <b-field label="Keywords" v-else-if="feeds.indexOf(GOOGLE_CONTENT) === -1 && feeds.indexOf(PARKED_DOMAIN) === -1 ">
          <b-input type="textarea" @keydown.tab.native.prevent="captureTab" name="textarea" v-model="keywordsImported"  placeholder="Keywords (separated by enter, tab, ( , ) or ( ; ) )"></b-input>
        </b-field>
        <b-field v-else>
          Different types of launching selected. Please select agency feeds with same Feed type. Keywords / Articles.
        </b-field>

        <p>Enable manual selection of:</p>

        <b-switch v-model="allowMediaSelection" class="pt-1 pb-2">Images/Videos</b-switch><br />
        <b-switch v-model="allowCustomizations" class="pt-1 pb-2">Allow customizations</b-switch>
        <b-button :disabled="(!isParkedDomain && !keywordsImported) || (isParkedDomain && !domainKeywords.length > 0) || isLoading" @click="checkKeywords" class="is-success is-large">{{ allowMediaSelection ? 'Next' : 'Import'}}</b-button>

      </card-component>

      <card-component v-else-if="allowMediaSelection || allowCustomizations">

        <table class="table is-hoverable is-bordered is-fullwidth is-striped" v-if="allowDomainSelection">
          <tr v-for="b in availableTypes" v-bind:key="`availableSubdomainLocalesParent-${b}`">

            <td v-if="allowDomainSelection && typeof availableSubdomainLocalesSelected[b] !== 'undefined'">

              <strong>Domain for {{ availableSubdomainLocales[b]['label'] }}:</strong><br />

              <select
                  v-model="availableSubdomainLocalesSelected[b]"
                  :loading="availableSubdomainLocalesIsLoading > 0"
                  @change="availableSubdomainLocalesChanged(b)"
                  class="form-control"
                  multiple>
                <option v-for="s in availableSubdomainLocales[b]['data']" :value="s.subdomain_locale.id" v-bind:key="`availableSubdomainLocalesChild-${b}-${s.subdomain_locale.id}`">
                  {{ s['subdomain_locale']['product'][getFieldCodeLabel()] }} - {{ s['subdomain_locale']['language']['name'] }} - {{ s['subdomain_locale']['country']['name'] }}
                </option>
              </select>

            </td>
            <td v-if="allowAdUnitSelection && typeof availableAdUnitsSelected[b] !== 'undefined'">

              <strong>AdUnit for {{ availableAdUnits[b]['label'] }}:</strong><br />

              <select
                  v-if="availableAdUnits[b]['data'].length > 0" v-model="availableAdUnitsSelected[b]"
                  :loading="availableAdUnitsIsLoading > 0"
                  class="form-control"
                  multiple>
                <option v-for="s in availableAdUnits[b]['data']" :value="s.adunit.id" v-bind:key="`availableAdUnitChild-${b}-${s.adunit.id}`">
                  Adunit-{{ s['adunit']['code'] }} {{ s['daily_tq_score_1'] ? " -  Latest TQs: " + renderNumber(s['daily_tq_score_1'], 2) : '(No TQ available)' }}
                </option>
              </select>
              <div v-else>
                No ad units found for this combination. Try changing subdomain.
              </div>

            </td>
          </tr>
        </table>

        <table class="table is-hoverable is-bordered is-fullwidth is-striped">
          <thead>
          <tr>
            <th>

            </th>
            <th>
              Keyword Name
            </th>
            <th>
              Country Code
            </th>
            <th>
              Language Code
            </th>
            <th>
              Network
            </th>
            <th>
              Saved?
            </th>
          </tr>
          </thead>
          <tbody v-for="(v, i) in keywordsImportedNormalized" :key="`checking-keywords-${i}`">
          <tr>
            <td>
              <b-button
                type="is-danger"
                icon-left="close-thick"
                class="is-small"
                @click="removeFromKeywordsImportedNormalized(i)"/>
            </td>
            <td>
              {{ v['keywordNameTitle'] || v['keywordName'] }}
            </td>
            <td>
              {{ v['countryCode'] }} *({{ v['countryName'] }})
            </td>
            <td>
              {{ selectedMultiFilter['languagesFromSubdomainLocales'].map(l => l.name).join(', ') }}
            </td>
            <td>
              {{ v['networkName'] }}
            </td>

            <td>
              <b-icon
                  v-if="v['savedStatus'] === 'OK'"
                  icon="check"
                  type="is-primary"
                  title="Saved">
              </b-icon>
              <b-icon
                  v-else-if="v['savedStatus'] === 'LOADING'"
                  icon="loading"
                  style="animation: spinAround 750ms infinite linear;">
              </b-icon>
              <b-icon
                  v-else-if="v['savedStatus'] === 'NOT_OK'"
                  icon="block-helper"
                  type="is-danger"
                  title="Not saved.">
              </b-icon>
            </td>

          </tr>
          <tr v-if="allowCustomizations">

            <td colspan="8">
              Creative: <a @click="v['creativeModal'] = true">{{ v['creatives'].length > 0 ? `custom (${v['creatives'].length})` : 'default'}}</a>

              <b-modal v-model="v['creativeModal']">
                <creative-selector :network-code="v['networkCode']" :keyword-name="v['keywordName']" v-model="v['creatives']" :initial-data="v['creatives']" @close="v['creativeModal'] = false" />
              </b-modal>

              <span v-if="v['networkCode'] === 'GDN'">
              | Status:
                <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['ad_group_status'] ? `custom (${v['extraSettings']['ad_group_status']})` : 'default'}}</a>
              </span>
              <span v-if="v['networkCode'] === 'FBA'">
              | Status:
                <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['adset_status'] ? `custom (${v['extraSettings']['adset_status']})` : 'default'}}</a>
              </span>
              <span v-if="v['networkCode'] === 'OBN'">
              | Status:
                <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['campaign_status'] ? `custom (${v['extraSettings']['campaign_status']})` : 'default'}}</a>
              </span>
              <span v-if="v['networkCode'] === 'FBA'">
              | Delivery Method:
                <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['delivery_method'] ? `custom (${v['extraSettings']['delivery_method']})` : 'default'}}</a>
              </span>
              <span v-if="v['networkCode'] === 'FBA'">
              | Age:
                <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['min_age'] ? `custom (${v['extraSettings']['min_age']}-${v['extraSettings']['max_age']})` : 'default'}}</a>
              </span>

              | Bid: <a @click="v['extraSettingsModal'] = true">{{ 'bid_amount' in v['extraSettings']? `custom (${v['extraSettings']['bid_amount'] ? v['extraSettings']['bid_amount'] : 'None'})` : 'default'}}</a>
              | Bidding Strategy: <a @click="v['extraSettingsModal'] = true">{{ 'bidding_strategy_code' in v['extraSettings'] && v['extraSettings']['bidding_strategy_code'] ? `custom (${v['extraSettings']['bidding_strategy_code']})` : 'default'}}</a>
              | Budget: <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['budget_amount'] ? `custom (${v['extraSettings']['budget_amount']} ${v['extraSettings']['account_currency_code']})` : 'default'}}</a>
              <span v-if="v['networkCode'] === 'GDN'">
              | Devices: <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['device_criteria'] ? `custom (${v['extraSettings']['device_criteria'].map((e) => e.type).join(',')})` : 'default'}}</a>
              | Placements: <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['placement_criteria'] ? `custom (${v['extraSettings']['placement_criteria'].map((e) => e.url).join(',')})` : 'default'}}</a>
              </span>
              <span v-if="v['networkCode'] === 'FBA'">
              | Devices: <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['device_criteria'] ? `custom (${v['extraSettings']['device_criteria'].map((e) => e.code).join(',')})` : 'default'}}</a>
              | Publishers: <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['publisher_platforms'] ? `custom (${v['extraSettings']['publisher_platforms'].map((e) => e.code).join(',')})` : 'default'}}</a>
              | Call To Action: <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['call_to_action'] ? `custom (${v['extraSettings']['call_to_action']})` : 'default'}}</a>
              | Creative Type: <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['creative_type'] ? `custom (${v['extraSettings']['creative_type']})` : 'default'}}</a>
              </span>
              <span v-if="v['networkCode'] === 'OBN'">
              | Devices: <a @click="v['extraSettingsModal'] = true">{{ v['extraSettings']['device_criteria'] ? `custom (${v['extraSettings']['device_criteria'].map((e) => e).join(',')})` : 'default'}}</a>
              </span>
              <b-modal v-model="v['extraSettingsModal']">
                <extra-settings-selector :network-code="v['networkCode']"
                                         :keyword-name="v['keywordName']"
                                         :country-code="v['countryCode']"
                                         v-model="v['extraSettings']"
                                         :initial-data="v['extraSettings']"
                                         @close="v['extraSettingsModal'] = false"
                                         :agency-feeds="selectedMultiFilter['availablePartners'].map((e) => { return e.agency_feed.id })"
                />
              </b-modal>
            </td>
          </tr>
          <tr v-if="allowMediaSelection">
            <td colspan="8">
              <image-picker
                  v-if="v['displayImageSearcherOnlyOncePerKeyword']"
                  :default-search-term="(v['keywordNameTitle'] || v['keywordName']) + ''"
                  :original-keyword="(v['keywordNameTitle'] || v['keywordName']) + ''"
                  copy-paste-enabled
                  :previously-selected-images-list="v['previouslySelectedImagesList']"
                  :existing-images="v['existingImages'] >= 10"
                  v-model="v['selectedImages']" :limit="10 - v['existingImages']" />
              <div v-else>
                Images for this keyword will be added automatically.
              </div>
            </td>

          </tr>
          </tbody>
        </table>

        <b-message title="Default" aria-close-label="Close message" v-if="savingInProgress === 0">
            Finishing Saving. Please check errors if any.
        </b-message>

        <b-button class="is-dark is-right" @click="restartImport" :disable="savingInProgress > 0">Restart</b-button>

        <b-button class="is-primary is-right" style="margin-left: 3rem;" @click="importKeyword" :disable="savingInProgress > 0">Import Keywords</b-button>

      </card-component>

    </section>

    <b-modal v-model="showCountryLanguageUsModal" class="country-language-list">
      <country-language-list  />
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import CardComponent from '@/components/CardComponent';
import {mapState} from "vuex";
import {
  creationFoundKeywordsService,
  administrationAvailableSubdomainLocalesService,
  administrationAvailableAdUnitService,
  creationKeywordImagesListService,
} from "@/services";
import ImagePicker from "@/components/Media/ImagePicker.vue";
import {numberUtils} from "@/helpers/number-utils";
import {permissionsUtils} from "@/helpers/permissions-utils";
import CountryLanguageList from '@/components/CountryLanguageList.vue';
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {contentsService} from "@/services/administration-contents.service";
import Multiselect from "vue-multiselect";
import CreativeSelector from "@/components/Element/CreativeSelector.vue";
import ExtraSettingsSelector from "@/components/Element/ExtraSettingsSelector.vue";
import KeywordsSelector from "@/components/Element/KeywordsSelector.vue";

export default {
  name: 'ManualImportPage',

  mixins: [
      numberUtils,
      permissionsUtils,
  ],

  mounted() {
    this.loadVuex()

    //this.prepareDev()
  },

  components: {
    KeywordsSelector,
    ExtraSettingsSelector,
    CreativeSelector,
    Multiselect,
    ImagePicker,
    CardComponent,
    CountryLanguageList,
    DependantMultiFilter,
  },

  data() {
    return {

      // Constants
      PARKED_DOMAIN: 'b9c83c1f-bf55-491b-ae07-26fb8b54c54c',
      GOOGLE_CONTENT: '5',

      // Variables
      selectedMultiFilter: {},
      selectedImages: [],
      isCheckingKeywords: false,
      keywordsImported: '',
      keywordsImportedNormalized: [],
      selectedNetworks: [],
      selectedAgencyFeeds: [],

      languagesIndexByCode: {},
      countriesIndexByCode: {},

      savingInProgress: -1,

      allowDomainSelection: false,
      allowAdUnitSelection: false,
      allowMediaSelection: true,
      allowCustomizations: true,


      availableTypes: [],

      availableSubdomainLocales: {},
      availableSubdomainLocalesSelected: {},
      availableSubdomainLocalesIsLoading: 0,

      availableAdUnits: {},
      availableAdUnitsSelected: {},
      availableAdUnitsIsLoading: 0,

      keywordsImageSearcher: [],

      showCountryLanguageUsModal: false,

      contents: [],
      contentsIsLoading: false,

      domainKeywords: [],
    }
  },

  computed: {
    ...mapState([
      'networks',
      'agencyFeeds',
      'languages',
      'countries',
      'subdomainLocales',
      'partners',
      'products',
    ]),

    isLoading() {
      return [this.networks.loading, this.languages.loading, this.subdomainLocales.loading, this.partners.loading, this.products.loading].includes(true)
    },
    feeds() {
      if(!this.selectedMultiFilter['availablePartners']){
        return [];
      }
      let a = this.selectedMultiFilter['availablePartners'].map((e) => {
        return e.agency_feed.feed;
      });

      return [...new Set(a)];
    },

    isContent(){
      return this.feeds.indexOf(this.GOOGLE_CONTENT) > -1 && this.feeds.length === 1;
    },

    isParkedDomain(){
      return this.feeds.indexOf(this.PARKED_DOMAIN) > -1 && this.feeds.length === 1;
    }
  },

  methods: {

    prepareDev(){
      // this.keywordsImported = "cheap flights";
      // FBA
      this.selectedMultiFilter = {"availableNetworks":[{"id":"2","code":"FBA","long_code":"FACEBOOK_AD","name":"Facebook Ad","image_required":true,"dealer":"2"}],"productsWithAvailableUrlCodes":[{"id":"90","created_on_datetime":"2022-11-29T12:40:10.752762","updated_on_datetime":"2023-06-26T17:53:55.673214","code":"displaybuyer","name":"DisplayBuyer","label":"Jaime","landing_page":"https://www.displaybuyer.com","is_for_new_creations":true,"is_active":true,"partner":"13"}],"availablePartners":[{"id":"13","agency_feed":{"id":"18","created_on_datetime":"2022-03-23T15:23:43.033409","updated_on_datetime":"2023-06-20T11:24:23.060222","code":"CBS_YAHOO","label":"BENJ_YAHOO","timezone_code":"UTC","keyword_key":"q","urlcode_key":"au","typetag_key":"tt","has_hourly_reporting":false,"lowest_tqs_allowed":4,"is_active":true,"agency":"11","feed":"2"},"organization":{"id":"1","created_on_datetime":"2021-01-25T18:57:22.279349","updated_on_datetime":"2023-06-22T11:00:58.388731","code":"PYTECH","name":"Pytech Innovation FZC","currency_code":"USD","is_active":true,"used_account_managers":["10","11","3f1ff400-b586-44b6-b162-2483f17159d0","4","7","130564ae-a304-46c4-a708-49e4e7f33a0d","6","12","45f8dde3-f662-4349-9d0e-503f080b326f"]},"created_on_datetime":"2022-03-23T15:25:05.822461","updated_on_datetime":"2023-06-01T10:51:54.274158","is_active":true,"code":"CBS_YAHOO"}],"accounts":[],"subdomainLocales":[{"country_id":"41","country_code":"CA","country_name":"Canada"}],"languagesFromSubdomainLocales":[{"id":"5","created_on_datetime":"2021-01-18T19:20:36.774120","updated_on_datetime":"2023-06-20T11:25:03.419063","code":"en","name":"English","is_active":true}]}
      // OBN
      // this.selectedMultiFilter = {"availableNetworks":[{"id":"4","code":"OBN","long_code":"OUTBRAIN_NATIVE","name":"Outbrain Native","image_required":true,"dealer":"3"}],"productsWithAvailableUrlCodes":[{"id":"88","created_on_datetime":"2022-11-29T12:40:10.725766","updated_on_datetime":"2023-06-26T17:53:55.727531","code":"answernow","name":"AnswerNow","label":"Katherine","landing_page":"https://www.answernow.com","is_for_new_creations":true,"is_active":true,"partner":"13"}],"availablePartners":[{"id":"13","agency_feed":{"id":"18","created_on_datetime":"2022-03-23T15:23:43.033409","updated_on_datetime":"2023-06-20T11:24:23.060222","code":"CBS_YAHOO","label":"BENJ_YAHOO","timezone_code":"UTC","keyword_key":"q","urlcode_key":"au","typetag_key":"tt","has_hourly_reporting":false,"lowest_tqs_allowed":4,"is_active":true,"agency":"11","feed":"2"},"organization":{"id":"1","created_on_datetime":"2021-01-25T18:57:22.279349","updated_on_datetime":"2023-06-22T11:00:58.388731","code":"PYTECH","name":"Pytech Innovation FZC","currency_code":"USD","is_active":true,"used_account_managers":["10","11","3f1ff400-b586-44b6-b162-2483f17159d0","4","7","130564ae-a304-46c4-a708-49e4e7f33a0d","6","12","45f8dde3-f662-4349-9d0e-503f080b326f"]},"created_on_datetime":"2022-03-23T15:25:05.822461","updated_on_datetime":"2023-06-01T10:51:54.274158","is_active":true,"code":"CBS_YAHOO"}],"accounts":[],"subdomainLocales":[{"country_id":"41","country_code":"CA","country_name":"Canada"}],"languagesFromSubdomainLocales":[{"id":"5","created_on_datetime":"2021-01-18T19:20:36.774120","updated_on_datetime":"2023-06-20T11:25:03.419063","code":"en","name":"English","is_active":true}]}
      this.checkKeywords();
    },

    loadVuex() {
      if(!this.networks.allAvailableNetworks.length) this.$store.dispatch('networks/getAllAvailableNetworks');
      if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll');
      if(!this.languages.all.length) this.$store.dispatch('languages/getAll');
      if(!this.countries.allFullList.length) this.$store.dispatch('countries/getAllFullList');
    },
    checkKeywords() {

      if(!this.selectedMultiFilter['availableNetworks'].length) {
        return alert('You must select a network.')
      }
      if(!this.selectedMultiFilter['subdomainLocales'].length) {
        return alert('You must select a country.')
      }
      if(!this.selectedMultiFilter['availablePartners'].length) {
        return alert('You must select a partner.')
      }

      let that = this;

      //don't move to next page if media is disabled
      if(this.allowMediaSelection || this.allowCustomizations){
        this.isCheckingKeywords = true;
      }

      // this.indexDataFromDependencies();
      this.normalizeKeywordsImported();

      for(let d in this.keywordsImportedNormalized) {

        let countryId = that.keywordsImportedNormalized[d]['countryId'];
        let keywordName = that.keywordsImportedNormalized[d]['keywordName'];

        // Set default high, just to make sure we only display after response of aAPI
        Vue.set(that.keywordsImportedNormalized[d], 'existingImages', 10);
        Vue.set(that.keywordsImportedNormalized[d], 'previouslySelectedImagesList', [])

        if(this.allowMediaSelection){
          creationKeywordImagesListService.get({
            'keyword_name': keywordName,
            'country': countryId
          }).then((e) => {
            Vue.set(that.keywordsImportedNormalized[d], 'previouslySelectedImagesList', e.data)
            Vue.set(that.keywordsImportedNormalized[d], 'existingImages', e.data.length);
          })
        }

        //directly import when they disable media selection
        if(!that.allowMediaSelection && !that.allowCustomizations && +d === this.keywordsImportedNormalized.length-1) {
          that.importKeyword()
        }
      }
    },

    loadSubdomainLocale(responseKey, countryId, networkId, languageId, label){

      let that = this,
          agencyFeedIds = this.selectedAgencyFeeds.map((e) => { return e.id }).join(','),
          key = this.getKeySubdomainLocale(countryId, networkId, languageId);

      if(key in this.availableSubdomainLocales){
        return
      }

      if(this.availableTypes.indexOf(key) === -1){
        this.availableTypes.push(key);
      }

      this.availableSubdomainLocalesIsLoading += 1;

      Vue.set(that.availableSubdomainLocales, key, {
          'data': [],
          'label': label,
      });

      administrationAvailableSubdomainLocalesService.getAll({
        'network': networkId,
        'language': languageId,
        'country': countryId,
        'agency_feed__in': agencyFeedIds
      }).then(response => {

        this.availableSubdomainLocalesIsLoading -= 1;

        let data = response.data['results'];

        Vue.set(that.availableSubdomainLocales, key, {
          'data': data,
          'label': label,
        });

        Vue.set(that.availableSubdomainLocalesSelected, key, []);
      })

    },

    loadAdUnits(responseKey, countryId, networkId, languageId, label, force){

      let that = this,
          agencyFeedIds = this.selectedAgencyFeeds.map((e) => { return e.id }).join(','),
          key = this.getKeySubdomainLocale(countryId, networkId, languageId),
          params;

      if(key in this.availableAdUnits && !force){
        return
      }

      if(this.availableTypes.indexOf(key) === -1){
        this.availableTypes.push(key);
      }

      this.availableAdUnitsIsLoading += 1;

      Vue.set(that.availableAdUnits, key, {
        'data': [],
        'label': label,
      });

      params = {
        'network': networkId,
        'language': languageId,
        'country': countryId,
        'agency_feed__in': agencyFeedIds
      };

      if(this.allowDomainSelection){
        if(this.availableSubdomainLocalesSelected[key]){
          params['subdomain_locale__in'] = this.availableSubdomainLocalesSelected[key].join(',');
        }
      }

      administrationAvailableAdUnitService.getAll(params).then(response => {

        this.availableAdUnitsIsLoading -= 1;

        let data = response.data['results'];

        Vue.set(that.availableAdUnits, key, {
          'data': data,
          'label': label,
        });

        Vue.set(that.availableAdUnitsSelected, key, []);
      })

    },

    getKeySubdomainLocale(countryId, networkId, languageId){
      let agencyFeedIds = this.selectedAgencyFeeds.map((e) => { return e.id });
      return agencyFeedIds.join('_') + '__' + countryId + '__' + networkId + '__' + languageId;
    },

    availableSubdomainLocalesChanged(key){
      let that = this,
          label;

      if(!this.allowAdUnitSelection){
        return;
      }

      for(let d in this.keywordsImportedNormalized) {

        let countryId = that.keywordsImportedNormalized[d]['countryId'];
        let networkId = that.keywordsImportedNormalized[d]['networkId'];
        let languageId = that.keywordsImportedNormalized[d]['languageId'];
        let countryName = that.keywordsImportedNormalized[d]['countryName'];
        let languageName = that.keywordsImportedNormalized[d]['languageName'];
        let networkName = that.keywordsImportedNormalized[d]['networkName'];

        let newKey = this.getKeySubdomainLocale(countryId, networkId, languageId);

        if(newKey === key){
          label = countryName + ' - ' + languageName + ' - ' + networkName
          this.loadAdUnits(d, countryId, networkId, languageId, label, true);
        }

      }
    },

    normalizeKeywordsImported() {

      let data = [];
      let keywords;

      if(this.isParkedDomain) {

        keywords = [];

        for(let i = 0; this.domainKeywords.length > i; i++){
          keywords.push({
            'keywordName': this.domainKeywords[i].domain.toLowerCase().trim(),
            'relatedSearches': this.domainKeywords[i].keywords.map(e => e.name.toLowerCase().trim())
          });
        }

      }else if(this.isContent){
        keywords = this.keywordsImported.map(e => e.resource_name);
      }else{
        keywords = this.keywordsImported.split(/[\n\t,;]+/).filter(r=>r)
      }

      let uniqueKeywordsChecker = [];

      for(let n in this.selectedMultiFilter['availableNetworks']){

        for(let d in keywords){
          for(const c in this.selectedMultiFilter['subdomainLocales']) {
            let row;

            if(this.isParkedDomain){
              row = keywords[d];
              Vue.set(row, 'relatedSearches', row['relatedSearches']);
            }else{
              row = Object.assign({}, {'keywordName': keywords[d].trim()});
            }

            if(this.isContent){
              Vue.set(row, 'keywordNameTitle', this.keywordsImported.filter(e => e.resource_name === keywords[d])[0].title);
            }else{
              Vue.set(row, 'keywordNameTitle', null);
            }
            Vue.set(row, 'keywordName', row['keywordName'].toLowerCase());
            Vue.set(row, 'keywordName', row['keywordName'].toLowerCase());
            Vue.set(row, 'networkId', this.selectedMultiFilter['availableNetworks'][n].id);
            Vue.set(row, 'networkCode', this.selectedMultiFilter['availableNetworks'][n].code);
            Vue.set(row, 'networkName', this.selectedMultiFilter['availableNetworks'][n].name);
            Vue.set(row, 'checkKeywordAndNetworkStatus', null);
            Vue.set(row, 'checkKeywordStatus', null);
            Vue.set(row, 'savedStatus', null);
            Vue.set(row, 'displayImageSearcherOnlyOncePerKeyword', this.displayImageSearcherOnlyOncePerKeyword(
                row['keywordName']
            ));

            Vue.set(row, 'countryCode', this.selectedMultiFilter['subdomainLocales'][c].country_code);
            Vue.set(row, 'countryName', this.selectedMultiFilter['subdomainLocales'][c].country_name);
            Vue.set(row, 'countryId', this.selectedMultiFilter['subdomainLocales'][c].country_id);

            if(this.allowCustomizations){
              Vue.set(row, 'creatives', []);
              Vue.set(row, 'creativeModal', false);
              Vue.set(row, 'extraSettings', {});
              Vue.set(row, 'extraSettingsModal', false);
            }

            if(uniqueKeywordsChecker.indexOf(row['keywordName'].toLowerCase()) > -1){
              let msg = 'Duplicated keyword: "' + row['keywordName'].toLowerCase() + '" has been removed.';
              this.$buefy.toast.open({'message': msg, 'position': 'is-top'});
              continue;
            }

            uniqueKeywordsChecker.push(row['keywordName'].toLowerCase());
            data.push(row);
          }

        }
      }

      this.keywordsImportedNormalized = data;
    },

    importKeyword() {

      let params;

      const that = this;
      const agency_feeds = this.selectedMultiFilter['availablePartners'].map((element) => { return element.agency_feed.id });
      const languages = this.selectedMultiFilter['languagesFromSubdomainLocales'].map((element) => { return element.id });
      const products = this.selectedMultiFilter['productsWithAvailableUrlCodes'].map((element) => { return element.id });
      const countries = this.selectedMultiFilter['subdomainLocales'].map((element) => { return element.country_id });

      this.savingInProgress = 0;

      for(let d in this.keywordsImportedNormalized) {
        let row = this.keywordsImportedNormalized[d];

        Vue.set(that.keywordsImportedNormalized[d], 'savedStatus', 'LOADING');

        this.savingInProgress += 1;

        if(row['keywordName'] === ''){
          return;
        }

        params = {
          'keyword_name': row['keywordName'],
          'country': row['countryId'],
          // 'languages': [ row['languageId'] ],
          languages,
          agency_feeds,
          'foundkeywordnetworks': [{'network': row['networkId']}],
          'sources': [1],
          'images': []
        };

        if(this.allowCustomizations){
          let extraSettingsNormalized = Object.assign({}, row['extraSettings']);

          if(extraSettingsNormalized['device_criteria']){
            extraSettingsNormalized['device_criteria'] = extraSettingsNormalized['device_criteria'].map((e) => e.id );
          }

          if(extraSettingsNormalized['placement_criteria']){
            extraSettingsNormalized['placement_criteria'] = extraSettingsNormalized['placement_criteria'].map((e) => e.id );
          }

          params['json_custom_settings'] = {
            ...extraSettingsNormalized
          }

          if( (row['creatives'] || []).length > 0){
            params['json_custom_settings']['creatives'] = this.normalizeCreatives(row['creatives']);
          }

          if(Object.keys(params['json_custom_settings']).length === 0){
            Vue.delete(params, 'json_custom_settings')
          }
        }

        let subdomain_locales = []
        const mainSubdomainList = [...that.subdomainLocales.baseLanguages, ...that.subdomainLocales.all]

        //add subdomain_locales if they select product or language
        for(let r in mainSubdomainList) {
          if(languages.length && products.length) {
            if(languages.includes(mainSubdomainList[r].subdomain_locale.language.id) &&
                products.includes(mainSubdomainList[r].subdomain_locale.product.id) &&
                countries.includes(mainSubdomainList[r].subdomain_locale.country.id)) {
                subdomain_locales.push(mainSubdomainList[r].subdomain_locale.id)
            }
          } else if(languages.length && !products.length) {
            if(languages.includes(mainSubdomainList[r].subdomain_locale.language.id) &&
                countries.includes(mainSubdomainList[r].subdomain_locale.country.id)) {
                subdomain_locales.push(mainSubdomainList[r].subdomain_locale.id)
            }
          } else if(products.length && !languages.length) {
            if(products.includes(mainSubdomainList[r].subdomain_locale.product.id) &&
                countries.includes(mainSubdomainList[r].subdomain_locale.country.id)) {
                subdomain_locales.push(mainSubdomainList[r].subdomain_locale.id)
            }
          }
        }
        if(subdomain_locales.length) params['subdomain_locales'] = [...new Set(subdomain_locales)]

        if(this.allowMediaSelection){
            params['images'] = this.getSelectedImagesByKeywordName(row['keywordName'], row['countryId']);
            params['personalized_images'] = this.getUploadedImagesByKeywordName(row['keywordName'], row['countryId']);
        }

        if(this.isParkedDomain){
          params['json_url_extra_parameters'] = {
            'sqs': row['relatedSearches'].map(e => e.name).join(',')
          };
        }

        creationFoundKeywordsService.post(params).then(
          () => {
            Vue.set(that.keywordsImportedNormalized[d], 'savedStatus', 'OK');
            this.savingInProgress -= 1;

            that.$buefy.snackbar.open({
              message: 'Keyword ('+ row['keywordName'] +') imported successfully.',
              queue: false
            })

            if(+d === that.keywordsImportedNormalized.length-1) {
              that.isCheckingKeywords = false;
              that.keywordsImported = '';
              that.savingInProgress = -1;
            }

          },
          error =>
          {
            this.savingInProgress -= 1;
            Vue.set(that.keywordsImportedNormalized[d], 'savedStatus', 'NOT_OK');
            alert('An Error Occurred 1');
            console.error(error);
          })
      }
    },

    normalizeCreatives(creatives){
      let normalizedCreative = {"creatives": []};

      for(let i in creatives['creatives']){
        normalizedCreative["creatives"].push(creatives['creatives'][i]);
      }

      return normalizedCreative;
    },

    removeFromKeywordsImportedNormalized(rowId){
      Vue.delete(this.keywordsImportedNormalized, rowId);
    },

    restartImport() {
      let x = confirm('Are you sure?')

      if(!x){
        return;
      }

      window.location.reload()
      this.isCheckingKeywords = false;
      this.keywordsImported = '';
      this.keywordsImportedNormalized = [];
      this.savingInProgress = -1;
    },

    displayImageSearcherOnlyOncePerKeyword(keyword){

      keyword = keyword.toLowerCase();

      if(this.keywordsImageSearcher.indexOf(keyword) > -1){
        return false;
      }

      this.keywordsImageSearcher.push(keyword);

      return true;

    },

    getSelectedImagesByKeywordName(keywordName, countryId){

      for(let i in this.keywordsImportedNormalized){
        let row = this.keywordsImportedNormalized[i];
        //since country became 
        if(row['keywordName'] === keywordName && row['countryId'] === countryId) {
          if(typeof row['selectedImages'] !== 'undefined' && row['selectedImages']['selectedImages'].length > 0){
            return row['selectedImages']['selectedImages'].map((e) => { return {'image_hash_unique': e.hash_unique} })
          }
        }
      }

    },

    getUploadedImagesByKeywordName(keywordName, countryId){

      for(let i in this.keywordsImportedNormalized){
        let row = this.keywordsImportedNormalized[i];
        //since country became
        if(row['keywordName'] === keywordName && row['countryId'] === countryId) {
          if(typeof row['selectedImages'] !== 'undefined' && row['selectedImages']['uploadedImages'].length > 0){
            return row['selectedImages']['uploadedImages'].map((e) => { return {'image_hash_unique': e.hash_unique} })
          }
        }
      }

    },

    captureTab(e){
      let target = e.target,
          char = "\t";

      if (target.setRangeText) {
          //if setRangeText function is supported by current browser
          target.setRangeText(char,
                              target.selectionStart,
                              target.selectionEnd,
                              'end');
      } else {
          // Deprecated, so only used when setRangeText is not available.
          target.focus()
          document.execCommand('insertText', false /*no UI*/, char);
      }
    },
  },
  watch: {
    selectedMultiFilter: {
      handler: function(newValueSelectedMultiFilter) {
        if(!this.isContent){
          return;
        }
        this.contentsIsLoading = true;
        contentsService.getAll({
          'agency_feed__in': newValueSelectedMultiFilter['availablePartners'].map(e => e.agency_feed.id).join(','),
          'product__in': newValueSelectedMultiFilter['productsWithAvailableUrlCodes'].map(e => e.id).join(','),
          'subdomain_locales__in': newValueSelectedMultiFilter['subdomainLocales'].map(e => e.id).join(','),
          'country__in': newValueSelectedMultiFilter['subdomainLocales'].map((element) => { return element.country_id }).join(','),
          'language__in': newValueSelectedMultiFilter['languagesFromSubdomainLocales'].map((element) => { return element.id }).join(','),
        }).then(response => {
          this.contents = response.data['results'];
          this.contentsIsLoading = false;
        })
      },
      deep: true
    }
  }

}
</script>