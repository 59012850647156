import { config } from './config';
import axios from "axios";

export const optimizationLogOptimizationGroupsService = {
    // getAllByDateRange,
    getAllByRouteHashDateRange,
    getAll,
    config
};

// export function getAllByDateRange(mainEntityId, fromDate, toDate){
//
//     let axiosConfig = {},  params = {};
//
//     params['main_entity'] = mainEntityId;
//     params['created_on_datetime__range'] = fromDate +'T00:00:00.0' + ',' + toDate + 'T23:59:59.0';
//     params['nocache'] = Date.now();
//
//     // Append to request.
//     axiosConfig['params'] = params;
//
//     return new Promise((resolve, reject) => {
//       axios
//         .get(`${config.apiUrl}/api/optimization/log-optimization-groups/`, axiosConfig)
//         .then(response => {
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
// }

export function getAll(mainEntityId){

    let axiosConfig = {},  params = {};

    params['main_entity'] = mainEntityId;
    params['ordering'] = '-created_on_datetime';
    params['nocache'] = Date.now();
    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/optimization/log-optimization-groups/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function getAllByRouteHashDateRange(routeHash, fromDate, toDate){

    let axiosConfig = {}, params = {};

    params['main_entity__route_hash'] = routeHash;
    params['created_on_datetime__range'] = fromDate +'T00:00:00.0' + ',' + toDate + 'T23:59:59.0';
    params['ordering'] = 'created_on_datetime';
    params['page_size'] = 5000;
    params['nocache'] = Date.now();

    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/optimization/log-optimization-groups/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}