<style scoped>
.box-shadow {
   box-shadow: 1px 1px 10px 0px #ccc
}
@media (max-width: 480px) {
  .modal-card {
    width: calc(100% - 40px);
  }
}
</style>
<template>
      <div class="modal-card my-6 box-shadow">
        <form @submit.prevent="handleSubmit">
            <p v-if="errorMsg" class="notification is-danger is-light m-4">{{ errorMsg }}</p>
            <header class="modal-card-head">
                <p class="modal-card-title">Login</p>
            </header>
            <section class="modal-card-body pb-6">
                <b-field label="Username" :type="errorMsg ? 'is-danger' : null">
                    <b-input
                        name="username"
                        type="text"
                        v-model="username"
                        placeholder="Username"
                        required>
                    </b-input>
                </b-field>

                <b-field label="Password" :type="errorMsg ? 'is-danger' : null">
                    <b-input
                        type="password"
                        v-model="password"
                        password-reveal
                        placeholder="Password"
                        required>
                    </b-input>
                </b-field>

                <b-modal v-model="ShowOTPSection">


                  <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">OTP Required</p>
                        <button
                            type="button"
                            class="delete"
                            @click="ShowOTPSection = false"/>
                    </header>
                    <section class="modal-card-body">

                      <b-field label="OTP" :type="errorMsg ? 'is-danger' : null" class="mt-2">
                            <b-input
                                type="text"
                                v-model="otp"
                                placeholder="OTP from an Authentication app" />
                      </b-field>
                      <div>
                          <b-checkbox v-model="remember_me" class="mt-3"
                              native-value="true">
                              Don't require OTP on this browser
                          </b-checkbox>
                      </div>

                    </section>
                    <footer class="modal-card-foot">

                      <button class="button is-primary" :disabled="loggingIn">Login</button>

                    </footer>
                </div>

                </b-modal>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-primary" :disabled="loggingIn" v-if="!ShowOTPSection">Login</button>
            </footer>
        </form>
    </div>
</template>

<script>
export default {
    data () {
        return {
            username: '',
            password: '',
            submitted: false,
            errorMsg: '',
            otp: '',
            ShowOTPSection: false,
            remember_me: [],
        }
    },
    computed: {
        loggingIn () {
            return this.$store.state.authentication.status.loggingIn;
        },
    },
    created () {
        // reset login status
        const { dispatch } = this.$store;
        dispatch('authentication/logout');
    },
    methods: {
        handleSubmit () {
            this.submitted = true;
            const { username, password, otp } = this;
            const { dispatch } = this.$store;
            if (username && password) {
                const payload = {username, password}
                
                if(otp) payload.otp = otp
                if(this.remember_me.length) payload.remember_me = true

                dispatch('authentication/login', payload)
                    .catch(er => {
                        if([400, 401].includes(er.response?.status)) {
                            this.errorMsg = 'No active account found with the given credentials'
                        } else if([403].includes(er.response?.status)) {
                            this.ShowOTPSection = true
                            // this.errorMsg = er.response?.data?.msg.toLowerCase().replace('otp', 'OTP') || 'Type a valid OTP'
                        }
                    })
            }
        }
    }
};
</script>