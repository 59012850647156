<template>
  <div>
    <section v-if="$store.state.theme.viewMode === 'normal'" class="section is-main-section" ref="filterstop">
      <dependant-multi-filter
        v-model="selectedMultiFilter"
        products-enabled
        networks-enabled
        agency-feeds-enabled
        countries-enabled
        accounts-enabled
        bidding-strategies-enabled

        @filter="filterList"
        @download="downloadAsCsv()" />

        <b-field>
            <strong>
              {{ !showActive && !showPaused && !showRemoved ? '(showing all)': '' }}
            </strong>
            <b-switch v-model="showActive">
                {{ showActive ? 'Showing Active' : 'Hiding Active' }}
            </b-switch>
            <b-switch v-model="showPaused">
                {{ showPaused ? 'Showing Paused' : 'Hiding Paused' }}
            </b-switch>
            <b-switch v-model="showRemoved">
                {{ showRemoved ? 'Showing Removed' : 'Hiding Removed' }}
            </b-switch>

        </b-field>
        <div style="border: 1px #ccc solid; padding: 1rem;" v-for="(dr, drId) in dateRules" v-bind:key="`dates-rules-${drId}`">
          <b-field>
           <b-datepicker
              class="form-control"
              style="min-width: 8rem; max-width: 9rem;"
              placeholder="Date..."
              v-model="dr.date">
              <b-button
              label="Today"
              type="is-primary"
              icon-left="calendar-today"
              @click="setSelectedDate('TODAY')" />
              <b-button
              label="Yesterday"
              type="is-primary"
              icon-left="calendar"
              @click="setSelectedDate('YESTERDAY')" />
          </b-datepicker>
          <b-button @click="addDateRules">
            Add Date
          </b-button>
          <b-button @click="removeDateRules(drId)">
            Remove This Date
          </b-button>
        </b-field>
        <b-field grouped v-for="(r, rowId) in dr.rules" v-bind:key="`performance-filter-${rowId}-${drId}`">

                <b-field @keyup.native.delete="e => delWithTab(r, drId, rowId, e)">
                    <b-button
                    type="is-danger"
                    @click="removeRule(r, drId, rowId);"
                    :disabled="dr.rules.length === 1"
                    icon-left="close" />

                    <b-select
                        :ref="'r' + rowId"
                        placeholder="Index"
                        v-model="dr.rules[rowId].indexName"
                        @change.native="setFirstOperator(drId, rowId)"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-name-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Operator"
                        v-model="dr.rules[rowId].indexOperator"
                        :disabled="Object.keys(dr.rules[rowId].indexName).length === 0">
                        <option
                            v-for="(indexOperator, indexOperatorI) in getOperators(dr.rules[rowId].indexName.operators)"
                            :key="`performance-index-operator-${rowId}-${indexOperatorI}`"
                            :value="indexOperator"
                        >
                          {{ indexOperator.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Index"
                        v-model="dr.rules[rowId].indexValueField"
                        v-if="(dr.rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-value-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>
                    <span
                        v-if="(dr.rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                        style="padding: 1rem;"
                    >*</span>
                    <b-input
                        type="number"
                        v-model="dr.rules[rowId].indexFactor"
                        v-if="(dr.rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                        style="width: 50px;"
                    ></b-input>

                    <b-input
                        type="number"
                        v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="dr.rules[rowId].indexName.indexType === 'number'"
                    ></b-input>
                    <b-input
                        type="number"
                        v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        v-else-if="dr.rules[rowId].indexName.indexType === 'money'"
                    ></b-input>
                    <b-input
                        type="number"
                        v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        prefix="%"
                        v-else-if="dr.rules[rowId].indexName.indexType === 'percentage'"
                    ></b-input>
                    <b-input
                        type="text"
                        v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        v-else-if="dr.rules[rowId].indexName.indexType === 'text'"
                    ></b-input>

                    <b-datetimepicker
                        v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="dr.rules[rowId].indexName.indexType === 'datetime'"
                        :datetime-formatter="formatDatetime"
                        :timepicker="{ enableSeconds: true }"
                        placeholder="Click to select...">

                        <template #left>
                            <b-button
                                label="Now"
                                type="is-primary"
                                icon-left="clock"
                                @click="datetime = new Date()" />
                        </template>

                        <template #right>
                            <b-button
                                label="Clear"
                                type="is-danger"
                                icon-left="close"
                                outlined
                                @click="datetime = null" />
                        </template>
                    </b-datetimepicker>

                    <b-select
                        v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="dr.rules[rowId].indexName.indexType === 'deliveryStatus'"
                    >
                      <option value="DELIVERING">
                        Delivering
                      </option>
                      <option value="NOT_DELIVERING">
                        Not Delivering
                      </option>
                    </b-select>

                    <b-select
                        v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="dr.rules[rowId].indexName.indexType === 'status'"
                    >
                      <option value="ACTIVE">
                        Active
                      </option>
                    </b-select>
                    <log-optimization-group-filter
                      v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                      v-else-if="dr.rules[rowId].indexName.indexType === 'optimizationMessage'"
                    ></log-optimization-group-filter>

                    <log-deletion-group-filter
                      v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                      v-else-if="dr.rules[rowId].indexName.indexType === 'deletionMessage'"
                    ></log-deletion-group-filter>

                    <b-input
                        type="text"
                        v-model="dr.rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        disabled
                        v-else
                    ></b-input>

                  <b-button
                    type="is-success"
                    @click="addRule(drId);"
                    @keyup.tab="addWithTab(rowId, drId)"
                    icon-left="check" />
                  </b-field>
        </b-field>
          </div>


      </section>


      <section class="section is-main-section" style="padding-top: 0;">
        Rows: {{ data.length }}
        <b-table
          :loading="isLoading"
          :striped="true"
          :hoverable="true"
          default-sort="total_count"
          :data="data"
          :height="tableHeight()"

          pagination-position="top"
          :pagination-rounded="false"
          sort-icon="chevron-up"
          sort-icon-size="is-medium"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :sticky-header="true"

          paginated
          :per-page="50"
          :sort-multiple="true"
          narrowed >
          <div v-if="showEmptyResultMessages" slot="empty" class="notification is-warning is-light has-text-centered">
            The selected query didn't return any records, Try a different date or reduce the amount of filters.
          </div>

          <b-table-column label="Hash" field="route_hash" sortable v-slot="props">
            {{ props.row.route_hash }}
          </b-table-column>

          <b-table-column field="country" label="Country" sortable v-slot="props">
            {{ props.row.country }}
          </b-table-column>

          <b-table-column field="product" label="Product" sortable v-slot="props">
            {{ props.row.product }}
          </b-table-column>

          <b-table-column field="keyword_name" label="Keyword" sortable v-slot="props">
            {{ props.row.keyword_name }}
          </b-table-column>

          <b-table-column label="From" field="from_date" sortable v-slot="props">
            {{ props.row.from_date }}
          </b-table-column>

          <b-table-column label="To" field="to_date" sortable v-slot="props">
            {{ props.row.to_date }}
          </b-table-column>

          <b-table-column label="Cost" field="sum_cost_amount" sortable v-slot="props">
            {{ renderCurrency(props.row.sum_cost_amount, 2) }}
          </b-table-column>

          <b-table-column label="Revenue" field="sum_revenue_amount_net" sortable v-slot="props">
            {{ renderCurrency(props.row.sum_revenue_amount_net, 2) }}
          </b-table-column>
          
          <b-table-column label="Profit" field="profit" sortable>
            <template v-slot="props">
              <span :class="{
                'has-text-success': makeNumber(props.row.profit) >= 0,
                'has-text-danger': makeNumber(props.row.profit) < 0,
              }">
                {{ renderCurrency(props.row.profit, 2) }}
              </span>
            </template>
          </b-table-column>

          <b-table-column field="bid_amount" label="Bid" sortable v-slot="props">
            {{ renderCurrency(props.row.bid_amount, 2) }}
          </b-table-column>

          <b-table-column field="sum_clicks_in_r" label="ClicksIn(R)" sortable v-slot="props">
            {{ renderNumber(props.row.sum_clicks_in_r, 0) }}
          </b-table-column>

          <b-table-column field="sum_clicks_in_c" label="ClicksIn(C)" sortable v-slot="props">
            {{ renderNumber(props.row.sum_clicks_in_c, 0) }}
          </b-table-column>

          <b-table-column field="sum_clicks_out_r" label="ClicksOut(R)" sortable v-slot="props">
            {{ renderNumber(props.row.sum_clicks_out_r, 0) }}
          </b-table-column>

          <b-table-column field="sum_clicks_out_c" label="ClicksOut(C)" sortable v-slot="props">
           {{ renderNumber(props.row.sum_clicks_out_c, 0) }}
          </b-table-column>

          <b-table-column field="ctr_r" label="CTR(R)" sortable v-slot="props">
            {{ renderNumber(props.row.ctr_r, 2) }}
          </b-table-column>

          <b-table-column field="ctr_c" label="CTR(C)" sortable v-slot="props">
           {{ renderNumber(props.row.ctr_c, 2) }}
          </b-table-column>

          <b-table-column field="cpc_c" label="CPC(C)" sortable v-slot="props">
            {{ renderCurrency(props.row.cpc_c, 3) }}
          </b-table-column>

          <b-table-column field="cpc_r" label="CPC(R)" sortable v-slot="props">
            {{ renderCurrency(props.row.cpc_r, 3) }}
          </b-table-column>

          <b-table-column field="cpa_c" label="CPA(C)" sortable v-slot="props">
            {{ renderCurrency(props.row.cpa_c, 3) }}
          </b-table-column>

          <b-table-column field="cpa_r" label="CPA(R)" sortable v-slot="props">
            {{ renderCurrency(props.row.cpa_r, 3) }}
          </b-table-column>

          <b-table-column field="cir" label="CiR" sortable v-slot="props">
            {{ renderNumber(props.row.cir, 2) }}
          </b-table-column>

          <b-table-column field="cor" label="CoR" sortable v-slot="props">
            {{ renderNumber(props.row.cor, 2) }}
          </b-table-column>

          <b-table-column field="username" label="username" sortable v-slot="props">
            {{ props.row.username }}
          </b-table-column>

          <b-table-column label="View Item" sortable v-slot="props">
            <a :href="`/performance-analysis?itemType=ROUTE_HASH&itemValue=${props.row.route_hash}&dateFrom=${props.row.from_date}&dateTo=${props.row.to_date}`" target="_blank">View</a>
          </b-table-column>

          <b-table-column label="View Keyword" sortable v-slot="props">
            <a :href="`/performance-analysis?itemType=KEYWORD_NAME&itemValue=${props.row.keyword_name}&dateFrom=${props.row.from_date}&dateTo=${props.row.to_date}`" target="_blank">View</a>
          </b-table-column>

        </b-table>
      </section>
  </div>
</template>

<script>
import Vue from "vue";
import {getAllKeywordsLife} from "@/services";
import moment from "moment";
import {numberUtils} from "@/helpers/number-utils";
import {timeUtils} from "@/helpers/time-utils";
import LogOptimizationGroupFilter from "@/components/Filter/LogOptimizationGroupFilter.vue";
import LogDeletionGroupFilter from "@/components/Filter/LogDeletionGroupFilter";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import { mapState } from "vuex";
import {permissionsUtils} from "@/helpers/permissions-utils";

export default {
  name: 'KeywordLifePage',

  created() {
    this.dateRules[0].rules.push(Object.assign({}, this.emptyRule))

    if(this.$route.query.productsId){
      let products = [];
      if(Array.isArray(this.$route.query.countriesId)) {
        for (let i in this.$route.query.productsId) {
          products.push({
            id: this.$route.query.productsId[i]
          });
        }
      }else{
        products.push({
          id: this.$route.query.productsId
        });
      }

      this.selectedProducts = products;
    }

    if(this.$route.query.countriesId){
      let countries = [];
      if(Array.isArray(this.$route.query.countriesId)){
        for(let i in this.$route.query.countriesId){
          countries.push({
            id: this.$route.query.countriesId[i]
          });
        }
      }else{
        countries.push({
          id: this.$route.query.countriesId
        });
      }


      this.selectedCountries = countries;
    }

    // hide url if the user isMarketingUntrusted
    if (!this.isMarketingUntrusted()){
      this.indexNames.splice(2, 0, {value: "route_hash__livemainentity__url_value", text: "Url", indexType: "text", operators: ['inText']})
    }

  },
  components: {
    LogOptimizationGroupFilter,
    LogDeletionGroupFilter,
    DependantMultiFilter,
  },


  data () {

    return {

      data: [],
      isLoading: false,

      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'products': [],
        'countries': [],
        'accounts': [],
        'biddingStrategies': [],
      },

      showEmptyResultMessages: false,

      dateRules: [{
        date: new Date(),
        rules: [],
      }],

      showRemoved: true,
      showPaused: true,
      showActive: true,

      selectedNetworks: [],
      selectedAgencyFeeds: [],
      selectedProducts: [],
      selectedCountries: [],
      selectedAccounts: [],
      selectedBiddingStrategies: [],

      // Sorting:
      singleSorting: null,
      sortingPriority: [],
      customKey: 'ctrlKey',

      selectedDate: new Date(),

      pageSize: 25,
      pageNumber: 1,

      displayNewRule: false,
      emptyRule: {indexName: {}, indexOperator: {}, indexValue: null, indexType: {}, indexValueField: '', indexFactor: 1},
      indexNamesObject: {},
      fieldFactor: 1,
      selectedIndexName: '',
      selectedIndexOperator: '',
      selectedIndexValue: '',
      indexNames: [
          {value: "route_hash", text: "Route Hash", indexType: "text", operators: ['in']},
          {value: "route_hash__livemainentity__keyword_name", text: "Keyword", indexType: "text", operators: ['eq', 'in', 'inText']},
          {value: "cost_amount", text: "Cost", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cost_amount_account_currency", text: "Cost(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cost_impressions", text: "Impression(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_c", text: "ClicksIn(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_r", text: "ClicksIn(Revenue)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_c", text: "ClicksOut(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_r", text: "ClicksOut(Revenue", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "revenue_amount_net", text: "Revenue(Net)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_budget_amount", text: "Budget", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_budget_amount_account_currency", text: "Budget(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_bid_amount", text: "BidAmount", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_bid_amount_account_currency", text: "BidAmount(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "route_hash__livemainentity__status", text: "Status", indexType: "status", operators: ['eq', 'in']},
          {value: "prop_delivery_status", text: "Delivery Status", indexType: "deliveryStatus", operators: ['eq', 'in']},
          {value: "profit", text: "Profit", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "roi", text: "ROI", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "ctr_r", text: "CR(R)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "ctr_c", text: "CR(C)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cpa_r", text: "CPA(R)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cpa_c", text: "CPA(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rpc", text: "RPC", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rpc_c", text: "RPC(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rps", text: "RPS", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_ratio", text: "ClicksOut(RevCostRatio)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_ratio", text: "ClicksIn(RevCostRatio)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},


          {value: "route_hash__livemainentity__last_optimization_on_datetime", text: "Last Optimization", indexType: "datetime", operators: ['lte', 'lt', 'gt', 'gte', 'eq',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "route_hash__livemainentity__created_on_datetime", text: "Created On", indexType: "datetime", operators: ['lte', 'lt', 'gt', 'gte', 'eq',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},

          {value: "log_optimization_group_filter", text: "Optimization", indexType: "optimizationMessage", operators: ['eq']},
          {value: "log_deletion_group_filter", text: "Deletion", indexType: "deletionMessage", operators: ['eq']},

      ],
      indexOperators: {
        'eq': {value: "", text: "="},
        'inText': {value: "__icontains", text: "~="},
        'in': {value: "__in", text: "=,"},
        'gt': {value: "__gt", text: ">"},
        'lt': {value: "__lt", text: "<"},
        'lte': {value: "__lte", text: "<="},
        'gte': {value: "__gte", text: ">="},

        'eqField': {value: "exactField", text: "=(Field)"},
        'gtField': {value: "gtField", text: ">(Field)"},
        'ltField': {value: "ltField", text: "<(Field)"},
        'lteField': {value: "lteField", text: "<=(Field)"},
        'gteField': {value: "gteField", text: ">=(Field)"},
      },
    }
  },
    mixins: [
    numberUtils,
    timeUtils,
        permissionsUtils
  ],

  computed: {
    ...mapState(['log-optimization-groupFilter']),

  },

  methods: {

    tableHeight () {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      return vh - 175
    },

    addWithTab(r, dateRulesIndex) {
      if(!this.$refs['r' + r][0].value.text) return;
      
      this.addRule(dateRulesIndex)
      const nextRow =  r+1
      this.$nextTick(() => {
        this.$refs['r' + nextRow][0].focus()
      })
    },

    delWithTab(r, dateRulesIndex, rowId, e) {
      if(e.code !== 'Delete' || rowId + 1 === 1) return;

      this.removeRule(r, dateRulesIndex, rowId)
      const previousRow = rowId - 1
      this.$nextTick(() => {
        this.$refs['r' + previousRow][0].focus()
      })
    },

    formatDatetime (time){
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },

    addDateRules (){
      Vue.set(this.dateRules, this.dateRules.length, {
        date: new Date(),
        rules: [Object.assign({}, this.emptyRule)]
      })
    },

    removeDateRules: function (dateRulesIndex) {
      if(dateRulesIndex === 0)
      {
        return alert('You cannot remove the first row.');
      }

      this.$delete(this.dateRules, dateRulesIndex);
    },

    removeRule: function (rule, dateRulesIndex, rowId) {
      this.$delete(this.dateRules[dateRulesIndex].rules, rowId);
    },

    addRule: function (dateRulesIndex){
      Vue.set(
          this.dateRules[dateRulesIndex].rules,
          this.dateRules[dateRulesIndex].rules.length,
          Object.assign({}, this.emptyRule)
      )
    },

    getOperators: function (operatorsArray) {
      let r = [];

      for(let i in operatorsArray){
        r.push(this.indexOperators[operatorsArray[i]]);
      }

      return r;
    },

    setFirstOperator: function (dateRulesIndex, rowId){
      let r = this.getOperators(this.dateRules[dateRulesIndex].rules[rowId].indexName.operators, rowId);
      if(this.dateRules[dateRulesIndex].rules[rowId] && this.dateRules[dateRulesIndex].rules[rowId].indexOperator){
        if(r.length > 0){
          if(JSON.stringify(this.dateRules[dateRulesIndex].rules[rowId].indexOperator) === '{}'){
            this.dateRules[dateRulesIndex].rules[rowId].indexOperator = r[0];
          }else if(this.dateRules[dateRulesIndex].rules[rowId].indexName.operators.indexOf(this.dateRules[dateRulesIndex].rules[rowId].indexOperator.value) === -1) {
            this.dateRules[dateRulesIndex].rules[rowId].indexOperator = r[0];
          }
        }
      }
    },

    selectedAgencyFeedsChanged: function (){
      this.$store.dispatch('products/getAll', {
        'agencyFeeds': this.selectedAgencyFeeds.map((element) => { return element.id })
      });
    },

    filterList() {

      this.showEmptyResultMessages = false;
      this.isLoading = true;
      this.data = [];

      let dateRulesParsed = [];

      for(let i in this.dateRules){
        dateRulesParsed.push(
            {
              date: moment(this.dateRules[i].date).format('YYYY-MM-DD'),
              rules: this.rulesParsed(i)
            }
        );
      }

      getAllKeywordsLife(
          this.selectedMultiFilter['networks'].map((element) => { return element.id }),
          this.selectedMultiFilter['products'].map((element) => { return element.id }),
          this.selectedMultiFilter['countries'].map((element) => { return element.id }),
          this.selectedMultiFilter['accounts'].map((element) => { return element.id }),
          this.selectedMultiFilter['biddingStrategies'].map((element) => { return element.id }),
          this.selectedMultiFilter['agencyFeeds'].map((element) => { return element.id }),
          dateRulesParsed,
          this.showRemoved,
          this.showPaused,
          this.showActive
      ).then(
              response => {
                this.isLoading = false;
                this.data = response.data;
                this.showEmptyResultMessages = true;
              },
              error => {
                this.isLoading = false;
                alert('An error happens.');
                console.error(error);
              }
          );
    },

    rulesParsed (dateRulesIndex) {
      let d = this.dateRules[dateRulesIndex].rules,
          r = [];

      for(let i in d){
        if(d[i] === null || !('indexName' in d[i]) || d[i]['indexName'] === null){
          continue;
        }

        if(!('indexOperator' in d[i]) || d[i]['indexOperator']['value'] === null){
          continue;
        }

        if(!('indexValue' in d[i]) || d[i]['indexValue'] === null){
          continue;
        }

        if(d[i]['indexOperator']['value'].indexOf('Field') > -1){
          continue;
        }

        let obj = {};

        if(d[i]['indexName']['value'].indexOf('custom') > -1){

          if(d[i]['indexName']['value'].indexOf('custom__logoptimizationgroup') > -1) {

            obj['log_optimization_group_filter'] = d[i]['indexValue'];

          }else if(d[i]['indexName']['value'].indexOf('custom__logdeletiongroup') > -1){

            obj['log_deletion_group_filter'] = d[i]['indexValue'];

          }else{

            alert('Unable to find custom filter name.');

          }

        }else{

          let kObj = d[i]['indexName']['value'] + d[i]['indexOperator']['value'];
          if(d[i]['indexName']['indexType'] === 'datetime'){
            obj[kObj] = this.formatDatetime(d[i]['indexValue']);
          }else{
            obj[kObj] = d[i]['indexValue'];
          }

        }

        r.push(obj);
      }

      return r;
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
