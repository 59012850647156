import { config } from './config';
import axios from "axios";

export const performancePartnerRevenuesExternalAgencyService = {
    get,
    config
};

function get(params){
    let axiosConfig = {},
        endpoint;

    // Append to request.
    axiosConfig['params'] = params;

    endpoint = '/api/performance/partner-revenues-external-agency/';

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

