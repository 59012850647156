import { config } from './config';
import axios from "axios";

export const saulFacebookMessagesService = {
    post,
    config
};

function post(params){

    let endpoint;

    endpoint = '/api/proxy/saul/facebook-app/messages/';

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}${endpoint}`, params)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}




