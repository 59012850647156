<template>
  <div>
    <dependant-multi-filter
        v-model="selectedMultiFilter"
        range-date-enabled
        products-enabled
        networks-enabled
        agency-feeds-enabled
        countries-enabled
        marketing-users-enabled
        accounts-enabled
        bidding-strategies-enabled
        currencies-enabled

        @filter="filterList"

    ></dependant-multi-filter>

    Consider with traffic when:
    <b-switch v-model="useCost" type="success">Cost</b-switch>
    <b-switch v-model="useClickInR" type="success">Clicks In (R)</b-switch>
    <b-switch v-model="useClickInC" type="success">Clicks In (C)</b-switch>
    <b-switch v-model="useClickOutR" type="success">Clicks Out (R)</b-switch>
    <b-switch v-model="useClickOutC" type="success">Clicks Out (R)</b-switch> is higher than 0
    <br />
    <div v-if="chartData" class="chart-area" style="height: 400px;">
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <bar-chart
        ref="bigChart"
        style="height: 300px;"
        chart-id="big-line-chart"
        :chart-data="chartData"
        :extra-options="extraOptions"
      ></bar-chart>
    </div>
  </div>
</template>
<script>
import BarChart from "@/components/Charts/BarChart";
import {
  performanceDashboardsChartTrafficDaysService
} from "@/services/performance-dashboards-chart-traffic-days.service";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import moment from "moment-timezone";
import {numberUtils} from "@/helpers/number-utils";
import {coloursUtils} from "@/helpers/colours-utils";
export default {
  name: 'DashboardTrafficChart',
  components: {DependantMultiFilter, BarChart},
  mixins: [
      numberUtils,
      coloursUtils
  ],
  props: {

  },
  methods: {

    filterList(){

      let params = {
        'network__in': this.selectedMultiFilter['networks'].map((element) => { return element.id }).join(','),
        'product__in': this.selectedMultiFilter['products'].map((element) => { return element.id }).join(','),
        'agency_feed__in': this.selectedMultiFilter['agencyFeeds'].map((element) => { return element.id }).join(','),
        'country__in': this.selectedMultiFilter['countries'].map((element) => { return element.id }).join(','),
        'account__in': this.selectedMultiFilter['accounts'].map((element) => { return element.id }).join(','),
        'bidding_strategy__in': this.selectedMultiFilter['biddingStrategies'].map((element) => { return element.id }).join(','),
        'account__currency_code__in': this.selectedMultiFilter['currencies'].map((element) => { return element.code }).join(','),
        'assigned_user__in': this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }).join(','),
      };

      params['date__range'] =
          moment(this.selectedMultiFilter['rangeDate'][0]).format('YYYY-MM-DD') + "," +
          moment(this.selectedMultiFilter['rangeDate'][1]).format('YYYY-MM-DD')
      ;
      params['main_entity__created_on_datetime__range'] =
          moment(this.selectedMultiFilter['rangeDate'][0]).format('YYYY-MM-DD 00:00:00') + "," +
          moment(this.selectedMultiFilter['rangeDate'][0]).format('YYYY-MM-DD 23:59:59')
      ;

      if(this.useCost){
        params['use_cost'] = true;
      }

      if(this.useClickInR){
        params['use_click_in_r'] = true;
      }

      if(this.useClickInC){
        params['use_click_in_c'] = true;
      }

      if(this.useClickOutR){
        params['use_click_out_r'] = true;
      }

      if(this.useClickOutC){
        params['use_click_out_c'] = true;
      }

      this.isLoading = true;
      performanceDashboardsChartTrafficDaysService.get(params).then(e => {
        this.data=this.normalizeData(e.data['results']);

        this.buildChart();
        this.isLoading = false;
      })
    },

    normalizeData(data){

      let normalizedData = [];

      for(let i in data){
        let row = data[i],
            groupedByMoment = moment(row['grouped_by'] + ' 00:00:00'),
            days = moment(groupedByMoment).diff(  moment(this.selectedMultiFilter['rangeDate'][0]).format('YYYY-MM-DD 00:00:00'), 'days');

            normalizedData.push({
              'days': days,
              'percentage': this.renderNumber((( (row['total_cnt']+row['with_traffic_cnt']) / row['total_cnt'] ) - 1)*100, 0)
            });
      }

      return normalizedData;
    },

    buildChart(){
      let data = this.data,
          series = [],
          labels = [],
          colors = [];

      for(let i in data){
        let row = data[i],
            label = '';

        series.push(row['percentage']);

        if(row['days'] > 1){
          label = row['days'] + ' days';
        }else if(row['days'] === 0){
          label = 'same day';
        }else{
          label = row['days'] + ' day';
        }
        labels.push(label);

        colors.push(this.numberToColorRgb(i*6))

      }

      this.series = series;
      this.labels = labels;
      this.colors = colors;
    }
  },
  data() {
    return {
      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'products': [],
        'countries': [],
        'accounts': [],
        'biddingStrategies': [],
        'marketingUsers': [],
        'currencies': [],
        'rangeDate': [new Date(),new Date()],
      },

      labels: [],
      series: [],
      colors: [],

      useCost: true,
      useClickInR: false,
      useClickInC: false,
      useClickOutR: false,
      useClickOutC: false,

      data: [],

      isLoading: false,
      extraOptions: {
        maintainAspectRatio:false,
        scales: {
            yAxes: [{
                ticks: {
                    max: 100,
                    min: 0,
                    stepSize: 1
                }
            }]
        }
      }
    }
  },

  computed: {
    chartData() {

      return {
        datasets: [{
          label: 'Percentage of keywords with traffic',
          data: this.series,
          backgroundColor: this.colors
        }],
        labels: this.labels,
      }

    }
  }
}
</script>