import { config } from './config';
import axios from "axios";

export const administrationAccessGroupsService = {
    getAll,
    save,
};

export function getAll(params) {

    // params['is_active'] = 'True'

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/access-groups`, {params})
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function save(payload) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/administration/access-groups`, payload)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}