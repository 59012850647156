import { config } from './config';
import axios from "axios";

export const administrationSubdomainLocaleService = {
    get,
    config
};

export function get(
    params
) {

    let axiosConfig = {};

    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/subdomain-locales/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}