import { administrationTqScoresService } from '@/services';

let emptyPaging = {
    page_size: 0,
    count: 0,
    pages: 1,
    page_number: 1,
    page_count: 1,
    overalls: {}
};

export const tqScores = {
    namespaced: true,
    state: {
        all: [],
        error: null,
        loading: false,
        paging: emptyPaging,
        ordering: ''
    },
    actions: {
        getAll({ commit }, payload) {
            commit('getAllRequest');

            let filters = {},
                pageNumber = payload['pageNumber'],
                ordering = payload['ordering'];

            if(payload['rangeDate']){
                filters['date__range'] = [
                    payload['rangeDate'][0].format('YYYY-MM-DD'),
                    payload['rangeDate'][1].format('YYYY-MM-DD')
                ].join(',')
            }

            if('countries'in payload && payload['countries'].length > 0){
                filters['country__in'] = payload['countries'].join(',')
            }

            if('agencyFeeds'in payload && payload['agencyFeeds'].length > 0){
                filters['adunit__partner__agency_feed__in'] = payload['agencyFeeds'].join(',')
            }

            administrationTqScoresService.getAll(
                filters,
                pageNumber,
                ordering)
                .then(
                    data => commit('getAllSuccess', {
                        ...data,
                        'ordering': ordering
                    }),
                    error => commit('getAllFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
            state.error = null;
            state.paging = emptyPaging;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
            state.error = null;
            state.paging = {
                page_size: response.data.page_size,
                count: response.data.count,
                pages: response.data.pages,
                page_number: response.data.page_number,
                page_count: response.data.page_count,
                overalls: response.data.overalls
            };
            state.ordering = response.ordering;
        },
        getAllFailure(state, error) {
            state.all = [];
            state.loading = false;
            state.error = error;
            state.paging = emptyPaging;
        }
    }
}
