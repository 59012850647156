import numeral from "numeraljs";
import moment from "moment/moment";

export const numberUtils = {
    methods: {
        renderPercentage(amount, decimals) {
            decimals = decimals || 0;

            if(!amount){
                return '0.' + ('0'.repeat(decimals)) + '%';
            }

            return numeral(amount).format('0.' + ('0'.repeat(decimals)) + '%')
        },
        renderCurrency(amount, decimals, symbol) {
            decimals = decimals || 0;
            symbol = symbol || '$'

            if(!amount){
                return symbol + '0.' + ('0'.repeat(decimals));
            }

            let num = numeral(amount);

            if(decimals > 0){
                num = num.format('0.' + ('0'.repeat(decimals)));
            }else{
                num = num.format('0');
            }

            if (!(num !== 0)) {
                return '';
            }
            return symbol + num;
        },
        renderNumber: function (amount, decimals) {
            if(!amount){
                return '0.' + ('0'.repeat(decimals));
            }

            let num = numeral(amount).format('0.' + ('0'.repeat(decimals)));
            if (!(num !== 0)) {
                return '';
            }

            return num;
        },
        makeNumber: function (str){
          let num = numeral(str).value()

          if(num < 0 || num > 0){
            return num
          }

          return 0;
        },

        formatDatetime (time){
          return moment(time).format('YYYY-MM-DD HH:mm:ss');
        },
    }
}