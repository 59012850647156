import { optimizationDashboardsReportPerformancesService } from '@/services';


export const dashboardsReportPerformances = {
    namespaced: true,
    state: {
        all: [],
        loading: false
    },
    actions: {
        getByDateRange({ commit }, payload) {
            commit('getAllRequest');

            let fromDate = payload['fromDate'],
                toDate = payload['toDate'],
                chartType = payload['chartType'],
                networks = payload['networks'],
                agencyFeeds = payload['agencyFeeds'],
                products = payload['products'],
                countries = payload['countries'],
                accounts = payload['accounts'];

            optimizationDashboardsReportPerformancesService.getByDateRange(
                fromDate,
                toDate,
                chartType,
                networks,
                agencyFeeds,
                products,
                countries,
                accounts
            )
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },
    },
    mutations: {
        getAllRequest(state) {
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        getAllFailure(state) {
            state.loading = false;
        },
    }
}
