import { config } from './config';
import axios from "axios";


export const devLanguagesService = {
    getAll,
    config
};


export function getAll() {

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/dev/languages/`)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}
