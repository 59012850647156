<template>
    <div>
        <section class="section is-main-section">
            <b-button @click="showModal = true; clickedRowId = null" type="is-success" class="my-3">Create New</b-button>
            <div class="card has-table has-mobile-sort-spaced">
                <div class="card-content">
                    <b-table
                        :loading="optimizationAutomation.loading"
                        :data="optimizationAutomation.all"
                        :striped="true"
                        :hoverable="true"
                        default-sort="total_count"
                        pagination-position="top"
                        :pagination-rounded="false"
                        sort-icon="chevron-up"
                        sort-icon-size="is-medium"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                    >
                        <b-table-column label="Name" field="name" sortable v-slot="props">
                            {{ props.row.name }}
                        </b-table-column>
                        <b-table-column label="Description" field="description" sortable v-slot="props">
                            {{ props.row.description }}
                        </b-table-column>
                        <b-table-column label="Agency Feeds" field="agency_feeds" sortable v-slot="props">
                            {{ getAgency(props.row.agency_feeds) }}
                        </b-table-column>
                        <b-table-column label="Networks" field="networks" sortable v-slot="props">
                            {{ getNetwork(props.row.networks) }}
                        </b-table-column>
                        <b-table-column label="Created by" field="created_by_user" sortable v-slot="props">
                            {{ getUser(props.row.created_by_user) }}
                        </b-table-column>
                        <b-table-column label="Actions" sortable v-slot="props">
                            <b-button icon-left="pencil" size="is-small" class="mr-2" type="is-info is-light" @click="showModal = true; clickedRowId = props.row.id">Edit</b-button>
                            <b-button icon-left="close-circle" size="is-small" class="mr-2" type="is-danger is-light" @click="confirmDelete(props.row.name, props.row.id)" :loading="deleteLoading[props.row.id]">Delete</b-button>
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </section>
        <CustomModalWrapper
            title="Optimization Automation"
            :show="showModal"
            customStyle="width:45vw"
        >
            <OptimizationAutomationModal
                @close="showModal = false"
                :id="clickedRowId"
            />
        </CustomModalWrapper>
    </div>
</template>

<script>
import {userService, optimizationAutomationService} from '@/services'
import {permissionsUtils} from "@/helpers/permissions-utils";
import OptimizationAutomationModal from "@/pages/optimization/OptimizationAutomationModal"
import CustomModalWrapper from "@/components/CustomModalWrapper"
import { mapState } from "vuex"
export default {
    name: 'OptimizationAutomation',

    data() {
        return {
            showModal: false,
            clickedRowId: null,
            users: [],
            deleteLoading: {},
        }
    },

    mixins: [
        permissionsUtils,
    ],

    components: {
        CustomModalWrapper,
        OptimizationAutomationModal,
    },

    created() {
        if(!this.optimizationAutomation.all.length) this.$store.dispatch('optimizationAutomation/getAll', {filters: {}, no_loading: false})
        if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll')
        if(!this.networks.all.length) this.$store.dispatch('networks/getAll')
        if(!this.biddingStrategies.all.length) this.$store.dispatch('biddingStrategies/getAll')
        userService.getUsers().then(r => this.users = r.data.results)
    },

    computed: {
        ...mapState([
            'optimizationAutomation',
            'agencyFeeds',
            'networks',
            'biddingStrategies',
        ]),
    },

    methods: {
        getAgency(id_list) {
            return id_list.map(i => {
                return this.agencyFeeds.all.find(a => a.id === i)?.[this.getFieldCodeLabel()] || '-'
            }).join(', ')
        },

        getNetwork(id_list) {
            return id_list.map(i => {
                return this.networks.all.find(n => n.id === i)?.name || '-'
            }).join(', ')
        },

        getUser(id) {
            return this.users.find(u => u.id === id)?.username
        },

        confirmDelete(name, id) {
            this.$buefy.dialog.confirm({
                title: 'Deleting Automation',
                message: `<div class='automation-delete-dialog'>Are you sure you want to delete <b>${name}</b>?</div>`,
                confirmText: 'Delete Automation',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteLoading[id] = true
                    optimizationAutomationService.remove(id)
                        .then(() => {
                            this.$store.dispatch('optimizationAutomation/getAll', {filters: {}, no_loading: true})
                            this.deleteLoading[id] = false
                        })
                        .catch(() => {
                            this.deleteLoading[id] = false
                            alert('Something went wrong...')
                            window.location.reload()
                        })
                },
            })
        },
    },
}

</script>