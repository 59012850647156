<template>
  <card-component>
    <b-loading v-model="isLoading" :is-full-page="false" ></b-loading>
    <div class="level is-mobile">

      <div class="level-item">

        <div class="is-widget-label">

          <h3 class="subtitle is-spaced">
            <a
                href="#"
                aria-label="more options"
                @click.prevent="reload"
            >
              <b-icon icon="reload" custom-size="default"/>
            </a> {{ label }}

            <b-button type="is-text is-small" :class="{ 'is-success': timezone === 'UTC'}" @click="reloadTimezone('UTC')">
              UTC</b-button>
            <b-button type="is-text is-small" :class="{ 'is-success': timezone === 'PST'}" @click="reloadTimezone('PST')">
              PST</b-button>
            <b-button type="is-text is-small" :class="{ 'is-success': timezone === 'ALL'}" @click="reloadTimezone('ALL')">
              ALL</b-button>
          </h3>
          <table class="table is-narrow">
            <tr>
              <td>
                <span v-if="calculateGrowth(number, previousNumber) !== 0"  style="font-size: 0.6rem;" :class="{
                  'has-text-success': calculateGrowth(number, previousNumber) > 0,
                  'has-text-danger': calculateGrowth(number, previousNumber) < 0
                }">
                  <b-icon
                    :icon="calculateGrowth(number, previousNumber) > 0 ? 'arrow-up-bold' : 'arrow-down-bold'"
                    size="is-small"
                    style="width: .3rem;"
                    :type="{
                      'is-success': calculateGrowth(number, previousNumber) > 0,
                      'is-danger': calculateGrowth(number, previousNumber) < 0
                    }">
                  </b-icon>
                  <b-tooltip :label="generateOverviewPerformanceTooltipByCode('previous_period_revenue',
                  'CURRENCY')" position="is-bottom">
                    {{ renderPercentage(calculateGrowth(number, previousNumber)) }}
                  </b-tooltip>
                </span><br />
                <growing-number :value="number" :prefix="prefix" :suffix="suffix" :class-name="className" />
              </td>
              <td>
                <span v-if="calculateGrowth(number1, previousNumber1) !== 0" style="font-size: 0.6rem;" :class="{
                  'has-text-success': calculateGrowth(number1, previousNumber1) > 0,
                  'has-text-danger': calculateGrowth(number1, previousNumber1) < 0 }">
                  <b-icon
                    :icon="calculateGrowth(number1, previousNumber1) > 0 ? 'arrow-up-bold' : 'arrow-down-bold'"
                    size="is-small"
                    style="width: .3rem;"
                    :type="{'is-success': calculateGrowth(number1, previousNumber1) > 0, 'is-danger': calculateGrowth(number1, previousNumber1) < 0 }">
                  </b-icon>

                  <b-tooltip :label="generateOverviewPerformanceTooltipByCode('previous_period_cost',
                  'CURRENCY')" position="is-bottom">
                    {{ renderPercentage(calculateGrowth(number1, previousNumber1)) }}
                  </b-tooltip>
                </span><br />
                <growing-number :value="number1" :prefix="prefix2" :suffix="suffix2" :class-name="className1" />
              </td>
              <td>
                <span v-if="calculateGrowth(number2, previousNumber2) !== 0"  style="font-size: 0.6rem;" :class="{'has-text-success': calculateGrowth(number2, previousNumber2) > 0, 'has-text-danger': calculateGrowth(number2, previousNumber2) < 0 }">
                  <b-icon
                    :icon="calculateGrowth(number2, previousNumber2) > 0 ? 'arrow-up-bold' : 'arrow-down-bold'"
                    size="is-small"
                    style="width: .3rem;"
                    :type="{'is-success': calculateGrowth(number2, previousNumber2) > 0, 'is-danger': calculateGrowth(number2, previousNumber2) < 0 }">
                  </b-icon>

                  <b-tooltip :label="generateOverviewPerformanceTooltipByCode('previous_period_profit',
                  'CURRENCY')" position="is-bottom">
                    {{ renderPercentage(calculateGrowth(number2, previousNumber2)) }}
                  </b-tooltip>
                </span><br />
                <growing-number :value="number2" :prefix="prefix2" :suffix="suffix2" :class-name="className2" />
              </td>
              <td>
                <span v-if="calculateGrowth(number3, previousNumber3) !== 0"  style="font-size: 0.6rem;" :class="{'has-text-success': calculateGrowth(number3, previousNumber3) > 0, 'has-text-danger': calculateGrowth(number3, previousNumber3) < 0 }">
                  <b-icon
                    :icon="calculateGrowth(number3, previousNumber3) > 0 ? 'arrow-up-bold' : 'arrow-down-bold'"
                    size="is-small"
                    style="width: .3rem;"
                    :type="{'is-success': calculateGrowth(number3, previousNumber3) > 0, 'is-danger': calculateGrowth(number3, previousNumber3) < 0 }">
                  </b-icon>
                  <b-tooltip :label="generateOverviewPerformanceTooltipByCode('previous_period_roi',
                  'PERCENTAGE')" position="is-bottom">
                    {{ renderPercentage(calculateGrowth(number3, previousNumber3)) }}
                  </b-tooltip>
                </span><br />
                <growing-number :value="number3" :prefix="prefix3" :suffix="suffix3" :class-name="className3" />
              </td>
            </tr>

          </table>

        </div>
      </div>
      <div v-if="icon" class="level-item has-widget-icon is-hidden-mobile">
        <div class="is-widget-icon">
          <b-icon :icon="icon" size="is-large" :type="type" />
        </div>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import GrowingNumber from '@/components/GrowingNumber'
import {numberUtils} from "@/helpers/number-utils";
import {timeUtils} from "@/helpers/time-utils";
import {BTooltip} from "buefy/src/components/tooltip"
import {mapState} from "vuex";
export default {
  name: 'CardWidget',
  components: { GrowingNumber, CardComponent, BTooltip },
  mixins: [
    numberUtils,
    timeUtils
  ],
  mounted() {
    this.loadPerformanceOverview();
  },
  props: {
    icon: {
      type: String,
      default: null
    },

    widgetType: {
      type: String,
      default: 'YESTERDAY'
    },

    className: {
      type: String,
      default: ''
    },

    prefix: {
      type: String,
      default: null
    },

    suffix: {
      type: String,
      default: null
    },

    className1: {
      type: String,
      default: ''
    },

    prefix1: {
      type: String,
      default: null
    },

    suffix1: {
      type: String,
      default: null
    },

    className2: {
      type: String,
      default: ''
    },

    prefix2: {
      type: String,
      default: null
    },

    suffix2: {
      type: String,
      default: null
    },

    className3: {
      type: String,
      default: ''
    },

    prefix3: {
      type: String,
      default: null
    },
    suffix3: {
      type: String,
      default: null
    },

    previousClassName: {
      type: String,
      default: ''
    },

    previousPrefix: {
      type: String,
      default: null
    },

    previousSuffix: {
      type: String,
      default: null
    },

    previousClassName1: {
      type: String,
      default: ''
    },

    previousPrefix1: {
      type: String,
      default: null
    },

    previousSuffix1: {
      type: String,
      default: null
    },

    previousClassName2: {
      type: String,
      default: ''
    },

    previousPrefix2: {
      type: String,
      default: null
    },

    previousSuffix2: {
      type: String,
      default: null
    },

    previousClassName3: {
      type: String,
      default: ''
    },

    previousPrefix3: {
      type: String,
      default: null
    },

    previousSuffix3: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    type: {
      type: String,
      default: null
    }

  },
  data () {
    return {
      'timezone': 'ALL',
    }
  },
  methods: {
    calculateGrowth(actualNumber, previousNumber) {
      return (actualNumber / previousNumber) - 1;
    },

    reloadTimezone(timezone){
      this.timezone = timezone;
      this.reload()
    },

    reload() {
      this.loadPerformanceOverview();
    },

    loadPerformanceOverview() {
      let range = this.setSelectedDates(this.widgetType);

      this.$store.dispatch(
        'dashboardsOverviewPerformances/getByDateRange', {
            code: this.widgetType,
            fromDate: this.dateToSqlString(range[0]),
            toDate: this.dateToSqlString(range[1]),
            timezone: this.timezone
        }
      );

    },

    generateOverviewPerformanceTooltipByCode(attribute, renderAs){
      let a = this.getOverviewPerformanceByCode(attribute),
          d_f = this.getOverviewPerformanceByCode('previous_period_date_from'),
          d_t = this.getOverviewPerformanceByCode('previous_period_date_to'),
          dates = '';

      if(renderAs==='PERCENTAGE'){
        a = this.renderPercentage(a, 2);
      }else if(renderAs==='CURRENCY'){
        a = this.renderCurrency(a, 2);
      }

      if(d_f===d_t){
        dates = d_f
      }else{
        dates = d_f + ',' + d_t;
      }

      return dates + ': ' + a;
    },

    getOverviewPerformanceByCode(attribute){
      return this.dashboardsOverviewPerformances['all'][this.widgetType][attribute];
    }
  },

  computed: {
    ...mapState([
      'dashboardsOverviewPerformances',
    ]),

    number(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['revenue'];
    },

    previousNumber(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['previous_period_revenue'];
    },

    number1(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['cost'];
    },

    previousNumber1(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['previous_period_cost'];
    },

    number2(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['profit'];
    },

    previousNumber2(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['previous_period_profit'];
    },

    number3(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['roi'] * 100;
    },

    previousNumber3(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['previous_period_roi'] * 100;
    },

    isLoading(){
      return this.dashboardsOverviewPerformances['all'][this.widgetType]['isLoading'];
    }
  }
}
</script>
