import { administrationFacebookPagesService } from '@/services';

export const facebookPages = {
    namespaced: true,
    state: {
        all: [],
        error: null,
        loading: false,

        unpublished: [],
        unpublishedLoading: true,
        unpublishedError: null,

        blocked: [],
        blockedLoading: true,
        blockedError: null,

        empty: [],
        emptyLoading: true,
        emptyError: null,

        full: [],
        fullLoading: true,
        fullError: null,

        almostFull: [],
        almostFullLoading: true,
        almostFullError: null,

        exceeded: [],
        exceededLoading: true,
        exceededError: null,

        pagesCapacity: {},
        pagesCapacityLoading: true,
        pagesCapacityError: null
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            administrationFacebookPagesService.getAll()
                .then(
                    dealers => commit('getAllSuccess', dealers),
                    error => commit('getAllFailure', error)
                );
        },

        getUnpublishedPagesList({ commit }) {
            commit('getUnpublishedPagesListRequest');

            administrationFacebookPagesService.getAll({
                'is_published': 'False',
                'is_active': 'True',
                'account__manager__is_active': 'True',
                'account__is_active': 'True',
                'account__agency_feed__is_active': 'True',
            })
                .then(
                    response => commit('getUnpublishedPagesListSuccess', response),
                    error => commit('getUnpublishedPagesListFailure', error)
                );
        },

        getBlockedPagesList({ commit }) {
            commit('getBlockedPagesListRequest');

            administrationFacebookPagesService.getAll({
                'blocked_on_datetime__isnull': 'False',
                'is_active': 'True',
                'account__manager__is_active': 'True',
                'account__is_active': 'True',
                'account__agency_feed__is_active': 'True',
            })
                .then(
                    response => commit('getBlockedPagesListSuccess', response),
                    error => commit('getBlockedPagesListFailure', error)
                );
        },

        getEmptyPagesList({ commit }) {
            commit('getEmptyPagesListRequest');

            administrationFacebookPagesService.getAll({
                'ads_live_count': '0',
                'is_active': 'True',
                'account__manager__is_active': 'True',
                'account__is_active': 'True',
                'account__agency_feed__is_active': 'True',
            })
                .then(
                    response => commit('getEmptyPagesListSuccess', response),
                    error => commit('getEmptyPagesListFailure', error)
                );
        },

        getFullPagesList({ commit }) {
            commit('getFullPagesListRequest');

            administrationFacebookPagesService.getAll({
                'ads_live_count__gte': '247',
                'is_active': 'True',
                'account__manager__is_active': 'True',
                'account__is_active': 'True',
                'account__agency_feed__is_active': 'True',
            })
                .then(
                    response => commit('getFullPagesListSuccess', response),
                    error => commit('getFullPagesListFailure', error)
                );
        },

        getAlmostFullPagesList({ commit }) {
            commit('getAlmostFullPagesListRequest');

            administrationFacebookPagesService.getAll({
                'ads_live_count__gte': '200',
                'ads_live_count__lt': '250',
                'is_active': 'True',
                'account__manager__is_active': 'True',
                'account__is_active': 'True',
                'account__agency_feed__is_active': 'True',
            })
                .then(
                    response => commit('getAlmostFullPagesListSuccess', response),
                    error => commit('getAlmostFullPagesListFailure', error)
                );
        },

        getExceededPagesList({ commit }) {
            commit('getExceededPagesListRequest');

            administrationFacebookPagesService.getAll({
                'ads_live_count__gte': '250',
                'is_active': 'True',
                'account__manager__is_active': 'True',
                'account__is_active': 'True',
                'account__agency_feed__is_active': 'True',
            })
                .then(
                    response => commit('getExceededPagesListSuccess', response),
                    error => commit('getExceededPagesListFailure', error)
                );
        },

        getPagesCapacityList({ commit }) {
            commit('getPagesCapacityListRequest');

            administrationFacebookPagesService.getAll({
                'ads_live_count__lt': '250',
                'is_active': 'True',
                'account__manager__is_active': 'True',
                'account__is_active': 'True',
                'account__agency_feed__is_active': 'True',
            })
                .then(
                    response => commit('getPagesCapacityListSuccess', response),
                    error => commit('getPagesCapacityListFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
            state.error = null;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
            state.error = null;
        },
        getAllFailure(state, error) {
            state.all = [];
            state.error = error;
            state.loading = false;
        },

        getUnpublishedPagesListRequest(state) {
            state.unpublished = [];
            state.unpublishedLoading = true;
            state.unpublishedError = null;
        },
        getUnpublishedPagesListSuccess(state, response) {
            state.unpublished = response.data.results;
            state.unpublishedLoading = false;
            state.unpublishedError = null;
        },
        getUnpublishedPagesListFailure(state, error) {
            state.unpublished = [];
            state.unpublishedLoading = false;
            state.unpublishedError = error;
        },

        getBlockedPagesListRequest(state) {
            state.blocked = [];
            state.blockedLoading = true;
            state.blockedError = null;
        },
        getBlockedPagesListSuccess(state, response) {
            state.blocked = response.data.results;
            state.blockedLoading = false;
            state.blockedError = null;
        },
        getBlockedPagesListFailure(state, error) {
            state.blocked = [];
            state.blockedLoading = false;
            state.blockedError = error;
        },

        getEmptyPagesListRequest(state) {
            state.empty = [];
            state.emptyError = null;
            state.emptyLoading = true;
        },
        getEmptyPagesListSuccess(state, response) {
            state.empty = response.data.results;
            state.emptyError = null;
            state.emptyLoading = false;
        },
        getEmptyPagesListFailure(state, error) {
            state.empty = [];
            state.emptyError = error;
            state.emptyLoading = false;
        },

        getFullPagesListRequest(state) {
            state.full = [];
            state.fullError = null;
            state.fullLoading = true;
        },
        getFullPagesListSuccess(state, response) {
            state.full = response.data.results;
            state.fullError = null;
            state.fullLoading = false;
        },
        getFullPagesListFailure(state, error) {
            state.full = [];
            state.fullError = error;
            state.fullLoading = false;
        },

        getAlmostFullPagesListRequest(state) {
            state.almostFull = [];
            state.almostFullError = null;
            state.almostFullLoading = true;
        },
        getAlmostFullPagesListSuccess(state, response) {
            state.almostFull = response.data.results;
            state.almostFullError = null;
            state.almostFullLoading = false;
        },
        getAlmostFullPagesListFailure(state, error) {
            state.almostFull = [];
            state.almostFullError = error;
            state.almostFullLoading = false;
        },

        getExceededPagesListRequest(state) {
            state.exceeded = [];
            state.exceededError = null;
            state.exceededLoading = true;
        },
        getExceededPagesListSuccess(state, response) {
            state.exceeded = response.data.results;
            state.exceededError = null;
            state.exceededLoading = false;
        },
        getExceededPagesListFailure(state, error) {
            state.exceeded = [];
            state.exceededError = error;
            state.exceededLoading = false;
        },

        getPagesCapacityListRequest(state) {
            state.pagesCapacity = {};
            state.pagesCapacityError = null;
            state.pagesCapacityLoading = true;
        },
        getPagesCapacityListSuccess(state, response) {
            let spaceLeft = {};

            for(let i in response.data.results){
                let row = response.data.results[i],
                    agencyFeed = '';

                if(!('account' in row) || !('agency_feed' in row.account) || !('code' in row.account.agency_feed)){
                    continue;
                }

                agencyFeed = row.account.agency_feed.code;

                if(!(agencyFeed in spaceLeft)){
                    spaceLeft[agencyFeed] = 0;
                }

                if(!('ads_limit' in row) || !('ads_live_count' in row)){
                    continue;
                }

                spaceLeft[agencyFeed] += row.ads_limit - row.ads_live_count;
            }

            state.pagesCapacity = spaceLeft;
            state.pagesCapacityError = null;
            state.pagesCapacityLoading = false;
        },
        getPagesCapacityListFailure(state, error) {
            state.pagesCapacity = {};
            state.pagesCapacityError = error;
            state.pagesCapacityLoading = false;
        }
    }
}
