<template>
    <section class="section is-main-section">
      <dashboard-image-picker></dashboard-image-picker>
    </section>
</template>
<script>
import DashboardImagePicker from "@/components/Dashboard/DashboardImagePicker";
export default {
  name: 'DashboardImagePickerPage',
  components: {DashboardImagePicker},
}
</script>