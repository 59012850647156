import { config } from './config';
import axios from "axios";

export const creationDashboardsFoundKeywordImagesService = {
    getByDateTimeRange,
    config
};

export function getByDateTimeRange(fromDateTime, toDateTime){

    let axiosConfig = {}

    let params = {}, endpoint;

    params['created_on_datetime__range'] = fromDateTime + ',' + toDateTime;

    // Append to request.
    axiosConfig['params'] = params;

    endpoint = '/api/creation/dashboard-found-keyword-images/';


    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}