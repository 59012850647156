<template>
    <div class="card">
        <div class="card-image">
            <img :src="require('@/assets/pytech_logo.png')" alt="Pytech logo" />
        </div>
        <div class="card-content hero pt-3">
            <div class="hero-body pt-0 pb-5">
                <p class="subtitle">Pytech is a state-of-the-art technology company, specialized in automation of human interactions at large scale within the marketing online industry. The company generates high quality website traffic to its clients, assuring genuine revenue streams.</p>
                <p class="title m-0">Our best services</p>
                <ul>
                    <li>Campaigns Algo-Optimization</li>
                    <li>Content Marketing</li>
                    <li>Data Analysis</li>
                    <li>Artificial Intelligence</li>
                    <li>SMM Services</li>
                </ul>
                <div class="contact">
                    <p class="title m-1">Our Office</p>
                    Business Center, <br />
                    Sharjah Publishing City Free Zone, <br />
                    Sharjah, United Arab Emirates <br />
                    P.O. 416654 <br />

                    <a  class="email py-1" href="mailto:info@pytech.ai">
                        <b-icon icon="email"  />
                        Contact Us
                    </a>
                    <a href="https://www.pytech.ai" target="_blank">To know more about us and our services visit www.pytech.ai</a>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
export default {
    name: 'AboutUsModal',

}
</script>

<style lang="scss" scoped>
.card {
    text-align: center;
    font-size: 1rem;
    .card-image {
        img {
            display: block;
            margin: 10px auto;
            width: 230px;
            height: 150px;
            background-color: #dbdbdb;
        }
    }
    .card-content {
        .email {
            display: flex;
            justify-content: center;
            color: inherit;
        }
        p.title {
            color: #696969;
        }
        ul {
            li {
                display: inline-block;
                margin: 10px;
            }
        }
    }
}
</style>
