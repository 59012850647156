import { config } from './config';
import axios from "axios";

export const optimizationDashboardsChartLogOptimizationsService = {
	getLogOptimizations,
	getLogDeletions,
	getOptimizationMessages,
	getDeletionMessages,
	getOptimizationTypes,
};

function getLogOptimizations(params){
	params = params || {
	}
	console.log('optimizations_params', params)
	return new Promise((resolve, reject) => {
		axios
		.get(`${config.apiUrl}/api/optimization/dashboards-chart-log-optimizations/`, {params})
		.then(response => {
			resolve(response);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

function getLogDeletions(params){
	params = params || {
	}
	console.log('deletions_params', params)
	return new Promise((resolve, reject) => {
		axios
		.get(`${config.apiUrl}/api/optimization/dashboards-chart-log-deletions/`, {params})
		.then(response => {
			resolve(response);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

function getOptimizationMessages(params){
	params = params || {
		'is_deletion': false
	}

	return new Promise((resolve, reject) => {
		axios
		.get(`${config.apiUrl}/api/optimization/optimization-messages/`, {params})
		.then(response => {
			resolve(response);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

function getDeletionMessages(params){
	params = params || {
		'is_deletion': true
	}

	return new Promise((resolve, reject) => {
		axios
		.get(`${config.apiUrl}/api/optimization/deletion-messages/`, {params})
		.then(response => {
			resolve(response);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

function getOptimizationTypes(params){
	params = params || {}

	return new Promise((resolve, reject) => {
		axios
		.get(`${config.apiUrl}/api/optimization/optimization-types/`, {params})
		.then(response => {
			resolve(response);
		})
		.catch((error) => {
			reject(error);
		});
	});
}