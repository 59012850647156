<template>
  <div>
    <b-select v-model="message" @input="update">
      <option v-for="o in optimizationMessages" :value="o" :key="`optimization-message-${o}`">
        {{ o }}
      </option>
    </b-select>
    <b-datetimepicker
        @input="update"
        class="form-control"
        placeholder="Date Time From..."
        v-model="selectedDatetimeFrom">
        <b-button
        label="Today"
        type="is-primary"
        icon-left="calendar-today"
        @click="setSelectedDate('TODAY')" />
        <b-button
        label="Yesterday"
        type="is-primary"
        icon-left="calendar"
        @click="setSelectedDate('YESTERDAY')" />
    </b-datetimepicker>
    <b-datetimepicker
        @input="update"
        class="form-control"
        placeholder="Date Time To..."
        v-model="selectedDatetimeTo">
        <b-button
        label="Today"
        type="is-primary"
        icon-left="calendar-today"
        @click="setSelectedDate('TODAY')" />
        <b-button
        label="Yesterday"
        type="is-primary"
        icon-left="calendar"
        @click="setSelectedDate('YESTERDAY')" />
    </b-datetimepicker>
  </div>
</template>

<script>
import { timeUtils} from '@/helpers/time-utils';
import moment from "moment";
import {optimizationDashboardsChartLogOptimizationsService} from "@/services";

export default {

  name: 'LogOptimizationGroupFilter',

  mixins: [
    timeUtils
  ],

  props: {},

  mounted() {
    this.selectedDatetimeFrom = moment().hours(0).minutes(0).seconds(0).toDate()
    this.selectedDatetimeTo = moment().hours(0).minutes(0).seconds(0).toDate()
    this.fetchMessages();
  },

  data() {
    return {
      selectedDatetimeFrom: null,
      selectedDatetimeTo: null,
      message: null,

      optimizationMessages: [],
    }
  },

  computed: {},

  methods: {
    update(){
      let that = this,
          obj = {
            'message': that.message,
            'started_on_datetime__gte': this.datetimeToSqlString(that.selectedDatetimeFrom),
            'started_on_datetime__lte': this.datetimeToSqlString(that.selectedDatetimeTo),
      };
      this.$emit('input', obj);
    },

    fetchMessages() {
      if(this.optimizationMessages.length > 0){
        return;
      }
      optimizationDashboardsChartLogOptimizationsService.getOptimizationMessages().then((e) => {
        this.optimizationMessages = e.data;
      })
    }
  },

}
</script>