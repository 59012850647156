import { dailyOptimizationPerformancesService } from '@/services';
import { SnackbarProgrammatic } from 'buefy'
import Vue from "vue";
import {setDefaultsMainEntity} from "@/helpers/optimization/set-defaults-main-entity";

let emptyOveralls = {
    "cost_amount": 0,
    "cost_amount_account_currency": 0,
    "impressions_c": 0,
    "clicks_in_c": 0,
    "clicks_out_c": 0,
    "revenue_amount_gross_partner_currency": 0,
    "revenue_amount_net_partner_currency": 0,
    "revenue_amount_gross": 0,
    "revenue_amount_net": 0,
    "clicks_in_r": 0,
    "clicks_out_r": 0,
    "profit": 0,
    "avg_roi": 0,
    "avg_ctr_c": 0,
    "avg_ctr_r": 0,
    "avg_cpc_c": 0,
    "avg_cpc_r": 0,
    "avg_cpa_c": 0,
    "avg_cpa_r": 0,
    "avg_rpc": 0,
    "avg_rpc_c": 0,
    "avg_rps": 0,
    "avg_clicks_out_ratio": 0,
    "avg_clicks_in_ratio": 0,
    "avg_prop_budget_amount": 0,
    "avg_prop_budget_amount_account_currency": 0,
    "avg_prop_bid_amount": 0,
    "avg_prop_bid_amount_account_currency": 0,
}

let emptyOverallsKpi = {
    "keywords_in_profit": 0,
    "keywords_no_traffic": 0,
    "keywords_in_loss": 0,
    "percentage_in_profit": 0,
    "percentage_in_no_traffic": 0,
    "percentage_in_loss": 0,
    "ratio_in_profit": 0,
    "ratio_in_no_traffic": 0,
    "ratio_in_loss": 0,
    "keywords_in_profit_roi": 0,
    "keywords_in_loss_roi": 0,
};

let emptyPaging = {
    page_size: 0,
    count: 0,
    pages: 1,
    page_number: 1,
    page_count: 1
};

export const dailyPerformances = {
    namespaced: true,
    state: {
        all: [],
        overalls: emptyOveralls,
        overallsKpi: emptyOverallsKpi,
        historical: [],
        historicalOveralls: {},
        paging: emptyPaging
    },
    actions: {
        getAll({ commit }, payload) {
            commit('getAllRequest');

            let pageId = payload['pageId'],
                date = payload['date'],
                sorting = payload['sorting'],
                networks = payload['networks'],
                products = payload['products'],
                countries = payload['countries'],
                accounts = payload['accounts'],
                biddingStrategies = payload['biddingStrategies'],
                agencyFeeds = payload['agencyFeeds'],
                marketingAgency = payload['marketingAgency'],
                currencies = payload['currencies'],
                assignedUsers = payload['assignedUsers'],
                rules = payload['rules'],
                rulesField = payload['rulesField'],
                showRemoved = payload['showRemoved'],
                showPaused = payload['showPaused'],
                showActive = payload['showActive'],
                pageSize = payload['pageSize'],
                accountBlockedOnDatetimeIsNull = payload['accountBlockedOnDatetimeIsNull'],
                asCSV = payload['asCSV'];

            return new Promise((resolve, reject) => {
                dailyOptimizationPerformancesService.getAll(
                    pageId,
                    sorting,
                    pageSize,
                    date,
                    networks,
                    products,
                    countries,
                    accounts,
                    biddingStrategies,
                    agencyFeeds,
                    marketingAgency,
                    currencies,
                    assignedUsers,
                    rules,
                    rulesField,
                    showRemoved,
                    showPaused,
                    showActive,
                    accountBlockedOnDatetimeIsNull,
                    asCSV
                )
                    .then(
                        performance => {commit('getAllSuccess', performance); resolve(performance)},
                        error => {commit('getAllFailure', error); reject(error)}
                    ); 
            })
        },

        optimize({commit}, payload) {

            dailyOptimizationPerformancesService.optimize(
                payload
            )
                .then(
                    data => commit('successSaved', data),
                    error => commit('errorSaving', error)
                );
        },

        delete({commit}, payload) {

            dailyOptimizationPerformancesService.deleteRequest(
                payload
            )
                .then(
                    data => commit('successSaved', data),
                    error => commit('errorSaving', error)
                );
        },

        getByDateRange({commit}, payload){
            dailyOptimizationPerformancesService.getByDateRange(
                payload
            )
                .then(
                    data => commit('getByDateRangeSuccess', data),
                    error => commit('getByDateRangeError', error)
                );
        },

        setIsDirty({commit}, payload){
            commit('setIsDirty', payload);
        },
    },
    mutations: {


        setIsDirty(state, payload) {
            Vue.set(state.all[payload['rowId']].route_hash.livemainentity, 'state_is_dirty', payload['value']);
        },

        getAllRequest(state) {
            state.all = [];
            state.loading = true;
            state.overalls = emptyOveralls;
            state.overallsKpi = emptyOverallsKpi;
            state.paging = emptyPaging;
        },
        getAllSuccess(state, response) {
            for(let i in response.data.results){
                let r = response.data.results[i];
                setDefaultsMainEntity(response.data.results, i, r.route_hash.livemainentity);
            }

            state.all = response.data.results;
            state.overalls = response.data.overall_performance;
            state.overallsKpi = response.data.overall_kpi;
            state.paging = {
                page_size: response.data.page_size,
                count: response.data.count,
                pages: response.data.pages,
                page_number: response.data.page_number,
                page_count: response.data.page_count
            };
            state.loading = false;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
            state.overalls = emptyOveralls;
            state.overallsKpi = emptyOverallsKpi;
            state.paging = emptyPaging;
        },

        successSaved() {
            SnackbarProgrammatic.open('Saved!')
        },

        errorSaving(state, error){
            SnackbarProgrammatic.open('Error!')
            console.error(error);
        },

        getByDateRangeSuccess(state, response){
            state.historical =  response.data.results;
            state.historicalOveralls = response.data.overall_performance;
        },

        getByDateRangeError(state, error){
            SnackbarProgrammatic.open('Error!')
            console.error(error);
        }
    }
}
