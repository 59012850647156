import { config } from './config';
import axios from "axios";


export const administrationNetworksService = {
    getAll,
    getAllAvailableNetworks,
};


export function getAll(params) {

    params = params || {}
    params['is_active'] = true

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/networks/`, {params})
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function getAllAvailableNetworks(params) {

  params = params || {}
  params['is_active'] = true

  return new Promise((resolve, reject) => {
    axios//available-networks
      .get(`${config.apiUrl}/api/administration/available-networks/`, {params})
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}