<style scoped>

/* Firefox old*/
@-moz-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

/* Opera and prob css3 final iteration */
@keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

.deleted-image {
  opacity: 0.5;
}

.image-is-loading {
  -moz-animation: blink normal 3s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 3s infinite ease-in-out; /* Webkit */
  animation: blink normal 3s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
</style>
<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>

    <section class="section is-main-section">
      <card-component
        title="Log Optimizations"
        icon="finance"
      >
        <div v-if="logOptimizationsChartData" class="chart-area">
          <b-loading :is-full-page="false" v-model="logOptimizations.loading"></b-loading>

          <dependant-multi-filter
            custom-class="px-1"
            v-model="logOptimizationFilter"
            range-date-enabled
            :default-range-date="defaultLogOptimizationRange"
            partners-enabled
            networks-enabled
            @filter="logOptimizationFilterList">

              <template #left>
                <multiselect
                v-model="logBaseData.selectedOptimizationMessage"
                @input="optimizationMessagesChanged"
                group-label="label"
                group-values="all"
                :group-select="true"
                :options="setGroup(logBaseData.optimizationMessages, 'optimizationMessages')"
                @remove="(e) => unselectGroup(e, 'logBaseData.selectedOptimizationMessage')"
                :loading="loading.optimizationMessages"
                :multiple="true"
                :close-on-select="true"
                placeholder="Message" />
                
                <multiselect
                v-model="logBaseData.selectedOptimizationType"
                @input="optimizationTypesChanged"
                group-label="label"
                group-values="all"
                :group-select="true"
                :options="setGroup(logBaseData.optimizationTypes, 'optimizationTypes')"
                @remove="(e) => unselectGroup(e, 'logBaseData.selectedOptimizationType')"
                :loading="loading.optimizationTypes"
                :multiple="true"
                :close-on-select="true"
                placeholder="Optimization Type" />
              </template>

            </dependant-multi-filter>

            <b-field label="Group By" class="px-1" horizontal>
              <b-checkbox-button v-model="groupByLogOptimization"
                  native-value="message"
                  type="is-success">
                  <span>Message</span>
              </b-checkbox-button>
              <b-checkbox-button v-model="groupByLogOptimization"
                  native-value="optimization_type"
                  type="is-success">
                  <span>Optimization Type</span>
              </b-checkbox-button>
              <b-checkbox-button v-model="groupByLogOptimization"
                  native-value="network"
                  type="is-success">
                  <span>Network</span>
              </b-checkbox-button>
              <b-checkbox-button v-model="groupByLogOptimization"
                  native-value="partner"
                  type="is-success">
                  <span>Partner</span>
              </b-checkbox-button>
            </b-field>

          <line-chart
            ref="logOptimization"
            style="height: 20rem;"
            chart-id="log-optimization-chart"
            :chart-data="logOptimizationsChartData"
            :extra-options="extraOptions"
          ></line-chart>
        </div>
      </card-component>

      <card-component
        title="Log Deletions"
        icon="finance"
      >
        <div v-if="logDeletionsChartData" class="chart-area">
          <b-loading :is-full-page="false" v-model="logDeletions.loading"></b-loading>

          <dependant-multi-filter
            custom-class="px-1"
            v-model="logDeletionsFilter"
            range-date-enabled
            :default-range-date="defaultLogOptimizationRange"
            partners-enabled
            networks-enabled
            @filter="logDeletionsFilterList">

              <template #left>
                <multiselect
                v-model="logBaseData.selectedDeletionMessage"
                @input="deletionMessagesChanged"
                group-label="label"
                group-values="all"
                :group-select="true"
                :options="setGroup(logBaseData.deletionMessages, 'deletionMessages')"
                @remove="(e) => unselectGroup(e, 'logBaseData.selectedDeletionMessage')"
                :loading="loading.deletionMessages"
                :multiple="true"
                :close-on-select="true"
                placeholder="Message" />
                
              </template>

            </dependant-multi-filter>

            <b-field label="Group By" class="px-1" horizontal>
              <b-checkbox-button v-model="groupByLogDeletions"
                  native-value="message"
                  type="is-success">
                  <span>Message</span>
              </b-checkbox-button>
              <b-checkbox-button v-model="groupByLogDeletions"
                  native-value="network"
                  type="is-success">
                  <span>Network</span>
              </b-checkbox-button>
              <b-checkbox-button v-model="groupByLogDeletions"
                  native-value="partner"
                  type="is-success">
                  <span>Partner</span>
              </b-checkbox-button>
            </b-field>

          <line-chart
            ref="logDeletions"
            style="height: 20rem;"
            chart-id="log-deletions-chart"
            :chart-data="logDeletionsChartData"
            :extra-options="extraOptions"
          ></line-chart>
        </div>
      </card-component>
    <!--
        <dependant-multi-filter
          v-model="selectedMultiFilter"
          range-date-enabled
          networks-enabled
          agency-feeds-enabled
          countries-enabled
          marketing-users-enabled
          accounts-enabled
          bidding-strategies-enabled
          currencies-enabled
          @filter="filterList"
      ></dependant-multi-filter>

      <h5>Log Messages:</h5>
      <b-table
            :data="logMessageData"
            :loading="logMessageIsLoading"
            :striped="true"
            :hoverable="true"
            :sticky-header="true"
        >
          <b-table-column field="message" label="Message" sortable>
            <template v-slot="props">
                {{ props.row.message }}
            </template>
          </b-table-column>
          <b-table-column field="count" label="Count" sortable>
            <template v-slot="props">
                {{ props.row.count }}
            </template>
          </b-table-column>
      </b-table>
      <h5>Log Type:</h5>
      <b-table
        :data="logTypeData"
        :loading="logTypeIsLoading"
        :striped="true"
        :hoverable="true"
        :sticky-header="true"
        >
          <b-table-column field="type" label="Type" sortable>
            <template v-slot="props">
                {{ props.row.optimization_type }}
            </template>
          </b-table-column>
          <b-table-column field="count" label="Count" sortable>
            <template v-slot="props">
                {{ props.row.count }}
            </template>
          </b-table-column>
      </b-table>
      <h5>Deletion Message:</h5>
      <b-table
        :data="logDeletionMessageData"
        :loading="logDeletionMessageIsLoading"
        :striped="true"
        :hoverable="true"
        :sticky-header="true"
        >
          <b-table-column field="message" label="Message" sortable>
            <template v-slot="props">
                {{ props.row.message }}
            </template>
          </b-table-column>
          <b-table-column field="count" label="Count" sortable>
            <template v-slot="props">
                {{ props.row.count }}
            </template>
          </b-table-column>
      </b-table>
    -->

    </section>

  </div>
</template>

<script>
import {notificatorUtils} from "@/helpers/notificator-utils";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {
  optimizationDashboardsChartLogOptimizationMessageService,
  optimizationDashboardsChartLogDeletionMessageService,
  optimizationDashboardsChartLogOptimizationTypeService,
  optimizationDashboardsChartLogOptimizationsService,
} from "@/services";
import moment from "moment/moment";
import CardComponent from '@/components/CardComponent'
import Multiselect from 'vue-multiselect'
import LineChart from '@/components/Charts/LineChart'
import { mapState } from "vuex";
import Vue from "vue";

export default {
  name: 'OptimizationAnalysisPage',

  mounted() {
    this.getOptimizationBaseData()
  },

  mixins: [
    notificatorUtils
  ],
  components: {
    DependantMultiFilter,
    CardComponent,
    Multiselect,
    LineChart,
  },

  data() {
    return {
      isLoading: false,

      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'countries': [],
        'accounts': [],
        'marketingUsers': [],
        'rangeDateTime': [new Date(),new Date()]
      },

      groupByLogOptimization: [],
      groupByLogDeletions: [],

      logMessageIsLoading: false,
      logMessageData: [],
      
      logTypeIsLoading: false,
      logTypeData: [],
      
      logDeletionMessageIsLoading: false,
      logDeletionMessageData: [],

      logOptimizationFilter: {},
      logDeletionsFilter: {},
      defaultLogOptimizationRange: [
        moment().subtract(30, 'days').toDate(),
        moment().toDate()
      ],
      logOptimizations: {
        all: [],
        loading: false,
      },
      logDeletions: {
        all: [],
        loading: false,
      },
      logBaseData: {
        optimizationMessages: [],
        selectedOptimizationMessage: [],
        deletionMessages: [],
        selectedDeletionMessage: [],
        optimizationTypes: [],
        selectedOptimizationType: [],
        loading: false,
      },
      chartColors: {
        default: {
          primary: '#00D1B2',
          info: '#209CEE',
          danger: '#FF3860',
          warning: '#FF5900'
        }
      },
      extraOptions: {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        responsive: true,
        tooltips: {
          backgroundColor: '#f5f5f5',
          titleFontColor: '#333',
          bodyFontColor: '#666',
          bodySpacing: 4,
          xPadding: 12,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest'
        },
        scales: {
          yAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(29,140,248,0.0)',
                zeroLineColor: 'transparent'
              },
              ticks: {
                padding: 20,
                fontColor: '#9a9a9a'
              }
            }
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(225,78,202,0.1)',
                zeroLineColor: 'transparent'
              },
              ticks: {
                padding: 20,
                fontColor: '#9a9a9a'
              }
            }
          ]
        }
      },
      activeChart: 'logOptimizations',
      trackSelectLabel: {},
      loading: {
        optimizationMessages: false,
        deletionMessages: false,
        optimizationTypes: false,
      },
    }
  },

  computed: {
    ...mapState([
      'partners',
      'networks',
    ]),

    logOptimizationsChartData () {
      const data = this.dataToChart(this.logOptimizations.all, 'date', 'name', 'count');
      return this.chartCalculation(data);
    },

    logDeletionsChartData() {
      const data = this.dataToChart(this.logDeletions.all, 'date', 'name', 'count');
      return this.chartCalculation(data)
    },
  },

  methods: {

    filterList () {
      let params = {};

      if(this.selectedMultiFilter['rangeDate'])
        params['created_on_datetime__range'] = [
            moment(this.selectedMultiFilter['rangeDate'][0]).format('YYYY-MM-DD 08:00:00'),
            moment(this.selectedMultiFilter['rangeDate'][1]).add('1', 'day').format('YYYY-MM-DD 07:59:59')
        ].join(',')

      if(this.selectedMultiFilter['networks'])
        params['network__in'] = this.selectedMultiFilter['networks'].map((e) => e.id).join(',')
      if(this.selectedMultiFilter['agencyFeeds'])
        params['agency_feed__in'] = this.selectedMultiFilter['agencyFeeds'].map((e) => e.id).join(',')
      if(this.selectedMultiFilter['countries'])
        params['country__in'] = this.selectedMultiFilter['countries'].map((e) => e.id).join(',')
      if(this.selectedMultiFilter['accounts'])
        params['account__in'] = this.selectedMultiFilter['accounts'].map((e) => e.id).join(',')
      if(this.selectedMultiFilter['marketingUsers'])
        params['created_by_user__in'] = this.selectedMultiFilter['marketingUsers'].map((e) => e.id).join(',')

      this.logDeletionMessageIsLoading = true;
      optimizationDashboardsChartLogDeletionMessageService.get(params).then((e) => {
        this.logDeletionMessageData = e.data['results']
        this.logDeletionMessageIsLoading = false;
      })

      this.logMessageIsLoading = true;
      optimizationDashboardsChartLogOptimizationMessageService.get(params).then((e) => {
        this.logMessageData = e.data['results']
        this.logMessageIsLoading = false;
      })

      this.logTypeIsLoading = true;
      optimizationDashboardsChartLogOptimizationTypeService.get(params).then((e) => {
        this.logTypeData = e.data['results']
        this.logTypeIsLoading = false;
      })

    },

    chartCalculation (data) {
      let chartData = {
        datasets: [],
        labels: []
      },
      borderColor,
      colors = [
        '#00D1B2',
        '#209CEE',
        '#FF3860',
        '#FF5900',
        '#5369F8',
        '#00ffff',
        '#7fffd4',
        '#0000ff',
        '#8a2be2',
        '#a52a2a',
        '#deb887',
        '#5f9ea0',
        '#7fff00',
        '#d2691e',
        '#6495ed',
        '#fff8dc',
        '#dc143c',
        '#00ffff',
        '#00008b',
        '#008b8b',
        '#b8860b',
        '#a9a9a9',
        '#006400',
        '#a9a9a9',
        '#bdb76b',
        '#8b008b',
        '#556b2f',
        '#ff8c00',
        '#9932cc',
        '#8b0000',
        '#e9967a',
        '#8fbc8f',
        '#483d8b',
        '#2f4f4f',
        '#00ced1',
        '#9400d3',
        '#ff1493',
        '#00bfff',
        '#1e90ff',
        '#b22222',
        '#228b22',
        '#ff00ff',
        '#ffd700',
        '#daa520',
        '#808080',
        '#008000',
        '#adff2f',
        '#ff69b4',
        '#cd5c5c',
        '#4b0082',
        '#f0e68c',
        '#7cfc00',
        '#add8e6',
        '#f08080',
        '#90ee90',
        '#ffb6c1',
        '#ffa07a',
        '#20b2aa',
        '#32cd32',
      ],
      i = 0;

      for(let name in data['dataset']){

        if(i >= colors.length){
          borderColor = '#000'
        } else{
          borderColor = colors[i];
        }

        let total = data['dataset'][name].reduce((a, b) => a + b, 0);
        chartData.datasets.push({
          fill: false,
          borderColor,
          borderWidth: 2,
          borderDash: [],
          label: name + ' (' + total + ')',
          borderDashOffset: 0.0,
          pointBackgroundColor: '#000',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: this.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: data['dataset'][name]
        });
      
        i++;
      }

      chartData.labels = data['labels'];
      return chartData
    },

    logOptimizationFilterList() {

      if(!this.groupByLogOptimization.length){
        return alert('You must group by something in order to proceed.');
      }

      const created_on_datetime__range = [
            moment(this.logOptimizationFilter['rangeDate'][0]).format('YYYY-MM-DD'),
            moment(this.logOptimizationFilter['rangeDate'][1]).format('YYYY-MM-DD')
        ].join(',')

      this.logOptimizations.loading = true

      optimizationDashboardsChartLogOptimizationsService.getLogOptimizations({
        created_on_datetime__range,
        'message__in' : this.logBaseData.selectedOptimizationMessage.join(),
        'optimization_type__in': this.logBaseData.selectedOptimizationType.join(),
        'network__in' : this.logOptimizationFilter['networks'].map((element) => { return element.id }).join(),
        'partner__in': this.logOptimizationFilter['partners'].map((element) => { return element.id }).join(),
        'group_by': this.groupByLogOptimization.join(),
      }).then(r => {

        let data = r.data
        let final_list = []
        for(const l in data) {
          for(const ll in data[l]) {
            final_list.push({name: l, ...data[l][ll]})
          }
        }

        this.logOptimizations.loading = false
        this.logOptimizations.all = final_list
      }).catch(er => {
        console.log(er)
        this.logOptimizations.loading = false
      })
    },

    logDeletionsFilterList() {

      if(!this.groupByLogDeletions.length){
        return alert('You must group by something in order to proceed.');
      }

      const created_on_datetime__range = [
            moment(this.logDeletionsFilter['rangeDate'][0]).format('YYYY-MM-DD'),
            moment(this.logDeletionsFilter['rangeDate'][1]).format('YYYY-MM-DD')
        ].join(',')

      this.logDeletions.loading = true

      optimizationDashboardsChartLogOptimizationsService.getLogDeletions({
        created_on_datetime__range,
        'message__in': this.logBaseData.selectedDeletionMessage.join(),
        'network__in': this.logDeletionsFilter['networks'].map((element) => { return element.id }).join(),
        'partner__in': this.logDeletionsFilter['partners'].map((element) => { return element.id }).join(),
        'group_by': this.groupByLogDeletions.join(),
      }).then(r => {

        let data = r.data
        let final_list = []
        for(const l in data) {
          for(const ll in data[l]) {
            final_list.push({name: l, ...data[l][ll]})
          }
        }

        this.logDeletions.loading = false
        this.logDeletions.all = final_list
      }).catch(er => {
        console.log(er)
        this.logDeletions.loading = false
      })
    },

    dataToChart (data, dateKey, labelKey, axisYKey) {

      if(data.length === 0){
        return [];
      }

      // Create all Labels (For example: US, NL, etc.)
      let labels = {};
      for(let i in data){
        labels[data[i][labelKey]] = 0;
      }

      // Get all dates
      let minDate, maxDate, currentDate, momentDate;
      for(let i in data){
        currentDate = data[i][dateKey];
        momentDate = moment(currentDate);
        if(!minDate){
          minDate = maxDate = currentDate;
        }

        if(momentDate < moment(minDate)){
          minDate = currentDate;
        }

        if(momentDate > moment(maxDate)){
          maxDate = currentDate;
        }
      }

      let allDates = {};
      let currentMoment = moment(minDate).subtract(1, 'days');
      let endMoment = moment(maxDate);
      while (currentMoment.isBefore(endMoment, 'day')) {
        currentMoment.add(1, 'days');
        allDates[currentMoment.clone().format('YYYY-MM-DD')] = 0;
      }

      //reorganize data.
      let reorganizedData = {};
      for(let i in data){

        if( typeof reorganizedData[data[i][labelKey]] === 'undefined' ){
          reorganizedData[data[i][labelKey]] = {};
        }
        if( typeof reorganizedData[  data[i][labelKey]  ][  data[i][dateKey]  ] === 'undefined' ){

          let amount = 0;

          if(data[i][axisYKey]){
            amount = data[i][axisYKey];
          }

          reorganizedData[  data[i][labelKey]  ][   data[i][dateKey]   ] = amount;
        }
      }

      allDates = Object.fromEntries(Object.entries(allDates).sort());

      // Add missing dates.
      for(let i in reorganizedData){
        reorganizedData[i] = {...allDates, ...reorganizedData[i] };
      }

      // convert objects to arrays.
      for(let i in reorganizedData){
        reorganizedData[i] = Object.fromEntries(Object.entries(reorganizedData[i]).sort());
        reorganizedData[i] = Object.values(reorganizedData[i]);
      }

      return {
        'dataset': reorganizedData,
        'labels': Object.keys(allDates)
      }
    },

    _dataToChart (data, dateKey, labelKeys, axisYKey) {
      if(data.length === 0){
        return [];
      }

      // Create all Labels (For example: US, NL, etc.)
      let labels = {};
      // for(let i in data){
      //   labels[data[i][labelKey]] = 0;
      // }
      for(let i in labelKeys){
        labels[i] = 0;
      }

      // Get all dates
      let minDate, maxDate, currentDate, momentDate;
      console.log('_',data)


      for(let i in data){
        console.log(data[i].partner)
        // if(data[i].partner){
        //   self.partners.all.find(p => p.id === data[i]?.partner)?.label

        // }

        currentDate = data[i][dateKey];
        momentDate = moment(currentDate);
        if(!minDate){
          minDate = maxDate = currentDate;
        }

        if(momentDate < moment(minDate)){
          minDate = currentDate;
        }

        if(momentDate > moment(maxDate)){
          maxDate = currentDate;
        }
      }

      let allDates = {};
      let currentMoment = moment(minDate).subtract(1, 'days');
      let endMoment = moment(maxDate);
      while (currentMoment.isBefore(endMoment, 'day')) {
        currentMoment.add(1, 'days');
        allDates[currentMoment.clone().format('YYYY-MM-DD')] = 0;
      }

      //reorganize data.
      let reorganizedData = {};
      for(let i in data){
        for(let labelKey in labelKeys) {
          if( typeof reorganizedData[data[i][labelKey]] === 'undefined' ){
            reorganizedData[data[i][labelKey]] = {};
          }
          if( typeof reorganizedData[  data[i][labelKey]  ][  data[i][dateKey]  ] === 'undefined' ){

            let amount = 0;

            if(data[i][axisYKey]){
              amount = data[i][axisYKey];
            }

            reorganizedData[  data[i][labelKey]  ][   data[i][dateKey]   ] = amount;
          }
        }
      }

      allDates = Object.fromEntries(Object.entries(allDates).sort());

      // Add missing dates.
      for(let i in reorganizedData){
        reorganizedData[i] = {...allDates, ...reorganizedData[i] };
      }

      // convert objects to arrays.
      for(let i in reorganizedData){
        reorganizedData[i] = Object.fromEntries(Object.entries(reorganizedData[i]).sort());
        reorganizedData[i] = Object.values(reorganizedData[i]);
      }

      return {
        'dataset': reorganizedData,
        'labels': Object.keys(allDates)
      }
    },

    getOptimizationBaseData(){
      const filters = {
        'created_on_datetime__gt': moment().subtract(30, 'days').format('YYYY-MM-DD')
      }

      this.loading.optimizationMessages = true
      optimizationDashboardsChartLogOptimizationsService.getOptimizationMessages(filters)
        .then(r => {
          this.logBaseData.optimizationMessages = r.data
          this.loading.optimizationMessages = false
        }).catch(er => {
          this.loading.optimizationMessages = false
          console.log(er)
        })

      this.loading.deletionMessages = true
      optimizationDashboardsChartLogOptimizationsService.getDeletionMessages(filters)
      .then(r => {
        this.logBaseData.deletionMessages = r.data
        this.loading.deletionMessages = false
      }).catch(er => {
        this.loading.deletionMessages = false
        console.log(er)
      })

      this.loading.optimizationTypes = true
      optimizationDashboardsChartLogOptimizationsService.getOptimizationTypes(filters)
      .then(r => {
        this.logBaseData.optimizationTypes = r.data
        this.loading.optimizationTypes = false
      }).catch(er => {
        this.loading.optimizationTypes = false
        console.log(er)
      })
    },

    optimizationTypesChanged() {
        this.trackSelectLabel['optimizationTypes'] = this.logBaseData.optimizationTypes.length !== this.logBaseData.selectedOptimizationType.length
    },

    optimizationMessagesChanged() {
        this.trackSelectLabel['optimizationMessages'] = this.logBaseData.optimizationMessages.length !== this.logBaseData.selectedOptimizationMessage.length
    },

    deletionMessagesChanged() {
        this.trackSelectLabel['deletionMessages'] = this.logBaseData.deletionMessages.length !== this.logBaseData.selectedDeletionMessage.length
    },

    setGroup(all, type, isSingle = false) {
      if(isSingle) return all;

      if(this.trackSelectLabel[type] === undefined) this.trackSelectLabel[type] = true
      return [{label: this.trackSelectLabel[type] ? 'Select all' : 'Unselect all', all}]
    },

    unselectGroup(selectedItems, objName, isSingle = false) {
      if(isSingle) return;

      //avoid deleting all selection when they deselect only one item
      if(Object.keys(selectedItems)?.length === this[objName]?.length) {
        this.$nextTick(() => {
          Vue.set(this, objName, [])
        })
      }
    },
  }
}
</script>