<template>
    <tiles>
        <card-widget
            class="tile is-child"
            type="is-info"
            icon="mdi mdi-calendar-multiple"
            widget-type="THIS_MONTH"

            prefix="$"
            class-name="has-text-info is-size-7 mr-1"

            prefix1="$"
            class-name1="has-text-danger is-size-7 mr-1"

            prefix2="$"
            class-name2="has-text-success is-size-6 mr-1"

            prefix3="%"
            class-name3="has-text-primary-dark is-size-7 mr-1"

            label="This Month"
        />
        <card-widget
            class="tile is-child"
            type="is-info"
            icon="mdi mdi-numeric-7-box-multiple-outline"
            widget-type="LAST_7_DAYS"

            prefix="$"
            class-name="has-text-info is-size-7 mr-1"

            prefix1="$"
            class-name1="has-text-danger is-size-7 mr-1"

            prefix2="$"
            class-name2="has-text-success is-size-6 mr-1"

            prefix3="%"
            class-name3="has-text-primary-dark is-size-7 mr-1"

            label="7 Days"
        />
        <card-widget
            class="tile is-child"
            type="is-info"
            icon="mdi mdi-numeric-1-box-multiple-outline"
            widget-type="YESTERDAY"

            prefix="$"
            class-name="has-text-info is-size-7 mr-1"

            prefix1="$"
            class-name1="has-text-danger is-size-7 mr-1"

            prefix2="$"
            class-name2="has-text-success is-size-6 mr-1"

            prefix3="%"
            class-name3="has-text-primary-dark is-size-7 mr-1"

            label="Yesterday"
        />
        <card-widget
            class="tile is-child"
            type="is-info"
            icon="mdi mdi-numeric-0-box-multiple-outline"
            widget-type="TODAY"

            prefix="$"
            class-name="has-text-info is-size-7 mr-1"

            prefix1="$"
            class-name1="has-text-danger is-size-7 mr-1"

            prefix2="$"
            class-name2="has-text-success is-size-6 mr-1"

            prefix3="%"
            class-name3="has-text-primary-dark is-size-7 mr-1"

            label="Today"
        />
    </tiles>
</template>

<script>
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
export default {
    name: 'DashboardTiles',

    components: {
        Tiles,
        CardWidget,
    },
}
</script>