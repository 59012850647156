import {config} from './config';
import axios from "axios";


export const administrationTqScoresService = {
    getAll,
    config
};


export function getAll(filters, pageNumber, ordering) {

    let axiosConfig = {}

    let params = {};

    params['page'] = pageNumber;
    if(ordering){
        params['ordering'] = ordering;
    }

    params = {...params, ...filters}

    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {

        axios
            .get(`${config.apiUrl}/api/administration/tq-scores/`, axiosConfig)
            .then(response => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}