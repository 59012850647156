<template>
    <b-select v-model="biddingStrategyValue" @input="update">
        <option
            v-for="option in biddingStrategiesList"
            :key="option.code"
            :value="option.code">
            {{ option.code }}
        </option>
    </b-select>
</template>
<script>
export default {
    name: 'BiddingStrategyField',

    props: {
        biddingStrategiesList: {
            type: Array,
            required: true,
        },

        value: {
            type: String,
        }
    },

    data() {
        return {
            biddingStrategyValue: null,
        }
    },

    mounted() {
        if(this.value) {
            this.biddingStrategyValue = this.value
        }
    },

    methods: {
        update() {
            this.$emit('updateValue', this.biddingStrategyValue)
        },

        updateFromEmptyFillers(val) {
            this.biddingStrategyValue = val
        }
    }
}
</script>