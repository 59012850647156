import { config } from './config';
import axios from "axios";

export const optimizationHourlyPerformancesService = {
    getAll,
    config
};

export function getAll(
    routeHash,
    hours
) {
    let axiosConfig = {}, params = {};

    // Normalize data
    params['route_hash'] = routeHash;
    params['last_hours'] = hours;
    params['nocache'] = Date.now();

    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/performance/hourly-performances/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}