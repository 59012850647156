import { config } from './config';
import axios from "axios";

export const userStorageService = {
    getRepos,
    createRepo,
    updateRepo,
    deleteRepo,
    config
};

function getRepos(params){
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/storage/user-storage`, {params})
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function createRepo(payload){
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api/storage/user-storage/`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateRepo(payload){
  return new Promise((resolve, reject) => {
    axios
      .put(`${config.apiUrl}/api/storage/user-storage/${payload.id}/`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteRepo(payload){
  return new Promise((resolve, reject) => {
    axios
      .delete(`${config.apiUrl}/api/storage/user-storage/${payload.id}/`, payload)
      .then(response => {
        //pass the id back to filter the state
        resolve({...response, data: payload});
      })
      .catch((error) => {
        reject(error);
      });
  });
}