<template>
  <div>
    <b-button @click="openAll()">Open All</b-button>
    <div v-for="page in pages" v-bind:key="`page-${page.id}`" style="border: 1px solid #ccc; margin: 1rem; padding: 1rem;">
      {{ page.name }} - {{ page.remote_id }} | {{ page.created_on_datetime }} - <a :href="`https://saul.pytech.ai/admin/facebook_app/facebookmanagerpage/${page.id}/change/`" target="_saul">Saul</a> <br />
      <a :href="page.url">{{ page.url }}</a><br /><br />
      Ads: {{ page.ads_live_count }}/{{ page.ads_limit }}<br /><br />

      Is Active: {{ page.is_active ? 'Y': 'N' }} |
      Is Published: {{ page.is_published ? 'Y': 'N' }} |
      Is Permanently Closed: {{ page.is_permanently_closed ? 'Y': 'N' }} |
      Can Post: {{ page.can_post ? 'Y': 'N' }} |
      Is Promotion Eligible: {{ page.promotion_eligible ? 'Y': 'N' }} |
      Not Promotion Eligible Reason: {{ page.promotion_ineligible_reason }} |
      Can Post: {{ page.can_post ? 'Y': 'N' }} |
      Is Blocked: {{ page.blocked_on_datetime ? 'Y': 'N' }} |
      Blocked When: {{ page.blocked_on_datetime }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'FacebookPagesViewer',
  props: {
    pages: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    openAll (){
      for( let i in this.pages){
        window.open(this.pages[i].url);
      }
    }
  }
}
</script>
