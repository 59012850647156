<template>
    <div>
        <form @submit.prevent>
            <b-field>
                <b-input
                    type="text"
                    placeholder="First Name"
                    v-model="first_name"
                    required>
                </b-input>
            </b-field>

            <b-field>
                <b-input
                    type="text"
                    placeholder="Last Name"
                    v-model="last_name"
                    required>
                </b-input>
            </b-field>

            <p v-if="errorMsg.type === 'general'" class="notification is-danger is-light py-2 px-2 my-2">
                <ul class="m-0 pl-4" style="list-style: circle;">
                    <li v-for="(er, i) in Object.entries(errorMsg.content)" :key="i">
                        <h4><strong>{{ capitalizeStr(er[0]) }}:</strong></h4>
                        <ul class="m-0 pl-4" style="list-style: square;">
                            <li v-for="(e, ii) in er[1]" :key="ii">{{ e }}</li>
                        </ul>
                    </li>
                </ul>
            </p>
            <b-button size="is-medium" type="is-info" @click="save" :disabled="!this.first_name || !this.last_name" :loading="myProfile.loading">Save</b-button>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'ProfilePageName',

    data() {
        return {
            first_name: '',
            last_name: '',
            errorMsg: {
                type: '',
                content: '',
            },
        }
    },

    created() {
        this.first_name = this.theme.firstName
        this.last_name = this.theme.lastName
    },

    computed: {
        ...mapState(['myProfile', 'theme']),
    },

    methods: {

        save() {
            if(!this.first_name || !this.last_name)  return


            this.$store.dispatch('myProfile/changeUserInfo', { first_name: this.first_name, last_name: this.last_name })
                .then(res => {
                    //update store
                    this.$store.commit('theme/user', res.data, {root: true})

                    this.$emit('customDestroy')
                    this.$buefy.snackbar.open({
                        message: 'Name updated Successfully!',
                        queue: false,
                    })
                })
                .catch(er => {
                    this.errorMsg.type = 'general'
                    this.errorMsg.content = er.response.data
                })
        },

        capitalizeStr(str) {
            str = str.split('_')
            let a = []
            for(const s in str) {
                a.push(str[s].charAt(0).toUpperCase() + str[s].slice(1))
            }
            return a.join(' ')
        },
    }
}
</script>