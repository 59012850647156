import { config } from './config';
import axios from "axios";

export const creationKeywordImagesListService = {
    get,
};

function get(params){
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/creation/keyword-images-list/`, { params })
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}
