<style scoped>

/* Firefox old*/
@-moz-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

/* Opera and prob css3 final iteration */
@keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

.deleted-image {
  opacity: 0.5;
}

.image-is-loading {
  -moz-animation: blink normal 3s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 3s infinite ease-in-out; /* Webkit */
  animation: blink normal 3s infinite ease-in-out; /* Opera and prob css3 final iteration */
}
</style>
<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <section class="section is-main-section" v-if="this.foundKeywordsCount === -1" >
        <b-message
            title="Click To Start"
            type="is-success"
            class="has-text-centered">

          <b-field style="max-width: 800px; margin: 0 auto;" class="is-centered">
            <multiselect
              v-model="selectedNetworks"
              :options="networks.all"
              :multiple="true"
              :close-on-select="true"
              placeholder="Networks"
              readonly
              disabled
              label="name"
              track-by="id">
            </multiselect>
          </b-field>
          <b-field style="max-width: 800px; margin: 0 auto;" class="is-centered">
            <multiselect
              v-model="selectedCountries"
              :options="countries.all"
              :multiple="true"
              :close-on-select="true"
              placeholder="Countries"
              label="name"
              track-by="id">
            </multiselect>
          </b-field>
          <b-field style="max-width: 800px; margin: 0 auto;" class="is-centered">
            <b-input
              v-model="filterKeyword"
              placeholder="Keyword Contains">
            </b-input>
          </b-field>
          <b-button type="is-primary" size="is-large" @click="start" :disabled="isLoading">Start!</b-button>
        </b-message>
    </section>
    <section class="section is-main-section" v-if="this.foundKeywordsCount === 0" >
        <b-message
            title="Success"
            type="is-success"
            aria-close-label="Close message">
            Well done! You are up-to-date.. Try again latter.
        </b-message>
    </section>
    <section class="section is-main-section" v-if="'keyword' in foundKeyword && this.foundKeywordsCount > 0" >
      <b-loading v-model="isLoading"></b-loading>
      <b-navbar fixed-bottom>

        <template #end>
          <b-navbar-item tag="div">
          </b-navbar-item>
          <b-navbar-item tag="div">

            <div class="buttons">

              <b-button class="is-danger m-1" @click="save(false)" :disabled="isLoading || isLoadingBackground">
                Disapprove and Next
              </b-button>

              <b-button class="is-primary m-1" @click="save(true)" :disabled="isLoading || isLoadingBackground">
                Approve and Next
              </b-button>

            </div>

          </b-navbar-item>
        </template>
      </b-navbar>

      <div>
        <p class="title">Keyword: <u>{{ foundKeyword.keyword.name }}</u> | Total: {{ foundKeywordsCount }} | id: {{ foundKeyword.id }}</p>
        <p v-if="'id' in hemingwayDomainKeywords.one && foundKeyword.keyword.name !== hemingwayDomainKeywords.one['translated_name']">
          English Keyword: <u>{{ hemingwayDomainKeywords.one['translated_name'] }}</u>
        </p>

        <div>
          <b-button class="is-success is-small" icon-left="magnify-minus" @click="magnify('-')"></b-button>
          <b-button class="is-success is-small ml-1" icon-left="magnify-plus" @click="magnify('+')" ></b-button>
          <b-button
              class="is-success is-small is-danger ml-3"
              icon-left="delete"
              v-if="approvedImages.length > 0"
              @click="deleteAll">Delete All</b-button>
          <b-button
              class="is-success is-small is-success-passive ml-3"
              icon-left="delete"
              v-if="approvedImages.length === 0"
              @click="addAll">
            Add All
          </b-button>
        </div>

        <div class="columns is-multiline">
          <div class="column" :class="`is-${columnClass}`" v-for="img in foundKeyword.images" v-bind:key="`list-images-${img.image_hash_unique}-${foundKeyword.keyword.name}-${counter}`">
            <div class="card">
              <div class="card-image" @click="viewImage(img)" style="cursor: pointer;">
                <figure class="image">
                  <img :src="getImgUrl(img)" alt="Placeholder image" :class="{ 'deleted-image': approvedImages.indexOf(img.id) === -1 }" />
                </figure>
              </div>
              <footer class="card-footer">
                <a href="#" class="card-footer-item">
                  <b-button
                      type="is-danger is-small"
                      v-if="approvedImages.indexOf(img.id) > -1"
                      @click.prevent.stop="deleteOne(img)"
                      icon-left="delete">
                      Delete
                  </b-button>
                  <b-button
                      type="is-success is-small"
                      v-if="approvedImages.indexOf(img.id) === -1"
                      @click.prevent.stop="addOne(img)"
                      icon-left="plus">
                      Add
                  </b-button>
                </a>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </section>


    <b-modal v-model="isImageModalActive">
      <p class="image">
        <img :src="openedImageUrl" />
      </p>
    </b-modal>
  </div>
</template>

<script>

import {
  creationFoundKeywordNetworkComplianceService,
} from "@/services";
import {mapState} from "vuex";
import {notificatorUtils} from "@/helpers/notificator-utils";
import vue from "vue";
import Multiselect from 'vue-multiselect'


export default {
  name: 'FoundKeywordNetworkCompliance',

  mounted() {
    if(!this.networks.all.length) this.$store.dispatch('networks/getAll');
    if(!this.countries.all.length) this.$store.dispatch('countries/getAll');

    let columnClass = localStorage.getItem('compliance-column-class');

    if(columnClass){
      this.columnClass = parseInt(columnClass);
    }
  },

  mixins: [
    notificatorUtils
  ],
  components: {
    Multiselect
  },

  data() {
    return {
      columnClass: 1,

      preLoad: 100,
      foundKeywordsCount: -1,
      foundKeywords: [],
      foundKeyword: {},

      isLoading: false,
      showIgnored: false,

      isLoadingBackground: false,

      counter: 0,

      selectedNetworks: [{
        id: 2,
        name: 'Facebook Ad',
        code: 'FBA'
      }],
      selectedCountries: [],
      filterKeyword: null,

      isImageModalActive: false,

      openedImageUrl: '',
      approvedImages: [],
    }
  },

  computed: {
    ...mapState([
      'hemingwayDomainKeywords',
    ]),
    ...mapState('theme', ['userName']),

    ...mapState([
      'networks',
      'countries',
    ]),

  },

  methods: {

    start(){
      this.loadData(true)
    },

    loadData(selectOne){


      let params = {
      };

      if(this.selectedCountries.length > 0){
        params['country__in'] = this.selectedCountries.map((element) => { return element.id }).join(',');
      }


      params['network__in'] = this.selectedNetworks.map((element) => { return element.id }).join(',');


      if(this.filterKeyword !== '' && this.filterKeyword){
        params['keyword__name__icontains'] = this.filterKeyword;
      }

      params['page_size'] = this.preLoad;

      if(selectOne){
        this.isLoading = true;
      }

      this.isLoadingBackground = true;

      creationFoundKeywordNetworkComplianceService.get(params).then(
            response => {
              let that = this;

              if(selectOne) {
                setTimeout(function () {
                  that.isLoading = false;
                }, 1.5 * 1000);
              }

              if(response.data['results']){
                this.foundKeywordsCount = response.data['count'];

                if(this.foundKeywordsCount === 0){
                  return;
                }

                for(let i in response.data['results']){
                  vue.set(this.foundKeywords, this.foundKeywords.length, response.data['results'][i]);
                }
                this.isLoadingBackground = false;
                if(selectOne){
                  this.openOne();
                }
              }else{
                alert('All keywords are complaint. Well done!');
              }
            },
            error =>
            {
              alert('An Error Occurred 2');
              console.error(error);
              this.isLoading = false;
            });
    },

    openOne(){
      this.approvedImages = [];

      if(this.foundKeywords.length > 0){
        this.foundKeyword = JSON.parse(JSON.stringify(this.foundKeywords[0]))
        this.$delete(this.foundKeywords, 0);
        this.counter = this.counter + 1;

        this.onAfterRecordLoaded();
      }

      if(this.foundKeywords.length < this.preLoad){
        this.loadData(false);
      }

    },

    onAfterRecordLoaded(){
      let that = this;

      this.$store.dispatch('hemingwayDomainKeywords/getOneByKeywordName', {
        'keywordName': that.foundKeyword.keyword.name
      });

      this.addAll();

    },

    getImgUrl(value, fullSize){
      fullSize = fullSize || false;

      let url;

      if(fullSize){
        url = 'https://mozart-api.pytech.ai/api/proxy/picasso/image/image-opener-public/?mode=full_image&image_hash_unique=';
      }else{
        url = 'https://mozart-api.pytech.ai/api/proxy/picasso/image/image-opener-public/?mode=thumbnail&image_hash_unique=';
      }

      url += value['image_hash_unique'];

      return url;
    },

    viewImage(value){
      this.isImageModalActive = true;
      let url = 'https://mozart-api.pytech.ai/api/proxy/picasso/image/image-opener-public/?mode=full_image&image_hash_unique=';
      url += value['image_hash_unique'];
      this.openedImageUrl = url;
    },

    save(isApproved){


      if(!isApproved){
        let x = confirm('Are you sure do you want to disapprove the whole keyword?');

        if(!x){
          return;
        }

        // We would like to store empty approved images if the keyword is not approved.
        this.approvedImages = [];
      }
      creationFoundKeywordNetworkComplianceService.patch(this.foundKeyword.id, {
        'is_approved': isApproved,
        'is_reviewed': true,
        'approved_images': this.approvedImages
      })

      this.next();
    },

    next(){
      this.openOne();
    },

    magnify(method){

      if(method==='+'){
        if(this.columnClass < 12){
          this.columnClass += 1;
        }
      }else if(method==='-'){
        if(this.columnClass > 1){
          this.columnClass -= 1;
        }
      }

      localStorage.setItem('compliance-column-class', this.columnClass);
    },

    deleteOne(img){
      let k = this.approvedImages.indexOf(img.id);

      this.approvedImages.splice(k, 1);

    },

    deleteAll(){
      this.approvedImages = [];
    },

    addOne(img){
      let k = this.approvedImages.indexOf(img.id);

      if(k > -1){
        return;
      }

      this.approvedImages.push(img.id);
    },

    addAll(){
      this.approvedImages = this.foundKeyword.images.map((e) => { return e.id });
    }
  }
}
</script>