import { config } from './config';
import axios from "axios";

export const OptimizationBulkActionsService = {
    post,
    config
};


function post(
    actions,
    rules,
    rulesField
){

    let endpoint, params = {};

    params['actions_json'] = JSON.stringify(actions);
    params['rules_json'] = JSON.stringify(rules);
    params['rules_field_json'] = JSON.stringify(rulesField);

    endpoint = '/api/optimization/bulk-actions-filter/';

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}${endpoint}`, params)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}




