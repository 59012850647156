import { config } from './config';
import axios from "axios";

export const optimizationDashboardsKpiService = {
    getByDateRange,
};

export function getByDateRange(params){
	params = params || {}

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/performance/dashboards-overview-kpi/`, {params})
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}