import { optimizationDashboardsKpiService } from '@/services';


export const dashboardsKpi = {
    namespaced: true,
    state: {
        all: [],
        loading: false
    },
    actions: {
        getByDateRange({ commit }, filters) {
            commit('getAllRequest');

            return new Promise((resolve, reject) =>  {
                optimizationDashboardsKpiService.getByDateRange(filters)
                .then(
                    data => {commit('getAllSuccess', data); resolve(data)},
                    error => {commit('getAllFailure', error); reject(error)}
                );
            })
        },
    },
    mutations: {
        getAllRequest(state) {
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        getAllFailure(state) {
            state.loading = false;
        }
    }
}
