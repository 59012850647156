import {administrationBiddingStrategyService} from "@/services/administration-bidding-strategy.service";

export const biddingStrategies = {
    namespaced: true,
    state: {
        all: [],
        allGDN: [],
        loadingGDN: false,
        allFacebook: [],
        loadingFacebook: false,
        allOBN: [],
        loadingOBN: false,
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            administrationBiddingStrategyService.getAll()
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },
        getAllGDN({ commit }) {
            commit('getAllGDNRequest');

            administrationBiddingStrategyService.getAll({
                'dealer': 1
            })
                .then(
                    data => commit('getAllGDNSuccess', data),
                    error => commit('getAllGDNFailure', error)
                );
        },
        getAllFacebook({ commit }) {
            commit('getAllFacebookRequest');

            administrationBiddingStrategyService.getAll({
                'dealer': 2
            })
                .then(
                    data => commit('getAllFacebookSuccess', data),
                    error => commit('getAllFacebookFailure', error)
                );
        },
        getAllOBN({ commit }) {
            commit('getAllOBNRequest');

            administrationBiddingStrategyService.getAll({
                'dealer': 3
            })
                .then(
                    data => commit('getAllOBNSuccess', data),
                    error => commit('getAllOBNFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data.data.results;
            state.loading = false;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
        },
        getAllGDNRequest(state) {
            state.allGDN = [];
            state.loadingGDN = true;
        },
        getAllGDNSuccess(state, data) {
            state.allGDN = data.data.results;
            state.loadingGDN = false;
        },
        getAllGDNFailure(state) {
            state.allGDN = [];
            state.loadingGDN = false;
        },
        getAllFacebookRequest(state) {
            state.allFacebook = [];
            state.loadingFacebook = true;
        },
        getAllFacebookSuccess(state, data) {
            state.allFacebook = data.data.results;
            state.loadingFacebook = false;
        },
        getAllFacebookFailure(state) {
            state.allFacebook = [];
            state.loadingFacebook = false;
        },
        getAllOBNRequest(state) {
            state.allOBN = [];
            state.loadingOBN = true;
        },
        getAllOBNSuccess(state, data) {
            state.allOBN = data.data.results;
            state.loadingOBN = false;
        },
        getAllOBNFailure(state) {
            state.allOBN = [];
            state.loadingOBN = false;
        }
    }
}
