<template>
    <b-modal
        v-model="showModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Log-in as.."
        close-button-aria-label="Close"
        class="custom-modal-wrapper"
        :can-cancel="false"
        aria-modal>
        
        <header class="modal-card-head">
            <p class="modal-card-title">Log-in as..</p>
        </header>
        <div class="modal-card">
            <div class="modal-card-body p-2 is-relative">
                <multiselect
                    v-model="selectedUser"
                    :options="users.all"
                    :multiple="false"
                    :loading="users.loading"
                    :close-on-select="true"
                    placeholder="Users"
                    label="username"
                    :searchable="true"
                    track-by="id">
                </multiselect>
            </div>
            <footer class="modal-card-foot p-2">
                <b-button @click="close">Close</b-button>
                <b-button @click="superLogin" type="is-success" :disabled="!selectedUser" :loading="submitLoading">Login</b-button>
            </footer>
        </div>
    </b-modal>
</template>

<script>
import { userService, myProfileService } from '@/services'
import Multiselect from 'vue-multiselect'
export default {
    name: 'SuperLoginPage',

    data() {
        return {
            showModal: true,
            selectedUser: null,
            users: {
                all: [],
                loading: false
            },
            submitLoading: false,
        }
    },

    components: {
        Multiselect,
    },

    created() {
        this.users.loading = true
        userService.getUsers()
            .then(res => {
                this.users.loading = false
                this.users.all = res.data.results
            })
            .catch(() => {
                alert('Something went wrong...')
                this.users.loading = false
            })
    },

    methods: {
        superLogin() {
            this.submitLoading = true
            myProfileService.superLogin({'user_id': this.selectedUser.id})
            .then(user => {
                this.submitLoading = false
                localStorage.setItem('user', JSON.stringify(user.data));
                this.$router.push({name: 'main'})
                window.location.reload()
            })
            .catch(() => {
                this.submitLoading = false
                alert('Something went wrong...')
                window.location.reload()
            })
        },

        close() {
            this.showModal = false
            this.$router.back()
        },
    },

}
</script>