<template>
  <card-component title="Clients" icon="briefcase" class="has-table has-mobile-sort-spaced mb-6 p-1" customHeaderClass="px-1">
    <template v-slot:toolbar-right>
      <b-field class="mt-3">

        <p class="control" v-if="isSuperUser()">
          <b-button size="is-small" :focused="chartType === 'BY_ACCOUNT'" @click="chartType='BY_ACCOUNT'">By Account</b-button>
        </p>
        <p class="control">
          <b-button size="is-small" :focused="chartType === 'BY_PRODUCT'" @click="chartType='BY_PRODUCT'">By Product</b-button>
        </p>
        <p class="control">
          <b-button size="is-small" :focused="chartType === 'BY_COUNTRY_PRODUCT'" @click="chartType='BY_COUNTRY_PRODUCT'">By Country Product</b-button>
        </p>
        <p class="control" v-if="isSuperUser()">
          <b-button size="is-small" :focused="chartType === 'BY_AD_UNIT'" @click="chartType='BY_AD_UNIT'">By Ad Unit</b-button>
        </p>
        <p class="control" v-if="isSuperUser()">
          <b-button size="is-small" :focused="chartType === 'BY_AD_UNIT_COUNTRY_PRODUCT'" @click="chartType='BY_AD_UNIT_COUNTRY_PRODUCT'">By Ad Unit Country Product</b-button>
        </p>

      </b-field>
    </template>
    <dependant-multi-filter
      v-model="selectedFilters"
      range-date-enabled
      agency-feeds-enabled
      countries-enabled
      networks-enabled
      products-enabled
      accounts-enabled
      :default-range-date="defaultRangeDate"
      @filter="fillReportData"
    >
    </dependant-multi-filter>
    <b-table
        :loading="dashboardsReportPerformances.loading"
        :paginated="paginated"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        default-sort="profit"
        :data="dashboardsReportPerformances.all"
        :sticky-header="true"
        height="450"
        class="custom-table-dashboard"
    >
      <b-table-column label="View" field="view" sortable v-slot="props">
        <router-link
            :to="{ name: 'optimization', query: { 'countriesId': [ props.row.country_id ], 'productsId': [ props.row.product_id ]  }, }">
          View
        </router-link>
      </b-table-column>
      <b-table-column label="Item" field="item" sortable v-slot="props">
        {{ props.row.item }}
      </b-table-column>
      <b-table-column label="Item 1" field="item_1" sortable v-slot="props">
        {{ props.row.item_1 }}
      </b-table-column>
      <b-table-column label="Keywords" field="keywords_cnt" sortable v-slot="props">
        {{ props.row.keywords_cnt }}
      </b-table-column>
      <b-table-column label="Cost" field="cost" sortable v-slot="props">
        {{ renderCurrency(props.row.cost, 2) }}
      </b-table-column>
      <b-table-column label="Revenue" field="revenue" sortable v-slot="props">
        {{ renderCurrency(props.row.revenue, 2) }}
      </b-table-column>
      <b-table-column label="ROI" field="roi" sortable v-slot="props">
        {{ renderPercentage(props.row.roi, 2) }}
      </b-table-column>
      <b-table-column label="CTR(R)" field="ctr_r" sortable v-slot="props">
        {{ renderPercentage(props.row.ctr_r, 2) }}
      </b-table-column>
      <b-table-column label="CTR(C)" field="ctr_c" sortable v-slot="props">
        {{ renderPercentage(props.row.ctr_c, 2) }}
      </b-table-column>
      <b-table-column label="Profit" field="profit" sortable v-slot="props">
        <span :class="{'has-text-danger': props.row.sum_profit < 0, 'has-text-success': props.row.sum_profit > 0}">
          {{ renderCurrency(props.row.sum_profit, 2) }}
        </span>
      </b-table-column>
      <b-table-column label="Higher Revenue" field="max_revenue" sortable v-slot="props">
        {{ renderCurrency(props.row.max_revenue, 2) }}
      </b-table-column>
      <b-table-column label="Max Lost" field="lowest_profit" sortable v-slot="props">
        {{ renderCurrency(props.row.lowest_profit, 2) }}
      </b-table-column>
      <b-table-column label="Max Profit" field="higher_profit" sortable v-slot="props">
        <span
            :class="{'has-text-danger': props.row.higher_profit > 40, 'has-text-success': props.row.higher_profit < 40}">
          {{ renderCurrency(props.row.higher_profit, 2) }}
        </span>
      </b-table-column>
      <b-table-column cell-class="is-progress-col" label="Progress" field="progress" sortable v-slot="props">
        {{ calculateProgressBar(props.row) }}% | Loss: <span
          class="has-text-danger">{{ renderCurrency(props.row.amount_in_loss, 2) }}</span> / Profit: <span
          class="has-text-success">{{ renderCurrency(props.row.amount_in_profit, 2) }}</span>
        <progress class="progress is-small is-primary" :value="calculateProgressBar(props.row)" max="100">
          {{ calculateProgressBar(props.row) }}
        </progress>
      </b-table-column>
    </b-table>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import numeraljs from "numeraljs";
import {numberUtils} from "@/helpers/number-utils";
import {timeUtils} from "@/helpers/time-utils";
import {mapState} from "vuex";
import moment from "moment-timezone";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {permissionsUtils} from "@/helpers/permissions-utils";

export default {
  name: 'ClientsTable',
  components: {
    CardComponent,
    DependantMultiFilter
  },
  mixins: [
    numberUtils,
    timeUtils,
    permissionsUtils
  ],

  created() {
    this.setSelectedDates('YESTERDAY', 'dailyReportSelectedDate');
    this.fillReportData()
  },

  data() {
    return {

      chartType: 'BY_COUNTRY_PRODUCT',

      defaultRangeDate: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate()
      ],

      paginated: true,
      perPage: 50,
      checkedRows: [],

      selectedFilters: {
        rangeDate: [
            moment().subtract(1, 'days'),
            moment().subtract(1, 'days')
        ],
        agencyFeeds: [],
        countries: [],
        networks: [],
        products: [],
        accounts: []
      }
    }
  },

  computed: {
    ...mapState([
      'dashboardsReportPerformances',
    ])
  },

  methods: {

    calculateProgressBar(row) {
      let val = row.amount_in_profit / ((row.amount_in_profit + (row.amount_in_loss * -1)) / 100);

      if (val > 100) {
        return 100;
      }

      if (isNaN(val)) {
        return 0;
      }

      return numeraljs(val).format('0');
    },

    fillReportData () {
      let fromDate,
          toDate;

      if(this.selectedFilters.rangeDate.length > 1){
        fromDate = moment(this.selectedFilters.rangeDate[0]).format('YYYY-MM-DD');
        toDate = moment(this.selectedFilters.rangeDate[1]).format('YYYY-MM-DD');
      }

      this.$store.dispatch(
        'dashboardsReportPerformances/getByDateRange', {
          fromDate: fromDate,
          toDate: toDate,
          chartType: this.chartType,
          networks: this.selectedFilters.networks,
          agencyFeeds: this.selectedFilters.agencyFeeds,
          products: this.selectedFilters.products,
          countries: this.selectedFilters.countries,
          accounts: this.selectedFilters.accounts,
        }
      );

    },
  }
}
</script>
