<template>
  <div>
    <div class="columns mt-1">
      <div class="column" v-for="(domainKeyword, rowId) in domainKeywords" v-bind:key="`domain-keywords-${rowId}`">
        <b-field label="Main Keyword:">
          <b-input v-model="domainKeyword.domain" @click.native="_updateModel" @mouseleave.native="_updateModel"  @paste.native="_handlePaste($event, rowId, null, null, true)"></b-input>
        </b-field>
        Related Keywords:
        <div class="column" v-for="(keyword, kRowId) in domainKeywords[rowId].keywords" v-bind:key="`domain-keywords-keyword-${rowId}--${kRowId}`">
            <b-input v-model="keyword.name" :placeholder="`Keyword ${keyword.position}/${requiredKeywords}..`" @click.native="_updateModel" @mouseleave.native="_updateModel" @paste.native="_handlePaste($event, rowId, kRowId, keyword.position, false)"></b-input>
        </div>
        <b-button @click="addNewDomain" class="is-success is-small" icon-left="plus-box">Add new</b-button>
        <b-button @click="removeDomain(rowId)" v-if="domainKeywords.length > 1" class="is-danger is-small is-pulled-right" icon-left="close">Remove</b-button>
      </div>
    </div>
  </div>
</template>

<script>

import vue from "vue";
import {permissionsUtils} from "@/helpers/permissions-utils";

export default {
  name: 'KeywordsSelector',
  mixins: [
      permissionsUtils
  ],
  props: {
    requiredKeywords: {
      type: Number,
    },
  },

  mounted() {

    for(let i = 1; i <= this.requiredKeywords; i++){
      this.domainKeywordEmpty.keywords.push({
        name: '',
        position: i
      });
    }

    this.domainKeywords.push(JSON.parse(JSON.stringify(this.domainKeywordEmpty)));

    this._updateModel(true);
  },

  data() {
    return {
      domainKeywordEmpty: {
        domain: '',
        keywords: []
      },

      domainKeywords: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    addNewDomain: function () {
      this.domainKeywords.push(
          Object.assign({}, this.domainKeywordEmpty)
      )
    },

    removeDomain: function (rowId){
      if(this.domainKeywords.length === 1){
        return;
      }

      vue.delete(this.domainKeywords, rowId);
    },

    _updateModel(ignoreValidation){
      ignoreValidation = ignoreValidation || false;
      if(this.validate()){
        //remove empty keywords
        let dk = [...this.domainKeywords];
        for(let i in dk){
            dk[i].keywords.filter(e => e !== '');
        }

        this.$emit('input', dk);
      }

      if(!ignoreValidation && !this.validate()){
        this.$emit('input', []);
      }

    },
    validate(){
      for(let i in this.domainKeywords){

        let domainKeyword = this.domainKeywords[i];

        if(domainKeyword.domain === ''){
          // let msg = 'You must type a domain/vertical name/main keyword';
          // this.$buefy.toast.open({'message': msg, 'position': 'is-top'});
          return false;
        }

        // for(let ii in domainKeyword['keywords']){
        //   let keyword = domainKeyword['keywords'][ii]['name'];
        //   if(keyword === ""){
        //   //   let msg = 'You must type all related keywords';
        //   //   this.$buefy.toast.open({'message': msg, 'position': 'is-top'});
        //        return false;
        //   }
        // }
      }
      return true;
    },

    _handlePaste(event, rowId, kRowId, keywordPosition, isMainKeyword){
        event.preventDefault();
        let paste = (event.clipboardData || window.clipboardData).getData("text"),
            items,
            startLoop = kRowId;

        if(paste){
          items = paste.split("\n");

          if(isMainKeyword){
            startLoop = 0;
            vue.set(this.domainKeywords[rowId], 'domain', items[0]);
            delete items[0];
            items = items.filter(Boolean);
            console.dir(items);
          }
          let itemsI = 0;
          for(let i = startLoop; i <= Object.keys(this.domainKeywords[rowId].keywords).length; i++){

            if(this.domainKeywords[rowId] && this.domainKeywords[rowId].keywords[i]){
              if(typeof this.domainKeywords[rowId].keywords[i] === "object"){
                vue.set(this.domainKeywords[rowId].keywords[i], 'name', items[itemsI]);
                itemsI = itemsI + 1;
              }
            }
          }

          this._updateModel(true);
        }
    }
  },
}
</script>
