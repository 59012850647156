<style>

.is-fullwidth {
  width: 100% !important;
}

.fb-message {
  float: left;
  border-radius: 1rem;
  background-color: #CCCCCC;
  color: #000000;
  padding: 1rem;
  display: inline-block;
  max-width: 85%;
}

.fb-message small {
  font-size: small;
}

.fb-message-page {
  float: right;
  background-color: #0a7cff;
  color: #FFFFFF;
}

.tall-textarea {
  min-height: 26em;
}

</style>
<template>
  <div>

    <section class="section is-main-section" v-if="this.conversationsCount === -1">


        <b-message
            title="Click To Start"
            type="is-success"
            class="has-text-centered">

          <multiselect
            v-model="selectedAgencyFeeds"
            :options="agencyFeeds.all"
            :multiple="true"
            :close-on-select="true"
            placeholder="Agency Feeds"
            label="code"
            style="max-width: 14%;"
            track-by="id">
          </multiselect>

          <b-button type="is-primary" size="is-large" @click="start" :disabled="isLoading">Start!</b-button>
        </b-message>
    </section>
    <section class="section is-main-section" v-if="this.conversationsCount === 0" >
        <b-message
            title="Success"
            type="is-success"
            aria-close-label="Close message">
            Well done! You are up-to-date.. Try again latter.
        </b-message>
    </section>
    <section class="section is-main-section" v-if="'id' in conversation && this.conversationsCount > 0" >
      <b-loading v-model="isLoading"></b-loading>
      <b-navbar fixed-bottom>

        <template #end>
            <b-navbar-item tag="div">
              <span> Processed (Current Session): {{ counter }}</span>

            </b-navbar-item>
            <b-navbar-item tag="div">
                <div class="buttons">
                  <b-button class="is-primary m-1" @click="save" :disabled="isLoading">
                    Send Message
                  </b-button>
                </div>
            </b-navbar-item>
        </template>
      </b-navbar>

      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child notification is-info ">
                <p class="title">(1 of {{ conversationsCount }}) - <b><u>{{ pageName }}</u></b>

                </p>
                <div class="subtitle">

                  <div class="columns">
                    <div class="column is-half">
                        <b-button
                            size="is-small"
                            type="is-info"
                            icon-left="message-reply-text"
                            v-for="(a, indexCode) in messageTemplates"
                            v-bind:key="`templates-${indexCode}`"
                            @click="setTemplateByCode(indexCode);"
                        >
                          {{ indexCode }}
                        </b-button>

                        <b-field label="Message">
                          <b-input maxlength="200" type="textarea" v-model="newMessage" custom-class="tall-textarea" ></b-input>
                        </b-field>
                        <b-button
                            size="is-small"
                            type="is-success"
                            @click="save"
                            icon-left="send">
                          Send Message!
                        </b-button>

                    </div>
                    <div class="column">
                      <div class="mt-6" v-for="c in conversation['facebookmessage_set']" v-bind:key="`conversations-message-${c['id']}`">
                          <span :class="{'fb-message': true, 'fb-message-page': c['from_interlocutor'] && c['from_interlocutor']['manager_page'] !== null}">
                            <span v-if="c['from_interlocutor']['manager_page'] === null">
                              <b-icon icon="account-question-outline"></b-icon>
                              {{ c['from_interlocutor']['name'] }} - <small>{{ etaFromUTC(c['created_time_utc']) }} ago:</small>
                            </span>
                            <span v-else>
                              <b-icon icon="account-tie-voice"></b-icon>
                              {{ c['from_interlocutor']['name'] }} - <small>{{ etaFromUTC(c['created_time_utc']) }} ago:</small>
                            </span> <br /><br />
                            {{ c['message'] }}
                          </span>
                          <div class="is-clearfix"></div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {saulFacebookConversationsService} from "@/services/saul-facebook-conversations.service";
import moment from "moment-timezone";
import {saulFacebookMessagesService} from "@/services/saul-facebook-messages.service";
import Multiselect from 'vue-multiselect'

export default {
  name: 'FacebookInboxPage',

  mounted() {
    if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll');
  },

  mixins: [
  ],
  components: {
    Multiselect,
  },

  data() {
    return {

      selectedAgencyFeeds: [],

      newMessage: '',

      conversationsCount: -1,
      conversation: {},

      isLoading: false,

      counter: 0,

      messageTemplates: {
          'it': "Gentile %{user},\n" +
            "\n" +
            "\n" +
            "Grazie per aver contattato %{pageName}.\n" +
            "\n" +
            "\n" +
            "Noi promuoviamo motori di ricerca web.\n" +
            "\n" +
            "\n" +
            "Si prega di notare che le immagini visualizzate sono dimostrative per il termine di ricerca suggerito.\n"  +
            "\n" +
            "Grazie,\n" +
            "Ci scusiamo se abbiamo causato qualche inconveniente.\n" +
            "%{pageName}\n",

        'es': "Hola %{user},\n" +
            "Gracias por contactar %{pageName}.\n" +
            "\n" +
            "\n" +
            "Nosotros promocionamos motores de búsquedas web.\n" +
            "\n" +
            "\n" +
            "Nos gustaría aprovechar la ocasión para informarle que las imágenes son demostrativas como ejemplo de una posible búsqueda online.\n"  +
            "\n" +
            "Gracias,\n" +
            "\n" +
            "Disculpe si le hemos causado algún inconveniente.\n" +
            "%{pageName}\n",

        'en': "Dear %{user},\n" +
            "\n" +
            "\n" +
            "Thank you for contacting %{pageName}.\n" +
            "\n" +
            "\n" +
            "We promote search engine websites.\n" +
            "\n" +
            "\n" +
            "Please note pictures displayed are demonstrative for the suggested search term.\n"  +
            "\n" +
            "Thank you,\n" +
            "Apologies if we caused any inconvenience.\n" +
            "%{pageName}\n"

      }
    }
  },

  computed: {
    ...mapState('theme', ['userName']),
    ...mapState([
      'agencyFeeds'
    ]),
    pageName() {

      if(this.conversation['facebookmessage_set'].length > 0){
        return this.conversation['facebookmessage_set'][0]?.to_interlocutors[0]?.name;
      }

      return '';
    },
    user() {

      if(this.conversation['facebookmessage_set'].length > 0){
        return this.conversation['facebookmessage_set'][0]?.from_interlocutor?.name;
      }

      return '';
    }
  },

  methods: {

    start() {
      this.loadData()
    },

    loadData() {
      let params = {
        'facebookmessage__is_replied__isnull': true,
        'facebookmessage__from_interlocutor__manager_page__isnull': true,
      };

      if(this.selectedAgencyFeeds.length > 0){
        params['manager_page__account__agency_feed__in'] = this.selectedAgencyFeeds.map((e) => { return e.id }).join(',');
      }

      this.conversation = {};

      this.isLoading = true;
      this.newMessage = '';
      saulFacebookConversationsService.get(params).then(
            response => {
              let that = this;

              that.isLoading = false;

              if(response.data['results']){
                this.conversationsCount = response.data['count'];

                if(this.conversationsCount === 0){
                  return;
                }

                this.conversation = response.data['results'][0];
                this.counter = this.counter + 1;

              }else{
                alert('All conversations are processed. Well done!');
              }
            },
            error =>
            {
              alert('An Error Occurred 2');
              console.error(error);
              this.isLoading = false;
            });
    },

    etaFromUTC(UTCDate)
    {
      let stillUtc = moment.utc(UTCDate).toDate(),
          local = moment(stillUtc).local(),
          today = moment(),
          days,
          hours,
          minutes,
          seconds;

      days = today.diff(local, 'days');

      if(days > 0){
        local = moment(local).add(days, 'days').toDate();
        hours = today.diff(local, 'hours');
        return days + ' days and ' + hours + ' hours';
      }

      hours = today.diff(local, 'hours');
      if(hours > 0){
        local = moment(local).add(hours, 'hours').toDate();
        minutes = today.diff(local, 'minutes');
        return hours + ' hours and ' + minutes + ' minutes';
      }

      minutes = today.diff(local, 'minutes');
      if(minutes > 0){
        local = moment(local).add(minutes, 'minutes').toDate();
        seconds = today.diff(local, 'seconds');
        return minutes + ' minutes and ' + seconds + ' seconds';
      }

      seconds = today.diff(local, 'seconds');
      return seconds + ' seconds';
    },


    save() {

      let params = {
        'message_text': this.newMessage,
        'conversation_id': this.conversation.id,
      };

      saulFacebookMessagesService.post(params).then(()=>{
        this.$buefy.toast.open('Saved!');
      }).catch((e)=>{
        alert(e);
      })

      this.next();
    },

    next(){
      this.loadData();
    },

    setTemplateByCode(templateCode)
    {
      let template = this.messageTemplates[templateCode];
      let names = this.user.split(' ');

      template = template.replaceAll('%{pageName}', this.pageName);
      template = template.replaceAll('%{user}', names[0]);
      this.newMessage = template;
    }

  }
}
</script>