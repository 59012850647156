<template>
  <div>
    <section class="section is-main-section" ref="filterstop">
            <dependant-multi-filter
              v-model="selectedMultiFilter"
              single-date-enabled
              products-enabled
              networks-enabled
              agency-feeds-enabled
              countries-enabled
              marketing-users-enabled
              accounts-enabled
              bidding-strategies-enabled
              currencies-enabled
              @filter="filterList"
          ></dependant-multi-filter>

        <div class="is-pulled-right is-hidden-mobile">
          <b-tag rounded type="is-success">
            <b-tooltip label="Keywords in Profit" dashed>
              K+: {{  dailyPerformances.overallsKpi.keywords_in_profit }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-warning">
            <b-tooltip label="Keywords No Traffic" dashed>
              K0: {{  dailyPerformances.overallsKpi.keywords_no_traffic }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Keywords in Loss" dashed>
              K-: {{  dailyPerformances.overallsKpi.keywords_in_loss }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="Percentage in Profit" dashed>
              %+: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_profit) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-warning">
            <b-tooltip label="Percentage in No Traffic" dashed>
              %0: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_no_traffic) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Percentage in Loss" dashed>
              %-: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_loss) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="Ratio in Profit" dashed>
              R+: {{  renderNumber(dailyPerformances.overallsKpi.ratio_in_profit, 2) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Ratio in Loss" dashed>
              R-: {{  renderNumber(dailyPerformances.overallsKpi.ratio_in_loss, 2) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="ROI in Profit" dashed>
              ROI+: {{  renderPercentage(dailyPerformances.overallsKpi.keywords_in_profit_roi, 2) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="ROI in Loss" dashed>
              ROI-: {{  renderPercentage(dailyPerformances.overallsKpi.keywords_in_loss_roi, 2) }}
            </b-tooltip>
          </b-tag>
        </div>

        <b-field grouped v-for="(r, rowId) in rules" v-bind:key="`performance-filter-${rowId}`">
                <b-field>
                    <b-button
                    type="is-danger"
                    @click="removeRule(r, rowId);"
                    :disabled="rules.length === 1"
                    icon-left="close" />

                    <b-select
                        placeholder="Index"
                        v-model="rules[rowId].indexName"
                        @change.native="setFirstOperator(rowId)"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-name-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Operator"
                        v-model="rules[rowId].indexOperator"
                        :disabled="Object.keys(rules[rowId].indexName).length === 0">
                        <option
                            v-for="(indexOperator, indexOperatorI) in getOperators(rules[rowId].indexName.operators)"
                            :key="`performance-index-operator-${rowId}-${indexOperatorI}`"
                            :value="indexOperator"
                        >
                          {{ indexOperator.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Index"
                        v-model="rules[rowId].indexValueField"
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-value-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>
                    <span
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                        style="padding: 1rem;"
                    >*</span>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexFactor"
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                        style="width: 50px;"
                    ></b-input>

                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'number'"
                    ></b-input>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        indexValue
                        v-else-if="rules[rowId].indexName.indexType === 'money'"
                    ></b-input>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        indexValue
                        prefix="%"
                        v-else-if="rules[rowId].indexName.indexType === 'percentage'"
                    ></b-input>
                    <b-input
                        type="text"
                        v-model="rules[rowId].indexValue"
                        indexValue
                        v-else-if="rules[rowId].indexName.indexType === 'text'"
                    ></b-input>

                    <b-datetimepicker
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'datetime'"
                        :datetime-formatter="formatDatetime"
                        :timepicker="{ enableSeconds: true }"
                        placeholder="Click to select...">

                        <template #left>
                            <b-button
                                label="Now"
                                type="is-primary"
                                icon-left="clock"
                                @click="datetime = new Date()" />
                        </template>

                        <template #right>
                            <b-button
                                label="Clear"
                                type="is-danger"
                                icon-left="close"
                                outlined
                                @click="datetime = null" />
                        </template>
                    </b-datetimepicker>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'deliveryStatus'"
                    >
                      <option value="DELIVERING">
                        Delivering
                      </option>
                      <option value="NOT_DELIVERING">
                        Not Delivering
                      </option>
                    </b-select>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'status'"
                    >
                      <option value="ACTIVE">
                        Active
                      </option>
                    </b-select>

                    <b-input
                        type="text"
                        v-model="rules[rowId].indexValue"
                        indexValue
                        disabled
                        v-else
                    ></b-input>

                  <b-button
                    type="is-success"
                    @click="addRule();"
                    icon-left="check" />
                  </b-field>
        </b-field>
        <b-field>
            <strong>
              {{ !showActive && !showPaused && !showRemoved ? '(showing all)': '' }}
            </strong>
            <b-switch v-model="showActive">
                {{ showActive ? 'Showing Active' : 'Hiding Active' }}
            </b-switch>
            <b-switch v-model="showPaused">
                {{ showPaused ? 'Showing Paused' : 'Hiding Paused' }}
            </b-switch>
            <b-switch v-model="showRemoved">
                {{ showRemoved ? 'Showing Removed' : 'Hiding Removed' }}
            </b-switch>

        </b-field>
      </section>
      <section class="section is-main-section" style="padding-top: 0;">
        <span class="is-hidden-desktop">
            Rev: {{ renderCurrency(dailyPerformances.overalls.sum_revenue_amount_net, 2) }}
            Costs: {{ renderCurrency(dailyPerformances.overalls.sum_cost_amount, 2) }}
            Profit: {{ renderCurrency(dailyPerformances.overalls.sum_profit, 2) }}
            ({{ renderPercentage(dailyPerformances.overalls.avg_roi, 2) }})
        </span>

        <b-table
            :data="dailyPerformances.all"

            :striped="true"
            :hoverable="true"
            :loading="dailyPerformances.loading"
            :height="tableHeight()"
            :sticky-header="true"

            pagination-position="top"
            default-sort-direction="DESC"
            :pagination-rounded="false"
            sort-icon="chevron-up"
            sort-icon-size="is-medium"
            default-sort="_profit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"

            :per-page="dailyPerformances.paging.page_size"
            :total="dailyPerformances.paging.count"
            :current-page="dailyPerformances.paging.page_number"

            @page-change="changePage"
            backend-pagination
            paginated

            backend-sorting
            @sort="sortPressed"
            @sorting-priority-removed="sortingPriorityRemoved"

            :sort-multiple="true"
            :sort-multiple-data="sortingPriority"
            :sort-multiple-key="customKey"

            detailed
            :show-detail-icon="showDetailIcon"

            :scrollable="true"
            narrowed
        >
          <b-table-column label="Actions">
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <clone-main-entity :main-entity-id="`${props.row.route_hash.livemainentity.id}`"></clone-main-entity>
              </template>
            </b-table-column>

            <b-table-column field="route_hash.livemainentity.country.name" label="Country" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div style="line-height: 0.7">
                <a @click="props.toggleDetails(props.row)">
                  {{ props.row.route_hash.livemainentity.country.name }}
                </a>
                <span style="font-size: 8px;word-wrap:break-word; display: inline-block; width: 100%;">
                  {{ renderDate(props.row.route_hash.livemainentity.created_on_datetime, 'MM-DD HH:mm') }} - Eta: {{ countDays(props.row.route_hash.livemainentity.created_on_datetime) }}D
                </span>
                  </div>
              </template>
            </b-table-column>

            <b-table-column field="products" label="Products" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">{{ props.row.route_hash.livemainentity.subdomain_locale.product.name  }}</template>
            </b-table-column>

            <b-table-column field="products" label="Keywords" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <b-icon icon="facebook" size="is-small" v-if="props.row.route_hash.network.dealer === 2" title="Facebook"></b-icon>
                <b-icon icon="google" size="is-small" v-else title="Google"></b-icon>
                <b-icon size="is-small"
                        :type="checkDataConsistency(props.row)"
                        :title="checkDataConsistency(props.row, 'title')"
                        icon="autorenew" style="display: inline;"></b-icon>
                <b-icon size="is-small"
                        type="is-success"
                        v-if="checkOptimizedHoursAgo(props.row.route_hash.livemainentity.last_optimization_on_datetime, props.row.route_hash.livemainentity.account.timezone_code) == 1"
                        :title="props.row.route_hash.livemainentity.last_optimization_on_datetime"
                        icon="calendar" style="display: inline;"></b-icon>
                <b-icon size="is-small"
                        type="is-success"
                        v-if="checkOptimizedHoursAgo(props.row.route_hash.livemainentity.last_optimization_on_datetime, props.row.route_hash.livemainentity.account.timezone_code) == 0"
                        :title="props.row.route_hash.livemainentity.last_optimization_on_datetime"
                        icon="clock" style="display: inline;"></b-icon>
                <b-icon size="is-small"
                        type="is-danger"
                        v-if="props.row.route_hash.livemainentity.delivery_status==='NOT_DELIVERING' &&
                              ['PAUSED', 'REMOVED'].indexOf(props.row.route_hash.livemainentity.status) < 0"
                        :title="props.row.route_hash.livemainentity.delivery_status"
                        icon="alert" style="display: inline;"></b-icon>
                {{ props.row.route_hash.livemainentity.keyword_name }}
              </template>
            </b-table-column>

           <b-table-column field="route_hash.id" label="Hash" width="85" class="is-small">
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                {{ props.row.route_hash.id }}
              </template>
            </b-table-column>

            <b-table-column field="revenue_amount_net" label="Revenue" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_revenue_amount_net, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.revenue_amount_net, 2) }}</template>
            </b-table-column>

            <b-table-column field="cost_amount" label="Cost" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_cost_amount, 2) }}
              </template>
              <template v-slot="props">

                <span :title="props.row.route_hash.livemainentity.account.currency_code">
                  {{ renderCurrency(props.row.cost_amount, 2) }}
                  <b-icon :title="props.row.route_hash.livemainentity.account.currency_code" v-if="props.row.route_hash.livemainentity.account.currency_code !== 'USD'" icon="account-cash" type="is-warning" class="is-inline-block"></b-icon>
                </span>

              </template>
            </b-table-column>

            <b-table-column field="profit" label="Profit" sortable numeric>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_profit, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row.profit) >= 0,
                  'has-text-danger': makeNumber(props.row.profit) < 0,
                }">
                  {{ renderCurrency(props.row.profit, 2) }}
                </span>

              </template>
            </b-table-column>

            <b-table-column field="roi" label="ROI" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformances.overalls.avg_roi, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row.roi) > 0,
                  'has-text-danger': makeNumber(props.row.roi) < 0,
                }">
                  {{ renderPercentage(props.row.roi, 2) }}
                </span>
              </template>
            </b-table-column>

              <template slot="top-left">
                <b-field>
                  <b-field label="Rows" label-position="on-border" style="width: 8rem;">
                    <b-input v-model="dailyPerformances.paging.count" disabled />
                  </b-field>
                  <b-field label="Page Size" label-position="on-border">
                    <b-select v-model="pageSize">
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                      <option>5000</option>
                      <option>10000</option>
                    </b-select>
                </b-field>
                  </b-field>
              </template>

            <template slot="detail" slot-scope="props">
                <article class="media">
                    <div class="media-content">
                        <div class="content">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Account:</th>
                                <th>Timezone:</th>
                                <th>Production/Active/Blocked:</th>
                              </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.name }} ||
                                <a
                                    :href="generateTrafficSourceLink(props)"
                                    :disabled="generateTrafficSourceLink(props) === null"
                                    target="_traffic_source">Open on traffic source</a>
                              </td>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.timezone_code }}
                              </td>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.is_production ? 'Y':'N'  }}
                                {{ props.row.route_hash.livemainentity.account.is_active ? 'Y':'N' }}
                                {{ props.row.route_hash.livemainentity.account.blocked_on_datetime ? props.row.route_hash.livemainentity.account.blocked_on_datetime : 'Ok' }}
                              </td>
                            </tr>
                            </tbody>
                            <thead>
                              <tr>
                                <th>Ads Review:</th>
                                <th>Ads Live:</th>
                                <th>Ads Limit:</th>
                                <th>Ads Error:</th>
                              </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.ads_in_review_count }}
                              </td>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.ads_running_count }}
                              </td>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.ads_limit }}
                              </td>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.ads_with_error_count }}
                              </td>
                            </tr>
                            </tbody>
                            <thead>
                              <tr>
                                <th colspan="3">Url:</th>
                              </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td colspan="3">
                                <a :href="props.row.route_hash.livemainentity.url_value" target="_blank">
                                  {{ props.row.route_hash.livemainentity.url_value }}
                                </a>
                              </td>
                            </tr>
                            </tbody>
                          </table>

                        </div>
                    </div>
                </article>
            </template>

        </b-table>

      </section>

  </div>
</template>

<script>
import numeral from "numeraljs"
import {mapState} from "vuex";
import moment from "moment-timezone";
import {osUtils} from "@/helpers/os-utils";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {numberUtils} from "@/helpers/number-utils";
import CloneMainEntity from "@/components/Element/CloneMainEntity";

export default {
  name: 'PerformanceViewer',
  created() {
    this.rules.push(Object.assign({}, this.emptyRule))

    if(this.$route.query.productsId){
      let products = [];
      if(Array.isArray(this.$route.query.countriesId)) {
        for (let i in this.$route.query.productsId) {
          products.push({
            id: this.$route.query.productsId[i]
          });
        }
      }else{
        products.push({
          id: this.$route.query.productsId
        });
      }

      this.selectedProducts = products;
    }

    if(this.$route.query.countriesId){
      let countries = [];
      if(Array.isArray(this.$route.query.countriesId)){
        for(let i in this.$route.query.countriesId){
          countries.push({
            id: this.$route.query.countriesId[i]
          });
        }
      }else{
        countries.push({
          id: this.$route.query.countriesId
        });
      }

      this.selectedCountries = countries;
    }

  },
  components: {
    CloneMainEntity,
    DependantMultiFilter,
  },
  mixins: [
      osUtils,
      numberUtils,
  ],
  data () {

    return {

      showRemoved: true,
      showPaused: true,
      showActive: true,

      showDetailIcon: true,

      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'products': [],
        'countries': [],
        'accounts': [],
        'biddingStrategies': [],
        'marketingUsers': [],
        'currencies': [],
        'date': new Date(),
      },

      // Sorting:
      singleSorting: null,
      sortingPriority: [],
      customKey: 'ctrlKey',

      selectedDate: new Date(),

      pageSize: 25,
      pageNumber: 1,

      selectedCurrencies: [],

      rules: [],
      displayNewRule: false,
      emptyRule: {indexName: {}, indexOperator: {}, indexValue: null, indexType: {}, indexValueField: '', indexFactor: 1},
      indexNamesObject: {},
      fieldFactor: 1,
      selectedIndexName: '',
      selectedIndexOperator: '',
      selectedIndexValue: '',
      indexNames: [
          {value: "route_hash", text: "Route Hash", indexType: "text", operators: ['in']},
          {value: "keyword_name", text: "Keyword", indexType: "text",
            operators: ['eq', 'in', 'inText']},
          {value: "url_value", text: "Url", indexType: "text", operators: ['inText']},
          {value: "cost_amount", text: "Cost", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cost_amount_account_currency", text: "Cost(AccountCurrency)", indexType: "money",
            operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},

          {value: "revenue_amount_net", text: "Revenue(Net)", indexType: "money",
            operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_status", text: "Status", indexType: "status", operators: ['eq', 'in']},
          {value: "prop_delivery_status", text: "Delivery Status", indexType: "deliveryStatus",
            operators: ['eq', 'in']},
          {value: "profit", text: "Profit", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "roi", text: "ROI", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},

          {value: "route_hash__livemainentity__created_on_datetime", text: "Created On", indexType: "datetime",
            operators: ['lte', 'lt', 'gt', 'gte', 'eq',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},

      ],
      indexOperators: {
        'eq': {value: "", text: "="},
        'inText': {value: "__icontains", text: "~="},
        'in': {value: "__in", text: "=,"},
        'gt': {value: "__gt", text: ">"},
        'lt': {value: "__lt", text: "<"},
        'lte': {value: "__lte", text: "<="},
        'gte': {value: "__gte", text: ">="},

        'eqField': {value: "exactField", text: "=(Field)"},
        'gtField': {value: "gtField", text: ">(Field)"},
        'ltField': {value: "ltField", text: "<(Field)"},
        'lteField': {value: "lteField", text: "<=(Field)"},
        'gteField': {value: "gteField", text: ">=(Field)"},
      },
    }
  },
  computed: {


    titleStack () {
      return ['Admin', 'Optimization']
    },
    ...mapState([
      'dailyPerformances',
      'countries',
    ]),
    rulesParsed () {
      let d = this.rules,
          r = [];

      for(let i in d){
        if(d[i] === null || !('indexName' in d[i]) || d[i]['indexName'] === null){
          continue;
        }

        if(!('indexOperator' in d[i]) || d[i]['indexOperator']['value'] === null){
          continue;
        }

        if(!('indexValue' in d[i]) || d[i]['indexValue'] === null){
          continue;
        }

        if(d[i]['indexOperator']['value'].indexOf('Field') > -1){
          continue;
        }

        let obj = {};

        if(d[i]['indexName']['value'].indexOf('custom') > -1){

          if(d[i]['indexName']['value'].indexOf('custom__logoptimizationgroup') > -1) {

            obj['log_optimization_group_filter'] = d[i]['indexValue'];

          }else if(d[i]['indexName']['value'].indexOf('custom__logdeletiongroup') > -1){

            obj['log_deletion_group_filter'] = d[i]['indexValue'];

          }else{

            alert('Unable to find custom filter name.');

          }

        }else{

          let kObj = d[i]['indexName']['value'] + d[i]['indexOperator']['value'];
          if(d[i]['indexName']['indexType'] === 'datetime'){
            obj[kObj] = this.formatDatetime(d[i]['indexValue']);
          }else{
            obj[kObj] = d[i]['indexValue'];
          }

        }

        r.push(obj);
      }

      return r;
    },

    rulesFieldParsed () {
      let d = this.rules,
          r = [];

      for(let i in d) {
        if (d[i] === null || !('indexName' in d[i]) || d[i]['indexName'] === null) {
          continue;
        }

        if (!('indexOperator' in d[i]) || d[i]['indexOperator']['value'] === null || typeof d[i]['indexOperator']['value'] === 'undefined') {
          continue;
        }

        if (d[i]['indexOperator']['value'].indexOf('Field') === -1) {
          continue;
        }

        if (!('indexValueField' in d[i]) || d[i]['indexValueField'] === null) {
          continue;
        }

        r.push({
          'field1': d[i]['indexName']['value'],
          'operator': d[i]['indexOperator']['value'].replace('Field', ''),
          'field2': d[i]['indexValueField']['value'],
          'factor': d[i]['indexFactor'],
          'addendum': 0,
        });
      }

      return r;
    },


  },
  methods: {

    generateTrafficSourceLink(props) {
      let link;

      if(props.row.route_hash.network.dealer === 2){
        link = `https://business.facebook.com/adsmanager/manage/ads?act=${props.row.route_hash.livemainentity.account.remote_id}&business_id=${props.row.route_hash.livemainentity.account.manager.remote_id}&selected_adset_ids=${this.getMainEntityRemoteId(props.row)}`;
      }else{
        link = null;
      }

      return link;
    },

    tableHeight () {
      let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
          otherHeights = 0,
          newHeight;

      if(typeof this.$refs.filterstop !== 'undefined') {
        otherHeights = (this.$refs.filterstop.clientHeight * 3);
      }

      newHeight = vh - otherHeights;

      if(newHeight < 1500) {
        newHeight = 1500;
      }

      return newHeight
    },

    changePage(pageId){
      this.filterList(pageId);
    },
    setSelectedDate (code) {
      let d;
      if (code === 'TODAY') {
        d = new Date();
        this.selectedDate = d;
      }else if (code === 'YESTERDAY') {
        d = new Date() - 1000 * 60 * 60 * 24;
        this.selectedDate = new Date(d)
      }
    },
    filterList (pageId) {
      let parsedDate = moment(this.selectedMultiFilter['date']).format('YYYY-MM-DD'),

      sorting;

      pageId = pageId || null;

      if(this.sortingPriority && this.sortingPriority.length > 0){
        sorting = this.sortingPriority;
      }else{
        sorting = this.singleSorting;
      }

      sorting = sorting ? sorting : {};

      this.$store.dispatch('dailyPerformances/getAll', {
        'sorting': sorting,
        'pageId': pageId,
        'date': parsedDate,
        'pageSize': this.pageSize,
        'networks': this.selectedMultiFilter['networks'].map((element) => { return element.id }),
        'products': this.selectedMultiFilter['products'].map((element) => { return element.id }),
        'agencyFeeds': this.selectedMultiFilter['agencyFeeds'].map((element) => { return element.id }),
        'countries': this.selectedMultiFilter['countries'].map((element) => { return element.id }),
        'accounts': this.selectedMultiFilter['accounts'].map((element) => { return element.id }),
        'biddingStrategies': this.selectedMultiFilter['biddingStrategies'].map((element) => { return element.id }),
        'currencies': this.selectedMultiFilter['currencies'].map((element) => { return element.code }),
        'assignedUsers': this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }),
        'rules': this.rulesParsed,
        'rulesField': this.rulesFieldParsed,
        'showRemoved': this.showRemoved,
        'showPaused': this.showPaused,
        'showActive': this.showActive,
      });
    },

    formatDatetime: function (time){
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },

    makeNumber: function (str){
      let num = numeral(str).value()

      if(num < 0 || num > 0){
        return num
      }

      return 0;
    },

    checkOptimizedHoursAgo(lastOptimizationDatetime, accountTimezone) {

      if(!lastOptimizationDatetime){
        return -1;
      }

      let lastOptimizationDatetimeMoment = moment(lastOptimizationDatetime).clone().tz('UTC'),

          lastAbsoluteHourOptimized = lastOptimizationDatetimeMoment.format('YYYY-MM-DD HH:00:00'),
          lastAbsoluteHourNow = moment().clone().tz('UTC').format('YYYY-MM-DD HH:00:00'),

          lastOptimizationDatetimeMomentAccountTimezone = moment(lastOptimizationDatetime).tz('UTC').clone().tz(accountTimezone),
          lastAbsoluteDayOptimized = lastOptimizationDatetimeMomentAccountTimezone.format('YYYY-MM-DD 00:00:00'),
          lastAbsoluteDayNow = moment().clone().tz(accountTimezone).format('YYYY-MM-DD 00:00:00');

      if( moment(lastAbsoluteHourOptimized).isSame(moment(lastAbsoluteHourNow))){
        return 0;
      }

      if( moment(lastAbsoluteDayOptimized).isSame(moment(lastAbsoluteDayNow))){
        return 1;
      }

      return -1;
    },

    sortPressed: function (field, order){
        if((this.customKey && event[this.customKey]) || !this.customKey) {
          let existingPriority = this.sortingPriority.filter(i => i.field === field)[0]
          if(existingPriority) {
            existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
          } else {
            // request sorted data from backend
            this.sortingPriority.push({field: field, order: order})
          }
          this.singleSorting = null;
          this.filterList()
        } else {
          // request regular sorted data from backend
          this.sortingPriority = []
          this.singleSorting = [{field: field, order: order}];
          this.filterList()
        }
    },

    sortingPriorityRemoved: function (field){
        this.sortingPriority = this.sortingPriority.filter((priority) => priority.field !== field)
    },

    removeRule: function (rule, rowId) {
      this.$delete(this.rules, rowId);
    },

    addRule: function (){
      this.rules.push(Object.assign({}, this.emptyRule))
    },

    getOperators: function (operatorsArray) {
      let r = [];

      for(let i in operatorsArray){
        r.push(this.indexOperators[operatorsArray[i]]);
      }

      return r;
    },

    setFirstOperator: function (rowId){
      let r = this.getOperators(this.rules[rowId].indexName.operators, rowId);
      if(this.rules[rowId] && this.rules[rowId].indexOperator){
        if(r.length > 0){
          if(JSON.stringify(this.rules[rowId].indexOperator) === '{}'){
            this.rules[rowId].indexOperator = r[0];
          }else if(this.rules[rowId].indexName.operators.indexOf(this.rules[rowId].indexOperator.value) === -1) {
            this.rules[rowId].indexOperator = r[0];
          }
        }
      }
    },

    checkDataConsistency: function (row, type){
      type = type || '';
      if(type === 'title'){
        return '' +
            'Costs(UTC): ' + row.last_cost_update_datetime_utc +
            '\n\r' +
            'Revenue(UTC): ' + row.last_revenue_update_datetime_utc +
            ''
            ;
      }

      if(row.is_completed === false){
        return 'is-danger';
      }

      return 'is-success';
    },

    getMainEntityRemoteId: function (row){

      let r = row.route_hash.livemainentity.resourcename.split('--');

      return r[1];
    },

    renderDate: function (date, format){
      format = format || 'YYYY-MM-DD';

      return moment(date).format(format);
    },

    countDays: function (date){
      let a = moment()
      return a.diff(moment(date), 'days') + 1
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
