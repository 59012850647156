import { administrationAccessGroupsService } from '@/services';

const emptyPaging = {
    page_size: 0,
    count: 0,
    pages: 1,
    page_number: 1,
    page_count: 1
};

export const accessGroups = {
    namespaced: true,
    state: {
        all: [],
        loading: false,
        paging: emptyPaging,
        openedRowId: null,
    },
    actions: {
        getAll({ commit }, filters) {
            commit('getAllRequest');
            administrationAccessGroupsService.getAll(filters)
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },

        save({ commit }, payload) {
            commit('saveRequest');
            administrationAccessGroupsService.save(payload)
                .then(
                    data => commit('saveSuccess', data),
                    error => commit('saveFailure', error)
                );
        },
    },
    mutations: {
        getAllRequest(state) {
            state.all = []
            state.loading = true;
            state.paging = emptyPaging
        },
        getAllSuccess(state, {data}) {
            state.all = data.results;
            state.paging = {
                page_size: data.page_size,
                count: data.count,
                pages: data.pages,
                page_number: data.page_number,
                page_count: data.page_count
            };
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.all = [];
            state.paging = emptyPaging
            console.dir(error);
        },
        saveRequest(state) {
            state.all = []
            state.loading = true;
        },
        saveSuccess(state, {data}) {
            state.all = data.results;
            state.loading = false;
        },
        saveFailure(state, error) {
            state.loading = false;
            console.dir(error);
        },
        openedRowId(state, {id}) {
            state.openedRowId = id
        },
    }
}
