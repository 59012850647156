<template>
    <div style="overflow: auto !important">
        <section class="modal-card-body p-2">
            <b-field label="Name">
                <b-input
                    name="name"
                    type="text"
                    v-model.trim="name"
                    placeholder="Name"
                    required>
                </b-input>
            </b-field>

            <b-field label="Description">
                <b-input
                    name="description"
                    type="textarea"
                    v-model.trim="description"
                    placeholder="Description"
                    rows="2"
                    required>
                </b-input>
            </b-field>

            <header class="card-header">
                <p class="card-header-title px-0 py-2">Details</p>
            </header>
            <multiselect
                v-model="details.agencyFeeds"
                :options="agencyFeeds.all"
                :multiple="true"
                :close-on-select="true"
                placeholder="Agency Feeds"
                :label="getFieldCodeLabel()"
                track-by="id">
            </multiselect>
            <multiselect
                v-model="details.networks"
                :options="networks.all"
                :multiple="true"
                :close-on-select="true"
                placeholder="Networks"
                label="name"
                track-by="id">
            </multiselect>

            <header class="card-header">
                <p class="card-header-title px-0 py-2">Rules</p>
            </header>

            <b-field grouped v-for="(r, rowId) in rules" v-bind:key="`performance-filter-${rowId}`">
                <b-field>
                    <b-button
                    type="is-danger"
                    @click="removeRule(r, rowId);"
                    :disabled="rules.length === 1"
                    icon-left="close" />

                    <b-select
                        placeholder="Index"
                        v-model="rules[rowId].indexName"
                        @change.native="setFirstOperator(rowId)"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-name-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Operator"
                        v-model="rules[rowId].indexOperator"
                        :disabled="Object.keys(rules[rowId].indexName).length === 0">
                        <option
                            v-for="(indexOperator, indexOperatorI) in getOperators(rules[rowId].indexName.operators)"
                            :key="`performance-index-operator-${rowId}-${indexOperatorI}`"
                            :value="indexOperator"
                        >
                          {{ indexOperator.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Index"
                        v-model="rules[rowId].indexValueField"
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-value-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>

                    <b-input
                        v-else-if="['number', 'money'].includes(rules[rowId].indexName.indexType)"
                        type="number"
                        placeholder="Value"
                        v-model.trim="rules[rowId].indexValue"
                        :disabled="Object.keys(rules[rowId].indexName).length === 0"
                        style="width: 85px;"
                    ></b-input>
                    <b-input
                        type="number"
                        placeholder="Value"
                        v-model="rules[rowId].indexValue"
                        indexValue
                        prefix="%"
                        v-else-if="rules[rowId].indexName.indexType === 'percentage'"
                    ></b-input>
                    <b-input
                        type="text"
                        v-model="rules[rowId].indexValue"
                        indexValue
                        v-else-if="rules[rowId].indexName.indexType === 'text'"
                    ></b-input>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'deliveryStatus'"
                    >
                        <option v-for="(ds, index) in ['DELIVERING', 'NOT_DELIVERING']" :key="index" :value="ds">
                            {{ ds }}
                        </option>
                    </b-select>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'deliveryMethods'"
                    >
                        <option v-for="(dm, index) in ['METHOD_1']" :key="index" :value="dm">
                            {{ dm }}
                        </option>
                    </b-select>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'biddingStrategy'"
                    >
                        <option
                            v-for="option in biddingStrategiesList"
                            :value="option.code"
                            :key="option.id">
                            {{ option.code }}
                        </option>
                    </b-select>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'status'"
                    >
                      <option v-for="(status, index) in ['ACTIVE', 'PAUSED', 'REMOVED']" :key="index" :value="status">
                        {{ status }}
                      </option>
                    </b-select>


                    <b-input
                        v-else
                        type="text"
                        placeholder="Value"
                        v-model.trim="rules[rowId].indexValue"
                        :disabled="Object.keys(rules[rowId].indexName).length === 0"
                        style="width: 60px;"
                    ></b-input>

                    <b-input
                        type="text"
                        maxlength="1"
                        placeholder="Days ago (0 to 7)"
                        onkeypress="return [48,49,50,51,52,53,54,55].includes(event.charCode)"
                        v-model.trim="rules[rowId].daysAgo"
                        :disabled="Object.keys(rules[rowId].indexName).length === 0"
                        style="width: 110px;"
                    ></b-input>

                  <b-button
                    type="is-success"
                    @click="addRule();"
                    icon-left="check" />
                  </b-field>
            </b-field>
      
            <header class="card-header">
                <p class="card-header-title px-0 py-2">Inverse Rules</p>
            </header>
            <b-field grouped v-for="(r, rowId) in inverseRules" v-bind:key="`performance-filter-inverse-rules-${rowId}`">
                <b-field>
                    <b-button
                    type="is-danger"
                    @click="removeRule(r, rowId, 'inverseRules');"
                    :disabled="inverseRules.length === 1"
                    icon-left="close" />

                    <b-select
                        placeholder="Index"
                        v-model="inverseRules[rowId].indexName"
                        @change.native="setFirstOperator(rowId, 'inverseRules')"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-name-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Operator"
                        v-model="inverseRules[rowId].indexOperator"
                        :disabled="Object.keys(inverseRules[rowId].indexName).length === 0">
                        <option
                            v-for="(indexOperator, indexOperatorI) in getOperators(inverseRules[rowId].indexName.operators)"
                            :key="`performance-index-operator-${rowId}-${indexOperatorI}`"
                            :value="indexOperator"
                        >
                          {{ indexOperator.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Index"
                        v-model="inverseRules[rowId].indexValueField"
                        v-if="(inverseRules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-value-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>

                    <b-input
                        v-else-if="['number', 'money'].includes(inverseRules[rowId].indexName.indexType)"
                        type="number"
                        placeholder="Value"
                        v-model.trim="inverseRules[rowId].indexValue"
                        :disabled="Object.keys(inverseRules[rowId].indexName).length === 0"
                        style="width: 85px;"
                    ></b-input>
                    <b-input
                        type="number"
                        placeholder="Value"
                        v-model="inverseRules[rowId].indexValue"
                        indexValue
                        prefix="%"
                        v-else-if="inverseRules[rowId].indexName.indexType === 'percentage'"
                    ></b-input>
                    <b-input
                        type="text"
                        v-model="inverseRules[rowId].indexValue"
                        indexValue
                        v-else-if="inverseRules[rowId].indexName.indexType === 'text'"
                    ></b-input>

                    <b-select
                        v-model="inverseRules[rowId].indexValue"
                        v-else-if="inverseRules[rowId].indexName.indexType === 'deliveryStatus'"
                    >
                        <option v-for="(ds, index) in ['DELIVERING', 'NOT_DELIVERING']" :key="index" :value="ds">
                            {{ ds }}
                        </option>
                    </b-select>

                    <b-select
                        v-model="inverseRules[rowId].indexValue"
                        v-else-if="inverseRules[rowId].indexName.indexType === 'deliveryMethods'"
                    >
                        <option v-for="(dm, index) in ['METHOD_1']" :key="index" :value="dm">
                            {{ dm }}
                        </option>
                    </b-select>

                    <b-select
                        v-model="inverseRules[rowId].indexValue"
                        v-else-if="inverseRules[rowId].indexName.indexType === 'biddingStrategy'"
                    >
                        <option
                            v-for="option in biddingStrategiesList"
                            :value="option.code"
                            :key="option.id">
                            {{ option.code }}
                        </option>
                    </b-select>

                    <b-select
                        v-model="inverseRules[rowId].indexValue"
                        v-else-if="inverseRules[rowId].indexName.indexType === 'status'"
                    >
                        <option v-for="(status, index) in ['ACTIVE', 'PAUSED', 'REMOVED']" :key="index" :value="status">
                            {{ status }}
                        </option>
                    </b-select>

                    <b-input
                        v-else
                        type="text"
                        placeholder="Value"
                        v-model.trim="inverseRules[rowId].indexValue"
                        :disabled="Object.keys(inverseRules[rowId].indexName).length === 0"
                        style="width: 60px;"
                    ></b-input>

                    <b-input
                        type="text"
                        maxlength="1"
                        placeholder="Days ago (0 to 7)"
                        onkeypress="return [48,49,50,51,52,53,54,55].includes(event.charCode)"
                        v-model.trim="inverseRules[rowId].daysAgo"
                        :disabled="Object.keys(inverseRules[rowId].indexName).length === 0"
                        style="width: 110px;"
                    ></b-input>

                    <b-button
                        type="is-success"
                        @click="addRule('inverseRules');"
                        icon-left="check" />
                </b-field>
            </b-field>

            <header class="card-header">
                <p class="card-header-title px-0 py-2">Actions</p>
            </header>

            <div>
                 <b-checkbox v-model="applyToBid">Bid</b-checkbox>
            </div>
            <div v-if="applyToBid">
                <div>
                Set as ((
                <b-select v-model="bidSetToField" :disabled="!applyToBid" style="max-width: 180px; display: inline-block;">
                    <option
                    v-for="option in allowedFieldsBid"
                    :value="option.id"
                    :key="option.id">
                    {{ option.name }}
                    </option>
                </b-select>
                <template v-if="bidSetToField === 'direct_value'">
                    <b-input v-model="bidDirectValue" type="number" class="ml-1" style="max-width: 120px; display: inline-block;"></b-input>
                </template>
                <template v-else>
                    *
                    <b-input v-model="bidFactor" :disabled="!applyToBid" type="number" step="0.01" style="max-width: 100px; display: inline-block;"></b-input>) +
                    <b-input v-model="bidNumber" :disabled="!applyToBid" type="number" step="0.01" style="max-width: 100px; display: inline-block;"></b-input>)
                </template>
                </div>
            </div>

            <hr />

            <div>
                <b-checkbox v-model="applyToBudget">Budget</b-checkbox>
            </div>
            <div v-if="applyToBudget">
                <div>
                Set as ((
                <b-select v-model="budgetSetToField" :disabled="!applyToBudget" style="max-width: 180px; display: inline-block;">
                    <option
                    v-for="option in allowedFieldsBudget"
                    :value="option.id"
                    :key="option.id">
                    {{ option.name }}
                    </option>
                </b-select>
                <template v-if="budgetSetToField === 'direct_value'">
                    <b-input v-model="budgetDirectValue" type="number" class="ml-1" style="max-width: 120px; display: inline-block;"></b-input>
                </template>
                <template v-else>
                    *
                    <b-input v-model="budgetFactor" :disabled="!applyToBudget" type="number" step="0.01" style="max-width: 100px; display: inline-block;"></b-input>) +
                    <b-input v-model="budgetNumber" :disabled="!applyToBudget" type="number" step="0.01" style="max-width: 100px; display: inline-block;"></b-input>)
                </template>
                </div>
            </div>

            <hr />

            <div>
                <b-checkbox v-model="applyToStatus">Status</b-checkbox>
            </div>
            <div v-if="applyToStatus">
                <div>
                <b-select v-model="status" :disabled="!applyToStatus" style="max-width: 180px; display: inline-block;">
                    <option
                        v-for="option in ['ACTIVE', 'PAUSED', 'REMOVED']"
                        :value="option"
                        :key="option">
                        {{ option }}
                    </option>
                </b-select>
                </div>
            </div>

            <hr />

            <div>
                <b-checkbox v-model="applyToBiddingStrategy">Bidding Strategy</b-checkbox>
            </div>
            <div v-if="applyToBiddingStrategy">
                <p v-if="!biddingStrategiesList.length" class="notification is-warning is-light p-2 m-0">Please chose a network</p>
                <b-select v-else v-model="selectedStrategy" style="display: inline-block;">
                    <option
                        v-for="option in biddingStrategiesList"
                        :value="option"
                        :key="option.id">
                        {{ option.code }}
                    </option>
                </b-select>

                <div v-if="selectedStrategy && selectedStrategy.is_bid_handled" class="mt-1">
                    <b-checkbox class="ml-4" v-model="strategyExtraDataCheck">Set as ((</b-checkbox>
                    <b-select v-model="strategySetToField" :disabled="!strategyExtraDataCheck" style="max-width: 180px; display: inline-block;">
                        <option
                        v-for="option in allowedFieldsStrategy"
                        :value="option.id"
                        :key="option.id">
                        {{ option.name }}
                        </option>
                    </b-select>*
                    <b-input v-model="strategyFactor" type="number" :disabled="!strategyExtraDataCheck" step="0.01" style="max-width: 100px; display: inline-block;"></b-input>) +
                    <b-input v-model="strategyNumber" type="number" :disabled="!strategyExtraDataCheck" step="0.01" style="max-width: 100px; display: inline-block;"></b-input>)
                </div>

            </div>
            <span v-if="errorMsg" class="is-block button is-danger is-light">{{ errorMsg }}</span>
        </section>
        <footer class="modal-card-foot p-3">
            <b-button
                label="Close"
                @click="$emit('close')" />
            <b-button
                label="Save"
                type="is-primary"
                :loading="loading"
                @click="submit" />
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import {permissionsUtils} from "@/helpers/permissions-utils";
export default {
    name: 'OptimizationAutomationModal',

    data() {
        return {
            name: null,
            description: null,
            details: {
                networks: [],
                agencyFeeds: [],
            },
            rules: [],
            inverseRules: [],
            emptyRule: {indexName: {}, indexOperator: {}, indexValue: null, indexType: {}, indexValueField: '', indexFactor: 1},

            indexNames: [
                //decimal fields:
                {value: "cost_amount", text: "Cost", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "cost_amount_account_currency", text: "Cost(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "impressions_c", text: "Impression(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "clicks_in_c", text: "ClicksIn(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "clicks_in_r", text: "ClicksIn(Revenue)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "clicks_out_c", text: "ClicksOut(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "clicks_out_r", text: "ClicksOut(Revenue", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "revenue_amount_net", text: "Revenue(Net)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "revenue_amount_net_partner_currency", text: "Revenue Partner(Net)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "budget_amount", text: "Budget", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "bid_amount", text: "BidAmount", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "profit", text: "Profit", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "roi", text: "ROI", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "ctr_r", text: "CR(R)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "ctr_c", text: "CR(C)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "cpc_c", text: "CPC(C)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "cpc_r", text: "CPC(R)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "cpa_r", text: "CPA(R)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "cpa_c", text: "CPA(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "rpc", text: "RPC", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "rpc_c", text: "RPC(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "rps", text: "RPS", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "rps_c", text: "RPS(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "revenue_amount_gross", text: "Gross", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "revenue_amount_gross_partner_currency", text: "Gross(Partner)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "clicks_out_ratio", text: "ClicksOut(RevCostRatio)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
                {value: "clicks_in_ratio", text: "ClicksIn(RevCostRatio)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
                    'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},

                //char fields:
                {value: "prop_delivery_status", text: "Delivery Status", indexType: "deliveryStatus", operators: ['eq']},
                {value: "prop_delivery_methods", text: "Delivery Methods", indexType: "deliveryMethods", operators: ['eq']},
                {value: "status", text: "Status", indexType: "status", operators: ['eq']},
                {value: "bidding_strategy", text: "Bidding Strategy", indexType: "biddingStrategy", operators: ['eq']},
            ],
            indexOperators: {
                'eq': {value: "=", text: "=", code: 'equal'},
                'gt': {value: "__gt", text: ">", code: 'greater'},
                'lt': {value: "__lt", text: "<", code: 'less'},
                'lte': {value: "__lte", text: "<=", code: 'less_or_equal'},
                'gte': {value: "__gte", text: ">=", code: 'greater_or_equal'},
                'in': {value: "__in", text: "=,", code: 'in'},

                'eqField': {value: "exactField", text: "=(Field)", code: 'equal'},
                'gtField': {value: "gtField", text: ">(Field)", code: 'greater'},
                'ltField': {value: "ltField", text: "<(Field)", code: 'less'},
                'lteField': {value: "lteField", text: "<=(Field)", code: 'less_or_equal'},
                'gteField': {value: "gteField", text: ">=(Field)", code: 'greater_or_equal'},
            },
            actions: [],

            applyToBid: false,
            bidSetToField: 'direct_value',
            allowedFieldsBid: [
                {id: "direct_value", name: "Direct Value"},
                {id: "bid_amount", name: "Bid Amount"},
                // {id: "rpc", name: "RPC"},
            ],
            bidFactor: 0,
            bidNumber: 0,
            bidDirectValue: 0,
            budgetDirectValue: 0,
            
            applyToBudget: false,
            budgetSetToField: 'direct_value',
            budgetFactor: 0,
            budgetNumber: 0,
            allowedFieldsBudget: [
                {id: "direct_value", name: "Direct Value"},
                {id: "bid_amount", name: "Bid Amount"},
                // {id: "cost_amount", name: "Cost"}
            ],
            
            applyToStatus: false,
            status: '',

            applyToBiddingStrategy: false,
            selectedStrategy: {},
            strategySetToField: '',
            strategyFactor: 0,
            strategyNumber: 0,
            allowedFieldsStrategy: [
                {id: "bid_amount", name: "Budget"},
            ],
            strategyExtraDataCheck: false,

            errorMsg: '',

            obj: {details:{agencyFeeds: []}},
            loading: false,
        }
    },

    props: {
        id: {
            default: null,
        },
    },

    components: {
        Multiselect,
    },

    mixins: [
        permissionsUtils,
    ],

    created() {
        if(this.id) this.populateAutomation()

        //rules
        this.rules.push(Object.assign({}, this.emptyRule))
        this.inverseRules.push(Object.assign({}, this.emptyRule))
    },

    computed: {
        ...mapState([
            'optimizationAutomation',
            'agencyFeeds',
            'networks',
            'biddingStrategies',
        ]),

        biddingStrategiesList() {
            //only allow MANUAL_CPC for now
            return this.biddingStrategies.all.filter(b => b.code === 'MANUAL_CPC')
        },
    },

    methods: {
        submit() {
            const { id, name, description, details } = this;
            
            //required fields
            if(!name || !description || !details.networks.length || !details.agencyFeeds.length) return;

            const payload = {
                name,
                description,
                networks: details.networks.map(n => n.id),
                agency_feeds: details.agencyFeeds.map(a => a.id),
                configuration_json: {},
            }

            const {check, msg} = this.isValidActions()             
            if(!check) {this.errorMsg = msg; return}

            // setup rules and inverse rules
            this.rulesParsed('rules')
            this.rulesParsed('inverseRules')
            const plain_rules = [], field_rules = [], self = this

            //rules
            for(let i in self.rules) {
                if(!self.rules[i].indexName.value) break;

                let obj = {
                    field: self.rules[i].indexName.value,
                    operator: self.rules[i].indexOperator.code,
                    filter_type: 'include',
                    days_ago: self.rules[i].daysAgo ? +self.rules[i].daysAgo : 0,
                }

                if(self.rules[i].indexOperator.value.indexOf('Field') > -1) {
                    field_rules.push({
                        ...obj,
                        value: self.rules[i].indexValueField.value,
                    })
                } else {
                    plain_rules.push({
                        ...obj,
                        value: self.rules[i].indexValue,
                    })   
                }
            }

            //inverse rules
            for(let i in self.inverseRules) {
                if(!self.inverseRules[i].indexName.value) break;

                let obj = {
                    field: self.inverseRules[i].indexName.value,
                    operator: self.inverseRules[i].indexOperator.code,
                    filter_type: 'exclude',
                    days_ago: self.inverseRules[i].daysAgo ? +self.inverseRules[i].daysAgo : 0,
                }

                if(self.inverseRules[i].indexOperator.value.indexOf('Field') > -1) {
                    field_rules.push({
                        ...obj,
                        value: self.inverseRules[i].indexValueField.value,
                    })
                } else {
                    plain_rules.push({
                        ...obj,
                        value: self.inverseRules[i].indexValue,
                    })   
                }
            }

            //finalize payload
            payload.configuration_json.actions = this.actions
            payload.configuration_json.plain_rules = plain_rules
            payload.configuration_json.field_rules = field_rules
            if(id) payload.id = id
            
            const dispatchType = id ? 'update' : 'create'
            this.loading = true
            this.$store.dispatch(`optimizationAutomation/${dispatchType}`, payload, 'no_loading')
                .then(() => {
                    this.loading = false
                    this.$store.dispatch('optimizationAutomation/getAll', {no_loading: true})
                    this.$emit('close')
                    this.$buefy.snackbar.open({message: `Automation ${dispatchType}d Successfully!`})
                }).catch(error => {
                    this.loading = false
                    alert(`Some errors occurred:\n` + Object.entries(error.response.data).map(er => er[0] + ': ' + er[1]).join('\n'))
                })
        },

        removeRule: function (rule, rowId, type = 'rules') {
            this.$delete(this[type], rowId);
        },

        addRule: function (type = 'rules'){
            this[type].push(Object.assign({}, this.emptyRule))
        },

        getOperators: function (operatorsArray) {
            let r = [];

            for(let i in operatorsArray){
                r.push(this.indexOperators[operatorsArray[i]]);
            }

            return r;
        },

        setFirstOperator: function (rowId, type = 'rules'){
            let r = this.getOperators(this[type][rowId].indexName.operators, rowId);
            if(this[type][rowId] && this[type][rowId].indexOperator){
                if(r.length > 0){
                    if(JSON.stringify(this[type][rowId].indexOperator) === '{}'){
                        this[type][rowId].indexOperator = r[0];
                    }else if(this[type][rowId].indexName.operators.indexOf(this[type][rowId].indexOperator.value) === -1) {
                        this[type][rowId].indexOperator = r[0];
                    }
                }
            }
        },

        rulesParsed (type = 'rules') {
            let d = this[type],
                r = [];

            for(let i in d){
                if(d[i] === null || !('indexName' in d[i]) || d[i]['indexName'] === null){
                continue;
                }

                if(!('indexOperator' in d[i]) || d[i]['indexOperator']['value'] === null){
                continue;
                }

                if(!('indexValue' in d[i]) || d[i]['indexValue'] === null){
                continue;
                }

                if(d[i]['indexOperator']['value'].indexOf('Field') > -1){
                continue;
                }

                let obj = {};

                if(d[i]['indexName']['value'].indexOf('custom') > -1){

                if(d[i]['indexName']['value'].indexOf('custom__logoptimizationgroup') > -1) {

                    obj['log_optimization_group_filter'] = d[i]['indexValue'];

                }else if(d[i]['indexName']['value'].indexOf('custom__logdeletiongroup') > -1){

                    obj['log_deletion_group_filter'] = d[i]['indexValue'];

                }else{

                    alert('Unable to find custom filter name.');

                }

                }else{

                let kObj = d[i]['indexName']['value'] + d[i]['indexOperator']['value'];
                if(d[i]['indexName']['indexType'] === 'datetime'){
                    obj[kObj] = this.formatDatetime(d[i]['indexValue']);
                }else{
                    obj[kObj] = d[i]['indexValue'];
                }

                }

                r.push(obj);
            }

            return r;
        },

        isValidActions() {
            const { applyToBid, applyToBudget, applyToStatus,
                    bidFactor, bidNumber, bidSetToField,
                    budgetFactor, budgetNumber, budgetSetToField,
                    strategyFactor, strategyNumber, strategySetToField,
                    status,
                    selectedStrategy,
                } = this

            const actions = []

            //at least select one action
            if(!applyToBid && !applyToBudget && !applyToStatus) return {check: false, msg: 'Select at least one action'}

            //setup bid
            if(applyToBid) {
                let bidError = false, obj = {}
                if(bidSetToField === 'direct_value') {
                    obj = {
                        value: this.bidDirectValue,
                        extra_data: null,
                    }
                    if(!this.bidDirectValue) bidError = true

                } else {

                    obj = {
                        value: null,
                        extra_data: {
                            field_name: bidSetToField,
                            field_multiplier: bidFactor,
                            field_sum: bidNumber,
                        },
                    }
                    if(!bidSetToField || !bidFactor || !bidNumber) bidError = true
                }

                if(bidError) return {check: false, msg: 'Fill out bid fields'}

                actions.push({
                    optimization_type: 'BID_AMOUNT',
                    ...obj
                })
            }

            //set budget
            if(applyToBudget) {
                let budgetError = false, obj = {}
                if(budgetSetToField === 'direct_value') {
                    obj = {
                        value: this.budgetDirectValue,
                        extra_data: null,
                    }
                    if(!this.budgetDirectValue) budgetError = true

                } else {

                    obj = {
                        value: null,
                        extra_data: {
                            field_name: budgetSetToField,
                            field_multiplier: budgetFactor,
                            field_sum: budgetNumber,
                        },
                    }
                    if(!budgetSetToField || !budgetFactor || !budgetNumber) budgetError = true
                }

                if(budgetError) return {check: false, msg: 'Fill out budget fields'}

                actions.push({
                    optimization_type: 'BUDGET_AMOUNT',
                    ...obj
                })
            }

            if(applyToStatus) {
                if(!status) return {check: false, msg: 'Choose a status'}

                actions.push({
                    optimization_type: 'STATUS',
                    value: status,
                    extra_data: null,
                })
            }

            //set strategy
            if(this.applyToBiddingStrategy) {

                let strategyError = false
                if(!selectedStrategy.code) strategyError = true
                if(this.strategyExtraDataCheck && (!strategySetToField || !strategyFactor || !strategyNumber)) strategyError = true
                if(strategyError) return {check: false, msg: 'Fill out strategy fields'}

                let obj = {
                    optimization_type: 'BIDDING_STRATEGY',
                    value: selectedStrategy.code,
                    extra_data: null, 
                }

                if(this.strategyExtraDataCheck) {
                    obj.extra_data = {
                        field_name: strategySetToField,
                        field_multiplier: strategyFactor,
                        field_sum: strategyNumber,
                    }
                }
                actions.push(obj)
            }

            this.actions = actions
            return {check: true}
        },

        populateAutomation() {
            const automation = this.optimizationAutomation.all.find(a => a.id === this.id)
            
            this.name = automation.name
            this.description = automation.description

            this.details.agencyFeeds = automation.agency_feeds.map(id => {
                return this.agencyFeeds.all.find(af => af.id === id)
            })
            this.details.networks = automation.networks.map(id => {
                return this.networks.all.find(n => n.id === id)
            })
            
            //set rules
            const rules = [], inverseRules = []

            automation.configuration_json.plain_rules.map(r => {
                let indexOperator = {}, indexType = {}
                for(let op in this.indexOperators) {
                    if(this.indexOperators[op].code === r.operator && op.indexOf('Field') === -1) {
                        indexOperator = this.indexOperators[op]
                        break;
                    }
                }

                let obj = {
                    indexFactor: 1,
                    daysAgo: r.days_ago,
                    indexName: this.indexNames.find(i => i.value === r.field),
                    indexOperator,
                    indexType,
                    indexValue: r.value,
                    indexValueField: '',
                }

                r.filter_type === 'include' ? rules.push(obj) : inverseRules.push(obj)
            })
            automation.configuration_json.field_rules.map(r => {
                let indexOperator = {}, indexType = {}
                for(let op in this.indexOperators) {
                    if(this.indexOperators[op].code === r.operator && op.indexOf('Field') > -1) {
                        indexOperator = this.indexOperators[op]
                        break;
                    }
                }

                let obj = {
                    indexFactor: 1,
                    daysAgo: r.days_ago,
                    indexName: this.indexNames.find(i => i.value === r.field),
                    indexOperator,
                    indexType,
                    indexValue: null,
                    indexValueField: this.indexNames.find(i => i.value === r.value),
                }

                r.filter_type === 'include' ? rules.push(obj) : inverseRules.push(obj)
            })            
            this.rules = rules
            this.inverseRules = inverseRules

            //set actions
            automation.configuration_json.actions.map(a => {

                if(a.optimization_type === 'BID_AMOUNT') {
                    this.applyToBid = true
                    if(a.value) {
                        this.bidSetToField = 'direct_value'
                        this.bidDirectValue = a.value
                    } else {
                        this.bidSetToField = a.extra_data.field_name
                        this.bidFactor = a.extra_data.field_multiplier
                        this.bidNumber = a.extra_data.field_sum
                    }
                }

                if(a.optimization_type === 'BUDGET_AMOUNT') {
                    this.applyToBudget = true
                    if(a.value) {
                        this.budgetSetToField = 'direct_value'
                        this.budgetDirectValue = a.value
                    } else {
                        this.budgetSetToField = a.extra_data.field_name
                        this.budgetFactor = a.extra_data.field_multiplier
                        this.budgetNumber = a.extra_data.field_sum
                    }
                }

                if(a.optimization_type === 'STATUS') {
                    this.applyToStatus = true
                    this.status = a.value
                }

                if(a.optimization_type === 'BIDDING_STRATEGY') {
                    this.applyToBiddingStrategy = true
                    let bs = this.biddingStrategies.all.find(b => b.code === a.value)
                    this.selectedStrategy = bs

                    if(a.extra_data) {
                        this.strategyExtraDataCheck = true
                        this.strategySetToField = a.extra_data.field_name
                        this.strategyFactor = a.extra_data.field_multiplier
                        this.strategyNumber = a.extra_data.field_sum
                    }
                }

            })
        },

    },
}
</script>

<style lang="scss">
.modal.InternalAgencyPageModal {
    .animation-content {
        width: auto;
        .modal-card {
            overflow: auto;
            .modal-card-body {
                overflow: unset;
            }
        }
    }
}
</style>