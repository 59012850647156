import { config } from './config';
import axios from "axios";

export const naplesSearchTermsService = {
    patch,
    config
};

function patch(id, params){

    let endpoint;

    endpoint = '/api/proxy/naples/domain/search-terms/' + id + '/';

    return new Promise((resolve, reject) => {
      axios
        .patch(`${config.apiUrl}${endpoint}`, params)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}




