var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-component',[_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"is-widget-label"},[_c('h3',{staticClass:"subtitle is-spaced"},[_c('a',{attrs:{"href":"#","aria-label":"more options"},on:{"click":function($event){$event.preventDefault();return _vm.reload($event)}}},[_c('b-icon',{attrs:{"icon":"reload","custom-size":"default"}})],1),_vm._v(" "+_vm._s(_vm.label)+" "),_c('b-button',{class:{ 'is-success': _vm.timezone === 'UTC'},attrs:{"type":"is-text is-small"},on:{"click":function($event){return _vm.reloadTimezone('UTC')}}},[_vm._v(" UTC")]),_c('b-button',{class:{ 'is-success': _vm.timezone === 'PST'},attrs:{"type":"is-text is-small"},on:{"click":function($event){return _vm.reloadTimezone('PST')}}},[_vm._v(" PST")]),_c('b-button',{class:{ 'is-success': _vm.timezone === 'ALL'},attrs:{"type":"is-text is-small"},on:{"click":function($event){return _vm.reloadTimezone('ALL')}}},[_vm._v(" ALL")])],1),_c('table',{staticClass:"table is-narrow"},[_c('tr',[_c('td',[(_vm.calculateGrowth(_vm.number, _vm.previousNumber) !== 0)?_c('span',{class:{
                'has-text-success': _vm.calculateGrowth(_vm.number, _vm.previousNumber) > 0,
                'has-text-danger': _vm.calculateGrowth(_vm.number, _vm.previousNumber) < 0
              },staticStyle:{"font-size":"0.6rem"}},[_c('b-icon',{staticStyle:{"width":".3rem"},attrs:{"icon":_vm.calculateGrowth(_vm.number, _vm.previousNumber) > 0 ? 'arrow-up-bold' : 'arrow-down-bold',"size":"is-small","type":{
                    'is-success': _vm.calculateGrowth(_vm.number, _vm.previousNumber) > 0,
                    'is-danger': _vm.calculateGrowth(_vm.number, _vm.previousNumber) < 0
                  }}}),_c('b-tooltip',{attrs:{"label":_vm.generateOverviewPerformanceTooltipByCode('previous_period_revenue',
                'CURRENCY'),"position":"is-bottom"}},[_vm._v(" "+_vm._s(_vm.renderPercentage(_vm.calculateGrowth(_vm.number, _vm.previousNumber)))+" ")])],1):_vm._e(),_c('br'),_c('growing-number',{attrs:{"value":_vm.number,"prefix":_vm.prefix,"suffix":_vm.suffix,"class-name":_vm.className}})],1),_c('td',[(_vm.calculateGrowth(_vm.number1, _vm.previousNumber1) !== 0)?_c('span',{class:{
                'has-text-success': _vm.calculateGrowth(_vm.number1, _vm.previousNumber1) > 0,
                'has-text-danger': _vm.calculateGrowth(_vm.number1, _vm.previousNumber1) < 0 },staticStyle:{"font-size":"0.6rem"}},[_c('b-icon',{staticStyle:{"width":".3rem"},attrs:{"icon":_vm.calculateGrowth(_vm.number1, _vm.previousNumber1) > 0 ? 'arrow-up-bold' : 'arrow-down-bold',"size":"is-small","type":{'is-success': _vm.calculateGrowth(_vm.number1, _vm.previousNumber1) > 0, 'is-danger': _vm.calculateGrowth(_vm.number1, _vm.previousNumber1) < 0 }}}),_c('b-tooltip',{attrs:{"label":_vm.generateOverviewPerformanceTooltipByCode('previous_period_cost',
                'CURRENCY'),"position":"is-bottom"}},[_vm._v(" "+_vm._s(_vm.renderPercentage(_vm.calculateGrowth(_vm.number1, _vm.previousNumber1)))+" ")])],1):_vm._e(),_c('br'),_c('growing-number',{attrs:{"value":_vm.number1,"prefix":_vm.prefix2,"suffix":_vm.suffix2,"class-name":_vm.className1}})],1),_c('td',[(_vm.calculateGrowth(_vm.number2, _vm.previousNumber2) !== 0)?_c('span',{class:{'has-text-success': _vm.calculateGrowth(_vm.number2, _vm.previousNumber2) > 0, 'has-text-danger': _vm.calculateGrowth(_vm.number2, _vm.previousNumber2) < 0 },staticStyle:{"font-size":"0.6rem"}},[_c('b-icon',{staticStyle:{"width":".3rem"},attrs:{"icon":_vm.calculateGrowth(_vm.number2, _vm.previousNumber2) > 0 ? 'arrow-up-bold' : 'arrow-down-bold',"size":"is-small","type":{'is-success': _vm.calculateGrowth(_vm.number2, _vm.previousNumber2) > 0, 'is-danger': _vm.calculateGrowth(_vm.number2, _vm.previousNumber2) < 0 }}}),_c('b-tooltip',{attrs:{"label":_vm.generateOverviewPerformanceTooltipByCode('previous_period_profit',
                'CURRENCY'),"position":"is-bottom"}},[_vm._v(" "+_vm._s(_vm.renderPercentage(_vm.calculateGrowth(_vm.number2, _vm.previousNumber2)))+" ")])],1):_vm._e(),_c('br'),_c('growing-number',{attrs:{"value":_vm.number2,"prefix":_vm.prefix2,"suffix":_vm.suffix2,"class-name":_vm.className2}})],1),_c('td',[(_vm.calculateGrowth(_vm.number3, _vm.previousNumber3) !== 0)?_c('span',{class:{'has-text-success': _vm.calculateGrowth(_vm.number3, _vm.previousNumber3) > 0, 'has-text-danger': _vm.calculateGrowth(_vm.number3, _vm.previousNumber3) < 0 },staticStyle:{"font-size":"0.6rem"}},[_c('b-icon',{staticStyle:{"width":".3rem"},attrs:{"icon":_vm.calculateGrowth(_vm.number3, _vm.previousNumber3) > 0 ? 'arrow-up-bold' : 'arrow-down-bold',"size":"is-small","type":{'is-success': _vm.calculateGrowth(_vm.number3, _vm.previousNumber3) > 0, 'is-danger': _vm.calculateGrowth(_vm.number3, _vm.previousNumber3) < 0 }}}),_c('b-tooltip',{attrs:{"label":_vm.generateOverviewPerformanceTooltipByCode('previous_period_roi',
                'PERCENTAGE'),"position":"is-bottom"}},[_vm._v(" "+_vm._s(_vm.renderPercentage(_vm.calculateGrowth(_vm.number3, _vm.previousNumber3)))+" ")])],1):_vm._e(),_c('br'),_c('growing-number',{attrs:{"value":_vm.number3,"prefix":_vm.prefix3,"suffix":_vm.suffix3,"class-name":_vm.className3}})],1)])])])]),(_vm.icon)?_c('div',{staticClass:"level-item has-widget-icon is-hidden-mobile"},[_c('div',{staticClass:"is-widget-icon"},[_c('b-icon',{attrs:{"icon":_vm.icon,"size":"is-large","type":_vm.type}})],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }