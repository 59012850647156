<template>
    <div>
        <template v-if="$store.state.theme.isTwoFactorAuth">
            <p>This is already enabled.</p>
        </template>
        <template v-else>
            <p>Through an Authentication app, two-factor authentication helps protect your account from unauthorized access by requiring a second authentication in addition to your Mozart password. <!--<a @click="showLearnMoreModal = true">Learn More</a> --></p>
            <b-button @click="getQRCode" type="is-info is-light" class="mt-2">Enable 2FA with QR Code</b-button>
        </template>

        <b-modal v-model="showLearnMoreModal" :width="640" scroll="keep">
            <header class="modal-card-head">
                <p class="modal-card-title">How to Two-factor Authentication</p>
            </header>
            <div class="modal-card-body p-2 is-relative">
                describe to the user how they set it up.
            </div>
            <footer class="modal-card-foot p-2">
                <b-button @click="showLearnMoreModal = false">Close</b-button>
            </footer>
        </b-modal>
        <CustomModalWrapper
            title="Two-factor Authentication"
            :show="showModal"
        >
        <div class="modal-card-body p-2 is-relative">
            <b-loading v-model="loading" :is-full-page="false" ></b-loading>
            <img :src="'data:image/png;base64, '+qr_base64" width="280" class="mx-auto is-block" alt="QR Image" style="min-height: 216px; background-color: #ddd;"/>
            <b-field class="mt-3">
                <b-input
                    ref="qr_code"
                    type="text"
                    placeholder="Code"
                    v-model.number="qr_code"
                    :autofocus="true"
                    required>
                </b-input>
            </b-field>
        </div>
        <footer class="modal-card-foot p-2">
            <b-button @click="showModal = false">Close</b-button>
            <b-button @click="verifyCode" type="is-success" :disabled="!qr_code">Submit</b-button>
        </footer>
        </CustomModalWrapper>
    </div>
</template>

<script>
import CustomModalWrapper from "@/components/CustomModalWrapper";
import { myProfileService } from '@/services'
export default {
    name: 'ProfilePageAuth',

    data() {
        return {
            showLearnMoreModal: false,
            showModal: false,
            loading: false,
            qr_code: '',
            qr_base64: '',
        }
    },

    components: {
        CustomModalWrapper,
    },

    computed: {},

    methods: {
        getQRCode() {
            this.showModal = true
            this.loading = true
            myProfileService.getQRImage()
                .then(res => {
                    this.qr_base64 = res.data.qr_base64
                    this.loading = false

                    this.$nextTick(() => this.$refs.qr_code.focus())
                })
                .catch(() => {
                    this.loading = false
                    alert('Something went wrong, Please try again!')
                })
        },

        verifyCode() {
            this.loading = true
            myProfileService.verifyCodeFromQR({
                    otp: this.qr_code,
                })
                .then(() => {

                    this.$store.commit('theme/user', {is_two_factor_auth: true})
                    this.$emit('customDestroy')
                    this.$buefy.snackbar.open({
                        message: '2Factor authentication enabled successfully, you will be logged out now.',
                        queue: false,
                        duration: 5000,
                    })
                    this.showModal = false
                    this.loading = false

                    // logout
                    this.$store.dispatch('authentication/logout');
                    window.location.reload();
                })
                .catch(() => {
                    this.loading = false
                    alert('Something went wrong, Please try again!')
                })
        },
    } 

}
</script>