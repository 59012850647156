import { config } from './config';
import axios from "axios";
// import {router} from "@/helpers";
import {store} from "@/store";

export const userService = {
    refreshToken,
    login,
    logout,
    getUsers,
    saveUserMarketingAgent,
    getMarketingAgencyUsers,
    updateAgencyUser,
    config
};

function login(payload) {

    return axios.post(`${config.apiUrl}/api/token/`, payload)
    .then(handleResponse)
    .then(user => {
        // login successful if there's a jwt token in the response
        if (user.access) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            if(user.trust_code) localStorage.setItem('__TRUST_CODE__', JSON.stringify(user.trust_code))
        }

        store.dispatch('theme/getUserDetails').then();
        return user;
    });
}

function logout() {

    // remove user from local storage to log user out
    // TODO: do proper logout.
    localStorage.removeItem('user');
    // router.push({ name: 'login' });
    // window.location.reload();
}

function handleResponse(response) {

    if (response.statusText !== 'OK' && response.status !== 200) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            location.reload();
        }

        const error = (response.data) || response.statusText;
        alert('response.statusText: '+ response.statusText + ', response.status: ' + response.status)
        return Promise.reject(error);
    }

    return response.data;
}

export function refreshToken() {
    let userTokenStorage = JSON.parse(localStorage.getItem('user'))
    let refreshTokenValue = userTokenStorage?.['refresh']
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/token/refresh/`, { refresh: refreshTokenValue })
          .then(handleResponse)
        .then(response => {
            let json = JSON.stringify(response.data);

            if(!json){
                return;
            }
            localStorage.setItem('user', json);
            if(response.data){
                resolve(response.data.token);
            }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

export function getUsers(params){

    let axiosConfig = {}

    if(params){
        axiosConfig['params'] = params;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/app_auth/users/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function saveUserMarketingAgent(payload) {
    return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/administration/create-marketing-agency-users`, payload)
          .then(response => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
}


export function getMarketingAgencyUsers(params){

    let axiosConfig = {}

    if(params){
        axiosConfig['params'] = params;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/list-marketing-agency-users`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function updateAgencyUser(payload) {
  return new Promise((resolve, reject) => {
      axios
        .patch(`${config.apiUrl}/api/administration/user-organization/${payload.user_id}/`, {is_active: payload.is_active})
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}