import { config } from './config';
import axios from "axios";

export const picassoImageDisableAllByChildHash = {
    post,
    config
};


function post(params){

    let endpoint;

    endpoint = '/api/proxy/picasso/image/disabled-all-by-child-hash/';

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}${endpoint}`, params)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}
