import { optimizationAutomationService } from "@/services/optimization-automation.service";

export const optimizationAutomation = {
    namespaced: true,

    state: {
        all: [],
        loading: false,
        error: null,
    },

    actions: {
        getAll({ commit }, {filters, no_loading}) {
             filters = filters || {}
             no_loading = no_loading || false

            if (!no_loading) commit('getAllRequest');

            optimizationAutomationService.getAll(filters)
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },

        create({ commit }, payload) {
            return new Promise((resolve, reject) =>  {
                optimizationAutomationService.create(payload)
                    .then(
                        data => {commit('createSuccess', data); resolve(data)},
                        error => {commit('createFailure', error); reject(error)}
                    );
            })
        },

        update(_, payload) {
            return new Promise((resolve, reject) =>  {
                optimizationAutomationService.update(payload)
                .then(
                    data => resolve(data),
                    error => reject(error)
                );
            })
        },
    },

    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data.data.results;
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.all = [];
            state.loading = false;
            state.error = error;
        },
        createSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        createFailure(state) {
            state.loading = false;
        },
    }
}