
import {creationSettingsService} from "@/services/creation-creation-settings.service";
import {mapState} from "vuex";

export const userSettings = {
    methods: {
        fetchUserSettings() {
          let that = this;

          creationSettingsService.get({
            'user': this.theme['userId']
          }).then(response => {
            that.userSettings = response.data['results']
            that.normalizeUserSettings()
          })
        },

        normalizeUserSettings(){
          let indexedObject = {};

          for(let i in this.userSettings){

            let result = this.userSettings[i],
                network = result['network'],
                agencyFeed = result['agency_feed'];

            if(!('network_' + network in indexedObject)){
              indexedObject['network_' + network] = {}
            }

            if(!('agencyFeed_' + agencyFeed in indexedObject['network_' + network])){
              indexedObject['network_' + network]['agencyFeed_' + agencyFeed] = {}
            }

            for(let countryCode in result['json_value']['country_settings']){

              indexedObject['network_' + network]['agencyFeed_' + agencyFeed][countryCode] = {
                'max_budget': result['json_value']['country_settings'][countryCode]['max_budget']
              };

            }
          }

          this.userSettingsIndexed = indexedObject;

        },

        getUserSetting(networkId, agencyFeedId, countryCode, propertyName, defaultValue){

          countryCode = countryCode.toUpperCase();

          if(!('network_' + networkId in this.userSettingsIndexed)){
            return defaultValue;
          }

          if(!('agencyFeed_' + agencyFeedId in this.userSettingsIndexed['network_' + networkId])){
            return defaultValue;
          }

          if(!(countryCode in this.userSettingsIndexed['network_' + networkId]['agencyFeed_' + agencyFeedId])){
            return defaultValue;
          }

          return this.userSettingsIndexed['network_' + networkId]['agencyFeed_' + agencyFeedId][countryCode][propertyName];

        },
    },
    computed: {
      ...mapState([
        'theme',
      ]),
    }
}