
export const coloursUtils = {
    methods: {
        numberToColorRgb(i) {
            let red = Math.floor(255 - (255 * i / 100));
            let green = Math.floor(255 * i / 100);
            // we format to css value and return
            return 'rgb('+green+','+red+',0)'
        }
    }
}