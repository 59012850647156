<template>
  <div class="custom-select-style">
      <div>
        <div>
          <div class="is-flex is-align-items-center my-1">
            Selected Items: <strong class="red--text"> {{ items.length }} </strong> -
            Set Limit:
            <b-input v-model="actionLimit" type="number" step="1" class="px-1" custom-class="custom-input-style" style="max-width: 100px; height: fit-content;"></b-input>
          </div>
          Selected Date: <strong class="red--text">{{ formatDate(selectedDate, 'ddd, YYYY-MM-DD') }}</strong>
        </div>
      </div>
      <hr class="my-3" />
      <div>
        <div>
          <b-checkbox
            v-model="applyToBid"
          >Bid</b-checkbox>
        </div>
      </div>
      <div v-if="applyToBid">
        <div class="is-flex is-align-items-center">
          Set as ((
          <b-select v-model="bidSetToField" :disabled="!applyToBid" style="max-width: 180px; display: inline-block;">
            <option
              v-for="option in allowedFieldsBid"
              :value="option.id"
              :key="option.id">
              {{ option.name }}
            </option>
          </b-select> <span class="px-1">*</span>
          <b-input v-model="bidFactor" :disabled="!applyToBid" type="number" step="0.01" custom-class="custom-input-style" style="max-width: 100px; display: inline-block;"></b-input>) <span class="px-1">+</span>
          <b-input v-model="bidNumber" :disabled="!applyToBid" type="number" step="0.01" custom-class="custom-input-style" style="max-width: 100px; display: inline-block;"></b-input>)
        </div>
      </div>
      <hr class="my-3" />
      <div>
        <div>
          <b-checkbox
            v-model="applyToBudget"
          >Budget</b-checkbox>
        </div>
      </div>
      <div v-if="applyToBudget">
        <div class="is-flex is-align-items-center">
          Set as ((
          <b-select v-model="budgetSetToField" :disabled="!applyToBudget" style="max-width: 180px; display: inline-block;">
            <option
              v-for="option in allowedFieldsBudget"
              :value="option.id"
              :key="option.id">
              {{ option.name }}
            </option>
          </b-select> <span class="px-1">*</span>
          <b-input v-model="budgetFactor" :disabled="!applyToBudget" type="number" step="0.01" custom-class="custom-input-style" style="max-width: 100px; display: inline-block;"></b-input>) <span class="px-1">+</span>
          <b-input v-model="budgetNumber" :disabled="!applyToBudget" type="number" step="0.01" custom-class="custom-input-style" style="max-width: 100px; display: inline-block;"></b-input>)
        </div>
      </div>
      <hr class="my-3" />
      <div>
        <div>
          <b-checkbox
            v-model="applyToStatus"
          >Status</b-checkbox>
        </div>
      </div>
      <div v-if="applyToStatus">
        <div>
          Set as

          <b-switch
              :disabled="!applyToStatus"
              v-model="statusSet"
              type="is-success">
              Status
          </b-switch>

           {{ statusSet ? 'ACTIVE' : 'PAUSED' }}
        </div>
      </div>
      <hr class="my-3" />
      <div>
        <div>
          <b-checkbox
            v-model="applyToBiddingStrategyGoogleDisplay"
            :disabled="applyToBid"
          >Bidding Strategy (GDN Only)</b-checkbox>
        </div>
      </div>
      <div v-if="applyToBiddingStrategyGoogleDisplay">
        <div>
          <b-select v-model="newBiddingStrategyGDN" :disabled="!applyToBiddingStrategyGoogleDisplay || applyToBid" style="max-width: 180px; display: inline-block;">
            <option
              v-for="option in biddingStrategies.allGDN"
              :value="option"
              :key="option.id">
              {{ option.code }}
            </option>
          </b-select>
        </div>
        <div v-if="newBiddingStrategyGDN['is_bid_handled'] && applyToBiddingStrategyGoogleDisplay">
          This bidding strategy requires a Bid Amount. <br />
          Set as ((
          <b-select v-model="bidSetToField" :disabled="!newBiddingStrategyGDN['is_bid_handled']" style="max-width: 180px; display: inline-block;">
            <option
              v-for="option in allowedFieldsBid"
              :value="option.id"
              :key="option.id">
              {{ option.name }}
            </option>
          </b-select> <span class="px-1">*</span>
          <b-input v-model="bidFactor" :disabled="!newBiddingStrategyGDN['is_bid_handled']" type="number" step="0.01" custom-class="custom-input-style" style="max-width: 100px; display: inline-block;"></b-input>) <span class="px-1">+</span>
          <b-input v-model="bidNumber" :disabled="!newBiddingStrategyGDN['is_bid_handled']" type="number" step="0.01" custom-class="custom-input-style" style="max-width: 100px; display: inline-block;"></b-input>)
        </div>
      </div>
      <hr class="my-3" />
      <div>
        <div>
          <b-checkbox
            v-model="applyToBiddingStrategyFacebook"
            :disabled="applyToBid"
          >Bidding Strategy (Facebook Only)</b-checkbox>
        </div>
      </div>
      <div v-if="applyToBiddingStrategyFacebook">
        <div>
          <b-select v-model="newBiddingStrategyFacebook" :disabled="!applyToBiddingStrategyFacebook || applyToBid" style="max-width: 180px; display: inline-block;">
            <option
              v-for="option in biddingStrategies.allFacebook"
              :value="option"
              :key="option.id">
              {{ option.code }}
            </option>
          </b-select>
        </div>
        <div v-if="newBiddingStrategyFacebook['is_bid_handled'] && applyToBiddingStrategyFacebook">
          This bidding strategy requires a Bid Amount. <br />
          Set as ((
          <b-select v-model="bidSetToField" :disabled="!newBiddingStrategyFacebook['is_bid_handled']" style="max-width: 180px; display: inline-block;">
            <option
              v-for="option in allowedFieldsBid"
              :value="option.id"
              :key="option.id">
              {{ option.name }}
            </option>
          </b-select> <span class="px-1">*</span>
          <b-input v-model="bidFactor" :disabled="!newBiddingStrategyFacebook['is_bid_handled']" type="number" step="0.01" custom-class="custom-input-style" style="max-width: 100px; display: inline-block;"></b-input>) <span class="px-1">+</span>
          <b-input v-model="bidNumber" :disabled="!newBiddingStrategyFacebook['is_bid_handled']" type="number" step="0.01" custom-class="custom-input-style" style="max-width: 100px; display: inline-block;"></b-input>)
        </div>
      </div>

    <hr class="my-3" />
      <div>
        <div>
          <b-checkbox
              v-model="applyToDeleteAll"
          >Delete All</b-checkbox>
        </div>
      </div>
      <div v-if="applyToDeleteAll">
        <div>
          <p><b>This will delete all, use with caution as it cannot be undone.</b></p>
        </div>
      </div>

      <hr class="my-3" />

      <div>
        <div class="mb-2">
          <p class="mb-2">Total Entities Instructions to launch: <strong class="red--text">{{ createActionsInstructions()['instructions'].length }}</strong></p>
          <div class="is-flex is-align-items-center mb-1">
            See
            <b-input v-model="sliceDemo" type="number" step="1" class="px-1" custom-class="custom-input-style" style="max-width: 90px; display: inline-block; height: fit-content;"></b-input>
            ({{ topChanges.length }}) examples below:
          </div>
            <div
              v-for="item in topChanges"
              :key="`${ item.id }__`"
            >
              <div>
                <div><b>{{ item.id }}</b></div>
              </div>
              <div class="columns" :key="`${item.id}__${details.object_type}`" v-for="details in item.details">
                <div class="column">
                  {{ details.object_type }}
                </div>
                <div class="column">
                  Old: {{ details.old_value }}
                </div>
                <div class="column">
                  New: {{ details.new_value }}
                </div>
              </div>
            </div>
            
        </div>
      </div>
  </div>
</template>
<script>
    // import axios from 'axios';
    // import moment from 'moment';
    import numeral from 'numeral';
    import {mapState} from "vuex";
    import {timeUtils} from "@/helpers/time-utils";
    import {userSettings} from "@/helpers/user/user-settings";

    export default {
      name: 'BulkAction',

      components: {

      },

      mixins: [
        timeUtils,
        userSettings,
      ],

      props: ['items', 'selectedDate', 'filters', 'filtersFields'],

      created() {

        if(this.biddingStrategies.allGDN.length === 0)
          this.$store.dispatch('biddingStrategies/getAllGDN');

        if(this.biddingStrategies.allFacebook.length === 0)
          this.$store.dispatch('biddingStrategies/getAllFacebook');

        if(this.biddingStrategies.all.length === 0)
          this.$store.dispatch('biddingStrategies/getAll');

        this.fetchUserSettings();
      },

      data: () => ({

          sliceDemo: 5,

          applyToBiddingStrategyGoogleDisplay: false,
          applyToBiddingStrategyFacebook: false,
          applyToBudget: false,
          applyToBid: false,
          applyToStatus: false,
          applyToDeleteAll: false,

          actionLimit: 500,

          bidSetToField: 'rpc',
          bidFactor: 0,
          bidNumber: 0,

          statusSet: false,

          allowedFieldsBid: [
            {id: "prop_bid_amount", name: "Bid Amount"},
            {id: "rpc", name: "RPC"}
          ],

          budgetSetToField: 'prop_budget_amount',
          budgetFactor: 0,
          budgetNumber: 0,

          allowedFieldsBudget: [
            {id: "prop_budget_amount", name: "Budget"},
            {id: "cost_amount", name: "Cost"}
          ],

          newBiddingStrategyGDN: {},
          newBiddingStrategyFacebook: {},

      }),

      methods: {

        getBiddingStrategyDetails (id){
          let biddingStrategy = this.biddingStrategies.all.filter((e) => e.id === id);
          return biddingStrategy[0];
        },

        getExchangeRate(row){
          let a = this.makeNumber(row.route_hash.livemainentity.budget_amount_account_currency),
              b = this.makeNumber(row.route_hash.livemainentity.budget_amount);
          return b / a;
        },

        makeNumber: function (str){
          let num = numeral(str).value()

          if(num < 0 || num > 0){
            return num
          }

          return 0;
        },

        renderNumber(amount, decimals) {
            if(!amount){
                return '0.' + ('0'.repeat(decimals));
            }

            let num = numeral(amount).format('0.' + ('0'.repeat(decimals)));
            if (!(num !== 0)) {
                return '';
            }

            return num;
        },

        createActionsInstructions: function ()
        {
          let data = {
                'instructions': [],
                'api': [],
                'actions': {}
              },
              self = this,
              changes,
              info = this.items.slice(0, this.actionLimit),
              newBiddingStrategy,
              oldBiddingStrategy,
              newBudget,
              oldBudget,
              newBid,
              oldBid,
              newStatus,
              oldStatus,
              row,
              apiItem,
              bidStep,
              budgetStep;

          function buildBiddingStrategies(newBiddingStrategyNetwork, bidStep) {
            if (oldBiddingStrategy.code !== newBiddingStrategy.code && newBiddingStrategy.code) {
              if (!newBiddingStrategyNetwork['is_bid_handled']) {
                changes.push({
                  "object_type": "BIDDING_STRATEGY",
                  "old_value": oldBiddingStrategy.code,
                  "new_value": newBiddingStrategy.code,
                });

                data['actions']['BIDDING_STRATEGY'] = {
                  "object_type": "BIDDING_STRATEGY",
                  'newBiddingStrategyGDN': newBiddingStrategyNetwork.code,
                }

                apiItem['oldBiddingStrategyDetails'] = oldBiddingStrategy
                apiItem['newBiddingStrategyDetails'] = newBiddingStrategy
              } else {
                newBid = (numeral(row[self.bidSetToField]).value() * numeral(self.bidFactor).value()) + numeral(self.bidNumber).value();
                oldBid = numeral(row['prop_bid_amount']).value();

                data['actions']['BIDDING_STRATEGY_WITH_BID'] = {
                  "object_type": "BIDDING_STRATEGY",
                  'newBiddingStrategyGDN': newBiddingStrategyNetwork.code,
                  'bidSetToField': self.bidSetToField,
                  'bidFactor': self.bidFactor,
                  'bidNumber': self.bidNumber,
                }

                if (oldBid !== newBid && newBid > 0) {
                  changes.push({
                    "object_type": "BIDDING_STRATEGY",
                    "old_value": oldBiddingStrategy.code,
                    "new_value": newBiddingStrategy.code,
                  });
                  changes.push({
                    "object_type": "BIDDING_STRATEGY_BID",
                    "old_value": numeral(oldBid).format('0.'+('0'.repeat(bidStep))),
                    "new_value": numeral(newBid).format('0.'+('0'.repeat(bidStep))),
                  });

                  apiItem['oldBiddingStrategyDetails'] = oldBiddingStrategy
                  apiItem['newBiddingStrategyDetails'] = newBiddingStrategy
                  apiItem['oldBid'] = numeral(oldBid).format('0.'+('0'.repeat(bidStep)))
                  apiItem['newBid'] = numeral(newBid).format('0.'+('0'.repeat(bidStep)))
                }
              }
            }
          }

          for (let key in info) {
              changes = [];
              row = info[key];

              if(this.getExchangeRate(row) < 0.30){
                  bidStep = 3;
                  budgetStep = 3;
              }else{
                  bidStep = 2;
                  budgetStep = 0;
              }

              apiItem = {
                // Used by optimization seving
                'route_hash': {
                  'livemainentity': {
                    'id': null,
                    'resourcename': null,
                    'account': {
                      'agency_feed': {
                        'id': null
                      }
                    },
                    'country': {
                      'code': null
                    }
                  },
                  'network': {
                    'code': null,
                    'id': null
                  },
                },

                'newStatus': null,
                'oldStatus': null,

                'newBudget': null,
                'oldBudget': null,

                'newBid': null,
                'oldBid': null,

                'newBiddingStrategyDetails': {},
                'oldBiddingStrategyDetails': {},
              }

              if(this.applyToBudget) {
                apiItem['route_hash']['livemainentity']['resourcename'] = row.route_hash.livemainentity.resourcename;
                apiItem['route_hash']['livemainentity']['id'] = row.route_hash.livemainentity.id;
                apiItem['route_hash']['network']['id'] = row.route_hash.network.id;
                apiItem['route_hash']['network']['code'] = row.route_hash.network.code;
                apiItem['route_hash']['livemainentity']['account']['agency_feed']['id'] = row.route_hash.livemainentity.account.agency_feed.id;
                apiItem['route_hash']['livemainentity']['country']['code'] = row.route_hash.livemainentity.country.code;
                apiItem['exchangeRate'] = this.getExchangeRate(row);

                let maxBudget = this.makeNumber(this.getUserSetting(
                  apiItem['route_hash']['network']['id'],
                  apiItem['route_hash']['livemainentity']['account']['agency_feed']['id'],
                  apiItem['route_hash']['livemainentity']['country']['code'],
                  'max_budget',
                  0
                ));

                data['actions']['BUDGET'] = {
                  "object_type": "BUDGET",
                  'budgetSetToField': self.budgetSetToField,
                  'budgetFactor': self.budgetFactor,
                  'budgetNumber': self.budgetNumber,
                }

                newBudget = ( numeral(row[self.budgetSetToField]).value() * numeral(self.budgetFactor).value()) + numeral(self.budgetNumber).value();
                oldBudget = numeral(row['prop_budget_amount']).value();

                if(maxBudget > 0 && newBudget > maxBudget){
                  newBudget = maxBudget;
                }

                if(oldBudget !== newBudget && newBudget > 0) {
                  changes.push({
                    "object_type": "BUDGET",
                    "old_value": numeral(oldBudget).format('0.'+('0'.repeat(budgetStep))),
                    "new_value": numeral(newBudget).format('0.'+('0'.repeat(budgetStep))),
                  });

                  apiItem['oldBudget'] = numeral(oldBudget).format('0')
                  apiItem['newBudget'] = numeral(newBudget).format('0')
                }
              }

              if(this.applyToBid) {

                apiItem['route_hash']['livemainentity']['resourcename'] = row.route_hash.livemainentity.resourcename;
                apiItem['route_hash']['livemainentity']['id'] = row.route_hash.livemainentity.id;
                apiItem['route_hash']['network']['id'] = row.route_hash.network.id;
                apiItem['route_hash']['network']['code'] = row.route_hash.network.code;
                apiItem['route_hash']['livemainentity']['account']['agency_feed']['id'] = row.route_hash.livemainentity.account.agency_feed.id;
                apiItem['route_hash']['livemainentity']['country']['code'] = row.route_hash.livemainentity.country.code;
                apiItem['exchangeRate'] = this.getExchangeRate(row);

                data['actions']['BID'] = {
                  "object_type": "BID",
                  'bidSetToField': self.bidSetToField,
                  'bidFactor': self.bidFactor,
                  'bidNumber': self.bidNumber,
                }

                newBid = (numeral(row[self.bidSetToField]).value() * numeral(self.bidFactor).value()) + numeral(self.bidNumber).value();
                oldBid = numeral(row['prop_bid_amount']).value();

                if(oldBid !== newBid && newBid > 0 && this.getBiddingStrategyDetails(row['prop_bidding_strategy'])['is_bid_handled']) {
                  changes.push({
                    "object_type": "BID",
                    "old_value": numeral(oldBid).format('0.'+('0'.repeat(bidStep))),
                    "new_value": numeral(newBid).format('0.'+('0'.repeat(bidStep))),
                  });

                  apiItem['oldBid'] = numeral(oldBid).format('0.'+('0'.repeat(bidStep)))
                  apiItem['newBid'] = numeral(newBid).format('0.'+('0'.repeat(bidStep)))
                }
              }

              if(this.applyToBiddingStrategyGoogleDisplay && row['route_hash']['network']['dealer'] === 1) {
                apiItem['route_hash']['livemainentity']['resourcename'] = row.route_hash.livemainentity.resourcename;
                apiItem['route_hash']['livemainentity']['id'] = row.route_hash.livemainentity.id;
                apiItem['route_hash']['network']['id'] = row.route_hash.network.id;
                apiItem['route_hash']['network']['code'] = row.route_hash.network.code;
                apiItem['route_hash']['livemainentity']['account']['agency_feed']['id'] = row.route_hash.livemainentity.account.agency_feed.id;
                apiItem['route_hash']['livemainentity']['country']['code'] = row.route_hash.livemainentity.country.code;
                apiItem['exchangeRate'] = this.getExchangeRate(row);

                oldBiddingStrategy = this.getBiddingStrategyDetails(row['prop_bidding_strategy']);
                newBiddingStrategy = this.newBiddingStrategyGDN;

                buildBiddingStrategies(this.newBiddingStrategyGDN, bidStep);
              }

              if(this.applyToBiddingStrategyFacebook && row['route_hash']['network']['dealer'] === 2) {
                apiItem['route_hash']['livemainentity']['resourcename'] = row.route_hash.livemainentity.resourcename;
                apiItem['route_hash']['livemainentity']['id'] = row.route_hash.livemainentity.id;
                apiItem['route_hash']['network']['id'] = row.route_hash.network.id;
                apiItem['route_hash']['network']['code'] = row.route_hash.network.code;
                apiItem['route_hash']['livemainentity']['account']['agency_feed']['id'] = row.route_hash.livemainentity.account.agency_feed.id;
                apiItem['route_hash']['livemainentity']['country']['code'] = row.route_hash.livemainentity.country.code;
                apiItem['exchangeRate'] = this.getExchangeRate(row);

                oldBiddingStrategy = this.getBiddingStrategyDetails(row['prop_bidding_strategy']);
                newBiddingStrategy = this.newBiddingStrategyFacebook;

                buildBiddingStrategies(this.newBiddingStrategyFacebook, bidStep);
              }

              if(this.applyToStatus) {
                apiItem['route_hash']['livemainentity']['resourcename'] = row.route_hash.livemainentity.resourcename;
                apiItem['route_hash']['livemainentity']['id'] = row.route_hash.livemainentity.id;
                apiItem['route_hash']['network']['id'] = row.route_hash.network.id;
                apiItem['route_hash']['network']['code'] = row.route_hash.network.code;
                apiItem['route_hash']['livemainentity']['account']['agency_feed']['id'] = row.route_hash.livemainentity.account.agency_feed.id;
                apiItem['route_hash']['livemainentity']['country']['code'] = row.route_hash.livemainentity.country.code;

                newStatus = this.statusSet;
                oldStatus = row['route_hash']['livemainentity']['status'] === 'ACTIVE';

                if(newStatus !== oldStatus && row['route_hash']['livemainentity']['status'] !== 'REMOVED') {

                  changes.push({
                    "object_type": "STATUS",
                    "old_value": oldStatus,
                    "new_value": newStatus,
                  });

                  apiItem['oldStatus'] = oldStatus
                  apiItem['newStatus'] = newStatus
                }
              }

              if(this.applyToDeleteAll) {
                apiItem['route_hash']['livemainentity']['resourcename'] = row.route_hash.livemainentity.resourcename;
                apiItem['route_hash']['livemainentity']['id'] = row.route_hash.livemainentity.id;
                apiItem['route_hash']['network']['id'] = row.route_hash.network.id;
                apiItem['route_hash']['network']['code'] = row.route_hash.network.code;
                apiItem['route_hash']['livemainentity']['account']['agency_feed']['id'] = row.route_hash.livemainentity.account.agency_feed.id;
                apiItem['route_hash']['livemainentity']['country']['code'] = row.route_hash.livemainentity.country.code;
                apiItem['route_hash']['network']['code'] = row.route_hash.network.code;

                newStatus = 'REMOVED';
                oldStatus = row['route_hash']['livemainentity']['status'];

                if( row['route_hash']['livemainentity']['status'] !== 'REMOVED') {

                  changes.push({
                    "object_type": "REMOVE",
                    "old_value": oldStatus,
                    "new_value": newStatus,
                  });

                  apiItem['oldStatus'] = oldStatus
                  apiItem['newStatus'] = newStatus
                }
              }

              if (changes.length > 0) {
                data['instructions'].push({
                    "id": row['route_hash']['livemainentity']['url_value'],
                    "details": changes,
                })
                apiItem['rowId'] = key;
                apiItem['index'] = key;
                data['api'].push(apiItem);
              }
          }

          this.$emit('actions', data['actions']);
          this.$emit('input', data['api']);

          return data;
        }
      },

      watch: {

        bidSetToField: function (newValue, oldValue){
          if(newValue === oldValue){
            return;
          }
          this.createActionsInstructions();
        },

        bidFactor: function (newValue, oldValue){
          if(newValue === oldValue){
            return;
          }
          this.createActionsInstructions();
        },

        bidNumber: function (newValue, oldValue){
          if(newValue === oldValue){
            return;
          }
          this.createActionsInstructions();
        },

        budgetSetToField: function (newValue, oldValue){
          if(newValue === oldValue){
            return;
          }
          this.createActionsInstructions();
        },

        budgetFactor: function (newValue, oldValue){
          if(newValue === oldValue){
            return;
          }
          this.createActionsInstructions();
        },

        budgetNumber: function (newValue, oldValue){
          if(newValue === oldValue){
            return;
          }
          this.createActionsInstructions();
        },

        getMaxBudget(row){
          let networkId = row.route_hash.network.id,
              agencyFeedId = row.route_hash.livemainentity.account.agency_feed.id,
              countryCode = row.route_hash.livemainentity.country.code.toUpperCase();

          return this.makeNumber(this.getUserSetting(networkId, agencyFeedId, countryCode, 'max_budget', 0));
        },
      },

      computed: {
        topChanges: function (){
          return this.createActionsInstructions()['instructions'].slice(0,this.sliceDemo);
        },
        ...mapState([
          'biddingStrategies',
        ])
      }
    }
</script>