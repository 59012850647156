import { config } from './config';
import axios from "axios";

export const optimizationDashboardsOverviewPerformancesService = {
    getByDateRange,
    config
};

export function getByDateRange(fromDate, toDate, timezone){

    let axiosConfig = {}, params = {};

    if(timezone){
        params['timezone_code'] = encodeURI(timezone);
    }

    params['date__range'] = fromDate + ',' + toDate;

    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/performance/dashboards-overview-performances/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

}