<template>
    <div>
        <form @submit.prevent :key="key">
            <b-field
                :type="!email ? null : isValidEmail ? null :'is-danger'"
                :message="!email ? null : isValidEmail ? null : 'Invalid Email'"
            >
                <b-input
                    type="email"
                    v-model.trim="email"
                    :disabled="disableEmailInput"
                    placeholder="Email address"
                    required>
                </b-input>
            </b-field>
            <p v-if="errorMsg.type === 'general'" class="notification is-danger is-light py-2 px-2 my-2">
                <ul class="m-0 pl-4" style="list-style: circle;">
                    <li v-for="(er, i) in Object.entries(errorMsg.content)" :key="i">
                        <h4><strong>{{ capitalizeStr(er[0]) }}:</strong></h4>
                        <ul class="m-0 pl-4" style="list-style: square;">
                            <li v-for="(e, ii) in er[1]" :key="ii">{{ e }}</li>
                        </ul>
                    </li>
                </ul>
            </p>
            <!-- <b-button v-if="isValidEmail && !hideVerifyBtn" type="is-success is-light is-block mb-4" @click="verify">verify email</b-button> -->
            <b-button size="is-medium" type="is-info" @click="save" :disabled="email && !isValidEmail" :loading="myProfile.loading">Save</b-button>
        </form>

        <CustomModalWrapper :show="showModal && !myProfile.loading" title="Verify Email" >
            <ProfilePageTokenModal agent="email" @closeAndChange="closeAndChange" @successOTP="successOTP" />
        </CustomModalWrapper>

    </div>
</template>

<script>
import { mapState } from "vuex"
import CustomModalWrapper from "@/components/CustomModalWrapper"
import ProfilePageTokenModal from "@/pages/profile/ProfilePageTokenModal"
export default {
    name: 'ProfilePageEmail',

    data() {
        return {
            disabled: true,
            email: '',
            showModal: false,
            hideVerifyBtn: false,
            disableEmailInput: false,
            key: 1,
            errorMsg: {
                type: '',
                content: '',
            },
        }
    },

    created() {
        this.email = this.theme.userEmail
    },

    components: {
        CustomModalWrapper,
        ProfilePageTokenModal,
    },

    computed: {
        ...mapState(['myProfile', 'theme']),

        isValidEmail() {
            return !!(this.email && /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.email));

        },
    },

    methods: {
        save() {
            this.$store.dispatch('myProfile/changeUserInfo', { email: this.email })
                .then(res => {
                    
                    //update store
                    this.$store.commit('theme/user', res.data, {root: true})

                    this.$emit('customDestroy')
                    this.$buefy.snackbar.open({
                        message: 'Email saved Successfully!',
                        queue: false,
                    })
                })
                .catch(er => {
                    this.errorMsg.type = 'general'
                    this.errorMsg.content = er.response.data
                })
        },

        verify() {
            this.$store.dispatch('myProfile/sendOTPToAgent', { agent: 'email', agentValue: this.email })
                        .then(() => {
                            console.log('show modal for token...')
                            this.showModal = true
                        })
        },

        successOTP() {
            this.showModal = false
            this.hideVerifyBtn = true
            this.disabled = false
            this.disableEmailInput = true
        },

        closeAndChange() {
            this.showModal = false
            this.email = ''
        },

        capitalizeStr(str) {
            str = str.split('_')
            let a = []
            for(const s in str) {
                a.push(str[s].charAt(0).toUpperCase() + str[s].slice(1))
            }
            return a.join(' ')
        },
    }

}
</script>