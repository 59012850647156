import {config} from './config';
import axios from "axios";
import Vue from "vue";
import {SnackbarProgrammatic} from "buefy";

export const dailyOptimizationPerformancesService = {
    getAll,
    optimize,
    getByDateRange,
    deleteRequest,
    getAllKeywordsLife,
    getAllPerformanceAnalysis,
    getAllDailyPerformanceAnalysis,
    getTqAnalysis,
    config
};

export function getAll(
    pageId,
    sorting,
    pageSize,
    date,
    networks,
    products,
    countries,
    accounts,
    biddingStrategies,
    agencyFeeds,
    marketingAgency,
    currencies,
    assignedUsers,
    rules,
    rulesField,
    showRemoved,
    showPaused,
    showActive,
    accountBlockedOnDatetimeIsNull,
    asCSV
) {

    let axiosConfig = {}, params = {}, statuses = [], url = '';

    asCSV = asCSV || false;

    // Normalize data
    params['date'] = date;
    params['page'] = pageId;
    params['page_size'] = pageSize;

    if(showRemoved){
        statuses.push('REMOVED');
    }

    if(showPaused){
        statuses.push('PAUSED');
    }

    if(showActive){
        statuses.push('ACTIVE');
    }

    if(accountBlockedOnDatetimeIsNull === false){
        params['account__blocked_on_datetime__isnull'] = accountBlockedOnDatetimeIsNull;
    }

    if(accountBlockedOnDatetimeIsNull === true){
        params['account__blocked_on_datetime__isnull'] = accountBlockedOnDatetimeIsNull;
    }

    if(statuses.length > 0 && statuses.length < 3){
        params['route_hash__livemainentity__status__in'] = statuses.join(',')
    }

    if(networks.length > 0){
        params['network__in'] = networks.join(',');
    }

    if(products.length > 0){
        params['product__in'] = products.join(',');
    }

    if(countries.length > 0){
        params['country__in'] = countries.join(',');
    }

    if(accounts.length > 0){
        params['account__in'] = accounts.join(',');
    }

    if(biddingStrategies.length > 0){
        params['bidding_strategy__in'] = biddingStrategies.join(',');
    }

    if(agencyFeeds.length > 0){
        params['agency_feed__in'] = agencyFeeds.join(',');
    }

    if(marketingAgency.length > 0){
        params['marketing_agency__in'] = marketingAgency.join(',');
    }

    if(currencies.length > 0){
        params['account__currency_code__in'] = currencies.join(',');
    }

    if(assignedUsers.length > 0){
        params['assigned_user__in'] = assignedUsers.join(',');
    }

    if(rules.length > 0){
        for(let i in rules){
            params = Object.assign(params, rules[i])
        }
    }

    if(sorting.length > 0) {

        let sortingGroup = [];

        for(let i in sorting) {
            let sort = sorting[i];
            if(sort['order'].toLowerCase() === 'desc'){
                if(!(sort['field'].indexOf('-') === 0) ){
                    sort['field'] = '-' + sort['field'];
                }
            }

            if(sortingGroup.indexOf(sort['field']) > -1){
                continue;
            }

            sortingGroup.push(sort['field']);
        }

        params['ordering'] = sortingGroup.join(',');
    }else{
        params['ordering'] = 'profit';
    }

    if(rulesField.length > 0){
        params['field_filters'] = JSON.stringify(rulesField);
    }

    params['nocache'] = Date.now()
    // Append to request.
    axiosConfig['params'] = params;

    if(asCSV){
        url = `${config.apiUrl}/api/performance/daily-performances-as-csv/`;
    }else{
        url = `${config.apiUrl}/api/performance/daily-performances/`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function getAllKeywordsLife(
    networks,
    products,
    countries,
    accounts,
    biddingStrategies,
    agencyFeeds,
    rules,
    showRemoved,
    showPaused,
    showActive
) {

    let axiosConfig = {}, params = {}, statuses = [];

    // Normalize data

    if(showRemoved){
        statuses.push('REMOVED');
    }

    if(showPaused){
        statuses.push('PAUSED');
    }

    if(showActive){
        statuses.push('ACTIVE');
    }

    if(statuses.length > 0){
        params['main_entity__status__in'] = statuses.join(',')
    }

    if(networks.length > 0){
        params['network__in'] = networks.join(',');
    }

    if(products.length > 0){
        params['product__in'] = products.join(',');
    }

    if(countries.length > 0){
        params['country__in'] = countries.join(',');
    }

    if(accounts.length > 0){
        params['account__in'] = accounts.join(',');
    }

    if(biddingStrategies.length > 0){
        params['main_entity__bidding_strategy__in'] = biddingStrategies.join(',');
    }

    if(agencyFeeds.length > 0){
        params['agency_feed__in'] = agencyFeeds.join(',');
    }

    params['rules_json'] = JSON.stringify(rules);

    params['ordering'] = 'profit';

    params['nocache'] = Date.now()
    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/performance/daily-performance-keywords-life/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function getAllPerformanceAnalysis(
    itemType,
    itemValue,
    dateFrom,
    dateTo
) {

    let axiosConfig = {}, params = {};

    // Normalize data
    if(itemType==='ROUTE_HASH'){
        params['route_hash'] = itemValue;
    }else if(itemType==='KEYWORD_NAME'){
        params['keyword_name'] = itemValue;
    }else{
        throw new Error('Invalid item value');
    }

    params['item_type'] = itemType
    params['date__range'] = dateFrom + ',' + dateTo
    params['nocache'] = Date.now()
    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/performance/performance-analysis/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function optimize(data, rowId, dataContext){

    rowId = rowId || null;
    dataContext = dataContext || [];

    let params = [];

    let exchangeRate = data['exchangeRate'] || 1;

    if(data.newBudget !== data.oldBudget){

        let newBudget = parseInt((data.newBudget / exchangeRate) + '', 10);
        let oldBudget = parseInt((data.oldBudget / exchangeRate) + '', 10);
        params
            .push({
                "optimization_type": 'BUDGET_AMOUNT',
                "network_code": data.route_hash.network.code,
                "resourcename": data.route_hash.livemainentity.resourcename,
                "old_value": oldBudget,
                "new_value": newBudget,
                "current_status": "QUEUED",
                "message": "MANUAL_OPTIMIZATION"
            });
        data.route_hash.livemainentity.budget_amount_account_currency = newBudget;
        if(rowId){
            dataContext[rowId].route_hash.livemainentity.budget_amount_account_currency = newBudget;
            dataContext[rowId].route_hash.livemainentity.state_is_budget_dirty = true;
            dataContext[rowId].newBudget = data.newBudget;
        }
    }

    if(data.newStatus !== data.oldStatus){
        let newStatusCode = data.newStatus ? 'ACTIVE' : 'PAUSED',
            oldStatusCode = data.oldStatus ? 'ACTIVE' : 'PAUSED';

        params
            .push({
                "optimization_type": 'STATUS',
                "network_code": data.route_hash.network.code,
                "resourcename": data.route_hash.livemainentity.resourcename,
                "old_value": oldStatusCode,
                "new_value": newStatusCode,
                "current_status": "QUEUED",
                "message": "MANUAL_OPTIMIZATION"
            });

        data.route_hash.livemainentity.status = newStatusCode;
        if(rowId){
            dataContext[rowId].route_hash.livemainentity.status = newStatusCode;
            dataContext[rowId].route_hash.livemainentity.state_is_status_dirty = true;
            dataContext[rowId].newStatus = data.newStatus;
        }
    }

    if(data.newBiddingStrategyDetails.id !== data.oldBiddingStrategyDetails.id){

        let d = {
                "optimization_type": 'BIDDING_STRATEGY',
                "network_code": data.route_hash.network.code,
                "resourcename": data.route_hash.livemainentity.resourcename,
                "old_value": data.oldBiddingStrategyDetails.code,
                "new_value": data.newBiddingStrategyDetails.code,
                "current_status": "QUEUED",
                "message": "MANUAL_OPTIMIZATION"
            };

        data.route_hash.livemainentity.bidding_strategy = data.newBiddingStrategyDetails;
        if(rowId){
            dataContext[rowId].route_hash.livemainentity.bidding_strategy = data.newBiddingStrategyDetails;
            dataContext[rowId].route_hash.livemainentity.state_is_bidding_dirty = true;
            dataContext[rowId].newBiddingStrategyDetails = data.newBiddingStrategyDetails;
        }

        if(data.newBiddingStrategyDetails.is_bid_handled){
            let newBid = data.newBid / exchangeRate
            d['additional_value'] = newBid.toFixed(2);
            data.route_hash.livemainentity.bid_amount_account_currency = newBid;
            if(rowId){
                dataContext[rowId].route_hash.livemainentity.bid_amount_account_currency = newBid;
                dataContext[rowId].route_hash.livemainentity.state_is_bidding_dirty = true;
                dataContext[rowId].newBid = data.newBid;
            }
        }

        params.push(d);
    }else{
        if(data.newBid !== data.oldBid){

            let newBid = data.newBid / exchangeRate;
            let oldBid = data.oldBid / exchangeRate;

            params
                .push({
                    "optimization_type": 'BID_AMOUNT',
                    "network_code": data.route_hash.network.code,
                    "resourcename": data.route_hash.livemainentity.resourcename,
                    "old_value": oldBid.toFixed(2),
                    "new_value": newBid.toFixed(2),
                    "current_status": "QUEUED",
                    "message": "MANUAL_OPTIMIZATION"
                });
            data.route_hash.livemainentity.bid_amount_account_currency = newBid;
            if(rowId){
                dataContext[rowId].route_hash.livemainentity.bid_amount_account_currency = newBid;
                dataContext[rowId].route_hash.livemainentity.state_is_bidding_dirty = true;
                Vue.set(dataContext[rowId], 'newBid', data.newBid);
            }
        }
    }

    if(params.length === 0){
        SnackbarProgrammatic.open('Nothing to save');
        return new Promise((resolve, reject) => {
            resolve()
            reject()
        });
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/optimization/optimization-requests/`, params)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}


export function getByDateRange(data){

    let axiosConfig = {}, params = {}

    params['route_hash'] = data['routeHashValue'];
    params['date__range'] = data['fromDate'] + ',' + data['toDate'];
    params['ordering'] = data['ordering'];

    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/performance/daily-performances/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function deleteRequest(data, rowId, dataContext){

    rowId = rowId || null;
    dataContext = dataContext || [];

    data.route_hash.livemainentity.status = 'REMOVED';
    if(rowId){
        dataContext[rowId].route_hash.livemainentity.status = 'REMOVED';
    }

    let dataRequest = {
        "resourcename": data.route_hash.livemainentity.resourcename,
        "network_code": data.route_hash.network.code,
        "message": "MANUAL_DELETION",
        "current_status": "QUEUED",
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/optimization/deletion-requests/`, dataRequest)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function getTqAnalysis(
    adUnitCode,
    countryCode,
    dateFrom,
    dateTo) {

    let axiosConfig = {}, params = {}

    params['dailyperformancehashitem__adunit__code'] = adUnitCode;
    params['dailyperformancehashitem__subdomain_locale__country__code'] = countryCode;
    params['date__range'] = dateFrom + ',' + dateTo;
    params['nocache'] = Date.now();

    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {

        axios
            .get(`${config.apiUrl}/api/optimization/daily-performance-hash-groups-tq-analysis/`, axiosConfig)
            .then(response => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getAllDailyPerformanceAnalysis(
    pageId,
    sorting,
    pageSize,
    dateFrom,
    dateTo,
    groupBy,
    networks,
    products,
    countries,
    accounts,
    biddingStrategies,
    agencyFeeds,
    marketingAgency,
    currencies,
    assignedUsers,
    rules,
    rulesField,
    showRemoved,
    showPaused,
    showActive,
) {

    let axiosConfig = {}, params = {}, statuses = [], url = '';

    // Normalize data
    params['date__range'] = dateFrom + ',' + dateTo;
    params['page'] = pageId;
    params['page_size'] = pageSize;

    if(showRemoved){
        statuses.push('REMOVED');
    }

    if(showPaused){
        statuses.push('PAUSED');
    }

    if(showActive){
        statuses.push('ACTIVE');
    }

    if(statuses.length > 0 && statuses.length < 3){
        params['route_hash__livemainentity__status__in'] = statuses.join(',')
    }

    if(networks.length > 0){
        params['network__in'] = networks.join(',');
    }

    if(products.length > 0){
        params['product__in'] = products.join(',');
    }

    if(countries.length > 0){
        params['country__in'] = countries.join(',');
    }

    if(accounts.length > 0){
        params['account__in'] = accounts.join(',');
    }

    if(biddingStrategies.length > 0){
        params['bidding_strategy__in'] = biddingStrategies.join(',');
    }

    if(agencyFeeds.length > 0){
        params['agency_feed__in'] = agencyFeeds.join(',');
    }

    if(currencies.length > 0){
        params['account__currency_code__in'] = currencies.join(',');
    }

    if(marketingAgency.length > 0){
        params['marketing_agency__in'] = marketingAgency.join(',');
    }

    if(assignedUsers.length > 0){
        params['assigned_user__in'] = assignedUsers.join(',');
    }

    params['group_by'] = groupBy;

    if(rules.length > 0){
        for(let i in rules){
            params = Object.assign(params, rules[i])
        }
    }

    if(sorting.length > 0) {

        let sortingGroup = [];

        for(let i in sorting) {
            let sort = sorting[i];
            if(sort['order'].toLowerCase() === 'desc'){
                if(!(sort['field'].indexOf('-') === 0) ){
                    sort['field'] = '-' + sort['field'];
                }
            }

            if(sortingGroup.indexOf(sort['field']) > -1){
                continue;
            }

            sortingGroup.push(sort['field']);
        }

        params['ordering'] = sortingGroup.join(',');
    }else{
        params['ordering'] = 'sum_profit';
    }

    if(rulesField.length > 0){
        params['field_filters'] = JSON.stringify(rulesField);
    }

    params['nocache'] = Date.now()
    // Append to request.
    axiosConfig['params'] = params;

    url = `${config.apiUrl}/api/performance/daily-performances-analysis/`;

    return new Promise((resolve, reject) => {
      axios
        .get(url, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}