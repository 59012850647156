import {userStorageService} from "@/services";


export const userStorage = {
    namespaced: true,
    state: {
        repos: [],
        loading: false,
    },
    actions: {
        getRepos({ commit }, {user}) {
            commit('getReposRequest');
            userStorageService.getRepos({
                type: 'IMAGE_REPOSITORY',
                user,
            })
                .then(
                    data => commit('getReposSuccess', data),
                    error => commit('getReposFailure', error)
                );
        },

        createRepo({ commit }, payload) {
            commit('createRepoRequest');
            userStorageService.createRepo(payload)
                .then(
                    data => {commit('createRepoSuccess', data)},
                    error => commit('createRepoFailure', error)
                );
        },

        updateRepo({ commit }, payload) {
            commit('updateRepoRequest');
            userStorageService.updateRepo(payload)
                .then(
                    data => {commit('updateRepoSuccess', data);},
                    error => commit('updateRepoFailure', error)
                );
        },

        deleteRepo({ commit }, payload) {
            commit('deleteRepoRequest');
            userStorageService.deleteRepo(payload)
                .then(
                    data => {commit('deleteRepoSuccess', data);},
                    error => commit('deleteRepoFailure', error)
                );
        },
    },
    mutations: {
        getReposRequest(state) {
            state.repos = [];
            state.loading = true;
        },
        getReposSuccess(state, data) {
            state.repos = data.data['results'];
            state.loading = false;
        },
        getReposFailure(state, error) {
            state.repos = [];
            state.loading = false;
            console.dir(error);
        },
        createRepoRequest(state) {
            state.loading = true;
        },
        createRepoSuccess(state, data) {
            state.repos = [data.data, ...state.repos];
            state.loading = false;
        },
        createRepoFailure(state, error) {
            state.loading = false;
            console.dir(error);
        },
        updateRepoRequest(state) {
            state.loading = true;
        },
        updateRepoSuccess(state, data) {
            state.repos = state.repos.map(r => r.id === data.data.id ? data.data : r)
            state.loading = false;
        },
        updateRepoFailure(state, error) {
            state.loading = false;
            console.dir(error);
        },
        deleteRepoRequest(state) {
            state.loading = true;
        },
        deleteRepoSuccess(state, data) {
            console.log('de', data)
            state.repos = state.repos.filter(r => r.id !== data.data.id)
            state.loading = false;
        },
        deleteRepoFailure(state, error) {
            state.loading = false;
            console.dir(error);
        },
    }
}
