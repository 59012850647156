import { administrationNetworksService } from '@/services';

export const networks = {
    namespaced: true,
    state: {
        all: [],
        allAvailableNetworks: [],
        loading: false
    },
    actions: {
        getAll({ commit }, filters) {
            commit('getAllRequest');

            administrationNetworksService.getAll(filters)
                .then(
                    dealers => commit('getAllSuccess', dealers),
                    error => commit('getAllFailure', error)
                );
        },

        getAllAvailableNetworks({ commit }, filters) {
            commit('getAllAvailableNetworksRequest');

            administrationNetworksService.getAllAvailableNetworks(filters)
                .then(
                    dealers => commit('getAllAvailableNetworksSuccess', dealers),
                    error => commit('getAllAvailableNetworksFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.loading = true;
        },
        getAllSuccess(state, dealers) {
            state.all = dealers.data.results;
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.all = [];
            console.dir(error);
        },
        getAllAvailableNetworksRequest(state) {
            state.loading = true;
        },
        getAllAvailableNetworksSuccess(state, dealers) {
            state.allAvailableNetworks = dealers.data.results;
            state.loading = false;
        },
        getAllAvailableNetworksFailure(state, error) {
            state.allAvailableNetworks = [];
            console.dir(error);
        }
    }
}
