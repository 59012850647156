import { devLanguagesService } from '@/services';


export const languages = {
    namespaced: true,
    state: {
        all: [],
        loading: false
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            devLanguagesService.getAll()
                .then(
                    performance => commit('getAllSuccess', performance),
                    error => commit('getAllFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        getAllFailure(state, error) {
            console.error(error);
            state.loading = false;
        }
    }
}
