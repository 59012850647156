var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"loading":_vm.isLoading,"paginated":_vm.paginated,"per-page":_vm.perPage,"striped":true,"hoverable":true,"default-sort":"score","data":_vm.data,"pagination-position":"top","pagination-rounded":false,"sort-icon":"chevron-up","sort-icon-size":"is-medium","aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page","total":_vm.total,"current-page":_vm.currentPage,"backend-pagination":"","backend-sorting":""},on:{"page-change":_vm.changePage,"sort":_vm.sortPressed}},[_c('b-table-column',{attrs:{"label":"AdUnit Code","field":"adunit.code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{attrs:{"href":("/tq-analysis?adUnitCode=" + (props.row.adunit.code) + "&countryCode=" + (props.row.country.code)),"target":"_blank"}},[_vm._v(" "+_vm._s(props.row.adunit.code)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"AdUnit Name","field":"adunit.name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.adunit.name)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Country","field":"country.name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.country.name)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Score","field":"score","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',[_vm._v(_vm._s(column.label))]),_c('br'),_vm._v(" "+_vm._s(_vm.renderNumber(_vm.overalls.avg_score, 3))+" ")]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.renderNumber(props.row.score, 2))+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Revenue","field":"revenue_amount_net","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',[_vm._v(_vm._s(column.label))]),_c('br'),_vm._v(" "+_vm._s(_vm.renderCurrency(_vm.overalls.sum_revenue, 2))+" ")]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.renderCurrency(props.row.revenue_amount_net, 2))+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Clicks In","field":"clicks_in","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',[_vm._v(_vm._s(column.label))]),_c('br'),_vm._v(" "+_vm._s(_vm.renderNumber(_vm.overalls.sum_clicks_in, 0))+" ")]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.clicks_in)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Clicks Out","field":"clicks_out","sortable":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',[_vm._v(_vm._s(column.label))]),_c('br'),_vm._v(" "+_vm._s(_vm.renderNumber(_vm.overalls.sum_clicks_out, 0))+" ")]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.clicks_out)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }