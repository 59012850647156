<template>
    <form action="">
        <section class="modal-card-body p-4">
            <label>Choose an Option</label>
            <multiselect
                v-model="option"
                :options="options"
                :close-on-select="true"
                placeholder="Options"
                label="name"
                track-by="id">
            </multiselect>

            <b-field class="file">
                <b-upload v-model="file" expanded :accept="'.pdf,.png,.jpeg'">
                    <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>{{ file.name || "Upload a file" }}</span>
                    </a>
                </b-upload>
            </b-field>

            <ol class="ml-3">
                <li v-for="(file, index) in files" :key="index" class="p-1">
                    File type: {{ file.type }}
                    <a :href="file.file" target="_blank" class="button is-small is-info is-light view-btn">View</a>
                </li>
            </ol>
        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Close"
                @click="clearFieldsAndClose" />
            <b-button
                label="Save"
                type="is-primary"
                @click="handelSetLimit" />
        </footer>
    </form>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
export default {
    name: 'InternalAgencyPageFilesModal',

    props: {
        id: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            options: [{id: 1, name: 'Signed Contract', type: 'SIGNED_CONTRACT'}, {id: 2, name: 'Proof of Payment Limit', type: 'PROOF_OF_PAYMENT_LIMIT'}],
            option: {},
            file: {},
        }
    },

    components: {
        Multiselect,
    },

    created() {
        if(!this.internalAgencyManager.files.length) this.$store.dispatch('internalAgencyManager/getFiles')
        console.log('created')
    },

    computed: {
        ...mapState([
            'internalAgencyManager',
        ]),

        files() {
            return this.internalAgencyManager.files.map(f => {if (f.marketing_agency === this.id) return f}).filter(r=>r)
        },
    },

    methods: {
        handelSetLimit() {
            const { option, file } = this
            if(option?.name && file.size) {
                console.log('dispatch update files...', this.id)
                const payload = {
                    marketing_agency: this.id,
                    type: option.type,
                    file,
                }
                this.$store.dispatch('internalAgencyManager/saveFile', payload)
                    .then(() => {
                        this.clearFieldsAndClose()
                        this.$router.go()
                        //notify the user
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: 'Files updated Successfully!',
                            actionText: 'Ok',
                            queue: false,
                        }); 
                    })
            }
        },

        close() {
            this.clearFieldsAndClose()
        },

        clearFieldsAndClose() {
            //clear fields
            this.option = {}
            this.file = {}
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-card {
    .modal-card-body {
        padding: 15px;
    }
}
.view-btn {
    vertical-align: middle;
}
</style>