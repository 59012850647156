import { config } from './config';
import axios from "axios";
// import {authHeader} from "@/helpers";

export const optimizationDashboardsChartKeywordsService = {
    getByDateRange,
    config
};

export function getByDateRange(fromDate,
                               toDate,
                               networks,
                               agencyFeeds,
                               products,
                               countries,
                               accounts){

    let axiosConfig = {}, params = {}, endpoint;

    params['date__range'] = fromDate + ',' + toDate;

    if(networks.length > 0){
        params['network__in'] = networks.map(e => {return e.id}).join(',')
    }

    if(agencyFeeds.length > 0){
        params['agency_feed__in'] = agencyFeeds.map(e => {return e.id}).join(',')
    }

    if(products.length > 0){
        params['product__in'] = products.map(e => {return e.id}).join(',')
    }

    if(countries.length > 0){
        params['country__in'] = countries.map(e => {return e.id}).join(',')
    }

    if(accounts.length > 0){
        params['account__in'] = accounts.map(e => {return e.id}).join(',')
    }

    // Append to request.
    axiosConfig['params'] = params;

    endpoint = '/api/performance/dashboards-chart-keywords/';


    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}