<template>
    <div>
        <section class="section is-main-section">
            <router-link :to="{name: 'users-new'}" class="button is-success mb-3 mt-1">Create New</router-link>
            <div class="card has-table has-mobile-sort-spaced">
                <div class="card-content">
                    <b-table
                        :loading="users.loadingAllMarketingAgencyUsers"
                        :data="users.allMarketingAgencyUsers"
                        :striped="true"
                        :hoverable="true"
                        default-sort="total_count"
                        pagination-position="top"
                        :pagination-rounded="false"
                        sort-icon="chevron-up"
                        sort-icon-size="is-medium"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                    >
                        <div slot="empty" class="notification is-warning is-light has-text-centered">
                            There's no users yet.
                        </div>
                        <b-table-column label="Firstname" field="user.first_name" sortable v-slot="props">
                            {{ props.row.user.first_name }}
                        </b-table-column>

                        <b-table-column label="Lastname" field="user.last_name" sortable v-slot="props">
                            {{ props.row.user.last_name }}
                        </b-table-column>

                        <b-table-column label="Username" field="user.username" sortable v-slot="props">
                            {{ props.row.user.username }}
                        </b-table-column>

                        <b-table-column label="Email" field="user.email" sortable v-slot="props">
                            {{ props.row.user.email }}
                        </b-table-column>

                        <b-table-column label="Is Admin" field="user.is_organization_admin" sortable v-slot="props">
                                <b-icon v-if="props.row.user.is_organization_admin" icon="check-circle" type="is-success" />
                                <b-icon v-else icon="close-circle" type="is-danger" />
                        </b-table-column>

                        <b-table-column label="Is Active" field="user.is_active" sortable>
                            <template v-slot:header="{ column }">
                                <span>{{ column.label }} <Tooltip label="A disabled user will not be able to login into the system" :appendToBody="true"/></span><br />
                            </template>
                            <template v-slot="props">
                                <b-field>
                                    <b-switch v-model="is_active[props.row.user.id]" @input="isActiveUpdate(props.row.user.id)"></b-switch>
                                </b-field>
                            </template>
                        </b-table-column>

                        <b-table-column label="Access" field="marketing_agent" sortable v-slot="props">
                            {{ props.row.marketing_agent.networks.map((e)=> e.code).join(', ') }} -> {{ props.row.marketing_agent.partner.agency_feed[getFieldCodeLabel()] }}
                        </b-table-column>

                        <b-table-column label="Profit share" field="profit_share" sortable v-slot="props">
                            {{ renderPercentage(props.row.profit_share) }}
                        </b-table-column>
                    </b-table>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {userService} from "@/services";
import Tooltip from '@/components/Tooltip';
import { mapState } from "vuex"
import {numberUtils} from "@/helpers/number-utils";
import {permissionsUtils} from "@/helpers/permissions-utils";
export default {

    name: 'UsersPage',

    data() {
        return {
            clickedUserId: null,
            showEditModal: false,
            is_active: {},
        }
    },

    created() {
        if (!this.users.allMarketingAgencyUsers.length) this.$store.dispatch('users/getMarketingAgencyUsers')
    },

    mixins: [
        permissionsUtils,
        numberUtils
    ],

    components: {
        Tooltip,
    },

    computed: {
        ...mapState([
            'users',
        ]),

    },

    methods: {
        isActiveUpdate(user_id) {
            userService.updateAgencyUser({
                user_id,
                is_active: this.is_active[user_id],
            }).then(() => {
                this.showEditModal = false
                this.$store.dispatch('users/getMarketingAgencyUsers', 'no_loading')
            }).catch(() => {
                alert('Something went wrong...')
            })
        },
    },

    watch: {
        'users.allMarketingAgencyUsers': function(val) {
            const self = this
            for(let i in val) {
                self.is_active[val[i].user.id] = val[i].user.is_active
            }
        },
    },
}

</script>