import { config } from './config';
import axios from "axios";

export const optimizationDashboardsChartPerformancesService = {
    getByDateRange,
    config
};

export function getByDateRange(dailyChartType, fromDate, toDate){

    let axiosConfig = {}, params = {}, endpoint;

    params['date__range'] = fromDate + ',' + toDate;

    // Append to request.
    axiosConfig['params'] = params;

    if(dailyChartType === 'BY_AGENCY_FEED'){
        endpoint = '/api/performance/dashboards-chart-performances-by-agency-feed/';
    }else if(dailyChartType === 'BY_COUNTRY'){
        endpoint = '/api/performance/dashboards-chart-performances-by-country/';
    }else if(dailyChartType === 'BY_DEALER'){
        endpoint = '/api/performance/dashboards-chart-performances-by-dealer/';
    }else if(dailyChartType === 'BY_PRODUCT'){
        endpoint = '/api/performance/dashboards-chart-performances-by-product/';
    }else{
        alert(`Unknown ${dailyChartType}`)
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}