import {mapState} from "vuex";

export const permissionsUtils = {
    methods: {
        isSuperUser() {
            return this.theme.userGroups.indexOf('IsSuperUser') > -1;
        },

        isMarketingUntrusted() {
            return this.theme.userGroups.indexOf('IsMarketingUntrusted') > -1;
        },

        executeWhenPermissionsAreLoaded(func) {

            let that = this;

            if (!this.theme.userId) {

                setTimeout(() => {
                    that.executeWhenPermissionsAreLoaded(func);
                }, 200);

                return;
            }

            func();
        },

        getUserId(){
            return this.theme.userId;
        },

        getUserGroups(){
            return this.theme.userGroups;
        },

        getFieldCodeLabel(){
            return !this.isSuperUser() ? 'label' : 'code';
        },

        getFieldNameLabel(){
            return !this.isSuperUser() ? 'label' : 'name';
        },
    },
    computed: {
        ...mapState([
            'theme',
        ])
    }
}