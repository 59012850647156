<template>
  <div>
    <b-loading v-model="hourlyPerformances.isLoading"></b-loading>
    <b-field horizontal grouped label="Last hours" position="is-centered">
      <b-input v-model="lastHours"/>
      <p class="control">
        <b-button
            icon-left="reload"
            @click.prevent="filter"
        ></b-button>
      </p>
    </b-field>
    <b-table
    :data="hourlyPerformances.all"
    narrowed
    :sticky-header="true"

    default-sort-direction="DESC"
    sort-icon="chevron-up"
    sort-icon-size="is-medium"
    default-sort="datetime_utc"

    :scrollable="true"
    :height="height"
    :opened-detailed="hourlyPerformances.all.map((i) => i.datetime_utc)"
    detailed
    custom-detail-row
    detail-key="datetime_utc"
>
    <b-table-column field="datetime_utc" label="Date" sortable cell-class="has-background-info-light  has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ hourlyPerformances.all.length }} hours
      </template>
      <template v-slot="props">{{ formatDatetime(props.row.datetime_utc)  }}</template>
    </b-table-column>

    <b-table-column field="revenue_amount_net" label="Revenue" sortable cell-class="has-background-info-light  has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesRevenue, 2) }}
      </template>
      <template v-slot="props">{{ renderCurrency(props.row.revenue_amount_net, 2) }}</template>
    </b-table-column>

    <b-table-column field="cost_amount" label="Cost" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesCost, 2) }}
      </template>
      <template v-slot="props">{{ renderCurrency(props.row.cost_amount, 2) }}</template>
    </b-table-column>

    <b-table-column field="profit" label="Profit" sortable numeric cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesRevenue-hourlyPerformancesCost, 2) }}
      </template>
      <template v-slot="props">
        <span :class="{
          'has-text-success': makeNumber(props.row.profit) >= 0,
          'has-text-danger': makeNumber(props.row.profit) < 0,
        }">
          {{ renderCurrency(props.row.profit, 2) }}
        </span>
      </template>
    </b-table-column>

    <b-table-column field="roi" label="ROI" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderPercentage((hourlyPerformancesRevenue-hourlyPerformancesCost)/hourlyPerformancesCost, 2) }}
      </template>
      <template v-slot="props">
        <span :class="{
          'has-text-success': makeNumber(props.row.roi) > 0,
          'has-text-danger': makeNumber(props.row.roi) < 0,
        }">
          {{ renderPercentage(props.row.roi, 2) }}
        </span>
      </template>
    </b-table-column>

    <b-table-column field="clicks_in_r" label="ClicksIn(R)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ hourlyPerformancesClicksInR }}
      </template>
      <template v-slot="props">
        <span>
          {{ makeNumber(props.row.clicks_in_r) }}
        </span>
      </template>
    </b-table-column>

    <b-table-column field="clicks_in_c" label="ClicksIn(C)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ hourlyPerformancesClicksInC }}
      </template>
      <template v-slot="props">
        <span>
          {{ makeNumber(props.row.clicks_in_c) }}
        </span>
      </template>
    </b-table-column>

    <b-table-column field="clicks_out_r" label="ClicksOut(R)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ hourlyPerformancesClicksOutR }}
      </template>
      <template v-slot="props">
        <span>
          {{ makeNumber(props.row.clicks_out_r) }}
        </span>
      </template>
    </b-table-column>

    <b-table-column field="clicks_out_c" label="ClicksOut(C)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ hourlyPerformancesClicksOutC }}
      </template>
      <template v-slot="props">
        <span>
          {{ makeNumber(props.row.clicks_out_c) }}
        </span>
      </template>
    </b-table-column>

    <b-table-column field="clicks_in_ratio" label="CiR" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderNumber(hourlyPerformancesClicksInR / hourlyPerformancesClicksInC, 2) }}
      </template>
      <template v-slot="props">
        <span>
          {{ props.row.clicks_out_ratio }}
        </span>
      </template>
    </b-table-column>
      <b-table-column field="clicks_out_ratio" label="CoR" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderNumber(hourlyPerformancesClicksOutR / hourlyPerformancesClicksOutC, 2) }}
      </template>
      <template v-slot="props">
        <span>
          {{ props.row.clicks_out_ratio }}
        </span>
      </template>
    </b-table-column>



    <b-table-column field="ctr_r" label="CTR(R)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderPercentage(hourlyPerformancesClicksOutR/hourlyPerformancesClicksInR, 2) }}
      </template>
      <template v-slot="props">{{ renderPercentage(props.row.ctr_r, 2) }}</template>
    </b-table-column>

    <b-table-column field="ctr_c" label="CTR(C)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderPercentage(hourlyPerformancesClicksOutC/hourlyPerformancesClicksInC, 2) }}
      </template>
      <template v-slot="props">{{ renderPercentage(props.row.ctr_c, 2) }}</template>
    </b-table-column>

    <b-table-column field="cpc_r" label="CPC(R)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderPercentage(hourlyPerformancesClicksOutC/hourlyPerformancesClicksInC, 2) }}
      </template>
      <template v-slot="props">{{ renderCurrency(props.row.cpc_r, 3) }}</template>
    </b-table-column>

    <b-table-column field="cpc_c" label="CPC(C)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesCost/hourlyPerformancesClicksInC, 3) }}
      </template>
      <template v-slot="props">{{ renderCurrency(props.row.cpc_c, 3) }}</template>
    </b-table-column>

    <b-table-column field="cpa_r" label="CPA(R)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesCost/hourlyPerformancesClicksOutR, 3) }}
      </template>
      <template v-slot="props">{{ renderCurrency(props.row.cpa_r, 3) }}</template>
    </b-table-column>

    <b-table-column field="cpa_c" label="CPA(C)" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesCost/hourlyPerformancesClicksOutC, 3) }}
      </template>
      <template v-slot="props">{{ renderCurrency(props.row.cpa_c, 3) }}</template>
    </b-table-column>

    <b-table-column field="prop_bid_amount" label="Bid" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesAvgBid, 3) }}
      </template>
      <template v-slot="props">{{ renderCurrency(props.row.prop_bid_amount, 2) }}</template>
    </b-table-column>

    <b-table-column field="prop_budget_amount" label="Budget" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesAvgBudget, 2) }}
      </template>
      <template v-slot="props">{{ renderCurrency( props.row.prop_budget_amount, 2) }}</template>
    </b-table-column>

    <b-table-column field="rpc" label="RPC" sortable cell-class="has-background-info-light has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesRevenue/hourlyPerformancesClicksOutR, 3) }}
      </template>
      <template v-slot="props">{{ renderCurrency( props.row.rpc, 3) }}</template>
    </b-table-column>

    <b-table-column field="rpc_c" label="RPC(C)" sortable cell-class="has-background-info-light  has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesRevenue/hourlyPerformancesClicksOutC, 3) }}
      </template>
      <template v-slot="props">{{ renderCurrency( props.row.rpc_c, 3) }}</template>
    </b-table-column>

    <b-table-column field="rps" label="RPS" sortable cell-class="has-background-info-light  has-text-black">
      <template v-slot:header="{ column }">
        <span>{{ column.label }}</span><br />
        {{ renderCurrency(hourlyPerformancesRevenue/hourlyPerformancesClicksInR, 3) }}
      </template>
      <template v-slot="props">{{ renderCurrency( props.row.rps, 3) }}</template>
    </b-table-column>

    <template slot="detail" slot-scope="props">
      <template v-if="props.row.optimizations.length > 0">
        <tr>
            <td></td>
            <td></td>
            <td colspan="2" class="has-background-primary-light is-size-7 has-text-weight-bold">Old Value</td>
            <td colspan="2" class="has-background-primary-light is-size-7 has-text-weight-bold">New Value</td>
            <td colspan="3" class="has-background-primary-light is-size-7 has-text-weight-bold">Message</td>
            <td colspan="3" class="has-background-primary-light is-size-7 has-text-weight-bold">By</td>
            <td colspan="3" class="has-background-primary-light is-size-7 has-text-weight-bold">Finished On</td>
            <td colspan="3" class="has-background-primary-light is-size-7 has-text-weight-bold">Status</td>
        </tr>
        <tr v-for="item in props.row.optimizations" :key="`optimizations-${item.id}`">
            <td></td>
            <td></td>
            <td colspan="2">{{ item.old_value }}</td>
            <td colspan="2">{{ item.new_value }}</td>
            <td colspan="3" v-if="isSuperUser()">{{ item.message }}</td>
            <td colspan="3" v-else>-</td>
            <td colspan="3">{{ formatDatetime(item.finished_on_datetime) }}</td>
            <td colspan="3">{{ item.created_by_user.username }}</td>
            <td colspan="3">{{ item.current_status }}</td>
        </tr>
      </template>
      <template v-if="props.row.deletions.length">
        <tr>
            <td></td>
            <td></td>
            <td colspan="3" class="has-background-primary-light is-size-7 has-text-weight-bold">Message</td>
            <td colspan="3" class="has-background-primary-light is-size-7 has-text-weight-bold">By</td>
            <td colspan="3" class="has-background-primary-light is-size-7 has-text-weight-bold">Finished On</td>
            <td colspan="3" class="has-background-primary-light is-size-7 has-text-weight-bold">Status</td>
        </tr>
        <tr v-for="item in props.row.deletions" :key="`deletions-${item.id}`">
            <td></td>
            <td></td>
            <td colspan="3" v-if="isSuperUser()" class="is-danger">{{ item.message }}</td>
            <td colspan="3" class="is-danger" v-else>DELETED</td>
            <td colspan="3">{{ getUsername(item.created_by_user) }}</td>
            <td colspan="3">{{ formatDatetime(item.finished_on_datetime) }}</td>
            <td colspan="3">{{ item.current_status }}</td>
        </tr>
      </template>
  </template>
</b-table>
  </div>
</template>

<script>
import moment from "moment-timezone";
import {mapState} from "vuex";
import {osUtils} from "@/helpers/os-utils";
import {numberUtils} from "@/helpers/number-utils";
import {permissionsUtils} from "@/helpers/permissions-utils";
export default {
  name: 'HourlyPerformanceTable',
  props: {
    routeHashValue: {
      type: String,
      default: ''
    },
    height: {
      type: [String, Number],
      default: 360
    }
  },

  mixins: [
      osUtils,
      numberUtils,
      permissionsUtils,
  ],

  mounted() {
    this.filter()
    if (!this.$store.state.users.length) this.$store.dispatch('users/getAll')
  },

  data: function (){
    return {
      lastHours: '12',
    }
  },

  methods: {

    filter: function (){
       this.$store.dispatch('hourlyPerformances/getAll', {
        'routeHashValue': this.routeHashValue,
        'hours': this.lastHours
      }).then(() => {
        this.$emit('hourlyPerformanceCount', this.hourlyPerformances.all.length)
      })
    },

    formatDatetime: function (time){
      return moment(time).format('DD/MMM HH:mm');
    },

    getUsername(id) {
      const user = this.$store.state.users.all.find(u => u.id === id)
      return user ? user.username : `UserId: ${id}`
    },
  },

  computed: {
    hourlyPerformancesCost() {
      return this.hourlyPerformances.all.reduce((prev, cur) => prev + this.makeNumber(cur.cost_amount), 0)
    },

    hourlyPerformancesRevenue() {
      return this.hourlyPerformances.all.reduce((prev, cur) => prev + this.makeNumber(cur.revenue_amount_net), 0)
    },

    hourlyPerformancesClicksInR() {
      return this.hourlyPerformances.all.reduce((prev, cur) => prev + this.makeNumber(cur.clicks_in_r), 0)
    },

    hourlyPerformancesClicksInC() {
      return this.hourlyPerformances.all.reduce((prev, cur) => prev + this.makeNumber(cur.clicks_in_c), 0)
    },
    hourlyPerformancesClicksOutR() {
      return this.hourlyPerformances.all.reduce((prev, cur) => prev + this.makeNumber(cur.clicks_out_r), 0)
    },

    hourlyPerformancesClicksOutC() {
      return this.hourlyPerformances.all.reduce((prev, cur) => prev + this.makeNumber(cur.clicks_out_c), 0)
    },

    hourlyPerformancesAvgBid() {
      let bid = this.hourlyPerformances.all.reduce((prev, cur) => prev + this.makeNumber(cur.prop_bid_amount), 0);
      return bid / this.hourlyPerformances.all.map((e) => e.prop_bid_amount > 0).length;
    },

    hourlyPerformancesAvgBudget() {
      let budget = this.hourlyPerformances.all.reduce((prev, cur) => prev + this.makeNumber(cur.prop_budget_amount), 0);
      return budget / this.hourlyPerformances.all.map((e) => e.prop_budget_amount > 0).length;
    },

    ...mapState([
      'hourlyPerformances',
    ])

  },

}
</script>
