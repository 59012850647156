<style scoped>
.isDone,
.isDone * {
  opacity: 0.5;
}
</style>
<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <section class="section is-main-section">

      <dependant-multi-filter
          v-model="selectedMultiFilter"
          agency-feeds-enabled
          countries-enabled
          accounts-enabled
          marketing-users-enabled
          @filter="filter"
      ></dependant-multi-filter>

      Account:
      <b-switch v-model="accountIsActive">Is Active</b-switch>
      <b-switch v-model="accountIsBlocked">Is Blocked</b-switch>
      <br />
      <br />

      Page:
      <b-switch v-model="pageIsActive">Is Active</b-switch>
      <b-switch v-model="pageIsBlocked">Is Blocked</b-switch>
      <b-switch v-model="pageIsPermanentlyClosed">Is Permanently Closed</b-switch>
      <b-switch v-model="pageIsPublished">Is Published</b-switch>
      <b-switch v-model="pageCanPost">Can Post</b-switch>

      <b-field label="Exclude Feedback..">
        <b-input v-model="excludeFeedback"></b-input>
      </b-field>
      <b-field label="Include Feedback..">
        <b-input v-model="includeFeedback"></b-input>
      </b-field>

      <b-modal
        v-model="showImageModal"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        @close="showImageModal=false"
        aria-modal>
        <template #default="props">
          <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">Image</p>
                <button
                    type="button"
                    class="delete"
                    @click="props.close" />
             </header>
              <section class="modal-card-body">
                <span>Hash Child: </span>
                <a
                    :href="`https://picasso.pytech.ai/admin/images/imagechild/?hash_unique_child=${selectedImage['image_remote_hash']}`"
                    target="_blank"
                >
                  {{ selectedImage['image_remote_hash'] }}
                </a>
                <br />
                <span>Created by: {{ selectedImage['created_by_username'] }}</span>
                <br />
                <img :src="selectedImage.image_url" style="max-width: 100%;">
                <br />
                <b-button class="is-danger" @click="disabledImage(selectedImage)">Disable Image</b-button>
                <br />
                <br />
                <b-button class="is-warning" @click="disabledFoundKeywordImage(selectedImage)" :disabled="!selectedImage['found_keyword_image_id']">Unlink Image from Keyword</b-button>
              </section>
            </div>
        </template>
      </b-modal>

      Total: {{ pagination.count }}
      <div v-for="ad in ads" v-bind:key="`ad-${ad.id}`" style="border: 1px solid #ccc; padding: 5px;" :class="{'mt-4': true, 'isDone': isMarkedAsDone(ad)}">
        Keyword: {{ ad.keyword_name }} |
        <b-button @click="alert(ad._json_approval_feedback)">
          See Error
        </b-button> |
        <a
            :href="`https://business.facebook.com/adsmanager/manage/ads?act=${ad.account.remote_id}&business_id=${ad.manager.remote_id}&selected_adset_ids=${ad.adset._remote_unique}`"
            target="_blank">
          Open on Facebook
        </a><br />

        Created: {{ ad.created_on_datetime }} | Account: {{ ad.account.name }} | Page: {{ ad.page.name }} (ads live: {{ ad.page.ads_live_count }})

        <div class="mt-1">
            <img :src="img.image_url" style="max-height: 300px;" @click="openImage(img)" v-for="(img, i) in ad.images" :key="`carousel-images-${ad.id}-${i}`">
        </div>

        <b-button class="is-warning" v-if="isMarkedAsDone(ad)" @click="markAsUndone(ad)">Mark As Undone</b-button>
        <b-button class="is-success" v-else @click="markAsDone(ad)">Mark As Done</b-button>

        <b-button class="is-danger" @click="deleteMainEntity(ad)">Delete</b-button>
      </div>

      <b-pagination
          :total="pagination.count"
          v-model="pagination.page_number"
          size="is-small"
          :per-page="pagination.page_size"
          @change="changePage"
      >
      </b-pagination>

    </section>
  </div>
</template>

<script>

import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {saulFacebookAdsService} from "@/services/saul-facebook-ads.service";
import {creationFoundKeywordImagesService, deleteRequest} from "@/services";
import {SnackbarProgrammatic} from "buefy";
import moment from "moment-timezone";
import {picassoImageDisableAllByChildHash} from "@/services/picasso-image-disable-all-by-child-hash.service";
export default {
  name: 'FacebookDisapprovedAdsPage',

  mounted() {

  },

  mixins: [
  ],
  components: {
    DependantMultiFilter
  },

  data() {
    return {

      showImageModal: false,
      selectedImage: {},


      isLoading: false,

      ads: [],

      excludeFeedback: 'This ad was disabled because this Page isn\'t allowed to advertise',
      includeFeedback: null,

      accountIsActive: true,
      accountIsBlocked: false,

      pageIsActive: true,
      pageIsBlocked: false,
      pageIsPermanentlyClosed: false,
      pageIsPublished: true,
      pageCanPost: true,

      pagination: {
        page_size: 25,
        count: 0,
        pages: 0,
        page_number: 1,
      },

      selectedMultiFilter: {
        'agencyFeeds': [],
        'countries': [],
        'accounts': [],
        'marketingUsers': [],
      }

    }
  },

  computed: {

  },

  methods: {
    alert(msg){
      alert(JSON.stringify(msg));
    },
    openImage(img){
      this.showImageModal = true;
      this.selectedImage = img;
    },
    disabledImage(img){

      picassoImageDisableAllByChildHash.post({
        'hash_unique_child': img['image_remote_hash']
      }).then(() => {
        SnackbarProgrammatic.open('Sent to disable!')
      }).catch(()=>{
        alert('An error occurred while trying to disable image.');
      })
    },
    filter(page){
      let that = this;

      this.isLoading = true;

      page = page || 1;

      let params = {

            '_is_active': true,
            '_effective_status': 'DISAPPROVED',
            'account__is_active': this.accountIsActive,
            'account__blocked_on_datetime__isnull': !this.accountIsBlocked,

            'manager_page__is_active': this.pageIsActive,
            'manager_page__blocked_on_datetime__isnull': !this.pageIsBlocked,
            'manager_page__is_permanently_closed': this.pageIsPermanentlyClosed,
            'manager_page__is_published': this.pageIsPublished,
            'manager_page__can_post': this.pageCanPost,

            'account__in': this.selectedMultiFilter['accounts'].map((element) => { return element.id }).join(','),
            'agency_feed__in': this.selectedMultiFilter['agencyFeeds'].map((element) => { return element.id }).join(','),
            'country__in': this.selectedMultiFilter['countries'].map((element) => { return element.id }).join(','),
            'assigned_user__in': this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }).join(','),
          }

          if(page > 1){
            params['page'] = page;
          }

          if(this.excludeFeedback){
            params['json_approval_feedback__not_icontains'] = this.excludeFeedback;
          }

          if(this.includeFeedback){
            params['json_approval_feedback__icontains'] = this.includeFeedback;
          }

      saulFacebookAdsService.get(
          params
      ).then(response => {
        that.ads = response.data['results']

        this.pagination.page_size = response.data['page_size']
        this.pagination.count = response.data['count']
        this.pagination.pages = response.data['pages']
        this.pagination.page_number = response.data['page_number']
        this.isLoading = false;
      }).catch(r => {
        alert('An Error Occurred.')
        console.dir(r);
        this.isLoading = false;
      })

    },

    changePage(page){
      this.filter(page)
    },

    markAsDone(row){

      let i = localStorage.getItem('disapprovedAdsDoneItems');

      if(i){
        i = JSON.parse(i);
      }else{
        i = {}
      }

      i[row.main_entity.id] = moment().format('YYYY-DD-MM HH:mm:ss');

      localStorage.setItem('disapprovedAdsDoneItems', JSON.stringify(i));
    },

    markAsUndone(row){

      let i = localStorage.getItem('disapprovedAdsDoneItems');

      if(i){
        i = JSON.parse(i);
      }else{
        i = {}
      }

      if(row.main_entity.id in i){
        delete i[row.main_entity.id];
      }

      localStorage.setItem('disapprovedAdsDoneItems', JSON.stringify(i));
    },

    isMarkedAsDone(row){
      let i = localStorage.getItem('disapprovedAdsDoneItems');

      if(i){
        i = JSON.parse(i);
      }else{
        i = {}
      }

      return row.main_entity.id in i;
    },

    deleteMainEntity(row){
      let x = confirm('Are you sure you want delete this ad?');

      if(!x){
        return;
      }

      deleteRequest({
        'network': {
          'code': 'FBA'
        },
        'route_hash': {
          'livemainentity': {
            'id': row.main_entity.id
          },
          'network': {
            'code': 'FBA'
          },
        }
      }).then(() => {
        SnackbarProgrammatic.open('Sent for deletion!')
      }).catch(() => {
        alert('An error occurred.')
      })
    },
    disabledFoundKeywordImage(selectedImage){

      creationFoundKeywordImagesService.patch(selectedImage['found_keyword_image_id'], {
        'is_active': false
      }).then(() => {
        SnackbarProgrammatic.open('Image unlinked!')
      }).catch(() => {
        alert('An error occurred.')
      })

    }
  }
}
</script>
