<template>
    <div>
        <section class="modal-card-body p-4">
            <b-table class="table" :data="access" hoverable bordered>
                <b-table-column field="agency_feed" label="Agency Feed" v-slot="props">
                    {{ props.row.agency_feed }}
                </b-table-column>
                <b-table-column field="networks" label="Networks" v-slot="props">
                    {{ props.row.networks.join(' - ') }}
                </b-table-column>
                <b-table-column field="profit_share" label="Profit share" v-slot="props">
                    {{ renderPercentage(props.row.profit_share, 2) }}
                </b-table-column>
            </b-table>
        </section>
        <footer class="modal-card-foot p-3">
            <b-button
                label="Close"
                @click="$emit('close')" />
        </footer>
    </div>
</template>

<script>
import {numberUtils} from "@/helpers/number-utils";
import { mapState } from 'vuex'
export default {
    name: 'InternalAgencyPageAccessModal',

    props: {
        id: {
            type: String,
            required: true,
        }
    },

    mixins: [
        numberUtils,
    ],

    created() {
        if(!this.internalAgencyManager.files.length) this.$store.dispatch('internalAgencyManager/getFiles')
    },

    computed: {
        ...mapState([
            'internalAgencyManager',
        ]),

        access() {
            return this.internalAgencyManager.all.map(a => {if (a.id === this.id) return a.access}).filter(r=>r)[0]
        },
    },

}
</script>