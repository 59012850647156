<template>
  <div>
    <section class="section is-main-section">
      <b-field message="Keyword">
          <b-input v-model="keywordName" placeholder="Enter a keyword"></b-input>
          <b-select placeholder="Select a network" v-model="selectedNetworkCode">
            <option
                v-for="option in networks"
                :value="option.id"
                :key="option.id">
                {{ option.name }}
            </option>
        </b-select>
          <p class="control">
              <b-button type="is-primary" @click="search">Search</b-button>
          </p>
      </b-field>
      <div style="width: 100%; height: 98%;">
      <b-carousel :autoplay="false" indicator-custom :indicator-inside="false" :overlay="false" @click="switchGallery(true)">

        <b-carousel-item v-for="(item, i) in toolsImageSearcher.all" :key="i">
            <a class="image">
              <img :draggable="false" :src="item.url" :title="i" style="max-width: 800px; max-height: 800px;">
            </a>
        </b-carousel-item>

        <span v-if="toolsImageSearcher.all" @click="switchGallery(false)" class="modal-close is-large"/>
        <template #indicators="props">
            <figure class="al image" :draggable="false">
                <img :draggable="false" :src="getImgUrl(props.i)" :title="props.i">
            </figure>
        </template>

        </b-carousel>
      </div>
    </section>
  </div>
</template>

<script>


import {mapState} from "vuex";

export default {
  name: 'ImageSearcherPage',
  created() {
  },
  components: {
  },
  data () {

    return {
      keywordName: '',
      selectedNetworkCode: 'FBA',

      networks: [
        {id: 'FBA', name: 'FBA'}
      ],

    }
  },
  computed: {
    ...mapState([
      'toolsImageSearcher',
    ]),
  },
  methods: {
    search () {
      let keywordName = this.keywordName,
          networkCode = this.selectedNetworkCode;

      if(keywordName === ''){
        alert('Keyword cannot be empty.')
        return;
      }

      this.$store.dispatch('toolsImageSearcher/search', {
        'keywordName': keywordName,
        'networkCode': networkCode,
      });
    },
    getImgUrl(value) {
      let url = this.toolsImageSearcher.all[value].url_thumbnail;
      // Remove AWS token
      if(url.indexOf('?') > -1){
        url = url.split('?')
        url = url[0];
      }

      return url;
    },
    switchGallery(value) {
        if (value) {
            return document.documentElement.classList.add('is-clipped')
        } else {
            return document.documentElement.classList.remove('is-clipped')
        }
    }
  }
}
</script>
