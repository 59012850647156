<template>
    <div>
        <section class="modal-card-body p-4">
            <multiselect
                v-model="selectedSubdomain"
                @input="subdomainLocalesChanged"
                :options="setGroup(filteredSubdomainLocales, 'subdomainLocales')"
                group-label="label"
                group-values="all"
                :group-select="true"
                :multiple="true"
                :close-on-select="true"
                :loading="subdomainLocales.loading"
                placeholder="Available Subdomain locales"
                label="label"
                track-by="id">
          </multiselect>
        </section>
        <footer class="modal-card-foot p-3">
            <b-button
                label="Close"
                @click="$emit('close')" />
            <b-button
                :loading="submitLoading"
                label="Save"
                type="is-primary"
                @click="handelSetLimit" />
        </footer>
</div>
</template>

<script>
import {
  administrationAvailableSubdomainLocalesService,
  creationFoundKeywordNetworkUserService,
} from "@/services";
import Multiselect from 'vue-multiselect'
import {permissionsUtils} from "@/helpers/permissions-utils";

export default {
    name: 'LaunchingQueuePageSubdomainLocalesModal',

    data() {
        return {
            subdomainLocales: {
                all: [],
                loading: false,
            },
            selectedSubdomain: [],
            trackSelectLabel: {},
            submitLoading: false,
        }
    },

    props: {
        row: {
            type: Object,
            required: true,
        },
        id: {
            type: [String, Number],
            required: true
        }
    },

    components: {
        Multiselect,
    },

    mixins: [
        permissionsUtils,
    ],

    created() {
        if(this.id === this.row.id) {
            this.subdomainLocales.loading = true
            console.log('start loading')
            administrationAvailableSubdomainLocalesService.getAll({
                'network': this.row.network.id,
                'languages': this.row.languages.map((element) => { return element.id }).join(','),
                'country': this.row.country.id,
                'agency_feed__in': this.row.agency_feeds.map((element) => { return element.id }).join(','),
            }).then((r) => {
                this.subdomainLocales.loading = false
                this.subdomainLocales.all = r.data.results
            }).catch((r) => {
                this.subdomainLocales.loading = false
                alert('An error occurred while trying to load Available subdomain locales');
                console.dir(r);
            })
        }
    },

    computed: {
        filteredSubdomainLocales() {
            return this.subdomainLocales.all.map(s => {
              return {
                id: s.subdomain_locale.id,
                label: `${s.subdomain_locale.country.code} - ${s.subdomain_locale.language.code} - ${s.subdomain_locale.product[this.getFieldCodeLabel()]}`
              }
            })
        },
    },

    methods: {
        handelSetLimit() {
            this.submitLoading = true
            creationFoundKeywordNetworkUserService.patch(this.id, {
                subdomain_locales: this.selectedSubdomain.map(m => m.id)
            })
            .then(() => {
                this.submitLoading = false
                //notify the user
                this.$buefy.snackbar.open({
                    duration: 10000,
                    message: 'subdomain locales are updated successfully',
                    queue: false,
                });
                this.$emit('close')
            })

        },

        subdomainLocalesChanged() {
            this.trackSelectLabel['subdomainLocales'] = this.filteredSubdomainLocales.length !== this.selectedSubdomain.length
        },

        setGroup(all, type, isSingle = false) {
            if(isSingle) return all;

            if(this.trackSelectLabel[type] === undefined) this.trackSelectLabel[type] = true
            return [{label: this.trackSelectLabel[type] ? 'Select all' : 'Unselect all', all}]
        },
    }
}
</script>