<template>
    <section class="section is-main-section">
      <h2>Traffic Chart</h2>
      <dashboard-traffic-chart></dashboard-traffic-chart>
    </section>
</template>
<script>
import DashboardTrafficChart from "@/components/Charts/DashboardTrafficChart";
export default {
  name: 'DashboardTrafficChartPage',
  components: {DashboardTrafficChart},
}
</script>