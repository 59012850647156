import {currenciesService} from "@/services/administration-available-account-currencies.service";

export const currencies = {
    namespaced: true,
    state: {
        all: [],
        loading: false,
    },
    actions: {
        getAll({ commit }, filters) {
            commit('getAllRequest');
            currenciesService.getAll(filters)
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data.data;
            state.loading = false;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
        }
    }
}
