<template>
  <card-component
      title="KPI"
      icon="monitor-dashboard"
      class="px-1 pb-1"
      customHeaderClass="px-1"
  >

    <b-loading :is-full-page="false" v-model="dashboardsKpi.loading" />

    <template v-slot:toolbar-right>
      <b-field class="mt-3">
        <b-datepicker
            size="is-small"
            placeholder="Date..."
            v-model="selectedDate"
            class="move-dropdown-to-right"
            range>
          <b-button
              label="This Month"
              type="is-primary"
              icon-left="calendar-today"
              @click="setSelectedDate('THIS_MONTH', 'dailyChartSelectedDate')"/>
          <b-button
              label="Previous Month"
              type="is-primary"
              icon-left="calendar"
              @click="setSelectedDate('PREVIOUS_MONTH', 'dailyChartSelectedDate')"/>
        </b-datepicker>
      </b-field>
      <a
          href="#"
          class="card-header-icon"
          aria-label="more options"
          @click.prevent="loadData"
      >
        <b-icon icon="reload" custom-size="default"/>

      </a>
    </template>
    <div v-if="dataNormalized" class="chart-area" style="max-height: 20rem; overflow: auto;">
      <table class="table is-narrow is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th></th>
            <th v-for="(header, rowId) in dataNormalized['headers']" v-bind:key="`header-${rowId}`" class="has-text-centered">
              <span v-html="formatHeader(header)"></span>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>
              Cost
            </td>
            <td v-for="(val, rowId) in dataNormalized['cost']" v-bind:key="`cost-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Revenue
            </td>
            <td v-for="(val, rowId) in dataNormalized['revenue']" v-bind:key="`revenue-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Profit
            </td>
            <td v-for="(val, rowId) in dataNormalized['sum_profit']" v-bind:key="`profit-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['roi']" v-bind:key="`roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Amount in Profit
            </td>
            <td v-for="(val, rowId) in dataNormalized['amount_in_profit']" v-bind:key="`amount_in_profit-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Amount in Profit (ROI)
            </td>
            <td v-for="(val, rowId) in dataNormalized['amount_in_profit_roi']" v-bind:key="`amount_in_profit_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Amount in Loss
            </td>
            <td v-for="(val, rowId) in dataNormalized['amount_in_loss']" v-bind:key="`amount_in_loss-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Amount in Loss (ROI)
            </td>
            <td v-for="(val, rowId) in dataNormalized['amount_in_loss_roi']" v-bind:key="`amount_in_loss_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords in Profit
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_in_profit']" v-bind:key="`keywords_in_profit-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords in Loss
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_in_loss']" v-bind:key="`keywords_in_loss-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords Aged &gt; 31 days
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_gt_31_count']" v-bind:key="`keywords_aged_gt_31_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              &gt; 31 - Cost
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_gt_31_cost']" v-bind:key="`keywords_aged_gt_31_cost-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              &gt; 31 - Revenue
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_gt_31_revenue']" v-bind:key="`keywords_aged_gt_31_revenue-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              &gt; 31 - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_gt_31_roi']" v-bind:key="`keywords_aged_gt_31_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords Aged between 14 and 30 days
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_30_14_count']" v-bind:key="`keywords_aged_range_30_14_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              14 &gt; 30 - Cost
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_30_14_cost']" v-bind:key="`keywords_aged_range_30_14_cost-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              14 &gt; 30 - Revenue
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_30_14_revenue']" v-bind:key="`keywords_aged_range_30_14_revenue-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              14 &gt; 30 - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_30_14_roi']" v-bind:key="`keywords_aged_range_30_14_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords Aged between 7 and 13 days
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_13_7_count']" v-bind:key="`keywords_aged_range_13_7_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              7 &gt; 13 - Cost
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_13_7_cost']" v-bind:key="`keywords_aged_range_13_7_cost-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              7 &gt; 13 - Revenue
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_13_7_revenue']" v-bind:key="`keywords_aged_range_13_7_revenue-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              7 &gt; 13 - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_13_7_roi']" v-bind:key="`keywords_aged_range_13_7_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords Aged between 3 and 6 days
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_6_3_count']" v-bind:key="`keywords_aged_range_6_3_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              3 &gt; 6 - Cost
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_6_3_cost']" v-bind:key="`keywords_aged_range_6_3_cost-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              3 &gt; 6 - Revenue
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_6_3_revenue']" v-bind:key="`keywords_aged_range_6_3_revenue-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              3 &gt; 6 - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_range_6_3_roi']" v-bind:key="`keywords_aged_range_6_3_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords Aged less 2 days
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_lt_2_count']" v-bind:key="`keywords_aged_lt_2_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              &lt; 2 - Cost
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_lt_2_cost']" v-bind:key="`keywords_aged_lt_2_cost-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              &lt; 2 - Revenue
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_lt_2_revenue']" v-bind:key="`keywords_aged_lt_2_revenue-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              &lt; 2 - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_aged_lt_2_roi']" v-bind:key="`keywords_aged_lt_2_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords with revenue lte 50$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_lte_50_usd_count']" v-bind:key="`keywords_with_revenue_lte_50_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords with revenue lte 50$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_lte_50_usd_roi']" v-bind:key="`keywords_with_revenue_lte_50_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords with revenue 51$ &gt; 100$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_51_and_100_usd_count']" v-bind:key="`keywords_with_revenue_between_51_and_100_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords with revenue 51$ &gt; 100$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_51_and_100_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_51_and_100_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords with revenue 101$ &gt; 150$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_101_and_150_usd_count']" v-bind:key="`keywords_with_revenue_between_101_and_150_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords with revenue 101$ &gt; 150$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_101_and_150_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_101_and_150_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 151$ &gt; 200$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_151_and_200_usd_count']" v-bind:key="`keywords_with_revenue_between_151_and_200_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 151$ &gt; 200$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_151_and_200_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_151_and_200_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 201$ &gt; 250$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_201_and_250_usd_count']" v-bind:key="`keywords_with_revenue_between_201_and_250_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 201$ &gt; 250$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_201_and_250_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_201_and_250_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords with revenue 251$ &gt; 300$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_251_and_300_usd_count']" v-bind:key="`keywords_with_revenue_between_201_and_250_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>

          <tr>
            <td>
              Keywords with revenue 251$ &gt; 300$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_251_and_300_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_201_and_250_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 301$ &gt; 400$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_301_and_400_usd_count']" v-bind:key="`keywords_with_revenue_between_301_and_400_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 301$ &gt; 400$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_301_and_400_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_301_and_400_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 401$ &gt; 500$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_401_and_500_usd_count']" v-bind:key="`keywords_with_revenue_between_401_and_500_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 401$ &gt; 500$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_401_and_500_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_401_and_500_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 501$ &gt; 750$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_501_and_750_usd_count']" v-bind:key="`keywords_with_revenue_between_501_and_750_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 501$ &gt; 750$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_501_and_750_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_501_and_750_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 751$ &gt; 1000$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_751_and_1000_usd_count']" v-bind:key="`keywords_with_revenue_between_751_and_1000_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue 751$ &gt; 1000$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_between_751_and_1000_usd_roi']"
                v-bind:key="`keywords_with_revenue_between_751_and_1000_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue &gt; 1001$
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_gte_1001_usd_count']" v-bind:key="`keywords_with_revenue_gte_1001_usd_count-${rowId}`" class="has-text-centered">
              {{ val }}
            </td>
          </tr>


          <tr>
            <td>
              Keywords with revenue &gt; 1001$ - ROI
            </td>
            <td v-for="(val, rowId) in dataNormalized['keywords_with_revenue_gte_1001_usd_roi']"
                v-bind:key="`keywords_with_revenue_gte_1001_usd_roi-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              CTR(R)
            </td>
            <td v-for="(val, rowId) in dataNormalized['ctr_r']" v-bind:key="`ctr_r-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              CTR(C)
            </td>
            <td v-for="(val, rowId) in dataNormalized['ctr_c']" v-bind:key="`ctr_c-${rowId}`" class="has-text-centered">
              {{ renderPercentage(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              Bid Amount
            </td>
            <td v-for="(val, rowId) in dataNormalized['bid_amount']" v-bind:key="`bid_amount-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 2) }}
            </td>
          </tr>


          <tr>
            <td>
              CPA(C)
            </td>
            <td v-for="(val, rowId) in dataNormalized['cpa_c']" v-bind:key="`cpa_c-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 3) }}
            </td>
          </tr>


          <tr>
            <td>
              CPA(R)
            </td>
            <td v-for="(val, rowId) in dataNormalized['cpa_r']" v-bind:key="`cpa_r-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 3) }}
            </td>
          </tr>


          <tr>
            <td>
              RPC(R)
            </td>
            <td v-for="(val, rowId) in dataNormalized['rpc_r']" v-bind:key="`rpc_r-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 3) }}
            </td>
          </tr>

          <tr>
            <td>
              RPC(C)
            </td>
            <td v-for="(val, rowId) in dataNormalized['rpc_c']" v-bind:key="`rpc_c-${rowId}`" class="has-text-centered">
              {{ renderCurrency(val, 3) }}
            </td>
          </tr>

        </tbody>

      </table>

    </div>

  </card-component>
</template>
<script>
import CardComponent from '@/components/CardComponent'
import moment from "moment";
import {mapState} from "vuex";
import {numberUtils} from "@/helpers/number-utils";
import {timeUtils} from "@/helpers/time-utils";

export default {
  name: 'DashboardKpi',
  components: {
    CardComponent,
  },
  mixins: [
    numberUtils,
    timeUtils
  ],
  computed: {
    ...mapState([
      'dashboardsKpi',
    ]),
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      selectedDate: [moment().subtract(1, 'day').toDate(),moment().subtract(1, 'day').toDate()],
      dataNormalized: {}
    }
  },

  methods: {
    loadData() {
      let that = this;
      this.$store.dispatch('dashboardsKpi/getByDateRange', {
        'date__range': moment(this.selectedDate[0]).format('YYYY-MM-DD') + ',' + moment(this.selectedDate[1]).format('YYYY-MM-DD'),
        })
        .then(response => {
          that.dataNormalized = that.normalizeData(response.data.results);
        })
    },

    normalizeData(data) {
      let obj = {
            headers: []
          }
      ;

      for(let i in data) {
        let header = data[i]['date_val'] + '||' + data[i]['network_code'] + '||' + data[i]['agency_feed'];
        obj.headers.push(header);

        for(let ii in data[i]){
          let columnName = ii,
              columnValue = data[i][ii];

          if(['date_val', 'network_code', 'agency_feed'].indexOf(columnName) > -1){
            continue;
          }

          if(!(ii in obj)){
            obj[columnName] = []
          }

          obj[ii].push(columnValue);
        }
      }

      return obj;
    },
    formatHeader(header){
      return header.split('||').join('<br />')
    }
  }
}
</script>