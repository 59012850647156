import { config } from './config';
import axios from "axios";

export const creationNoVideosFoundKeywordsService = {
    post,
    get,
    config
};

function get(params){

    let axiosConfig = {}
    let endpoint;

    // Append to request.
    axiosConfig['params'] = params;

    endpoint = '/api/creation/no-videos-found-keywords/?lock_minutes=10';

    endpoint += '&nocache=' + Date.now();

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function post(params){

    let endpoint;

    endpoint = '/api/creation/no-videos-found-keywords/';

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}${endpoint}`, params)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

