<style lang="scss" scoped>

.video-unselected {
  border: 2px solid #FFF;
}

.video-selected {
  border: 2px solid blue;
}

.video-success {
  border: 2px solid darkgreen;
  opacity: 0.3;
}

.video-error {
  border: 2px solid red;
  opacity: 0.3;
}

.previously-selected {
  float: left;
  margin: 3px;
  cursor: pointer;
  img {
    width: 90px;
    height: 50px;
    background-color: #ddd;
  }
}

.previously-selected-modal {
  width: 100%;
  height: 100%;
  background-color: azure;
}

.modal-videos {
  width: 210px;
  height: 120px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  span.remove {
    position: absolute;
    z-index: 1;
    right: 2px;
    top: 1px;
    &:hover {
      cursor: pointer;
    }
    > span.icon {
      background-color: whitesmoke;
      border-radius: 50%;
    }
  }
  img {
    width: 100%;
    height: 100%;
    background-color: #ddd;
  }
}

.repo-selected-videos {
  img {
    width: 36px;
    height: 36px;
    margin-right: 1px;
    cursor: pointer;
  }
}
.dropdown-options {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: aliceblue;
  z-index: 1;
  width: 15px;
  border-radius: 12px;
  &:hover {
    span.icon {
      cursor: pointer;
    }
  }
  span.icon {
    width: 15px;
    color: #363636;
  }
  .selected-dropdown-item {
    &.dropdown-item {
      background-color: #dbdbdb;
    }
  }
  label {
    display: block;
    font-size: calc(0.75rem * 3 / 4);
    font-weight: 600;
    margin-left: 15px;
  }
}
.preview-selected-vdo-main-screen {
  img {
    max-width: 60px;
    min-height: 36px;
    margin-right: 2px;
    background-color: #ddd;
    cursor: pointer;
  }
}

/* Firefox old*/
@-moz-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

/* Opera and prob css3 final iteration */
@keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

.video-is-loading {
  -moz-animation: blink normal 3s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 3s infinite ease-in-out; /* Webkit */
  animation: blink normal 3s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

</style>
<template>
  <div>
    <div v-if="previouslySelectedVideosList.length" class="mb-2">
      <h4> Previously Selected: {{ 10 - limit  }}</h4>
      <div v-for="(vdo, index) in previouslySelectedVideosList"
          :key="'previouslySelected_' + index"
          class="previously-selected">
        <img :src="getImgUrl(vdo)" @click="previouslySelectedModal.index = index; previouslySelectedModal.show = true" />
      </div>
      <b-modal v-model="previouslySelectedModal.show">
        <img :src="getImgUrl(previouslySelectedVideosList[previouslySelectedModal.index])" class="is-block mx-auto previously-selected-modal">
      </b-modal>
      <div class="is-clearfix"></div>
    </div>  

    <b-field class="mb-1">
      <b-input
          placeholder="Search..."
          style="max-width: 100%; min-width: 50rem;"
          @keyup.native.enter="search"
          v-model="searchVideoQuery"
          type="search"></b-input>
      <p class="control">
        <b-button class="button is-primary" @click="search" :disabled="isLoadingPicassoFreeVideos">Search!</b-button>
      </p>
    </b-field>
    <div class="mb-1 is-flex is-justify-content-space-between is-align-items-center">
      <div>
          <template v-if="copyPasteEnabled">
            <b-button :disabled="!selectedVideos.length" type="is-warning" class="mr-1" size="is-small" @click="copy">Copy Selected videos</b-button>
            <b-button v-if="showPasteBtn" class="mr-1" type="is-warning" size="is-small" @click="paste">Paste Selected videos</b-button>
          </template>
          <template v-if="reposEnabled">
            <b-button type="is-success" size="is-small" class="mr-1" v-for="(repo, index) in userStorage.repos" :key="index" @click="showRepoModal = true; selectedRepoIndex = index; excludedVideos = []">{{ repo.value.name }} ({{ repo.value.items.length }})</b-button>
            <b-modal v-model="showRepoModal" v-if="userStorage.repos[selectedRepoIndex]">
              <div class="card" style="max-width:878px">
                <div class="card-content p-2">
                  <div v-if="filteredRepoVideos.length" class="is-flex">
                    <b-button size="is-small" type="is-success" class="is-block mb-1 mr-1" @click="useRepo()" >Use repository</b-button>
                    <b-button size="is-small" type="is-danger" @click="deleteRepo">Delete repository</b-button>
                  </div>
                  <div v-else class="notification is-warning is-light">no videos found.</div>
                  <div v-if="repoSelectedVideos.length" class="repo-selected-videos mb-1">
                    <b-tooltip label="remove" position="is-right">

                      <img v-for="(vdo, index) in repoSelectedVideos" :key="index" :src="vdo.url" @click="removeRepoSelectedVideo(vdo)" />
                    </b-tooltip>
                  </div>
                  <div v-for="(vdo, index) in filteredRepoVideos" :key="'repoVideos_' + index" class="modal-videos">
                    <span @click="showDeleteVideoModal = true; deletedVideoIndex = index" class="remove">
                      <b-icon icon="close" size="is-small" />
                    </span>
                    <img :src="vdo['image']" />
                  </div>
                  <b-modal
                      v-model="showDeleteVideoModal"
                      has-modal-card
                      trap-focus
                      :destroy-on-hide="false"
                      aria-role="dialog"
                      aria-label="Example Modal"
                      close-button-aria-label="Close"
                      aria-modal>
                      <div class="card">
                        <header class="modal-card-head py-3">
                          <p class="modal-card-title">Deleting Video</p>
                        </header>
                        <div class="card-content py-4">
                          Delete the video permanently from the repository, <strong>or</strong> soft delete, to use the other videos
                        </div>
                        <footer class="modal-card-foot py-3">
                          <b-button
                            label="Permanent delete"
                            type="is-danger is-small"
                            @click="deletePermanentlyFromRepo()" />
                          <b-button
                            label="Soft delete"
                            type="is-warning is-small"
                            @click="removeVideoFromRepo()"
                            />
                        </footer>
                      </div>
                  </b-modal>
                </div>
              </div>
            </b-modal>
        </template>
      </div>
    <div><slot name="left-buttons-box"></slot></div>
    </div>

    Select videos: {{ selectedVideos.length }}/{{ limit }}
    <div class="preview-selected-vdo-main-screen">
      <b-tooltip v-if="selectedVideos.length" label="remove" position="is-right">
        <img v-for="(vdo, index) in selectedVideos" :key="index" :src="vdo.video_thumbnail" @click="removeRepoSelectedVideo(vdo)" />
      </b-tooltip>
    </div>
    <b-loading v-model="isLoadingPicassoFreeVideos"></b-loading>
    <div style="max-height: 600px; overflow: auto;">
      <strong>Free Videos</strong><br />
      <div v-for="(vdo, index) in picassoFreeVideosData" v-bind:key="`list-videos-free-videos-${vdo.download_link}-${searchVideoQuery}-${counter}-${vdo.hashUnique}`"
           style="max-width: 300px; min-height: 650px; float: left; margin: 3px; position: relative">
        <b-dropdown v-if="isSelectedVideo(vdo.hashUnique)" aria-role="menu" class="is-pulled-right dropdown-options" position="is-bottom-left">
          <template #trigger>
              <b-icon icon="dots-vertical" size="is-small" />
          </template>

          <b-dropdown-item custom aria-role="listitem">
            <b-field label="Create new repository" label-position="on-border">
              <b-input v-model="newRepoName" placeholder="Create new repo" size="is-small"/>
              <p class="control">
                <b-button @click="createRepo" :disabled="!newRepoName.trim()" class="button is-primary" size="is-small" :loading="userStorage.loading">Save</b-button>
              </p>
            </b-field>
          </b-dropdown-item>
          
          <label>Add video to:</label>
          <b-dropdown-item v-for="(repo, dropIndex) in userStorage.repos" :key="dropIndex" @click="addToRepo(repo, vdo)" :class="highlightSelectedRepo(repo, vdo) ? 'selected-dropdown-item' : null" aria-role="listitem">
            <span class="ml-1">
              {{ repo.value.name }} ({{ repo.value.items.length }})
            </span>

          </b-dropdown-item>
        </b-dropdown>

        <div :class="{
                'video-unselected': true,
                'video-success': vdo['uploadedIsSuccess'] === true,
                'video-error': vdo['uploadedIsSuccess'] === false,
                'video-is-loading': vdo['isLoading'] === true,
              }">
          <b-button @click="selectFreeVideo(vdo, index)" type="is-primary" size="is-small" :disable="vdo['uploadedIsSuccess'] === true || vdo['isLoading'] === true">Select this video</b-button>
          <video width="470" height="255" :poster="vdo['image']" controls>
            <source :src="vdo['preview_link']" type="video/mp4">
            <source :src="vdo['preview_link']" type="video/ogg">
            <source :src="vdo['preview_link']" type="video/webm">
            <object :data="vdo['preview_link']" width="470" height="255">
            <embed :src="vdo['preview_link']" width="470" height="255"></object>
        </video>
        </div>
      </div>
      <div class="is-clearfix"></div>
      <div v-if="!isLoadingPicassoFreeVideos && picassoFreeVideosData.length === 0">
        <b-message
          title="No Free Videos Found"
          type="is-danger"
          aria-close-label="Close message">
          No Free videos were found, try using different words to find more videos. <br />
          <strong>Tip:</strong> Use synonymous or similar meaning words.
        </b-message>
      </div>
    </div>
    </div>
</template>

<script>

import {toolsVideoSearcherService} from "@/services";
import vue from "vue";
import { mapState } from "vuex";

export default {
  name: 'VideoPicker',
  props: {
    defaultSearchTerm: {
      type: String,
      required: true,
    },
    originalKeyword: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 1
    },
    copyPasteEnabled: {
      type: Boolean,
      default: false,
    },
    reposEnabled: {
      type: Boolean,
      default: true,
    },
    previouslySelectedVideosList: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      searchVideoQuery: '',
      isLoadingPicassoFreeVideos: false,
      picassoFreeVideosData: [],
      uploadingFreeVideos: [],

      selectedVideos: [],

      counter: 0,
      repos: [],
      newRepoName: '',
      showRepoModal: false,
      selectedRepoIndex: 0,
      excludedVideos: [],
      showDeleteVideoModal: false,
      deletedVideoIndex: 0,

      previouslySelectedModal: {
        show: false,
        index: 0,
      }
    }
  },

  created() {
    this.searchVideoQuery = this.defaultSearchTerm;
    if(this.searchVideoQuery.length > 0){
      this.search();
    }

    if(!this.userStorage.repos.length) this.$store.dispatch('userStorage/getRepos', {user: this.$store.state.theme.userId});
  },

  computed: {
    ...mapState(['userStorage']),

    showPasteBtn() {
      return this.$store.state.theme.videoPickerShowPasteBtn
    },

    filteredRepoVideos() {
      const newList = [...this.userStorage.repos[this.selectedRepoIndex]['value']['items']]
      if(!this.excludedVideos.length) return newList
      
      this.excludedVideos.forEach((ex) => {
        newList.forEach((s, index) => {
          if(ex.hash_unique === s.hash_unique) {
            newList.splice(index, 1)
            return false
          }
        })
        return false
      })
      return newList
    },
    
    repoSelectedVideos() {
      return this.selectedVideos.map(i => {
        let s = this.filteredRepoVideos.find(r => i.hash_unique === r.hash_unique)
        return s
      }).filter(r=>r)
    }
  },

  methods: {
    search() {

      if(this.searchVideoQuery === ''){
        return alert('Error: Use write something in order to search');
      }

      this.searchPicassoFreeVideos(
          this.searchVideoQuery
      )

    },


    searchPicassoFreeVideos(keyword) {
      let that = this;

      this.isLoadingPicassoFreeVideos = true;
      this.picassoFreeVideosData = [];

      toolsVideoSearcherService.searchFreeVideos(keyword)
          .then(
              response => {
                that.isLoadingPicassoFreeVideos = false;
                that.picassoFreeVideosData = response.data;
              },
              error => {
                this.isLoadingPicassoFreeVideos = false;
                alert('An error happens while searching on free videos repository.');
                console.error(error);
              }
          );

    },


    selectFreeVideo(vdo, index){

      let that = this,
          url = vdo['download_link'],
          thumbnailVideo = vdo['image'],
          keywordName = this.originalKeyword;

      if(vdo['isLoading']){
        alert('Video upload is loading please wait.')
        return;
      }

      if(vdo['uploadedIsSuccess']){
        this.removeVideo(
          this.picassoFreeVideosData[index]['hashUnique']
        );
        return;
      }

      //prevent selection over the limit
      if(this.selectedVideos.length >= this.limit){
        return alert('You can select maximum ' + this.limit + ' videos, please save and move on to the next keyword.');
      }

      this.uploadingFreeVideos.push(url);

      vue.set(this.picassoFreeVideosData[index], 'isLoading', true);
      vue.set(this.picassoFreeVideosData[index], 'uploadedIsSuccess', null);

      toolsVideoSearcherService.uploadByUrl(
          keywordName,
          thumbnailVideo,
          url
      ).then(
              response => {
                that.selectVideo(response.data);
                vue.set(vdo, 'hashUnique', response.data['hash_unique']);
                vue.set(this.picassoFreeVideosData[index], 'hashUnique', response.data['hash_unique']);
                delete this.uploadingFreeVideos[index];
                vue.set(this.picassoFreeVideosData[index], 'isLoading', false);
                vue.set(this.picassoFreeVideosData[index], 'uploadedIsSuccess', true);
              },
              error => {
                delete this.uploadingFreeVideos[index];
                vue.set(this.picassoFreeVideosData[index], 'isLoading', false);
                vue.set(this.picassoFreeVideosData[index], 'uploadedIsSuccess', false);
                alert('An error happens while saving: ' + url);
                console.error(error);
              }
          );
    },

    selectVideo(vdo){

      let key = vdo.hash_unique;

      if(this.selectedVideos.length >= this.limit){
        return alert('You can select maximum ' + this.limit + ' videos, please save and move on to the next keyword.');
      }

      for(let i in this.selectedVideos){
        if(this.selectedVideos[i].hash_unique === key){
          return this.$buefy.snackbar.open({
              message: 'Video already selected',
            });
        }
      }

      this.selectedVideos.push(vdo);

      this.selectedVideosChanged()
    },

    removeVideo(hashUnique){
      let that = this;

      for(let i in this.selectedVideos){
        if(this.selectedVideos[i].hash_unique === hashUnique){
          vue.delete(this.selectedVideos, i);
          break;
        }
      }

      for(let index in this.picassoFreeVideosData){
        if(this.picassoFreeVideosData[index].hashUnique === hashUnique){
          this.$nextTick(()=>{
            vue.set(that.picassoFreeVideosData[index], 'isLoading', false);
            vue.set(that.picassoFreeVideosData[index], 'uploadedIsSuccess', null);
          })
          break;
        }
      }

      this.selectedVideosChanged()
    },

    selectedVideosChanged(){
      this.$emit('input', this.selectedVideos);
    },

    createRepo() {
      if(!this.newRepoName) return;

      this.$store.dispatch('userStorage/createRepo', {
        type: 'IMAGE_REPOSITORY',
        user: this.$store.state.theme.userId,
        value: {name: this.newRepoName.trim(), items: []},
      })

      this.newRepoName = ''
    },

    addToRepo(repo, vdo) {
      //Avoid video duplication
      const alreadyInRepo = repo.value.items.find(i => i.hash_unique === vdo.hashUnique)
      if (!alreadyInRepo) {
        repo.value.items.push({url: vdo.url, hash_unique: vdo.hashUnique})

        this.$store.dispatch('userStorage/updateRepo', {
          type: 'IMAGE_REPOSITORY',
          user: this.$store.state.theme.userId,
          value: repo.value,
          id: repo.id,
        })
      }
    },

    useRepo() {
      if(this.filteredRepoVideos.length > this.limit) {
        this.showRepoModal = false
        return alert('You can select maximum ' + this.limit + ' videos (selected repository has ' + this.filteredRepoVideos.length +' videos)')
      }
      if(this.selectedVideos.length >= this.limit) return this.showRepoModal = false
      
      //Avoid video duplication
      const _filteredRepoVideos = this.filteredRepoVideos.map(fri => {
        const isAlreadySelected = this.selectedVideos.find(si => si.hash_unique == fri.hash_unique)
        if(!isAlreadySelected) return fri
      }).filter(r=>r)
      this.selectedVideos.push(..._filteredRepoVideos)

      this.selectedVideosChanged()
      this.showRepoModal = false
    },

    highlightSelectedVideoInPicasso(list, target) {
      for(let i in list) {
       let alreadyInPicassoList = false
        for(let index in this.picassoFreeVideosData){
          if(this.picassoFreeVideosData[index].hashUnique === list[i].hash_unique || this.picassoFreeVideosData[index].url === list[i].url ){
            alreadyInPicassoList = true
            this.$nextTick(()=>{
              vue.set(target.picassoFreeVideosData[index], 'isLoading', false);
              vue.set(target.picassoFreeVideosData[index], 'uploadedIsSuccess', true);
              vue.set(target.picassoFreeVideosData[index], 'hashUnique', list[i].hash_unique)
            })
            break;
          }
        }
        if(!alreadyInPicassoList) {
          this.picassoFreeVideosData =
          [
            {
            hashUnique: list[i].hash_unique,
            url: list[i].url,
            isLoading: false,
            uploadedIsSuccess: true,
            },
            ...this.picassoFreeVideosData
          ]
        }
      }
    },

    deleteRepo() {
      if (confirm(`Are you sure you want to delete ${this.userStorage.repos[this.selectedRepoIndex].value.name}!`)) {
        this.$store.dispatch('userStorage/deleteRepo', {
          id: this.userStorage.repos[this.selectedRepoIndex].id,
        })
        this.showRepoModal = false
      }
    },

    isSelectedVideo(hash) {
      return this.selectedVideos.find(i => i.hash_unique === hash)
    },

    highlightSelectedRepo(repo, vdo) {
      return repo.value.items.find(i => i.hash_unique === vdo.hashUnique)
    },

    removeVideoFromRepo() {
      this.excludedVideos.push(this.filteredRepoVideos[this.deletedVideoIndex])
      this.showDeleteVideoModal = false
    },

    deletePermanentlyFromRepo() {
      const vdo = this.filteredRepoVideos[this.deletedVideoIndex]

      //do soft del
      this.removeRepoSelectedVideo(vdo)
      this.removeVideoFromRepo(vdo)
      this.showDeleteVideoModal = false

      //permanent del
      let {name, items} = this.userStorage.repos[this.selectedRepoIndex].value
      items = items.map(i => {
        if(i.hash_unique !== vdo.hash_unique) return i
      }).filter(r => r)

      const value = {name, items}
      this.$store.dispatch('userStorage/updateRepo', {
        type: 'IMAGE_REPOSITORY',
        user: this.$store.state.theme.userId,
        value,
        id: this.userStorage.repos[this.selectedRepoIndex].id,
      })
    },

    removeRepoSelectedVideo(vdo) {
      let videoIndex = this.findSelectedVideoIndex(vdo);
      if(!isNaN(videoIndex)) this.selectedVideos.splice(videoIndex, 1)

      //remove from picasso videos
      const self = this
      for(let index in this.picassoFreeVideosData){
        if(this.picassoFreeVideosData[index].hashUnique === vdo.hash_unique){
          this.$nextTick(()=>{
            vue.set(self.picassoFreeVideosData[index], 'isLoading', false);
            vue.set(self.picassoFreeVideosData[index], 'uploadedIsSuccess', null);
          })
          break;
        }
      }
    },

    findSelectedVideoIndex(vdo) {
      for (let i = 0; i < this.selectedVideos.length; i++) {
        if (this.selectedVideos[i]['hash_unique'] === vdo.hash_unique) return i;
      }
    },

    copy() {
      localStorage.setItem('videos_clipboard', '[]')
      localStorage.setItem('videos_clipboard', JSON.stringify(this.selectedVideos))
      this.$store.commit('theme/set', ['videoPickerShowPasteBtn', true])
      this.internalPasteBtnControl = false

      this.$buefy.toast.open({
          duration: 900,
          message: "Copied!",
          type: 'is-success is-small',
      })
    },

    paste() {
      const videos_clipboard = JSON.parse(localStorage.getItem('videos_clipboard'))
      if (!videos_clipboard) return;

      if(videos_clipboard.length > this.limit) {
        return alert('You can select maximum ' + this.limit + ' videos (copied videos: ' + videos_clipboard.length +')')
      }

      const self = this
      for(let ic in videos_clipboard) {
        const isInSelectedVideos = self.selectedVideos.find(si => si.hash_unique === videos_clipboard[ic].hash_unique)
        if(!isInSelectedVideos) self.selectedVideos.push(videos_clipboard[ic])
      }

      this.highlightSelectedVideoInPicasso(self.selectedVideos, this)

      //rest the clipboard //no need, cuz in case i wanna paste it more than once, but i will leave it here
      // localStorage.setItem('videos_clipboard', null)
      //this.$store.commit('theme/set', ['videoPickerShowPasteBtn', false])
      this.selectedVideosChanged()

      this.$buefy.toast.open({
          duration: 900,
          message: "Pasted!",
          type: 'is-success is-small',
      })
    },

    getImgUrl(val, mode = 'full_video'){  // size => full_video or thumbnail
      return `https://mozart-api.pytech.ai/api/proxy/picasso/video/video-opener-public/?mode=${mode}&video_hash_unique=${val['video_hash_unique']}`;
    },


  }
}

</script>
