import numeral from "numeraljs";

export function setDefaultsMainEntity(dailyPerformances, index, livemainentity) {

    let bidStep, budgetStep,
        r = dailyPerformances[index];

    function makeNumber(str) {
        return numeral(str).value();
    }

    function getExchangeRate(livemainentity) {
        let a = makeNumber(livemainentity.budget_amount_account_currency),
            b = makeNumber(livemainentity.budget_amount);
        return b / a;
    }

    function renderNumber(amount, decimals) {
        if (!amount) {
            return '0.' + ('0'.repeat(decimals));
        }

        let num = numeral(amount).format('0.' + ('0'.repeat(decimals)));
        if (!(num !== 0)) {
            return '';
        }

        return num;
    }

    r['exchangeRate'] = getExchangeRate(livemainentity);

    if(r['exchangeRate'] < 1){
        bidStep = 3;
        budgetStep = 3;
    }else{
        bidStep = 2;
        budgetStep = 0;
    }

    r['newBid'] = renderNumber(livemainentity.bid_amount, bidStep);
    r['oldBid'] = renderNumber(livemainentity.bid_amount, bidStep);

    r['newBudget'] = renderNumber(livemainentity.budget_amount, budgetStep);
    r['oldBudget'] = renderNumber(livemainentity.budget_amount, budgetStep);

    if(makeNumber(r['newBid']) === 0){
        r['newBid'] = null;
        r['oldBid'] = null;
    }
    r['selectedRowNewBiddingStrategyId'] = livemainentity.bidding_strategy;
    r['selectedRowOldBiddingStrategyId'] = livemainentity.bidding_strategy;
    r['newStatus'] = livemainentity.status === 'ACTIVE';
    r['oldStatus'] = livemainentity.status === 'ACTIVE';
    r['index'] = index;
}