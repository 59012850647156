<template>
    <div>
        <section class="section is-main-section">
            <router-link :to="{name: 'access-groups-new'}" class="button is-success mb-3 mt-1">Create New</router-link>
            <div class="card has-table has-mobile-sort-spaced p-1">
                <div class="card-content">
                    <b-table
                        :loading="accessGroups.loading"
                        :data="accessGroups.all"
                        :striped="true"
                        :hoverable="true"
                        default-sort="total_count"
                        pagination-position="top"
                        :pagination-rounded="false"
                        sort-icon="chevron-up"
                        sort-icon-size="is-medium"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"

                        :per-page="accessGroups.paging.page_size"
                        :total="accessGroups.paging.count"
                        :current-page="accessGroups.paging.page_number"

                        @page-change="changePage"
                        backend-pagination
                        paginated
                        >
                        <div slot="empty" class="notification is-warning is-light has-text-centered">
                            There's no groups yet.
                        </div>
                        <b-table-column label="Group Name" field="group_name" sortable v-slot="props">
                            {{ props.row.name }}
                        </b-table-column>

                        <b-table-column label="Users" field="users" sortable v-slot="props">
                            <ol>
                                <li v-for="(user, index) in props.row.users" :key="index"> {{ getUserName(user.user) }} ({{ user.is_hidden ? 'Is Hidden': 'Is Not Hidden' }})</li>
                            </ol>
                        </b-table-column>

                        <b-table-column label="Actions" sortable v-slot="props">
                            <b-button type="is-success is-small" @click="updateOpenedRow(props.row.id)">Edit</b-button>
                        </b-table-column>

                        <template slot="top-left">
                            <b-field>
                            <b-field label="Rows" label-position="on-border" style="width: 8rem;">
                                <b-input v-model="paging.count" size="is-small" disabled />
                            </b-field>
                            <b-field label="Page Size" label-position="on-border">
                                <b-select v-model="pageSize" size="is-small">
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                                <option>500</option>
                                <option>5000</option>
                                <option>10000</option>
                                </b-select>
                                </b-field>
                            </b-field>
                        </template>
                    </b-table>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import { mapState } from "vuex"
export default {

    name: 'AccessGroupsPage',

    created() {
        if (!this.accessGroups.all.length) this.$store.dispatch('accessGroups/getAll')
        if (!this.users.allMarketing.length) this.$store.dispatch('users/getAllMarketing')
    },

    data() {
        return {
            pageSize: 25,
            pageId: 1,
            paging: {
                page_size: 0,
                count: 0,
                pages: 1,
                page_number: 1,
                page_count: 1
            },
        }
    },

    computed: {
        ...mapState([
            'users',
            'accessGroups',
        ]),

    },

    methods: {
        changePage(pageId){
            this.pageId = pageId
        },

        updateOpenedRow(id) {
            this.$store.commit('accessGroups/openedRowId', {id})
            this.$router.push({name: 'access-groups-new'})
        },

        getUserName(id) {
            return this.users.allMarketing.find(user => user.id === id)?.username
        },
    },
}

</script>