<template>
  <div>
    <section class="section is-main-section" ref="filterstop">
        <b-field :horizontal="isMobile()">
           <b-input v-model="itemValue" />
           <b-select v-model="itemType">
             <option value="ROUTE_HASH">Route Hash</option>
             <option value="KEYWORD_NAME">Keyword</option>
           </b-select>
           <b-datepicker
              class="form-control"
              style="min-width: 15rem; max-width: 15rem;"
              placeholder="Date..."
              v-model="selectedDates"
              range>
              <b-button
              label="Yesterday"
              type="is-primary"
              icon-left="calendar"
              @click="setSelectedDates('YESTERDAY', 'selectedDates')" />
             <b-button
              label="This Month"
              type="is-primary"
              icon-left="calendar"
              @click="setSelectedDates('THIS_MONTH', 'selectedDates')" />
          </b-datepicker>
          <b-button type="is-primary" @click="view">View!</b-button>
        </b-field>

      </section>

      <section class="section is-main-section" style="padding-top: 0;">
        <b-loading v-model="isLoading"></b-loading>
          <line-chart
            ref="chartPerformanceData"
            style="height: 20rem;"
            chart-id="big-line-chart1"
            :chart-data="chartPerformanceData"
            :extra-options="extraOptions"
          >
          </line-chart>

          <line-chart
            ref="chartROIData"
            style="height: 10rem;"
            chart-id="big-line-chart2"
            :chart-data="chartROIData"
            :extra-options="extraOptions"
          >
          </line-chart>

          <line-chart
            ref="chartCPARPCData"
            style="height: 15rem;"
            chart-id="big-line-chart3"
            :chart-data="chartCPARPCData"
            :extra-options="extraOptions"
          >
          </line-chart>

          <line-chart
            ref="chartClicksData"
            style="height: 15rem;"
            chart-id="big-line-chart4"
            :chart-data="chartClicksData"
            :extra-options="extraOptions"
          >
          </line-chart>

          <line-chart
            ref="chartCTRData"
            style="height: 15rem;"
            chart-id="big-line-chart5"
            :chart-data="chartCTRData"
            :extra-options="extraOptions"
          >
          </line-chart>

          <b-table
              :data="dataOptimization"

              :striped="true"
              :hoverable="true"
              :loading="isLoadingOptimization"
              :sticky-header="true"

              default-sort-direction="DESC"
              sort-icon="chevron-up"
              sort-icon-size="is-medium"
              default-sort="created_on_datetime"

              :scrollable="true"
              height="360"
          >
              <b-table-column field="created_on_datetime" label="Date" sortable>
                <template v-slot:header="{ column }">
                  <span>{{ column.label }}</span><br />
                  -
                </template>
                <template v-slot="props">{{ props.row.created_on_datetime  }}</template>
              </b-table-column>

              <b-table-column field="optimization_type" label="Type" sortable>
                <template v-slot:header="{ column }">
                  <span>{{ column.label }}</span><br />
                </template>
                <template v-slot="props">{{ props.row.optimization_type.code }}</template>
              </b-table-column>

              <b-table-column field="old_value" label="Old" sortable>
                <template v-slot:header="{ column }">
                  <span>{{ column.label }}</span><br />
                </template>
                <template v-slot="props">{{ props.row.old_value }}</template>
              </b-table-column>

              <b-table-column field="new_value" label="New" sortable>
                <template v-slot:header="{ column }">
                  <span>{{ column.label }}</span><br />
                </template>
                <template v-slot="props">{{ props.row.new_value }}</template>
              </b-table-column>

              <b-table-column field="message" label="message" sortable>
                <template v-slot:header="{ column }">
                  <span>{{ column.label }}</span><br />
                </template>
                <template v-slot="props">{{ props.row.message }}</template>
              </b-table-column>

              <b-table-column field="current_status" label="current_status" sortable>
                <template v-slot:header="{ column }">
                  <span>{{ column.label }}</span><br />
                </template>
                <template v-slot="props">{{ props.row.current_status }}</template>
              </b-table-column>

          </b-table>
      </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from "numeral"
// import * as chartConfig from '@/components/Charts/chart.config'
// import TitleBar from '@/components/TitleBar'
// import HeroBar from '@/components/HeroBar'
// import Tiles from '@/components/Tiles'
import {mapState} from "vuex";
// import moment from 'moment';
// import CardWidget from '@/components/CardWidget'
// import CardComponent from '@/components/CardComponent'
// import LineChart from '@/components/Charts/LineChart'
// import ClientsTableSample from '@/components/ClientsTableSample'
// import Multiselect from 'vue-multiselect'
// import Vue from "vue";
import moment from "moment";
import {numberUtils} from "@/helpers/number-utils";
import {timeUtils} from "@/helpers/time-utils";
import {getAllByRouteHashDateRange, getAllPerformanceAnalysis} from "@/services";
import LineChart from '@/components/Charts/LineChart'

export default {
  name: 'PerformanceAnalysisPage',
  components: {
    LineChart
  },

  created() {

    let isFiltered = false;

    if(this.$route.query.itemType){
      this.itemType = this.$route.query.itemType;
      isFiltered = true;
    }

    if(this.$route.query.itemValue){
      this.itemValue = this.$route.query.itemValue;
      isFiltered = true;
    }

    if(this.$route.query.dateFrom && this.$route.query.dateTo){
      this.selectedDates = [
          moment(this.$route.query.dateFrom).toDate(),
          moment(this.$route.query.dateTo).toDate()
      ];
      isFiltered = true;
    }

    if(isFiltered){
      this.view()
    }
  },

  data () {
    return {

      // TODO: remove for now it is debugging
      selectedDates: [moment().subtract(30, 'days').toDate(), moment().toDate() ],
      itemType: 'ROUTE_HASH',
      itemValue: null,

      data: [],
      isLoading: false,

      dataOptimization: [],
      isLoadingOptimization: false,

      // Charts
      chartColors: {
        default: {
          primary: '#00D1B2',
          info: '#209CEE',
          danger: '#FF3860',
          dangerDark: '#992039',
          warning: '#FF5900',
          warningDark: '#9e3e05',
        }
      },
      extraOptions: {
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          responsive: true,
          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: 'nearest',
            intersect: 0,
            position: 'nearest'
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9a9a9a'
                }
              }
            ],

            xAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(225,78,202,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9a9a9a'
                }
              }
            ]
          }
        },
    }
  },
  mixins: [
    numberUtils,
    timeUtils
  ],
  computed: {
    ...mapState([
    ]),
    chartPerformanceData () {
      let data = this.dataToChartPivot(this.data, 'date', {
        'cost': 'Cost',
        'revenue': 'Revenue',
        'profit': 'Profit',
      });

      let chartData = {
        datasets: [],
        labels: []
      },
          color;

      for(let name in data['dataset']){

        if(name === 'Cost'){
          color =   this.chartColors.default.danger
        }else if(name === 'Revenue'){
          color =   this.chartColors.default.warning
        }else if(name === 'Profit'){
          color =   this.chartColors.default.info
        }else{
          color =   this.chartColors.default.primary
        }

        chartData.datasets.push({
          fill: false,
          borderColor: color,
          borderWidth: 2,
          borderDash: [],
          label: name,
          borderDashOffset: 0.0,
          pointBackgroundColor: '#000',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: this.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: data['dataset'][name]
        });
      }

      chartData.labels = data['labels'];

      return chartData;
    },

    chartCPARPCData () {
      let data = this.dataToChartPivot(this.data, 'date', {
        'bid_amount': 'Bid Amount',
        'cpa_c': 'CPA(C)',
        'cpa_r': 'CPA(R)',
        'rpc_r': 'RPC(R)',
        'rpc_c': 'RPC(C)',
      });

      let chartData = {
        datasets: [],
        labels: []
      },
          color;

      for(let name in data['dataset']){

        if(name === 'Bid Amount'){
          color =   this.chartColors.default.info
        }else if(name === 'CPA(C)'){
          color =   this.chartColors.default.warning
        }else if(name === 'CPA(R)'){
          color =   this.chartColors.default.warningDark
        }else if(name === 'RPC(R)'){
          color =   this.chartColors.default.danger
        }else if(name === 'RPC(C)'){
          color =   this.chartColors.default.dangerDark
        }else{
          color =   this.chartColors.default.primary
        }

        chartData.datasets.push({
          fill: false,
          borderColor: color,
          borderWidth: 2,
          borderDash: [],
          label: name,
          borderDashOffset: 0.0,
          pointBackgroundColor: '#000',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: this.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: data['dataset'][name]
        });
      }

      chartData.labels = data['labels'];

      return chartData;
    },

    chartClicksData () {
      let data = this.dataToChartPivot(this.data, 'date', {
        'clicks_out_r': 'Clicks Out(R)',
        'clicks_out_c': 'Clicks Out(C)',
      });

      let chartData = {
        datasets: [],
        labels: []
      },
          color;

      for(let name in data['dataset']){

        if(name === 'Clicks Out(R)'){
          color =   this.chartColors.default.info
        }else if(name === 'Clicks Out(C)'){
          color =   this.chartColors.default.warning
        }else{
          color =   this.chartColors.default.primary
        }

        chartData.datasets.push({
          fill: false,
          borderColor: color,
          borderWidth: 2,
          borderDash: [],
          label: name,
          borderDashOffset: 0.0,
          pointBackgroundColor: '#000',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: this.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: data['dataset'][name]
        });
      }

      chartData.labels = data['labels'];

      return chartData;
    },

    chartCTRData () {
      let data = this.dataToChartPivot(this.data, 'date', {
        'ctr_r': 'CTR(R)',
        'ctr_c': 'CTR(C)',
      });

      let chartData = {
        datasets: [],
        labels: []
      },
          color;

      for(let name in data['dataset']){

        if(name === 'CTR(R)'){
          color =   this.chartColors.default.info
        }else if(name === 'CTR(C)'){
          color =   this.chartColors.default.warning
        }else{
          color =   this.chartColors.default.primary
        }

        chartData.datasets.push({
          fill: false,
          borderColor: color,
          borderWidth: 2,
          borderDash: [],
          label: name,
          borderDashOffset: 0.0,
          pointBackgroundColor: '#000',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: this.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: data['dataset'][name]
        });
      }

      chartData.labels = data['labels'];

      return chartData;
    },

    chartROIData () {
      let data = this.dataToChartPivot(this.data, 'date', {
        'roi': 'ROI',
      });

      let chartData = {
        datasets: [],
        labels: []
      },
          color;

      for(let name in data['dataset']){

        color =   this.chartColors.default.primary

        chartData.datasets.push({
          fill: false,
          borderColor: color,
          borderWidth: 2,
          borderDash: [],
          label: name,
          borderDashOffset: 0.0,
          pointBackgroundColor: '#000',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: this.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: data['dataset'][name]
        });
      }

      chartData.labels = data['labels'];

      return chartData;
    },
  },
  methods: {
    isMobile () {
      return !!(window.matchMedia("(max-width: 767px)").matches)
    },

    view () {
      this.isLoading = true;

      getAllPerformanceAnalysis(
          this.itemType,
          this.itemValue,
          moment(this.selectedDates[0]).format('YYYY-MM-DD'),
          moment(this.selectedDates[1]).format('YYYY-MM-DD')
      ).then(
              response => {
                this.isLoading = false;
                this.data = response.data.results;
              },
              error => {
                this.isLoading = false;
                alert('An error happens.');
                console.error(error);
              }
          );

      this.isLoadingOptimization = true;

      if(this.itemType !== 'ROUTE_HASH'){
        return;
      }

      getAllByRouteHashDateRange(
          this.itemValue,
          moment(this.selectedDates[0]).format('YYYY-MM-DD'),
          moment(this.selectedDates[1]).format('YYYY-MM-DD')
      ).then(
              response => {
                this.isLoadingOptimization = false;
                this.dataOptimization = response.data.results;
              },
              error => {
                this.isLoadingOptimization = false;
                alert('An error happens.');
                console.error(error);
              }
          );
    },

    dataToChartPivot (data, dateKey, labelKeys) {

      if(data.length === 0){
        return [];
      }

      // Create all Labels (For example: US, NL, etc.)
      let labels = {};
      for(let i in labelKeys){
        labels[i] = 0;
      }

      // Get all dates
      let minDate, maxDate, currentDate, momentDate;
      for(let i in data){
        currentDate = data[i][dateKey];
        momentDate = moment(currentDate);
        if(!minDate){
          minDate = maxDate = currentDate;
        }

        if(momentDate < moment(minDate)){
          minDate = currentDate;
        }

        if(momentDate > moment(maxDate)){
          maxDate = currentDate;
        }
      }

      let allDates = {};
      let currentMoment = moment(minDate).subtract(1, 'days');
      let endMoment = moment(maxDate);
      while (currentMoment.isBefore(endMoment, 'day')) {
        currentMoment.add(1, 'days');
        allDates[currentMoment.clone().format('YYYY-MM-DD')] = 0;
      }

      //reorganize data.
      let reorganizedData = {};
      for(let i in data){
        for(let labelKey in labelKeys) {
          // labelKeys[labelKey] = data[i][labelKey]
          if (typeof reorganizedData[labelKeys[labelKey]] === 'undefined') {
            reorganizedData[labelKeys[labelKey]] = {};
          }
          if (typeof reorganizedData[labelKeys[labelKey]][data[i][dateKey]] === 'undefined') {

            let amount = 0;

            if (data[i][labelKey]) {
              amount = data[i][labelKey];
            }

            reorganizedData[labelKeys[labelKey]][data[i][dateKey]] = amount;
          }
        }
      }

      allDates = Object.fromEntries(Object.entries(allDates).sort());

      // Add missing dates.
      for(let i in reorganizedData){
        reorganizedData[i] = {...allDates, ...reorganizedData[i] };
      }

      // convert objects to arrays.
      for(let i in reorganizedData){
        reorganizedData[i] = Object.fromEntries(Object.entries(reorganizedData[i]).sort());
        reorganizedData[i] = Object.values(reorganizedData[i]);
      }

      return {
        'dataset': reorganizedData,
        'labels': Object.keys(allDates)
      }
    },
  }
}
</script>
