<template>
  <div>
    <b-table
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="revenue"
      default-sort-direction="DESC"
      :data="data"
      ref="table-keywords"

      :pagination-rounded="false"
      sort-icon="chevron-up"
      sort-icon-size="is-medium"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"

      :total="total"
      :current-page="currentPage"

      @page-change="changePage"
      backend-pagination

      backend-sorting
      @sort="sortPressed"

      :sticky-header="true"
      height="450"
      class="custom-table-dashboard"
    >
      <b-table-column label="Keyword" field="keyword_name" sortable v-slot="props">
        {{ props.row.keyword_name }}
      </b-table-column>
      <b-table-column label="Country" field="country_name" sortable v-slot="props">
        {{ props.row.country_name }}
      </b-table-column>
      <b-table-column label="Revenue" field="revenue" sortable v-slot="props">
        {{ renderCurrency(props.row.revenue, 2) }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import numeral from "numeraljs";

export default {
  name: 'KeywordsPerformanceTable',
  props: {
    data: {
      type: Array,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    paginated: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 50
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },

  methods: {
    renderCurrency (amount, decimals) {
      let num = numeral(amount).format('0.' + ('0'.repeat(decimals)));
      if (!(num !== 0)) {
        return '';
      }

      return '$' + num;
    },

    changePage() {
      this.$emit('page-change', ...arguments)
    },

    sortPressed() {
      this.$emit('sort-pressed', ...arguments)
    }
  }
}
</script>
