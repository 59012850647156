// import { userService } from '../services';

import axios from "axios";
import {config} from "@/services/config";

export const theme = {
    namespaced: true,
    state: {
        /* User */
        userId: null,
        userName: '',
        firstName: '',
        lastName: '',
        userPhone: null,
        userEmail: null,
        userGroups: [],
        userAvatar: null,

        /* NavBar */
        isNavBarVisible: true,

        /* FooterBar */
        isFooterBarVisible: true,

        /* Aside */
        isAsideVisible: false,
        isAsideMobileExpanded: false,

        viewMode: 'normal',
        imagePickerShowPasteBtn: false,
        pendingCheckedKeywordsRequests: 0,

        systemUnderMaintenance: false,
        maintenanceStopTimeInUTC: null, //type maintenance end in utc time ex. 2023-05-20 13:00:00, or null
    },
    mutations: {
        /* A fit-them-all commit */
        basic(state, payload) {
            state[payload.key] = payload.value
            console.log(payload.key)
        },
        set(state, [key, value]) {
            state[key] = value
        },

        /* User */
        user(state, payload) {
            if (payload.username) {
                state.userName = payload.username
            }
            if (payload.first_name) {
                state.firstName = payload.first_name
            }
            if (payload.last_name) {
                state.lastName = payload.last_name
            }
            if (payload.id) {
                state.userId = payload.id
            }
            if (payload?.results?.agent) {
                const { agent, agentValue } = payload?.results
                const capitalizeFirstLetter  = agent.charAt(0).toUpperCase() + agent.slice(1)
                state['user' + capitalizeFirstLetter] = agentValue
            }
            if (payload.email) {
                state.userEmail = payload.email
            }
            if (payload.avatar) {
                state.userAvatar = payload.avatar
            }
            if (payload.groups) {
                state.userGroups = payload.groups
            }
            state.isTwoFactorAuth = payload.is_two_factor_auth
        },

        /* Aside Mobile */
        asideMobileStateToggle(state, payload = null) {
            const htmlClassName = 'has-aside-mobile-expanded'

            let isShow

            if (payload !== null) {
                isShow = payload
            } else {
                isShow = !state.isAsideMobileExpanded
            }

            if (isShow) {
                document.documentElement.classList.add(htmlClassName)
            } else {
                document.documentElement.classList.remove(htmlClassName)
            }

            state.isAsideMobileExpanded = isShow
        },

        asideMobileStateHide(state) {
            const htmlClassName = 'has-aside-mobile-expanded'
            document.documentElement.classList.remove(htmlClassName)
            state.isAsideMobileExpanded = false
        }
    },
    getters: {
        isUserLoaded (state) {
            return state.userId !== null;
        }
    },
    actions: {
        getUserDetails({ commit, getters }) {
            let user = JSON.parse(localStorage.getItem('user'));

            if(!user){
                return;
            }

            let axiosConfig = {
                headers: { 'Authorization': 'Bearer ' + user.access }
            }

            if(getters.isUserLoaded){
                return;
            }

            return axios.get(
                `${config.apiUrl}/api/app_auth/my-user/`, axiosConfig
            )
            .then(userResult => {
                userResult = userResult.data;

                if(userResult.is_staff){
                    userResult['groups'].push('IsStaff')
                }

                if(userResult.is_superuser){
                    userResult['groups'].push('IsSuperUser')
                }

                if(userResult.is_organization_admin){
                    userResult['groups'].push('IsOrganizationAdmin')
                }

                if(window.location.href.indexOf('localhost') > -1){
                    userResult['groups'].push('IsDev')
                }

                commit('user', userResult);
            });
        }
    }
}