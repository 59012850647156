import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../home/HomePage'
import LoginPage from '../login/LoginPage'
import SuperLoginPage from '../login/SuperLogin'
import TrackingCodesPage from "@/pages/admin/TrackingCodesPage";
import ImageSearcherPage from "@/pages/tools/ImageSearcherPage";
import ManualImportPage from "@/pages/found_keywords/ManualImportPage";
import NoImagesFoundKeywordsPage from "@/pages/found_keywords/NoImagesFoundKeywordsPage";
import KeywordsLifePage from "@/pages/tools/KeywordsLifePage";
import PerformanceAnalysisPage from "@/pages/tools/PerformanceAnalysisPage";
import TQAnalysisPage from "@/pages/tools/TQAnalysisPage";
import SearchTermsPage from "@/pages/pipelines/SearchTermsPage";
import FacebookInboxPage from "@/pages/pipelines/FacebookInboxPage";
import LaunchingSettingsPage from "@/pages/admin/LaunchingSettingsPage";
import LaunchingQueuePage from "@/pages/found_keywords/LaunchingQueuePage";
import FacebookDisapprovedAdsPage from "@/pages/pipelines/FacebookDisapprovedAdsPage";
import ExternalAgenciesRevenue from "@/pages/performances/ExternalAgenciesRevenue";
import FoundKeywordNetworkCompliancePage from "@/pages/found_keywords/FoundKeywordNetworkCompliancePage";
import OptimizationAnalysisPage from "@/pages/reporting/OptimizationAnalysisPage";
import OptimizationAutomationPage from "@/pages/optimization/OptimizationAutomationPage";
import DailyOptimizationPage from "@/pages/optimization/DailyOptimizationPage";
import PerformanceViewerPage from "@/pages/optimization/PerformanceViewerPage";
import DashboardImagePickerPage from "@/pages/reporting/DashboardImagePickerPage";
import UrlBuilder from "@/pages/found_keywords/UrlBuilder";
import InternalAgencyPage from "@/pages/internal-agency/InternalAgencyPage"
import UsersPage from "@/pages/users/UsersPage"
import UsersPageNew from "@/pages/users/UsersPageNew"
import AccessGroup from "@/pages/access-groups/AccessGroup"
import AccessGroupNew from "@/pages/access-groups/AccessGroupNew"
import InternalAgencyPageNew from "@/pages/internal-agency/InternalAgencyPageNew"
import AdvertisersCheckPage from '@/pages/found_keywords/AdvertisersCheckPage'

// import ManualLauncherPage from "@/pages/found_keywords/ManualLauncherPage";

import DailyPerformanceAnalysisPage from "@/pages/optimization/DailyPerformanceAnalysisPage";

import DashboardTrafficChartPage from "@/pages/reporting/DashboardTrafficChartPage";
import ProfilePage from "@/pages/profile/ProfilePage";
import NoVideosFoundKeywordsPage from "@/pages/found_keywords/NoVideosFoundKeywordsPage.vue";
import ManualImportParkedDomainPage from "@/pages/found_keywords/ManualImportParkedDomainPage.vue";
import ProductRequestPage from "@/pages/found_keywords/ProductRequestPage.vue";


Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: HomePage, name: 'main' },
    { path: '/login', component: LoginPage, name: 'login' },
    { path: '/super-login', component: SuperLoginPage, name: 'super-login' },
    // { path: '/optimization', component: OptimizationPage, name: 'optimization' },
    { path: '/daily-optimization', component: DailyOptimizationPage, name: 'daily-optimization' },
    { path: '/daily-performance-analysis', component: DailyPerformanceAnalysisPage, name: 'daily-performance-analysis' },
    { path: '/optimization-automation', component: OptimizationAutomationPage, name: 'optimization-automation' },
    { path: '/performance-viewer', component: PerformanceViewerPage, name: 'performance-viewer' },
    { path: '/tracking-codes', component: TrackingCodesPage, name: 'tracking-codes' },
    { path: '/launching-settings', component: LaunchingSettingsPage, name: 'launching-settings' },
    { path: '/manual-import', component: ManualImportPage, name: 'manual-import' },
    { path: '/manual-import-parked-domain', component: ManualImportParkedDomainPage, name: 'manual-import-parked-domain' },
    { path: '/product-request', component: ProductRequestPage, name: 'product-request' },
    // { path: '/manual-launcher', component: ManualLauncherPage, name: 'manual-launcher' },
    // { path: '/prevalidate-keywords', component: PrevalidateKeywords, name: 'prevalidate-keywords' },
    { path: '/url-builder', component: UrlBuilder, name: 'url-builder' },
    { path: '/no-images-found-keywords', component: NoImagesFoundKeywordsPage, name: 'no-images-found-keywords' },
    { path: '/no-videos-found-keywords', component: NoVideosFoundKeywordsPage, name: 'no-videos-found-keywords' },
    { path: '/launching-queue', component: LaunchingQueuePage, name: 'launching-queue' },
    { path: '/image-searcher', component: ImageSearcherPage, name: 'image-searcher' },
    { path: '/keywords-life', component: KeywordsLifePage, name: 'keyword-life' },
    { path: '/performance-analysis', component: PerformanceAnalysisPage, name: 'performance-analysis' },
    { path: '/tq-analysis', component: TQAnalysisPage, name: 'tq-analysis' },
    { path: '/search-terms', component: SearchTermsPage, name: 'search-terms' },
    { path: '/facebook-inbox', component: FacebookInboxPage, name: 'facebook-inbox' },
    { path: '/facebook-disapproved-ads', component: FacebookDisapprovedAdsPage, name: 'facebook-disapproved-ads' },
    { path: '/external-agencies-revenue', component: ExternalAgenciesRevenue, name: 'external-agencies-revenue' },
    { path: '/facebook-compliance-review', component: FoundKeywordNetworkCompliancePage, name: 'facebook-compliance-review' },
    { path: '/optimization-analysis', component: OptimizationAnalysisPage, name: 'optimization-analysis' },
    { path: '/dashboard-image-picker', component: DashboardImagePickerPage, name: 'dashboard-image-picker' },
    { path: '/dashboard-traffic-chart', component: DashboardTrafficChartPage, name: 'dashboard-traffic-chart' },
    { path: '/advertisers-check', component: AdvertisersCheckPage, name: 'advertisers-check' },
    {
      path: '/marketing-agency',
      component: {render(b) {return b('router-view')}},
      redirect: { name: 'internal-agency-page' },
      children: [
        {
          path: '/',
          name: 'internal-agency-page',
          component: InternalAgencyPage
        },
        {
          path: 'new',
          name: 'internal-agency-new',
          component: InternalAgencyPageNew,
        },
      ],
    },
    {
      path: '/marketing-agency-users',
      component: {render(b) {return b('router-view')}},
      redirect: { name: 'users' },
      children: [
        {
          path: '/',
          name: 'users',
          component: UsersPage
        },
        {
          path: 'new',
          name: 'users-new',
          component: UsersPageNew,
        },
      ],
    },
    {
      path: '/access-groups',
      component: {render(b) {return b('router-view')}},
      redirect: { name: 'access groups' },
      children: [
        {
          path: '/',
          name: 'access groups',
          component: AccessGroup,
        },
        {
          path: 'new',
          name: 'access-groups-new',
          component: AccessGroupNew,
        },
      ],
    },
    { path: '/profile', component: ProfilePage, name: 'profile' },

    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next({name :'login'}).catch(() => {});
  }

  // let groups = store.state.theme.userGroups;
  //
  // if(to.path === '/optimization' && groups.indexOf('IsManagement') > -1){
  //   alert('You are not allowed to visit this page.');
  //   return;
  // }

  next();
})