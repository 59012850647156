import { hemingwayDomainKeywordsService } from '@/services';


export const hemingwayDomainKeywords = {
    namespaced: true,
    state: {
        one: {},
        oneLoading: false
    },
    actions: {
        getOneByKeywordName({ commit }, payload) {
            commit('getOneRequest');

            let keywordName = payload['keywordName'];

            hemingwayDomainKeywordsService.post({
                'name': keywordName
            })
                .then(
                    data => commit('getOneSuccess', data),
                    error => commit('getOneFailure', error)
                );
        },
    },
    mutations: {
        getOneRequest(state) {
            state.oneLoading = true;
        },
        getOneSuccess(state, response) {
            state.one = response.data;
            state.oneLoading = false;
        },
        getOneFailure(state) {
            state.oneLoading = false;
        },
    }
}
