<template>
  <div>
    <section>
      <b-loading v-model="isLoading"></b-loading>
      <div>
        <div class="columns">
          <div class="column">
            <b-field
              label="Agency Feed"
              :type="{ 'is-danger': formData.agencyFeed.hasError }"
              :message="{ 'Please provide a value for this item': formData.agencyFeed.hasError }"
            >
              <b-select v-model="formData.agencyFeed.value" :disabled="formData.id.value">
                <option v-for="s in agencyFeeds['all']" :value="s.id" v-bind:key="`agency-feeds-gdn-${s.id}`">
                  {{ s[getFieldCodeLabel()] }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Network"
            >
              <b-select v-model="formData.network.value" disabled>
                <option v-for="s in networks['all']" :value="s.id" v-bind:key="`networks-gdn-${s.id}`">
                  {{ s.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
                label="Creative Optimization"
            >
              <b-button type="is-success" @click="toggleCreativesModal">Watermarks/Frames</b-button>
            </b-field>

            <b-modal :active.sync="creativeModal" has-modal-card can-cancel>
              <div class="modal-card">
                <header class="modal-card-head">
                  <p class="modal-card-title">Creative Optimization</p>
                </header>
                <section class="modal-card-body p-5">
                  <water-marks-frames-selector :agency-feed="formData.agencyFeed.value"
                                               :initial-data="formData.watermarksFrames.value"
                  v-model="formData.watermarksFrames.value"></water-marks-frames-selector>
                </section>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
      <div v-if="formData.id.value === null && users['all'].length > 0">

        <b-field
          label="Pre-populate by user"
        >
          <b-select v-model="cloneFromUser">
            <option v-for="s in users['all']" :value="s" v-bind:key="`clone-users-gdn-${s.id}`">
              {{ s.username }}
            </option>
          </b-select>
          <p class="control">
            <b-button @click="prePopulateByUser" class="is-info">Pre-Populate!</b-button>
          </p>
        </b-field>
      </div>
      <div>
        <b-field
            label="Description"
            :type="{ 'is-danger': formData.description.hasError }"
            :message="{ 'Please provide a descriptive description': formData.description.hasError }">
            <b-input v-model="formData.description.value" type="textarea" style="width: 100%"></b-input>
        </b-field>
      </div>
    <div class="columns mt-1">
      <div class="column is-2">
        <b-field
            label="User"
            v-if="users['all'].length > 0"
            >
          <b-select v-model="formData.user.value">
            <option v-for="s in users['all']" :value="s" v-bind:key="`users-${s.id}`">
              {{ s.username }}
            </option>
          </b-select>
        </b-field>
        <b-field
            label="User"
            v-else
            >
            {{ formData.user.value.username }}
        </b-field>
      </div>

      <div class="column is-2">
        <b-field  :type="{ 'is-danger': formData.excludeLaunch.hasError }"
                  :message="{ 'Please provide a value for this item': formData.excludeLaunch.hasError }"
        >
          <template #label>
            Exclude Launch <Tooltip label="If an ad is not launched within these days, the system will not try to relaunch it, unless manually relaunched. This is a safety measure to avoid launching ads with outdated keywords. The recommended value is 3." />
          </template>
          <b-input v-model.number="formData.excludeLaunch.value" type="number" min="0" pattern="[0-9]" onkeypress="return !(event.charCode == 46)" step="1"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field :type="{ 'is-danger': formData.adType.hasError }"
                 :message="{ 'Please provide a value for this item': formData.adType.hasError }"
                 label="Ad Type"
        >
          <b-select v-model="formData.adType.value">
            <option v-for="s in ['IMAGE', 'VIDEO', 'TEXT']" :value="s" :key="`ad-type-${s}`">
              {{ s }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div v-if="isSuperUser()" class="column is-2">
        <b-field label="Launching Type">
              <b-select v-model="formData.launchingType.value">
                <option v-for="s in launchingType" :value="s.value" :key="`launching-type-${s.value}`">
                  {{ s.label }}
                </option>
              </b-select>
          </b-field>
      </div>

      <div v-if="isSuperUser()" class="column is-2">
        <b-field label="URL Rotation Type">
              <b-select v-model="formData.urlRotationType.value">
                <option v-for="s in urlRotationType" :value="s.value" :key="`url-rotation-type-${s.value}`">
                  {{ s.label }}
                </option>
              </b-select>
          </b-field>
      </div>
    </div>

      <div class="columns">
        <div class="column" v-if="visibleTo('IsSuperUser')">
          <b-field label="Allow Auto Queue">
              <b-checkbox v-model="formData.allowAutoQueue.value"></b-checkbox>
          </b-field>
        </div>
        <div class="column" v-if="visibleTo('IsSuperUser')">
          <b-field label="Subdomain Multiplier"
                   :type="{ 'is-danger': formData.subdomainMultiplier.hasError }"
                   :message="{ 'Please provide a value for this item': formData.subdomainMultiplier.hasError }"
          >
            <b-input v-model="formData.subdomainMultiplier.value" type="number" step="1"
            ></b-input>
          </b-field>
        </div>
        <div class="column" v-if="visibleTo('IsSuperUser')">
          <b-field label="Max Clones"
                   :type="{ 'is-danger': formData.maxClones.hasError }"
                   :message="{ 'Please provide a value for this item': formData.maxClones.hasError }"
          >
            <b-input v-model="formData.maxClones.value" type="number" step="1"
            ></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Ads Multiplier"
                   :type="{ 'is-danger': formData.adMultiplier.hasError }"
                   :message="{ 'Please provide a value for this item': formData.adMultiplier.hasError }"
          >
            <b-input v-model="formData.adMultiplier.value" type="number" step="1"
            ></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Campaign Status">
              <b-select v-model="formData.campaignStatus.value">
                <option v-for="s in status" :value="s.value" v-bind:key="`campaign-status-${s.value}`">
                  {{ s.label }}
                </option>
              </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="AdGroup Status">
              <b-select v-model="formData.adGroupStatus.value">
                <option v-for="s in status" :value="s.value" v-bind:key="`campaign-status-${s.value}`">
                  {{ s.label }}
                </option>
              </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Ad Status">
              <b-select v-model="formData.adStatus.value">
                <option v-for="s in status" :value="s.value" v-bind:key="`campaign-status-${s.value}`">
                  {{ s.label }}
                </option>
              </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
        <b-field
            label="Device Criteria"
            :type="{ 'is-danger': formData.deviceCriteria.hasError }"
            :message="{ 'Please provide select at least one value': formData.deviceCriteria.hasError }">
          <multiselect
            v-model="formData.deviceCriteria.value"
            :options="deviceCriteria"
            :multiple="true"
            :close-on-select="true"
            placeholder="Device Criteria"
            :disable="deviceCriteria.length === 0"
            label="type"
            track-by="id">
          </multiselect>
        </b-field>
        </div>
        <div class="column">
          <b-field
              label="Placement Criteria"
              :type="{ 'is-danger': formData.placementCriteria.hasError }"
              :message="{ 'Please provide select at least one value': formData.placementCriteria.hasError }">
            <multiselect
              v-model="formData.placementCriteria.value"
              :options="placementCriteria"
              :multiple="true"
              :close-on-select="true"
              placeholder="Placement Criteria"
              label="url"
              track-by="id">
            </multiselect>
          </b-field>
        </div>
      </div>
        <b-field label="Is Active">
            <b-checkbox v-model="formData.isActive.value"></b-checkbox>
        </b-field>

    <template v-if="isSuperUser()">
      <h2 class="subtitle">
        <b> Account Managers</b>
      </h2>

      <multiselect
        v-model="formData.accountManagers.value"
        :options="accountManagers"
        :multiple="true"
        placeholder="Account Managers"
        label="name"
        track-by="id"
      ></multiselect>
    </template>

    <b-loading v-model="countries.loading"></b-loading>
      <b-loading v-model="countries.loadingFullList"></b-loading>
      <h2 class="subtitle is-flex is-justify-content-space-between">
        <div style="width: 100%;">
          <b>Country Settings:</b>
          <div v-if="!this.formData.agencyFeed.value && !id" class="notification is-warning mt-3 has-text-centered is-light is-size-5 p-3">Select an agency feed, in order to continue.</div>
        </div>
        <b-button v-if="formData.countrySettings.length" size="is-small" type="is-info is-light" style="margin-right: 55px;" @click="fillAllEmpties()">Fill All Empties</b-button>
      </h2>

      <div class="columns" v-for="(countrySetting, rowId) in formData.countrySettings"
       v-bind:key="`country_settings-${rowId}`">
      <div class="column">
          <b-field label="Country">
            {{ countrySetting.country.value }} ({{ getCountryName(countrySetting.country.value)}})
          </b-field>
        </div>
        <div class="column">
          <b-field
            :type="{ 'is-danger': countrySetting.accountCurrencyCode.hasError }"
            :message="{ 'Please provide a value': countrySetting.accountCurrencyCode.hasError }"
          >
            <template #label>
              Account Currency <Tooltip label="If you wish to set bids and budget with a precision of lower than 0.01 USD, consider using a currency such as HKD or AED. This feature can be especially useful for countries with lower GDP. Remember that bids and budgets must be set using the selected currency." />
            </template>
            <b-select v-model="countrySetting.accountCurrencyCode.value">
              <option :value="c" v-for="c in filteredCurrencies" v-bind:key="`currencies-${c}`">
                {{ c }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column">
          <b-field
            :type="{ 'is-danger': countrySetting.biddingStrategy.hasError }"
            :message="{ 'Please provide a value': countrySetting.biddingStrategy.hasError }">
            
            <template #label>
              Bidding Strategy <tooltip label="Remember to double check the bid after setting the bidding strategy." />
            </template>

            <BiddingStrategyField :biddingStrategiesList="biddingStrategies['allGDN']" @updateValue="e => updateBiddingStrategy(e, rowId)" :value="countrySetting.biddingStrategy.value" :ref="'bs_'+rowId"/>
          </b-field>
        </div>

        <div class="column">
          <b-field
            :type="{ 'is-danger': countrySetting.bidAmount.hasError }"
            :message="{ 'Please provide a value': countrySetting.bidAmount.hasError }">

            <template #label>
              Bid Amount <tooltip label="Amount using Account Currency." />
            </template>
            <decimal-input v-model="countrySetting.bidAmount.value"
                           step="0.01"
                           prefix="$"
                           :ref="'bid_'+rowId"
                           :disabled="!isBidHandled(countrySetting.biddingStrategy.value) || !countrySetting.biddingStrategy.value"
                           controls-alignment="right"></decimal-input>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :type="{ 'is-danger': countrySetting.budgetAmount.hasError }"
            :message="{ 'Please provide a value': countrySetting.budgetAmount.hasError }">

            <template #label>
              Budget Amount <tooltip label="Amount using Account Currency." />
            </template>

            <decimal-input v-model="countrySetting.budgetAmount.value"
                           step="1"
                           prefix="$"
                           only-integers
                           controls-alignment="right"></decimal-input>
            <!-- <b-input v-model.number="countrySetting.budgetAmount.value" type="number" min="0" pattern="[0-9]" onkeypress="return !(event.charCode == 46)" step="1" /> -->
          </b-field>
        </div>
        <div class="column" v-if="visibleTo('IsSuperUser')">
          <b-field label="Max Budget Amount"
                   :type="{ 'is-danger': countrySetting.maxBudgetAmount.hasError }"
                   :message="{ 'Please provide a value': countrySetting.maxBudgetAmount.hasError }">
            <!-- <decimal-input v-model="countrySetting.maxBudgetAmount.value"
                           step="1"
                           prefix="$"
                           controls-alignment="right"></decimal-input> -->
            
            <b-input v-model.number="countrySetting.maxBudgetAmount.value" type="number" min="0" pattern="[0-9]" onkeypress="return !(event.charCode == 46)" step="1" />
          </b-field>
        </div>
        <div class="column">
          <b-field
            :type="{ 'is-danger': countrySetting.dailyLimit.hasError }"
            :message="{ 'Please provide a value': countrySetting.dailyLimit.hasError }">

            <template #label>
              Daily Limit <tooltip label="Maximum number of Ads created daily for this country." />
            </template>

            <b-input v-model.number="countrySetting.dailyLimit.value" type="number" min="0" pattern="[0-9]" onkeypress="return !(event.charCode == 46)" step="1" />
          </b-field>
        </div>

        <div class="column">
          <b-field
            :type="{ 'is-danger': countrySetting.languages.hasError }"
            :message="{ 'Please provide at least one value': countrySetting.languages.hasError }">

            <template #label>
              Languages <tooltip label="The languages used to build the templates for this country." />
            </template>

            <multiselect v-model="countrySetting.languages.value"
                         :options="filteredlanguages"
                         :multiple="true"
                         label="name"
                         track-by="id"
                         placeholder="Languages"></multiselect>
          </b-field>
        </div>
        <div class="column mt-5"><b-button size="is-small" type="is-info is-light" @click="fillEmpties(countrySetting, rowId)">Fill Empties</b-button></div>
      </div>
      <b-button class="is-primary is-pulled-right" @click="save">Save!</b-button>
      <b-button class="is-text is-pulled-left" @click="cancel">Cancel</b-button>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import Multiselect from "vue-multiselect";
import {larryGoogleDeviceCriteriaService} from "@/services/larry-google-device-criteria.service";
import {larryGooglePlacementCriteriaService} from "@/services/larry-google-placement-criteria.service";
import DecimalInput from "@/components/Element/DecimalInput";
import {mapState} from "vuex";
import {
  administrationAccountManagerService,
  administrationAccountsService,
  devCountriesService,
  devLanguagesService
} from "@/services";
import {creationSettingsService} from "@/services/creation-creation-settings.service";
import {permissionsUtils} from "@/helpers/permissions-utils";
import Tooltip from '@/components/Tooltip';
import BiddingStrategyField from './CreationSettingFormBiddingStrategyField.vue'
import WaterMarksFramesSelector from "@/components/WaterMarksFramesSelector.vue";
import vue from "vue";

export default {
  name: 'CreatingSettingGoogleDisplayForm',

  mixins: [
    permissionsUtils,
  ],

  created() {
    this.fetchDeviceCriteria();
    this.fetchPlacementCriteria();

    let id = this.id;

    if(!this.biddingStrategies.allGDN.length) this.$store.dispatch('biddingStrategies/getAllGDN');
    if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll');
    if(!this.networks.all.length) this.$store.dispatch('networks/getAll');
    if(!this.countries.all.length) this.$store.dispatch('countries/getAll');
    if (!this.countries.allFullList.length) this.$store.dispatch('countries/getAllFullList');
    if(!this.users.all.length) this.$store.dispatch('users/getAll');

    if(id){
      this.fetchSettingsById(id)
    }else{
      console.log('populate settings etc.')
    }

    this.formData.network.value = this.network;
    // TODO: awaiting enpoint to get only currencies
    // this.fetchListOfCurrencies();
  },

  props: [
    'id',
    'network'
  ],
  components: {
    WaterMarksFramesSelector,
    DecimalInput,
    Multiselect,
    Tooltip,
    BiddingStrategyField,
  },
  data () {
    return {
      isLoading: false,
      cloneFromUser: null,

      formData: {
        id: {
          value: null,
        },
        description: {
          value: '',
          hasError: false
        },
        user: {
          value: {},
          hasError: false
        },
        allowAutoQueue: {
          value: true,
          hasError: false
        },
        adMultiplier: {
          value: 1,
          hasError: false
        },
        subdomainMultiplier: {
          value: 1,
          hasError: false
        },
        maxClones: {
          value: 1,
          hasError: false
        },
        campaignStatus: {
          value: 'ENABLED',
          hasError: false
        },
        launchingType: {
          value: 'DIRECT',
          hasError: false
        },
        urlRotationType: {
          value: 'STATIC',
          hasError: false
        },
        adGroupStatus: {
          value: 'ENABLED',
          hasError: false
        },
        adStatus: {
          value: 'ENABLED',
          hasError: false
        },
        deviceCriteria: {
          value: [],
          hasError: false
        },
        placementCriteria: {
          value: [],
          hasError: false
        },
        isActive: {
          value: true,
          hasError: false
        },

        agencyFeed: {
          value: null,
          hasError: false
        },

        network: {
          value: null,
          hasError: false
        },

        excludeLaunch: {
          value: 3,
          hasError: false
        },

        accountManagers: {
          value: [],
          hasError: false,
        },

        watermarksFrames: {
          value: {},
        },

        countrySettings: [],

        adType: {
          value: 'IMAGE',
          hasError: false,
        },
      },

      status: [
        {'label': 'ACTIVE', 'value': 'ENABLED'},
        {'label': 'PAUSED', 'value': 'PAUSED'},
      ],
      
      launchingType: [
        {'label': 'DIRECT', 'value': 'DIRECT'},
        {'label': 'AUTOMATIC', 'value': 'AUTOMATIC'},
      ],

      urlRotationType: [
        {'label': 'STATIC', 'value': 'STATIC'},
        {'label': 'DYNAMIC', 'value': 'DYNAMIC'},
      ],

      deviceCriteria: [],
      placementCriteria: [],
      // currencies: ['USD', 'HKD', 'AED'],
      languages: [],
      accountManagers: [],
      configuredAgencyFeedsCode: [],

      creativeModal: false,
      fCountries: null,
      sCountries: null
    }
  },
  computed: {
    ...mapState([
      'biddingStrategies',
      'agencyFeeds',
      'networks',
      'users',
      'theme',
      'countries',
      'currencies',
    ]),

    filteredCurrencies() {
      return this.currencies.all
    },
  
    filteredlanguages() {
      //set a unique list of languages
      let langs = [], l = [];
      for (let i in this.sCountries) {
        if(this.sCountries[i].default_language){
          l[this.sCountries[i].default_language.code] = {...this.sCountries[i].default_language}
        }
      }
      for (let key in l) {
        langs.push(l[key])
      }

      return langs
    },

  },
  methods: {

      visibleTo (groupName){
        return this.theme.userGroups.indexOf(groupName) > -1;
      },

      removeAllIdsFromFormData(){
        this.formData.id.value = null;
        this.formData.user.value = null;
      },

      fetchDeviceCriteria(){
        let that = this;

        larryGoogleDeviceCriteriaService.get().then(response => {
          that.deviceCriteria = response.data['results']
          that.setObjectMultiselectDeviceCriteria()
        })
      },

      fetchPlacementCriteria(){
        let that = this;

        larryGooglePlacementCriteriaService.get().then(response => {
          that.placementCriteria = response.data['results']
          that.setObjectMultiselectPlacementCriteria()
        })
      },

      fetchSettingsById(id) {
        let that = this;

        this.isLoading = true;
        creationSettingsService.getById(id).then(response => {
          that.populateForm(response.data)
          this.isLoading = false
        }).catch(r => {
          alert('An Error Occurred.')
          console.dir(r);
        })
      },

      fetchSettingsByUserId(userId) {
        let that = this;
        this.isLoading = true;
        creationSettingsService.get({
          'user': userId,
          'network': this.formData.network.value,
          'agency_feed': this.formData.agencyFeed.value
        }).then(response => {
          that.isLoading = false;
          if (response.data['results'].length === 0) {
            return alert('No configurations found for this user, please try another one.')
          }
          that.populateForm(response.data['results'][0])
          that.removeAllIdsFromFormData()

        }).catch(r => {
          alert('An Error Occurred.')
          console.dir(r);
        })
      },

      fetchAccountManagers(agencyFeed){
        let that = this;

        administrationAccountManagerService.getAll({
          'agency_feed__code': agencyFeed,
          'dealer': 1,
        }).then(response => {
          that.accountManagers = response.data['results'];
          that.setObjectMultiselectAccountManagers()
        })

      },

      isValid(){
        let isValid = true;

        if(this.formData.description.value.length === 0){
          isValid = false;
          this.formData.description.hasError = !isValid;
        }else{
          this.formData.description.hasError = false;
        }

        if(this.isEmpty(this.formData.network.value)){
          isValid = false;
          this.formData.network.hasError = !isValid;
        }else{
          this.formData.network.hasError = false;
        }

        if(this.isEmpty(this.formData.user.value)){
          isValid = false;
          this.formData.user.hasError = !isValid;
        }else{
          this.formData.user.hasError = false;
        }

        if(this.formData.adMultiplier.value.length === 0){
          isValid = false;
          this.formData.adMultiplier.hasError = !isValid;
        }else{
          this.formData.adMultiplier.hasError = false;
        }

        if(this.visibleTo('IsSuperUser')){
          if (this.formData.subdomainMultiplier.value.length === 0) {
            isValid = false;
            this.formData.subdomainMultiplier.hasError = !isValid;
          } else {
            this.formData.subdomainMultiplier.hasError = false;
          }

          if (this.formData.maxClones.value.length === 0) {
            isValid = false;
            this.formData.maxClones.hasError = !isValid;
          } else {
            this.formData.maxClones.hasError = false;
          }
        }

        if(this.formData.deviceCriteria.value.length === 0){
          isValid = false;
          this.formData.deviceCriteria.hasError = !isValid;
        }else{
          this.formData.deviceCriteria.hasError = false;
        }

        if(this.formData.placementCriteria.value.length === 0){
          isValid = false;
          this.formData.placementCriteria.hasError = !isValid;
        }else{
          this.formData.placementCriteria.hasError = false;
        }

        for (let i in this.formData.countrySettings) {

          let countrySetting = this.formData.countrySettings[i];

          if (this.isEmpty(countrySetting.accountCurrencyCode.value)) {
            isValid = false;
            countrySetting.accountCurrencyCode.hasError = !isValid;
          } else {
            countrySetting.accountCurrencyCode.hasError = false;
          }

          if (countrySetting.biddingStrategy.value) {
            let biddingStrategy = this.biddingStrategyDetails(countrySetting.biddingStrategy.value);
            if (biddingStrategy['is_bid_handled']) {
              if (this.isEmpty(countrySetting.bidAmount.value)) {
                isValid = false;
                countrySetting.bidAmount.hasError = !isValid;
              } else {
                countrySetting.bidAmount.hasError = false;
              }

              let bidAmountParts = countrySetting.bidAmount.value.toString().split('.');
              if (bidAmountParts.length > 1 && bidAmountParts[1].length > 2) {
                this.$buefy.snackbar.open({'message':'Bid amount decimals must be maximum 2 places, for example: 0.01', 'position': 'is-bottom'});
                isValid = false;
                countrySetting.bidAmount.hasError = !isValid;
              } else {
                countrySetting.bidAmount.hasError = false;
              }

            } else {
              countrySetting.bidAmount.value = null;
              countrySetting.bidAmount.hasError = false;
            }
          } else {
            countrySetting.bidAmount.hasError = false;
          }

          if(this.visibleTo('IsSuperUser')) {
            if (this.isEmpty(countrySetting.maxBudgetAmount.value)) {
              isValid = false;
              countrySetting.maxBudgetAmount.hasError = !isValid;
            } else {
              countrySetting.maxBudgetAmount.hasError = false;
            }
          }

          if (this.isEmpty(countrySetting.budgetAmount.value)) {
            isValid = false;
            countrySetting.budgetAmount.hasError = !isValid;
          } else {
            countrySetting.budgetAmount.hasError = false;
          }

          if (this.isEmptyAllowZero(countrySetting.dailyLimit.value)) {
            isValid = false;
            countrySetting.dailyLimit.hasError = !isValid;
          } else {
            countrySetting.dailyLimit.hasError = false;
          }

          if (countrySetting.biddingStrategy.value === null) {
            isValid = false;
            countrySetting.biddingStrategy.hasError = !isValid;
          } else {
            countrySetting.biddingStrategy.hasError = false;
          }

          if (this.isEmpty(countrySetting.languages.value) || countrySetting.languages.value.length === 0) {
            isValid = false;
            countrySetting.languages.hasError = !isValid;
          } else {
            countrySetting.languages.hasError = false;
          }
        }

        return isValid;
      },

      isEmpty(val){
        return !val || val === '';
      },

      isEmptyAllowZero(val){
        if(val === 0 || val === '0'){
          return false;
        }
        return !val || val === '';
      },

      populateForm(data){
        this.formData.id.value = data['id'];
        this.formData.description.value = data['description'];
        this.formData.allowAutoQueue.value = data['allow_auto_queue'];
        this.formData.adMultiplier.value = data['json_value']['ad_multiplier'];
        this.formData.subdomainMultiplier.value = data['subdomain_multiplier'];
        this.formData.maxClones.value = data['max_clones'];
        this.formData.campaignStatus.value = data['json_value']['campaign_status'];
        this.formData.launchingType.value = data['launching_type'];
        this.formData.adType.value = data['ad_type'];
        this.formData.urlRotationType.value = data['url_rotation_type'];
        this.formData.adGroupStatus.value = data['json_value']['adgroup_status'];
        this.formData.adStatus.value = data['json_value']['ad_status'];
        this.formData.deviceCriteria.value = data['json_value']['device_criteria'];
        this.formData.placementCriteria.value = data['json_value']['placement_criteria'];
        this.formData.isActive.value = data['is_active'];
        this.formData.user.value = data['user'];
        this.formData.accountManagers.value = data['account_managers'];
        vue.set(this.formData.watermarksFrames, 'value', {
          'watermarks': data['json_value']['watermarks'],
          'frames': data['json_value']['frames'],
        });
        this.formData.agencyFeed.value = data['agency_feed'];
        this.formData.network.value = data['network'];
        this.formData.excludeLaunch.value = data['exclude_launch_if_older_than_days'];

        this.formData.countrySettings = [];

        for(let i in data['json_value']['country_settings']) {
          let countrySettings = data['json_value']['country_settings'][i];

          let language_value = []
          countrySettings['language_codes'].filter(lang => {
            language_value.push(this.filteredlanguages.find(l => l.id === lang || l.code === lang))
          })

          this.formData.countrySettings.push({
            country: {
              value: i,
            },
            accountCurrencyCode: {
              value: countrySettings['account_currency_code'],
              hasError: false
            },
            bidAmount: {
              value: countrySettings['bid_amount'],
              hasError: false
            },
            budgetAmount: {
              value: countrySettings['budget_amount'],
              hasError: false
            },
            maxBudgetAmount: {
              value: countrySettings['max_budget'],
              hasError: false
            },
            dailyLimit: {
              value: countrySettings['daily_limit'],
              hasError: false
            },
            biddingStrategy: {
              value: countrySettings['bidding_strategy_code'],
              hasError: false
            },
            languages: {
              value: language_value,
              hasError: false
            }
          });
        }

        if(!this.isMarketingUntrusted()){
          this.fetchAccountManagers()
        }
        this.setObjectMultiselectDeviceCriteria()
        this.setObjectMultiselectPlacementCriteria()

        this.fillNewCountries()

      },

      prePopulateByUser(){

        if(!this.cloneFromUser){
          return alert('Please select a user.');
        }

        if (!this.formData.agencyFeed.value) {
          return alert('Please select an agency feed to copy from.');
        }

        if (!this.formData.network.value) {
          return alert('Please select a network to copy from.');
        }

        this.fetchSettingsByUserId(this.cloneFromUser.id);

      },

      setObjectMultiselectDeviceCriteria(){

        if(this.deviceCriteria.length === 0){
          return;
        }

        let indexed = {};

        for(let i in this.deviceCriteria) {
          indexed['id_' + this.deviceCriteria[i]['id']] = this.deviceCriteria[i];
        }

        for(let i in this.formData.deviceCriteria.value) {
          let row = this.formData.deviceCriteria.value[i];

          if (typeof row === "object") {
            continue
          }

          Vue.set(this.formData.deviceCriteria.value, i, indexed['id_' + row]);
        }

      },

      setObjectMultiselectAccountManagers(){

        let indexed = {};

        for(let i in this.accountManagers) {
          indexed['id_' + this.accountManagers[i]['id']] = this.accountManagers[i];
        }

        let formAccountManagers = this.formData.accountManagers.value;

        for(let i in formAccountManagers) {
            let row = formAccountManagers[i];

            if (typeof row === "object") {
              continue
            }

            if(!('id_' + row in indexed)){
              // alert('Attention, the account manager: ' + row + ' is not available in list, do not save!' + JSON.stringify(this.accountManagers));
              continue;
            }

            Vue.set(
                this.formData.accountManagers.value,
                i,
                indexed['id_' + row]
            );
        }

      },

      setObjectMultiselectPlacementCriteria(){

        if(this.placementCriteria.length === 0){
          return;
        }

        let indexed = {};

        for(let i in this.placementCriteria) {
          indexed['id_' + this.placementCriteria[i]['id']] = this.placementCriteria[i];
        }

        for(let i in this.formData.placementCriteria.value) {
          let row = this.formData.placementCriteria.value[i];

          if (typeof row === "object") {
            continue
          }

          Vue.set(this.formData.placementCriteria.value, i, indexed['id_' + row]);
        }

      },

      biddingStrategyDetails: function (biddingStrategyCode){
         let r = this.$store.state.biddingStrategies.allGDN.filter((row) => row.code === biddingStrategyCode);
         return r[0];
      },

      fillNewCountries(){
        this.fillNewCountry()
      },

    fillNewCountry(agencyFeed){

        agencyFeed = agencyFeed || this.formData.agencyFeed.value;

        let p;
        if(this.agencyFeeds.all.filter(c => c.id === agencyFeed)[0]?.feed.code === 'PARKED_DOMAIN'){
          this.fCountries = devCountriesService.getAllFullList;
          p = {};
          this.sCountries = this.countries.allFullList;
        }else{
          this.fCountries = devCountriesService.getAll;
          p = {
            'agency_feed': agencyFeed
          };
          this.sCountries = this.countries.all;
        }

        this.fCountries(p).then(response => {

          let newCountries = response.data['results'],
              newCountriesIndexed = {};

          for(let i in newCountries){
            newCountriesIndexed[newCountries[i]['code']] = newCountries[i];
          }

          for(let i in this.formData['countrySettings']){
            let countryCode = this.formData['countrySettings'][i]['country']['value'];
            delete newCountriesIndexed[countryCode];
          }

          for(let countryCode in newCountriesIndexed){
            let language_value = this.sCountries.find(c => c.code === countryCode)?.default_language

            Vue.set(this.formData.countrySettings, this.formData.countrySettings.length, {
              country: {
                value: countryCode,
              },
              accountCurrencyCode: {
                value: null,
                hasError: false,
              },
              bidAmount: {
                value: null,
                hasError: false,
              },
              biddingStrategy: {
                value: null,
                hasError: false,
              },
              maxBudgetAmount: {
                value: 35,
                hasError: false,
              },
              budgetAmount: {
                value: 0,
                hasError: false,
              },
              dailyLimit: {
                value: 0,
                hasError: false,
              },
              languages: {
                value: [language_value],
                hasError: false,
              },
            })
          }
        })
      },

      getNormalizedFormData(){
        let data = {
          'id': this.formData.id.value,
          'json_value': {
            "ad_status": this.formData.adStatus.value,
            "adgroup_status": this.formData.adGroupStatus.value,
            "ad_multiplier": parseInt(this.formData.adMultiplier.value),
            "campaign_status": this.formData.campaignStatus.value,
            "device_criteria": this.formData.deviceCriteria.value.map((e) => {
              return typeof e === 'object' ? e.id : e
            }),
            "placement_criteria": this.formData.placementCriteria.value.map((e) => {
              return typeof e === 'object' ? e.id : e
            }),
            "country_settings": {},
            "frames": this.formData.watermarksFrames.value['frames'],
            "watermarks": this.formData.watermarksFrames.value['watermarks'],
          },
          "launching_type": this.formData.launchingType.value,
          "url_rotation_type": this.formData.urlRotationType.value,
          'description': this.formData.description.value,
          'exclude_launch_if_older_than_days': this.formData.excludeLaunch.value,
          'allow_auto_queue': this.formData.allowAutoQueue.value,
          'subdomain_multiplier': this.formData.subdomainMultiplier.value,
          'max_clones': this.formData.maxClones.value,
          'is_active': this.formData.isActive.value,
          'user': this.formData.user.value.id,
          'network': this.formData.network.value,
          'agency_feed': this.formData.agencyFeed.value,
          'account_managers': this.formData.accountManagers.value.map((e) => {
            return typeof e === 'object' ? e.id : e
          }),
          'ad_type': this.formData.adType.value,
        };

        for (let i in this.formData.countrySettings) {
          let countrySetting = this.formData.countrySettings[i],
              languages = [];

          if (countrySetting.languages.value) {
            languages = countrySetting.languages.value.map((e) => {
              return typeof e === 'string' ? e : e.code
            })
          }

          data['json_value']['country_settings'][countrySetting.country.value] = {
            'account_currency_code': countrySetting.accountCurrencyCode.value,
            'bid_amount': countrySetting.bidAmount.value,
            'budget_amount': countrySetting.budgetAmount.value,
            'max_budget': countrySetting.maxBudgetAmount.value,
            'daily_limit': countrySetting.dailyLimit.value,
            'bidding_strategy_code': countrySetting.biddingStrategy.value,
            'language_codes': languages,
          };
        }

        return data;
      },

      save() {

        if(!this.isValid()){
          return alert('Please resolve the errors and try again.');
        }

        //validate agencyFeed
        if(this.isEmpty(this.formData.agencyFeed.value)) {
          this.formData.agencyFeed.hasError = true;
          return alert('Select an agency feed, in order to continue.');
        } else { this.formData.agencyFeed.hasError = false }

        let data = this.getNormalizedFormData(),
          that = this;

        this.isLoading = true;

        creationSettingsService.save(data).then((e) => {
          that.isLoading = false;
          console.dir(e);
          this.$emit('saved');
        }).catch((e) => {
          that.isLoading = false;
          alert(e.response.data.details);
          console.dir(e);
        });

      },

      cancel(){
        this.$emit('cancel');
      },
      fetchLanguages(f) {
        let that = this;

        devLanguagesService.getAll().then(response => {

          let languages = [];

          for(let i in response.data['results']){
            let row = response.data['results'][i];
            languages.push(row.code);
          }

          that.languages = languages;
          if(f){
            f();
          }
        })
      },

      fetchListOfCurrencies(){
      let that = this;
      administrationAccountsService.getAll({
        'is_active': 'True',
        'is_for_new_creations': 'True',
        'blocked_on_datetime__isnull': 'True',
        'network__code': 'GDN',
        'agency_feed': this.formData.agencyFeed.value
      }).then(e => {
        let currencies = [];
        for(let a in e.data.results){
          let r = e.data.results[a];

          if(currencies.indexOf(r['currency_code']) === -1){
            currencies.push(r['currency_code'])
          }
        }
        that.currencies = currencies;
      })
    },

    getCountryName(code) {
      return this.sCountries.find(c => c.code === code)?.name
    },

    fillEmpties(countrySetting, rowId) {
       if(!countrySetting.accountCurrencyCode.value) {
        this.formData.countrySettings[rowId].accountCurrencyCode.value = 'USD'
       }

       if(!countrySetting.biddingStrategy.value && !countrySetting.bidAmount.value) {
        this.formData.countrySettings[rowId].bidAmount.value = 0.01
      }
      
      if(!countrySetting.biddingStrategy.value) {
        //if google is the selected network
        
        if(this.formData.network.value === '1') {
          // update the main value
          this.formData.countrySettings[rowId].biddingStrategy.value = 'MANUAL_CPC'
          //update the view component
          const bsId = 'bs_'+rowId
          this.$refs[bsId][0].updateFromEmptyFillers('MANUAL_CPC')
        }

      }

       if(!countrySetting.budgetAmount.value) {
        this.formData.countrySettings[rowId].budgetAmount.value = 1
       }
    },

    fillAllEmpties() {
      const self = this
      for(let i in this.formData.countrySettings) {
        self.fillEmpties(this.formData.countrySettings[i], i)
      }
    },

    getLanguageLabel(langs) {
      let row
      for(let i in this.formData.countrySettings) {
        row = this.formData.countrySettings[i].languages.value
        row = row.map(e => {
          return langs.find(s => s?.id === e || s?.code === e)
        })
        this.formData.countrySettings[i].languages.value = row
      }
    },

    fetchCurrencies() {
      this.$store.dispatch('currencies/getAll', {agency_feed: this.formData.agencyFeed.value});
    },

    isBidHandled(biddingStrategyCode) {
      return this.biddingStrategyDetails(biddingStrategyCode)?.is_bid_handled;
    },

    updateBiddingStrategy(val, r) {
      this.formData.countrySettings[r].biddingStrategy.value = val
      this.formData.countrySettings[r].bidAmount.value = null
      //focus bid amount
      const bidId = 'bid_'+r
      this.$refs[bidId][0].$refs.input.focus()
    },

    toggleCreativesModal() {
      this.creativeModal = !this.creativeModal;
    },

  },
  watch: {
    'formData.agencyFeed.value': function (newValue){

      if(!this.isMarketingUntrusted()){
        this.fetchAccountManagers(newValue);
      }
      this.fetchLanguages(() => this.fillNewCountry(newValue));
      this.fetchCurrencies();
    }
  }
}
</script>