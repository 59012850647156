<style scoped>
/* Firefox old*/
@-moz-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

/* Opera and prob css3 final iteration */
@keyframes blink {
    0% {
        opacity:0.8;
    }
    50% {
        opacity:0.5;
    }
    100% {
        opacity:0.8;
    }
}

</style>
<template>
  <div>
    <section class="section is-main-section" v-if="this.foundKeywordsCount === -1" >
        <b-message
            title="Click To Start"
            type="is-success"
            class="has-text-centered">

          <b-field style="max-width: 800px; margin: 0 auto;" class="is-centered">
            <multiselect
              v-model="selectedNetworks"
              :options="networks.all"
              :multiple="true"
              :close-on-select="true"
              placeholder="Networks"
              label="name"
              track-by="id">
            </multiselect>
          </b-field>
          <b-field style="max-width: 800px; margin: 0 auto;" class="is-centered">
            <multiselect
              v-model="selectedCountries"
              :options="countries.all"
              :multiple="true"
              :close-on-select="true"
              placeholder="Countries"
              label="name"
              track-by="id">
            </multiselect>
          </b-field>
          <b-field style="max-width: 800px; margin: 0 auto;" class="is-centered">
            <b-input
              v-model="filterKeyword"
              placeholder="Keyword Contains">
            </b-input>
          </b-field>
          <b-button type="is-primary" size="is-large" class="mt-1" @click="start" :disabled="isLoading">Start!</b-button>
        </b-message>
    </section>
    <section class="section is-main-section" v-if="this.foundKeywordsCount === 0" >
        <b-message
            title="Success"
            type="is-success"
            aria-close-label="Close message">
            Well done! You are up-to-date.. Try again latter.
        </b-message>
    </section>
    <section class="section is-main-section" v-if="'keyword' in foundKeyword && this.foundKeywordsCount > 0" >
      <b-loading v-model="isLoading"></b-loading>
      <b-navbar fixed-bottom>
        <template #start>
            <b-navbar-item tag="div"></b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item tag="div">
              <span>
                <strong v-if="foundKeyword.title">
                  Article: {{ foundKeyword.title }}
                </strong>
                <strong v-else>
                  Keyword: {{ hemingwayDomainKeywords.one['translated_name'] }}
                </strong>
                <span class="px-1">&lt;&gt;</span></span>

              <div  v-if="'id' in hemingwayDomainKeywords.one">
                <span v-for="a in foundKeyword.networks" v-bind:key="`networks-${a.id}`">
                  Networks:
                  <strong>{{ a.name }}</strong> <span class="px-1">&lt;&gt;</span>
                </span>

                  <div v-if="!isMarketingUntrusted() && hemingwayDomainKeywords.one['text_understanding'] && hemingwayDomainKeywords.one['text_understanding']['ibm_watson'] && hemingwayDomainKeywords.one['text_understanding']['ibm_watson']['categories']">
                    <span v-for="a in hemingwayDomainKeywords.one['text_understanding']['ibm_watson']['categories']" v-bind:key="`ibm-categories-${a.label}-${a.score}`">
                      Categories: 
                    <strong>{{ a.label }} ({{ a.score }})</strong> <span class="px-1">&lt;&gt;</span>
                  </span>
                </div>

                <div v-if="!isMarketingUntrusted() && hemingwayDomainKeywords.one['text_understanding'] && hemingwayDomainKeywords.one['text_understanding']['ibm_watson'] && hemingwayDomainKeywords.one['text_understanding']['ibm_watson']['entities']">
                  <span v-for="a in hemingwayDomainKeywords.one['text_understanding']['ibm_watson']['entities']" v-bind:key="`ibm-entities-${a.type}-${a.text}`">
                    Entities: 
                    <strong>{{ a.type }}: {{ a.text }}</strong><span class="px-1">&lt;&gt;</span>
                  </span>
                </div>

                <div v-if="!isMarketingUntrusted() && hemingwayDomainKeywords.one['text_understanding'] && hemingwayDomainKeywords.one['text_understanding']['ibm_watson'] && hemingwayDomainKeywords.one['text_understanding']['ibm_watson']['concepts']">
                  Concepts:
                  <span v-for="a in hemingwayDomainKeywords.one['text_understanding']['ibm_watson']['concepts']" v-bind:key="`ibm-concepts-${a.text}-${a.relevance}`">
                    <strong>{{ a.text }} - {{ a.dbpedia_resource }} ({{ a.relevance }})</strong><span class="px-1">&lt;&gt;</span>
                  </span>
                </div>

                <span v-for="a in foundKeyword.agency_feeds" v-bind:key="`agency-feeds-${a.id}`">
                  Agency Feeds:
                  <strong>{{ a[getFieldCodeLabel()] }}</strong><span class="px-1">&lt;&gt;</span>
                </span>
                <span>
                  Locale:
                  <strong><u>{{ foundKeyword.country.name }}</u></strong><span class="px-1">&lt;&gt;</span>
                </span>
                <span v-for="a in foundKeyword.languages" v-bind:key="`languages-${a.id}`">
                  {{ a.name }}<span class="px-1">&lt;&gt;</span>
                </span>
                <span v-if="!isMarketingUntrusted()">
                  Sources:
                  <span v-for="a in foundKeyword.sources" v-bind:key="`found_keyword_source_code-${a.id}`">
                    <strong>{{ a.found_keyword_source_code }} </strong>
                  </span> <span class="px-1">&lt;&gt;</span>
                </span>
              </div>

              <span> Images Added (Current Session): {{ counterImagesAdded }}</span>
              <div v-if="selectedImages['selectedImages'].length > 0">
                <span class="px-1">&lt;&gt;</span><strong>Selected Images ({{ selectedImages['selectedImages'].length }} / 10)</strong>
              </div>
            </b-navbar-item>
        </template>
      </b-navbar>

      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent">
              
              <div class="tile is-child notification">
                <p class="title">(1 of {{ foundKeywordsCount }}) Keyword:
                  <u>{{ foundKeyword.keyword.name }}</u>
                  <b-button size="is-small" type="is-light is-warning" class="mt-1 ml-2" @click="showHowToPickImageModal = true">How to pick images?</b-button>
                </p>
                <p class="subtitle mb-2">Select at 10 images or Ignore:</p>
                <image-picker
                  :key="rerenderImagePickerComponent"
                  :default-search-term="foundKeyword.keyword.name + ''"
                  :original-keyword="foundKeyword.keyword.name + ''"
                  copy-paste-enabled
                  v-model="selectedImages">
                  <template #left-buttons-box>
                    <b-button class="is-primary m-1" @click="save" :disabled="isLoading">Save</b-button>
                  </template>
                </image-picker>
              </div>
          </div>
        </div>
      </div>
      </div>
    </section>

    <b-modal v-model="showHowToPickImageModal">
      <section class="modal-card-body p-3 is-flex is-justify-content-space-between">
        <div style="max-height: 40rem;">
                <strong>Notes About Image:</strong>
                <ul>
                  <li>- Not nude, sexy or provocative images</li>
                  <li>- Avoid images with watermarks: sales, deals, Christmas decoration, etc.</li>
                  <li>- Avoid images with text or fake buttons inside. For example a television with buttons inside.</li>
                </ul>

                <strong>Important Examples:</strong>

                <ul>
                  <li>- Focus on the Product (when possible):<br />
                    <span>Example "expressvpn discount" search for "expressvpn" or "vpn"</span><br />
                    <span>"Cheap Hotel Dublin" search for "Dublin"</span>
                  </li>

                  <li>
                    - Choose Real Photo: <br />
                    <span>Sometimes is not possible but images like this are ok:</span><br />
                    <img src="https://picasso-pytech.s3.amazonaws.com/thumbnails_images/2022/01/10/system-engineering-concept-engineers-working-in-the-office-gui-pict.jpg" alt="image" />
                  </li>

                  <li>
                    - Avoid duplicated images, always.
                  </li>

                  <li>
                    - Never use images with watermarks (for example Shutterstock):<br />
                    <span></span>
                    <img src="https://image.shutterstock.com/image-photo/woman-car-key-600w-367361063.jpg" alt="image" />
                  </li>
                  <li>
                    - Never use images with only text (This one in particular contains the word DEAL which is not allowed):<br />
                    <span></span>
                    <img src="https://picasso-pytech.s3.amazonaws.com/thumbnails_images/2022/01/10/great-deal-word-on-white-isolated-background-picture-id506759726.jpg" alt="image" />
                  </li>
                  <li>
                    - Never use images with icons, infographis and clip-arts:<br />
                    <span></span>
                    <img src="https://picasso-pytech.s3.amazonaws.com/thumbnails_images/2022/01/10/shopping-and-ecommerce-line-icons-editable-stroke-pixel-perfect-for.jpg" alt="image" />
                    <img src="https://picasso-pytech.s3.amazonaws.com/thumbnails_images/2022/01/10/growth-chart-stats-big-sale-sellout-retail-black-friday-discount-an.jpg" alt="image" />
                    <img src="https://picasso-pytech.s3.amazonaws.com/thumbnails_images/2022/01/10/red-realistic-textured-sell-tag-with-rope-vector-id1175753036.jpg" alt="image" />
                  </li>

                  <li>
                    - Never use images with fake images, as they confuse users:<br />
                    <span></span>
                    <img src="https://picasso-pytech.s3.amazonaws.com/full_images/2021/03/11/8027647fa8fc6d40ccef.jpeg" alt="image" />
                  </li>

                  <li>
                    - Never use images sexual, nude or provocative:<br />
                    <span></span>
                    <img src="https://picasso-pytech.s3.amazonaws.com/full_images/2021/04/11/a2bd3729e6551491790d.jpeg" alt="image" />
                  </li>
                  <li>
                    - Never use images with offers watermarks: sales, deals, offers, discounts<br />
                    <span></span>
                    <img src="https://picasso-pytech.s3.amazonaws.com/full_images/2021/04/17/044545da1ff6239206f0.jpeg" alt="image" />
                  </li>
                </ul>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import {
  creationNoImagesFoundKeywordsService
} from "@/services";
import {mapState} from "vuex";
import {notificatorUtils} from "@/helpers/notificator-utils";
import vue from "vue";
import Multiselect from 'vue-multiselect'
import ImagePicker from "@/components/Media/ImagePicker.vue";
import {permissionsUtils} from "@/helpers/permissions-utils";

export default {
  name: 'NoImagesFoundKeywords',

  mounted() {
    if(!this.networks.all.length) this.$store.dispatch('networks/getAll');
    if(!this.countries.all.length) this.$store.dispatch('countries/getAll');
  },

  mixins: [
    notificatorUtils,
      permissionsUtils
  ],
  components: {
    Multiselect,
    ImagePicker,
  },

  data() {
    return {
      lastId: null,
      preLoad: 1,
      foundKeywordsCount: -1,
      foundKeywords: [],
      foundKeyword: {},
      selectedImages: [],

      isLoading: false,

      searchImageQuery: '',
      counter: 0,
      counterImagesAdded: 0,

      selectedNetworks: [],
      selectedCountries: [],
      filterKeyword: null,

      rerenderImagePickerComponent: 0,

      showHowToPickImageModal: false,
    }
  },

  computed: {
    ...mapState([
      'toolsImageSearcher',
      'hemingwayDomainKeywords',
    ]),
    ...mapState('theme', ['userName']),

    ...mapState([
      'networks',
      'countries',
    ]),
  },

  methods: {

    start() {
      this.loadData(true)
    },

    loadData(selectOne) {


      let params = {
      };

      if(this.selectedCountries.length > 0){
        params['country__in'] = this.selectedCountries.map((element) => { return element.id }).join(',');
      }

      if(this.selectedNetworks.length > 0){
        params['networks__in'] = this.selectedNetworks.map((element) => { return element.id }).join(',');
      }

      if(this.filterKeyword !== '' && this.filterKeyword){
        params['keyword__name__icontains'] = this.filterKeyword;
      }

      params['page_size'] = this.preLoad;

      this.isLoading = true;

      creationNoImagesFoundKeywordsService.get(params).then(
        response => {
          let that = this;

          if(selectOne) {
            setTimeout(function () {
              that.isLoading = false;
            }, .5 * 1000);
          }

          if(response.data['results']){
            this.foundKeywordsCount = response.data['count'];

            if(this.foundKeywordsCount === 0){
              return;
            }

            for(let i in response.data['results']){
              vue.set(this.foundKeywords, this.foundKeywords.length, response.data['results'][i]);
            }

            if(selectOne){
              this.openOne();
            }
          }else{
            alert('All keywords have images. Well done!');
          }
        },
        error =>
        {
          alert('An Error Occurred 2');
          console.error(error);
          this.isLoading = false;
        });
    },

    openOne(){

      this.selectedImages = [];

      if(this.foundKeywords.length > 0){
        this.foundKeyword = JSON.parse(JSON.stringify(this.foundKeywords[0]));

        if(this.foundKeyword['id'] !== this.lastId){
            this.lastId = this.foundKeyword['id'];
            this.searchImageQuery = this.foundKeyword.keyword.name;
            this.counter = this.counter + 1;
            this.onAfterRecordLoaded();
            this.rerenderImagePickerComponent += 1;
            return;
        }else{
            // Work around race condition, when saving and getting at the same time,
            // you may get the same keyword again.
            return;
        }

      }

      this.loadData(true);

    },

    onAfterRecordLoaded() {
      let that = this;

      this.$store.dispatch('hemingwayDomainKeywords/getOneByKeywordName', {
        'keywordName': that.foundKeyword.keyword.name
      });

    },

    save() {
      for(let i in this.selectedImages['selectedImages']){
        this.$store.dispatch('foundKeywordImages/save', {
          foundKeywordId: this.foundKeyword.id,
          imageHashUnique: this.selectedImages['selectedImages'][i].hash_unique
        });
      }

      this.counterImagesAdded += this.selectedImages['selectedImages'].length;
      this.foundKeywords = [];

      this.next();
    },

    getImgUrl(obj) {
      let url = obj.url_thumbnail;
      // Remove AWS token
      if(url.indexOf('?') > -1){
        url = url.split('?')
        url = url[0];
      }

      return url;
    },

    next(){
      this.openOne();
    }
  }
}
</script>