<template>
  <div>
    <b-table
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="score"
      :data="data"


      pagination-position="top"
      :pagination-rounded="false"
      sort-icon="chevron-up"
      sort-icon-size="is-medium"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"

      :total="total"
      :current-page="currentPage"

      @page-change="changePage"
      backend-pagination

      backend-sorting
      @sort="sortPressed"
    >
      <b-table-column label="AdUnit Code" field="adunit.code" sortable v-slot="props">
        <a :href="`/tq-analysis?adUnitCode=${props.row.adunit.code}&countryCode=${props.row.country.code}`" target="_blank">
          {{ props.row.adunit.code }}
        </a>
      </b-table-column>
      <b-table-column label="AdUnit Name" field="adunit.name" sortable v-slot="props">
        {{ props.row.adunit.name }}
      </b-table-column>
      <b-table-column label="Country" field="country.name" sortable v-slot="props">
        {{ props.row.country.name }}
      </b-table-column>
      <b-table-column label="Score" field="score" sortable>

        <template v-slot:header="{ column }">
          <span>{{ column.label }}</span><br />
          {{ renderNumber(overalls.avg_score, 3) }}
        </template>
        <template v-slot="props">
          {{ renderNumber(props.row.score, 2) }}
        </template>

      </b-table-column>
      <b-table-column label="Revenue" field="revenue_amount_net" sortable>

        <template v-slot:header="{ column }">
          <span>{{ column.label }}</span><br />
          {{ renderCurrency(overalls.sum_revenue, 2) }}
        </template>
        <template v-slot="props">
          {{ renderCurrency(props.row.revenue_amount_net, 2) }}
        </template>

      </b-table-column>
      <b-table-column label="Clicks In" field="clicks_in" sortable>

        <template v-slot:header="{ column }">
          <span>{{ column.label }}</span><br />
          {{ renderNumber(overalls.sum_clicks_in, 0) }}
        </template>
        <template v-slot="props">
          {{ props.row.clicks_in }}
        </template>

      </b-table-column>
      <b-table-column label="Clicks Out" field="clicks_out" sortable>

        <template v-slot:header="{ column }">
          <span>{{ column.label }}</span><br />
          {{ renderNumber(overalls.sum_clicks_out, 0) }}
        </template>
        <template v-slot="props">
          {{ props.row.clicks_out }}
        </template>

      </b-table-column>
    </b-table>
  </div>
</template>

<script>

import {numberUtils} from "@/helpers/number-utils";

export default {
  name: 'TqScoresTable',
  mixins: [
    numberUtils
  ],
  props: {
    data: {
      type: Array,
      default: null
    },

    overalls: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    paginated: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 50
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      checkedRows: []
    }
  },

  methods: {

    changePage() {
      this.$emit('page-change', ...arguments)
    },

    sortPressed() {
      this.$emit('sort-pressed', ...arguments)
    }
  }
}
</script>
