import moment from "moment-timezone";

export const timeUtils = {
    methods: {
        setSelectedDate(code) {
            let d;
            if (code === 'TODAY') {
                d = new Date();
                this.selectedDate = d;
            } else if (code === 'YESTERDAY') {
                d = new Date() - 1000 * 60 * 60 * 24;
                this.selectedDate = new Date(d);
            }
        },

        setSelectedDates(dateCode, propertyName) {
            let range = [];
            if(dateCode==='THIS_MONTH'){
                range = [
                  moment().clone().startOf('month').toDate(),
                  moment().clone().endOf('month').toDate()
                ];
            }else if(dateCode==='PREVIOUS_MONTH'){
                range = [
                  moment().clone().subtract(1, 'months').startOf('month').toDate(),
                  moment().clone().subtract(1, 'months').endOf('month').toDate()
                ];
            }else if(dateCode==='LAST_7_DAYS'){
                range = [
                  moment().clone().subtract(8, 'days').toDate(),
                  moment().clone().toDate()
                ];
            }else if(dateCode==='YESTERDAY'){
                range = [
                  moment().clone().subtract(1, 'days').toDate(),
                  moment().clone().subtract(1, 'days').toDate(),
                ];
            }else if(dateCode==='TODAY'){
                range = [
                  moment().clone().toDate(),
                  moment().clone().toDate(),
                ];
            }else{
                this.$buefy.snackbar.open(dateCode + ' is not within the valid options.');
            }

            if(propertyName){
              this[propertyName] = range;
            }

            return range;
        },

        dateToSqlString(date) {
            return moment(date).format('YYYY-MM-DD')
        },

        datetimeToSqlString(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },

        formatDate(date, format) {
            return moment(date).format(format)
        },
    }
}
