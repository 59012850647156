import { administrationProductsService } from '@/services';

export const products = {
    namespaced: true,
    state: {
        all: [],
        allProductsWithAvailableUrlCodes: [],
        loading: false
    },
    actions: {
        getAll({ commit }, payload) {
            commit('getAllRequest');

            payload = payload || {};
            payload['agency_feed__in'] = payload['agency_feed__in'] || [];
            payload['network__in'] = payload['network__in'] || [];
            payload['partner__in'] = payload['partner__in'] || [];
            payload['country__in'] = payload['country__in'] || [];
            payload['adunit__in'] = payload['adunit__in'] || [];
            payload['language__in'] = payload['language__in'] || [];

            administrationProductsService.getAll(payload)
                .then(
                    dealers => commit('getAllSuccess', dealers),
                    error => commit('getAllFailure', error)
                );
        },

        getAllProductsWithAvailableUrlCodes({ commit }, payload) {
            commit('getAllProductsWithAvailableUrlCodesRequest');

            payload = payload || {};
            payload['agency_feed__in'] = payload['agency_feed__in'] || [];
            payload['network__in'] = payload['network__in'] || [];
            payload['partner__in'] = payload['partner__in'] || [];
            payload['country__in'] = payload['country__in'] || [];
            payload['adunit__in'] = payload['adunit__in'] || [];
            payload['language__in'] = payload['language__in'] || [];

            administrationProductsService.getAllProductsWithAvailableUrlCodes(payload)
                .then(
                    data => commit('getAllProductsWithAvailableUrlCodesSuccess', data),
                    error => commit('getAllProductsWithAvailableUrlCodesFailure', error)
                );
        },
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, dealers) {
            state.all = dealers.data.results;
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.all = [ error ];
        },
        getAllProductsWithAvailableUrlCodesRequest(state) {
            state.allProductsWithAvailableUrlCodes = [];
            state.loading = true;
        },
        getAllProductsWithAvailableUrlCodesSuccess(state, data) {
            state.allProductsWithAvailableUrlCodes = data.data.results;
            state.loading = false;
        },
        getAllProductsWithAvailableUrlCodesFailure(state, error) {
            state.allProductsWithAvailableUrlCodes = [ error ];
        },
    }
}
