import { optimizationHourlyPerformancesService } from '@/services';

export const hourlyPerformances = {
    namespaced: true,
    state: {
        all: [],
        loading: false
    },
    actions: {
        getAll({ commit }, payload) {
            commit('getAllRequest');

            let routeHashValue = payload['routeHashValue'],
                hours = payload['hours'];

                return new Promise((resolve, reject) => {
                    optimizationHourlyPerformancesService.getAll(
                        routeHashValue,
                        hours
                    )
                        .then(
                            performance => {commit('getAllSuccess', performance); resolve(performance)},
                            error => {commit('getAllFailure', error); reject(error)}
                        );
                })
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results || [];
            state.loading = false;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
        }
    }
}
