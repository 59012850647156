<style>
.period-1-class {
  background-color: #dbdbdb !important;
}
.period-2-class {
  background-color: #ecf4fe !important;
}
.table td {
  vertical-align: middle;
}
</style>
<template>
  <div>
    <section v-if="$store.state.theme.viewMode === 'normal'" class="section is-main-section pb-4" ref="filterstop">
            <dependant-multi-filter
              v-model="selectedMultiFilter"
              range-date-enabled
              products-enabled
              networks-enabled
              agency-feeds-enabled
              countries-enabled
              marketing-users-enabled
              :accounts-enabled="isSuperUser()"
              :marketing-agency-enabled="getUserGroups().some(r => ['IsSuperUser', 'IsBusinessDeveloper'].includes(r))"
              bidding-strategies-enabled
              currencies-enabled

              @filter="filterList"
          ></dependant-multi-filter>

        <div class="is-pulled-right is-hidden-mobile">
          <b-tag rounded type="is-success">
            <b-tooltip label="Keywords in Profit" dashed>
              K+: {{  dailyPerformances.overallsKpi.keywords_in_profit }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ dailyPerformancesPeriodOne.overallsKpi.keywords_in_profit }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ dailyPerformancesPeriodTwo.overallsKpi.keywords_in_profit }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-warning">
            <b-tooltip label="Keywords No Traffic" dashed>
              K0: {{  dailyPerformances.overallsKpi.keywords_no_traffic }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ dailyPerformancesPeriodOne.overallsKpi.keywords_no_traffic }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ dailyPerformancesPeriodTwo.overallsKpi.keywords_no_traffic }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Keywords in Loss" dashed>
              K-: {{  dailyPerformances.overallsKpi.keywords_in_loss }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ dailyPerformancesPeriodOne.overallsKpi.keywords_in_loss }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ dailyPerformancesPeriodTwo.overallsKpi.keywords_in_loss }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="Percentage in Profit" dashed>
              %+: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_profit) }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ renderPercentage(dailyPerformancesPeriodOne.overallsKpi.percentage_in_profit) }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ renderPercentage(dailyPerformancesPeriodTwo.overallsKpi.percentage_in_profit) }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-warning">
            <b-tooltip label="Percentage in No Traffic" dashed>
              %0: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_no_traffic) }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ renderPercentage(dailyPerformancesPeriodOne.overallsKpi.percentage_in_no_traffic) }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ renderPercentage(dailyPerformancesPeriodTwo.overallsKpi.percentage_in_no_traffic) }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Percentage in Loss" dashed>
              %-: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_loss) }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ renderPercentage(dailyPerformancesPeriodOne.overallsKpi.percentage_in_loss) }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ renderPercentage(dailyPerformancesPeriodTwo.overallsKpi.percentage_in_loss) }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="Ratio in Profit" dashed>
              R+: {{  renderNumber(dailyPerformances.overallsKpi.ratio_in_profit, 2) }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ renderNumber(dailyPerformancesPeriodOne.overallsKpi.ratio_in_profit) }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ renderNumber(dailyPerformancesPeriodTwo.overallsKpi.ratio_in_profit) }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Ratio in Loss" dashed>
              R-: {{  renderNumber(dailyPerformances.overallsKpi.ratio_in_loss, 2) }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ renderNumber(dailyPerformancesPeriodOne.overallsKpi.ratio_in_loss) }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ renderNumber(dailyPerformancesPeriodTwo.overallsKpi.ratio_in_loss) }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="ROI in Profit" dashed>
              ROI+: {{  renderPercentage(dailyPerformances.overallsKpi.keywords_in_profit_roi, 2) }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ renderPercentage(dailyPerformancesPeriodOne.overallsKpi.keywords_in_profit_roi) }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ renderPercentage(dailyPerformancesPeriodTwo.overallsKpi.keywords_in_profit_roi) }}</template>
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="ROI in Loss" dashed>
              ROI-: {{  renderPercentage(dailyPerformances.overallsKpi.keywords_in_loss_roi, 2) }}
              <template v-if="selectedRangeDate_period_1.length"> || {{ renderPercentage(dailyPerformancesPeriodOne.overallsKpi.keywords_in_loss_roi) }}</template>
              <template v-if="selectedRangeDate_period_2.length"> || {{ renderPercentage(dailyPerformancesPeriodTwo.overallsKpi.keywords_in_loss_roi) }}</template>
            </b-tooltip>
          </b-tag>
        </div>

        <b-field grouped v-for="(r, rowId) in rules" v-bind:key="`performance-filter-${rowId}`">
                <b-field @keyup.native.delete="e => delWithTab(r, rowId, e)">
                    <b-button
                    type="is-danger"
                    @click="removeRule(r, rowId);"
                    :disabled="rules.length === 1"
                    icon-left="close" />

                    <b-select
                        :ref="'r' + rowId"
                        placeholder="Index"
                        v-model="rules[rowId].indexName"
                        @change.native="setFirstOperator(rowId)"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-name-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Operator"
                        v-model="rules[rowId].indexOperator"
                        :disabled="Object.keys(rules[rowId].indexName).length === 0">
                        <option
                            v-for="(indexOperator, indexOperatorI) in getOperators(rules[rowId].indexName.operators)"
                            :key="`performance-index-operator-${rowId}-${indexOperatorI}`"
                            :value="indexOperator"
                        >
                          {{ indexOperator.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Index"
                        v-model="rules[rowId].indexValueField"
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-value-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>
                    <span
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                        style="padding: 1rem;"
                    >*</span>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexFactor"
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                        style="width: 50px;"
                    ></b-input>

                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="rules[rowId].indexName.indexType === 'number'"
                    ></b-input>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        v-else-if="rules[rowId].indexName.indexType === 'money'"
                    ></b-input>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        prefix="%"
                        v-else-if="rules[rowId].indexName.indexType === 'percentage'"
                    ></b-input>
                    <b-input
                        type="text"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        v-else-if="rules[rowId].indexName.indexType === 'text'"
                    ></b-input>

                    <b-datetimepicker
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="rules[rowId].indexName.indexType === 'datetime'"
                        :datetime-formatter="formatDatetime"
                        :timepicker="{ enableSeconds: true }"
                        placeholder="Click to select...">

                        <template #left>
                            <b-button
                                label="Now"
                                type="is-primary"
                                icon-left="clock"
                                @click="datetime = new Date()" />
                        </template>

                        <template #right>
                            <b-button
                                label="Clear"
                                type="is-danger"
                                icon-left="close"
                                outlined
                                @click="datetime = null" />
                        </template>
                    </b-datetimepicker>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="rules[rowId].indexName.indexType === 'deliveryStatus'"
                    >
                      <option value="DELIVERING">
                        Delivering
                      </option>
                      <option value="NOT_DELIVERING">
                        Not Delivering
                      </option>
                    </b-select>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="rules[rowId].indexName.indexType === 'status'"
                    >
                      <option value="ACTIVE">
                        Active
                      </option>
                    </b-select>
                    <log-optimization-group-filter
                      v-model="rules[rowId].indexValue"
                      @keyup.native.enter="filterList"
                      v-else-if="rules[rowId].indexName.indexType === 'optimizationMessage'"
                    ></log-optimization-group-filter>

                    <log-deletion-group-filter
                      v-model="rules[rowId].indexValue"
                      @keyup.native.enter="filterList"
                      v-else-if="rules[rowId].indexName.indexType === 'deletionMessage'"
                    ></log-deletion-group-filter>

                    <b-input
                        type="text"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        disabled
                        v-else
                    ></b-input>

                  <b-button
                    type="is-success"
                    @click="addRule();"
                    @keyup.tab="addWithTab(rowId)"
                    icon-left="check" />
                  </b-field>
        </b-field>

        <b-field grouped>
          <b-button class="mr-1" @click="togglePeriodDisplay">{{  showPeriodDatePicker ? 'Hide Periods' : 'Compare with Period'   }}</b-button>
          <template v-if="showPeriodDatePicker">
            <b-field
              label="Period 1"
              label-position="on-border"
            >
              <b-datepicker
                v-if="true"
                style="min-width: 16rem;"
                class="form-control is-inline-block"
                placeholder="Period 1..."
                v-model="selectedRangeDate_period_1"
                range>
                <b-button
                    label="Yesterday"
                    type="is-primary"
                    icon-left="calendar"
                    @click="setSelectedDates('YESTERDAY', 'selectedRangeDate_period_1')"/>
                <b-button
                    label="Last 7 days"
                    type="is-primary"
                    icon-left="calendar"
                    @click="setSelectedDates('LAST_7_DAYS','selectedRangeDate_period_1')"/>
                <b-button
                    label="This Month"
                    type="is-primary"
                    icon-left="calendar-today"
                    @click="setSelectedDates('THIS_MONTH','selectedRangeDate_period_1')"/>
                <b-button
                    label="Previous Month"
                    type="is-primary"
                    icon-left="calendar"
                    @click="setSelectedDates('PREVIOUS_MONTH','selectedRangeDate_period_1')"/>

                <b-button 
                    label="Clear"
                    type="is-warning mt-1"
                    icon-left="close"
                    :disabled="!selectedRangeDate_period_1.length"
                    @click="clearPeriod('dailyPerformancesPeriodOne', 1)" />
              </b-datepicker>
            </b-field>

            <b-field
              label="Period 2"
              label-position="on-border"
            >
              <b-datepicker
                v-if="true"
                style="min-width: 16rem;"
                class="form-control is-inline-block"
                placeholder="Period 2..."
                v-model="selectedRangeDate_period_2"
                range>
                <b-button
                    label="Yesterday"
                    type="is-primary"
                    icon-left="calendar"
                    @click="setSelectedDates('YESTERDAY', 'selectedRangeDate_period_2')"/>
                <b-button
                    label="Last 7 days"
                    type="is-primary"
                    icon-left="calendar"
                    @click="setSelectedDates('LAST_7_DAYS','selectedRangeDate_period_2')"/>
                <b-button
                    label="This Month"
                    type="is-primary"
                    icon-left="calendar-today"
                    @click="setSelectedDates('THIS_MONTH','selectedRangeDate_period_2')"/>
                <b-button
                    label="Previous Month"
                    type="is-primary"
                    icon-left="calendar"
                    @click="setSelectedDates('PREVIOUS_MONTH','selectedRangeDate_period_2')"/>

                <b-button 
                    label="Clear"
                    type="is-warning mt-1"
                    icon-left="close"
                    :disabled="!selectedRangeDate_period_2.length"
                    @click="clearPeriod('dailyPerformancesPeriodTwo', 2)" />
              </b-datepicker>
            </b-field>
          </template>
        </b-field>

        <b-field>
            <strong>
              {{ !showActive && !showPaused && !showRemoved ? '(showing all)': '' }}
            </strong>
            <b-switch v-model="showActive">
                {{ showActive ? 'Showing Active' : 'Hiding Active' }}
            </b-switch>
            <b-switch v-model="showPaused">
                {{ showPaused ? 'Showing Paused' : 'Hiding Paused' }}
            </b-switch>
            <b-switch v-model="showRemoved">
                {{ showRemoved ? 'Showing Removed' : 'Hiding Removed' }}
            </b-switch>
        </b-field>
      </section>

      <ViewModes />
      
      <section class="section is-main-section" style="padding-top: 0;">
        <span class="is-hidden-desktop">
            Rev: {{ renderCurrency(dailyPerformances.overalls.sum_revenue_amount_net, 2) }}
            Costs: {{ renderCurrency(dailyPerformances.overalls.sum_cost_amount, 2) }}
            Profit: {{ renderCurrency(dailyPerformances.overalls.sum_profit, 2) }}
            ({{ renderPercentage(dailyPerformances.overalls.avg_roi, 2) }})
        </span>

        <b-field label="Group By" horizontal>

            <b-checkbox-button v-model="groupBy"
                native-value="network"
                type="is-success">
                <span>Network</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy"
                native-value="agency_feed"
                type="is-success">
                <span>Agency Feed</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy" v-if="getUserGroups().some(r => ['IsSuperUser', 'IsBusinessDeveloper'].includes(r))"
                native-value="marketing_agency"
                type="is-success">
                <span>Marketing Agency</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy"
                native-value="product"
                type="is-success">
                <span>Product</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy"
                native-value="country"
                type="is-success">
                <span>Country</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy" v-if="isSuperUser()"
                native-value="account"
                type="is-success">
                <span>Account</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy"
                native-value="bidding_strategy"
                type="is-success">
                <span>Bidding Strategy</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy"
                native-value="currency_code"
                type="is-success">
                <span>Currency</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy"
                native-value="assigned_user"
                type="is-success">
                <span>User</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy"
                native-value="keyword_name"
                type="is-success">
                <span>Keyword</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="groupBy"
                native-value="daily_date"
                type="is-success">
                <span>Date</span>
            </b-checkbox-button>

        </b-field>

        <b-table
            :data="tableData.all"
            :key="updateTable"

            :striped="true"
            :hoverable="true"
            :loading="dailyPerformances.loading"
            :height="tableHeight()"
            :sticky-header="true"

            pagination-position="top"
            default-sort-direction="DESC"
            :pagination-rounded="false"
            sort-icon="chevron-up"
            sort-icon-size="is-medium"
            default-sort="sum_profit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"

            :per-page="dailyPerformances.paging.page_size"
            :total="dailyPerformances.paging.count"
            :current-page="dailyPerformances.paging.page_number"

            @page-change="changePage"
            backend-pagination
            paginated

            backend-sorting
            @sort="sortPressed"
            @sorting-priority-removed="sortingPriorityRemoved"

            :sort-multiple="true"
            :sort-multiple-data="sortingPriority"
            :sort-multiple-key="customKey"

            :scrollable="true"
            narrowed
        >

            <div v-if="showEmptyResultMessage" slot="empty" class="notification is-warning is-light has-text-centered">
              The selected query didn't return any records, Try a different date or reduce the amount of filters.
            </div>

            <b-table-column field="daily_date" label="Date" v-if="groupBy.indexOf('daily_date') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row, 'daily_date') }}
                </div>
              </template>
            </b-table-column>

            <b-table-column field="_daily_date" label="Date Period 1" v-if="groupBy.indexOf('daily_date') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row, '_daily_date') }}
                </div>
              </template>
            </b-table-column>
            <b-table-column field="__daily_date" label="Date Period 2" v-if="groupBy.indexOf('daily_date') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row, '__daily_date') }}
                </div>
              </template>
            </b-table-column>

            <b-table-column field="network.code" label="Network" v-if="groupBy.indexOf('network') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  <b-icon icon="facebook" size="is-small" v-if="getName(props.row.network, 'code') === 'FBA'" title="Facebook"></b-icon>
                  <b-icon icon="google" size="is-small"  v-else-if="getName(props.row.network, 'code') === 'GDN'"  title="Google"></b-icon>
                  <b-icon icon="google-plus" size="is-small" v-else title="Google Plus"></b-icon>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="_network.code" label="Network Period 1" v-if="groupBy.indexOf('network') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  <b-icon icon="facebook" size="is-small" v-if="getName(props.row._network, 'code') === 'FBA'" title="Facebook"></b-icon>
                  <b-icon icon="google" size="is-small"  v-else-if="getName(props.row._network, 'code') === 'GDN'"  title="Google"></b-icon>
                  <b-icon icon="google-plus" size="is-small" v-else title="Google Plus"></b-icon>
                </div>
              </template>
            </b-table-column>
            <b-table-column field="__network.code" label="Network Period 2" v-if="groupBy.indexOf('network') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  <b-icon icon="facebook" size="is-small" v-if="getName(props.row.__network, 'code') === 'FBA'" title="Facebook"></b-icon>
                  <b-icon icon="google" size="is-small"  v-else-if="getName(props.row.__network, 'code') === 'GDN'"  title="Google"></b-icon>
                  <b-icon icon="google-plus" size="is-small" v-else title="Google Plus"></b-icon>
                </div>
              </template>
            </b-table-column>

          <b-table-column :field="`agency_feed.${[getFieldCodeLabel()]}`" label="Agency Feed" v-if="groupBy.indexOf('agency_feed') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row.agency_feed, getFieldCodeLabel()) }}
                </div>
              </template>
            </b-table-column>

            <b-table-column :field="`_agency_feed.${[getFieldCodeLabel()]}`" label="Agency Feed Period 1" v-if="groupBy.indexOf('agency_feed') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row._agency_feed, getFieldCodeLabel()) }}
                </div>
              </template>
            </b-table-column>
            <b-table-column :field="`__agency_feed.${[getFieldCodeLabel()]}`" label="Agency Feed Period 2" v-if="groupBy.indexOf('agency_feed') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row.__agency_feed, getFieldCodeLabel()) }}
                </div>
              </template>
            </b-table-column>

            <b-table-column field="marketing_agency" label="Marketing Agency" v-if="groupBy.indexOf('marketing_agency') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{  getName(props.row, 'marketing_agency') }}
                </div>
              </template>
            </b-table-column>
            <b-table-column field="_marketing_agency" label="Marketing Agency Period 1" v-if="groupBy.indexOf('marketing_agency') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{  getName(props.row, '_marketing_agency') }}
                </div>
              </template>
            </b-table-column>
            <b-table-column field="__marketing_agency" label="Marketing Agency Period 2" v-if="groupBy.indexOf('marketing_agency') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{  getName(props.row, '__marketing_agency') }}
                </div>
              </template>
            </b-table-column>

          <b-table-column field="country.name" label="Country" v-if="groupBy.indexOf('country') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row.country, 'name') }}
                </div>
              </template>
            </b-table-column>
            <b-table-column field="_country.name" label="Country Period 1" v-if="groupBy.indexOf('country') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row._country, 'name') }}
                </div>
              </template>
            </b-table-column>
            <b-table-column field="__country.name" label="Country Period 2" v-if="groupBy.indexOf('country') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  {{ getName(props.row.__country, 'name') }}
                </div>
              </template>
            </b-table-column>

            <b-table-column :field="`product.${[getFieldNameLabel()]}`" label="Product" v-if="groupBy.indexOf('product') > -1"  sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">{{ getName(props.row.product, getFieldNameLabel()) }}</template>

            </b-table-column>

            <b-table-column field="_product.name" label="Product Period 1" v-if="groupBy.indexOf('product') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class"  sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">{{ getName(props.row._product, getFieldNameLabel())  }}</template>
            </b-table-column>
            <b-table-column field="__product.name" label="Product Period 2" v-if="groupBy.indexOf('product') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class"  sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">{{ getName(props.row.__product, getFieldNameLabel())  }}</template>
            </b-table-column>

            <b-table-column field="keyword_name" label="Keyword" v-if="groupBy.indexOf('keyword_name') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row, 'keyword_name') }}</span>
              </template>
            </b-table-column>

            <b-table-column field="_keyword_name" label="Keyword Period 1" v-if="groupBy.indexOf('keyword_name') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row, '_keyword_name') }}</span>
              </template>
            </b-table-column>
            <b-table-column field="__keyword_name" label="Keyword Period 2" v-if="groupBy.indexOf('keyword_name') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row, '__keyword_name') }}</span>
              </template>
            </b-table-column>            

            <b-table-column field="account.name" label="Account" v-if="groupBy.indexOf('account') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row.account, 'name') }}</span>
              </template>
            </b-table-column>

            <b-table-column field="_account.name" label="Account Period 1" v-if="groupBy.indexOf('account') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row._account, 'name') }}</span>
              </template>
            </b-table-column>
            <b-table-column field="__account.name" label="Account Period 2" v-if="groupBy.indexOf('account') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row.__account, 'name') }}</span>
              </template>
            </b-table-column>

            <b-table-column field="bidding_strategy.name" label="Bidding Strategy" v-if="groupBy.indexOf('bidding_strategy') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row.bidding_strategy, 'code') }}</span>
              </template>
            </b-table-column>

            <b-table-column field="_bidding_strategy.name" label="Bidding Strategy Period 1" v-if="groupBy.indexOf('bidding_strategy') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row._bidding_strategy, 'code') }}</span>
              </template>
            </b-table-column>
            <b-table-column field="__bidding_strategy.name" label="Bidding Strategy Period 2" v-if="groupBy.indexOf('bidding_strategy') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row.__bidding_strategy, 'code') }}</span>
              </template>
            </b-table-column>

            <b-table-column field="currency_code" label="Currency" v-if="groupBy.indexOf('currency_code') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row, 'currency_code') }}</span>
              </template>
            </b-table-column>
            <b-table-column field="_currency_code" label="Currency Period 1" v-if="groupBy.indexOf('currency_code') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row, '_currency_code') }}</span>
              </template>
            </b-table-column>
            <b-table-column field="__currency_code" label="Currency Period 2" v-if="groupBy.indexOf('currency_code') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row, '__currency_code') }}</span>
              </template>
            </b-table-column>

            <b-table-column field="assigned_user.username" label="User" v-if="groupBy.indexOf('assigned_user') > -1" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row.assigned_user, 'username') }}</span>
              </template>
            </b-table-column>

            <b-table-column field="_assigned_user.username" label="User Period 1" v-if="groupBy.indexOf('assigned_user') > -1 && selectedRangeDate_period_1.length" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row._assigned_user, 'username') }}</span>
              </template>
            </b-table-column>
            <b-table-column field="__assigned_user.username" label="User Period 2" v-if="groupBy.indexOf('assigned_user') > -1 && selectedRangeDate_period_2.length" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ getName(props.row.__assigned_user, 'username') }}</span>
              </template>
            </b-table-column>

            <b-table-column field="cnt" label="Count" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ props.row.cnt }}</span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_cnt" label="Count Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ props.row._cnt }}</span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_2.length" field="__cnt" label="Count Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ props.row.__cnt }}</span>
              </template>
            </b-table-column>

            <b-table-column field="sum_revenue_amount_net" label="Revenue" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_revenue_amount_net, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.sum_revenue_amount_net, 2) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_sum_revenue_amount_net" label="Revenue Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.sum_revenue_amount_net, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row._sum_revenue_amount_net, 2) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_2.length" field="__sum_revenue_amount_net" label="Revenue Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.sum_revenue_amount_net, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.__sum_revenue_amount_net, 2) }}</template>
            </b-table-column>

            <b-table-column field="sum_cost_amount" label="Cost" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_cost_amount, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderCurrency(props.row.sum_cost_amount, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_sum_cost_amount" label="Cost Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.sum_cost_amount, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderCurrency(props.row._sum_cost_amount, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_2.length" field="__sum_cost_amount" label="Cost Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.sum_cost_amount, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderCurrency(props.row.__sum_cost_amount, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="sum_profit" label="Profit" sortable numeric>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_profit, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row.sum_profit) >= 0,
                  'has-text-danger': makeNumber(props.row.sum_profit) < 0,
                }">
                  {{ renderCurrency(props.row.sum_profit, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_sum_profit Period 1" label="Profit Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable numeric>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.sum_profit, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row._sum_profit) >= 0,
                  'has-text-danger': makeNumber(props.row._sum_profit) < 0,
                }">
                  {{ renderCurrency(props.row._sum_profit, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_2.length" field="__sum_profit" label="Profit Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable numeric>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.sum_profit, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row.__sum_profit) >= 0,
                  'has-text-danger': makeNumber(props.row.__sum_profit) < 0,
                }">
                  {{ renderCurrency(props.row.__sum_profit, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="avg_roi" label="ROI" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformances.overalls.avg_roi, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row.avg_roi) > 0,
                  'has-text-danger': makeNumber(props.row.avg_roi) < 0,
                }">
                  {{ renderPercentage(props.row.avg_roi, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_roi" label="ROI Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformancesPeriodOne.overalls.avg_roi, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row._avg_roi) > 0,
                  'has-text-danger': makeNumber(props.row._avg_roi) < 0,
                }">
                  {{ renderPercentage(props.row._avg_roi, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_roi" label="ROI Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformancesPeriodTwo.overalls.avg_roi, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row.__avg_roi) > 0,
                  'has-text-danger': makeNumber(props.row.__avg_roi) < 0,
                }">
                  {{ renderPercentage(props.row.__avg_roi, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="avg_prop_bid_amount" label="Bid(H)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_prop_bid_amount, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.avg_prop_bid_amount, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_prop_bid_amount" label="Bid(H) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.avg_prop_bid_amount, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row._avg_prop_bid_amount, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_prop_bid_amount" label="Bid(H) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.avg_prop_bid_amount, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.__avg_prop_bid_amount, 3) }}</template>
            </b-table-column>

            <b-table-column field="avg_prop_budget_amount" label="Budget(H)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_prop_budget_amount, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.avg_prop_budget_amount, 2) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_prop_budget_amount" label="Budget(H) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.avg_prop_budget_amount, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row._avg_prop_budget_amount, 2) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_prop_budget_amount" label="Budget(H) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.avg_prop_budget_amount, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.__avg_prop_budget_amount, 2) }}</template>
            </b-table-column>

            <b-table-column field="sum_clicks_in_r" label="ClicksIn(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_in_r }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.sum_clicks_in_r, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_sum_clicks_in_r" label="ClicksIn(R) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformancesPeriodOne.overalls.sum_clicks_in_r }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row._sum_clicks_in_r, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_2.length" field="__sum_clicks_in_r" label="ClicksIn(R) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformancesPeriodTwo.overalls.sum_clicks_in_r }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.__sum_clicks_in_r, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="sum_clicks_in_c" label="ClicksIn(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_in_c }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.sum_clicks_in_c, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_sum_clicks_in_c" label="ClicksIn(C) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformancesPeriodOne.overalls.sum_clicks_in_c }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row._sum_clicks_in_c, 0) }}
                </span>
              </template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__sum_clicks_in_c" label="ClicksIn(C) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformancesPeriodTwo.overalls.sum_clicks_in_c }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.__sum_clicks_in_c, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="sum_clicks_out_r" label="ClicksOut(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_out_r }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.sum_clicks_out_r, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_sum_clicks_out_r" label="ClicksOut(R) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformancesPeriodOne.overalls.sum_clicks_out_r }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row._sum_clicks_out_r, 0) }}
                </span>
              </template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__sum_clicks_out_r" label="ClicksOut(R) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformancesPeriodTwo.overalls.sum_clicks_out_r }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.__sum_clicks_out_r, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="sum_clicks_out_c" label="ClicksOut(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_out_c }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.sum_clicks_out_c, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_sum_clicks_out_c" label="ClicksOut(C) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformancesPeriodOne.overalls.sum_clicks_out_c }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row._sum_clicks_out_c, 0) }}
                </span>
              </template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__sum_clicks_out_c" label="ClicksOut(C) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_out_c }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.__sum_clicks_out_c, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="avg_clicks_in_ratio" label="CiR" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderNumber(dailyPerformances.overalls.avg_clicks_in_ratio, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.avg_clicks_in_ratio, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_clicks_in_ratio" label="CiR Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderNumber(dailyPerformancesPeriodOne.overalls.avg_clicks_in_ratio, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row._avg_clicks_in_ratio, 2) }}
                </span>
              </template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_clicks_in_ratio" label="CiR Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderNumber(dailyPerformancesPeriodTwo.overalls.avg_clicks_in_ratio, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.__avg_clicks_in_ratio, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="avg_clicks_out_ratio" label="CoR" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderNumber(dailyPerformances.overalls.avg_clicks_out_ratio, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.avg_clicks_out_ratio, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_clicks_out_ratio" label="CoR Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderNumber(dailyPerformancesPeriodOne.overalls.avg_clicks_out_ratio, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row._avg_clicks_out_ratio, 2) }}
                </span>
              </template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_clicks_out_ratio" label="CoR Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderNumber(dailyPerformancesPeriodTwo.overalls.avg_clicks_out_ratio, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.__avg_clicks_out_ratio, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="avg_ctr_r" label="CR(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformances.overalls.avg_ctr_r, 2) }}
              </template>
              <template v-slot="props">{{ renderPercentage(props.row.avg_ctr_r, 2) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_ctr_r" label="CR(R) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformancesPeriodOne.overalls.avg_ctr_r, 2) }}
              </template>
              <template v-slot="props">{{ renderPercentage(props.row._avg_ctr_r, 2) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_ctr_r" label="CR(R) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformancesPeriodTwo.overalls.avg_ctr_r, 2) }}
              </template>
              <template v-slot="props">{{ renderPercentage(props.row.__avg_ctr_r, 2) }}</template>
            </b-table-column>

            <b-table-column field="avg_ctr_c" label="CR(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformances.overalls.avg_ctr_c, 2) }}
              </template>
              <template v-slot="props">{{ renderPercentage(props.row.avg_ctr_c, 2) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_ctr_c" label="CR(C) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformancesPeriodOne.overalls.avg_ctr_c, 2) }}
              </template>
              <template v-slot="props">{{ renderPercentage(props.row._avg_ctr_c, 2) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_ctr_c" label="CR(C) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformancesPeriodTwo.overalls.avg_ctr_c, 2) }}
              </template>
              <template v-slot="props">{{ renderPercentage(props.row.__avg_ctr_c, 2) }}</template>
            </b-table-column>

            <b-table-column field="avg_cpc_r" label="CPC(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_cpc_r, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.avg_cpc_r, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_cpc_r" label="CPC(R) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.avg_cpc_r, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row._avg_cpc_r, 3) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_cpc_r" label="CPC(R) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.avg_cpc_r, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.__avg_cpc_r, 3) }}</template>
            </b-table-column>     

            <b-table-column field="avg_cpc_c" label="CPC(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_cpc_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.avg_cpc_c, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_cpc_c" label="CPC(C) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.avg_cpc_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row._avg_cpc_c, 3) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_cpc_c" label="CPC(C) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.avg_cpc_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.__avg_cpc_c, 3) }}</template>
            </b-table-column>

            <b-table-column field="avg_cpa_r" label="CPA(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_cpa_r, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.avg_cpa_r, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_cpa_r" label="CPA(R) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.avg_cpa_r, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row._avg_cpa_r, 3) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_cpa_r" label="CPA(R) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.avg_cpa_r, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.__avg_cpa_r, 3) }}</template>
            </b-table-column>

            <b-table-column field="avg_cpa_c" label="CPA(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_cpa_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.avg_cpa_c, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_cpa_c" label="CPA(C) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.avg_cpa_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row._avg_cpa_c, 3) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_cpa_c" label="CPA(C) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.avg_cpa_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.__avg_cpa_c, 3) }}</template>
            </b-table-column>

            <b-table-column field="avg_rpc" label="RPC" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_rpc, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.avg_rpc, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_rpc" label="RPC Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls.avg_rpc, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row._avg_rpc, 3) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_rpc" label="RPC Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.avg_rpc, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.__avg_rpc, 3) }}</template>
            </b-table-column>

            <b-table-column field="avg_rpc_c" label="RPC(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_rpc_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.avg_rpc_c, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_rpc_c" label="RPC(C) Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodOne.overalls._avg_rpc_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.avg_rpc_c, 3) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_rpc_c" label="RPC(C) Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformancesPeriodTwo.overalls.avg_rpc_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.__avg_rpc_c, 3) }}</template>
            </b-table-column>

            <b-table-column field="avg_rps" label="RPS" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency( dailyPerformances.overalls.avg_rps, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.avg_rps, 3) }}</template>
            </b-table-column>

            <b-table-column v-if="selectedRangeDate_period_1.length" field="_avg_rps" label="RPS Period 1" headerClass="period-1-class" cellClass="period-1-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency( dailyPerformancesPeriodOne.overalls.avg_rps, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row._avg_rps, 3) }}</template>
            </b-table-column>
            <b-table-column v-if="selectedRangeDate_period_2.length" field="__avg_rps" label="RPS Period 2" headerClass="period-2-class" cellClass="period-2-class" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency( dailyPerformancesPeriodTwo.overalls.avg_rps, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.__avg_rps, 3) }}</template>
            </b-table-column>

              <template slot="top-left">
                <b-field>
                  <b-field label="Rows" label-position="on-border" style="width: 8rem;">
                    <b-input :value="tableData.count" disabled />
                  </b-field>
                  <b-field label="Page Size" label-position="on-border">
                    <b-select v-model="pageSize">
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                      <option>5000</option>
                      <option>10000</option>
                    </b-select>
                </b-field>
                  </b-field>
              </template>

        </b-table>

      </section>

  </div>
</template>

<script>
import numeral from "numeraljs"
import {mapState} from "vuex";
import moment from "moment-timezone";
import Vue from "vue";
import {osUtils} from "@/helpers/os-utils";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {numberUtils} from "@/helpers/number-utils";
import {userSettings} from "@/helpers/user/user-settings";
import {notificatorUtils} from "@/helpers/notificator-utils";
import {permissionsUtils} from "@/helpers/permissions-utils";
import LogDeletionGroupFilter from "@/components/Filter/LogDeletionGroupFilter";
import {getAllDailyPerformanceAnalysis} from "@/services";
import LogOptimizationGroupFilter from "@/components/Filter/LogOptimizationGroupFilter.vue";
import ViewModes from '@/components/ViewModes';
import {timeUtils} from '@/helpers/time-utils';

export default {
  name: 'DailyPerformanceAnalysisPage',
  created() {
    this.rules.push(Object.assign({}, this.emptyRule))

    if(this.$route.query.productsId){
      let products = [];
      if(Array.isArray(this.$route.query.countriesId)) {
        for (let i in this.$route.query.productsId) {
          products.push({
            id: this.$route.query.productsId[i]
          });
        }
      }else{
        products.push({
          id: this.$route.query.productsId
        });
      }

      this.selectedProducts = products;
    }

    if(this.$route.query.countriesId){
      let countries = [];
      if(Array.isArray(this.$route.query.countriesId)){
        for(let i in this.$route.query.countriesId){
          countries.push({
            id: this.$route.query.countriesId[i]
          });
        }
      }else{
        countries.push({
          id: this.$route.query.countriesId
        });
      }

      this.selectedCountries = countries;
    }

    this.hideFilters();

    // hide url if the user isMarketingUntrusted
    if (!this.isMarketingUntrusted()) this.indexNames.splice(2, 0, {value: "url_value", text: "Url", indexType: "text", operators: ['inText']})

    this.dailyPerformances.overalls = this.dailyPerformancesPeriodOne.overalls = this.dailyPerformancesPeriodTwo.overalls = this.defaultOveralls
    this.dailyPerformances.overallsKpi = this.dailyPerformancesPeriodOne.overallsKpi = this.dailyPerformancesPeriodTwo.overallsKpi = this.defaultOverallsKpi

  },
  components: {
    LogOptimizationGroupFilter,
    LogDeletionGroupFilter,
    DependantMultiFilter,
    ViewModes,
  },
  mixins: [
      osUtils,
      numberUtils,
      userSettings,
      notificatorUtils,
      permissionsUtils,
      timeUtils,
  ],
  data () {

    return {

      updateTable: 0,
      showPeriodDatePicker: false,
      
      showRemoved: true,
      showPaused: true,
      showActive: true,

      showDetailIcon: true,

      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'products': [],
        'countries': [],
        'accounts': [],
        'biddingStrategies': [],
        'marketingUsers': [],
        'currencies': [],
        'rangeDate': [],
      },

      // Sorting:
      singleSorting: null,
      sortingPriority: [],
      customKey: 'ctrlKey',

      pageSize: 25,
      pageNumber: 1,

      selectedCurrencies: [],

      rules: [],
      displayNewRule: false,
      emptyRule: {indexName: {}, indexOperator: {}, indexValue: null, indexType: {}, indexValueField: '', indexFactor: 1},
      indexNamesObject: {},
      fieldFactor: 1,
      selectedIndexName: '',
      selectedIndexOperator: '',
      selectedIndexValue: '',
      indexNames: [
          {value: "route_hash", text: "Route Hash", indexType: "text", operators: ['in']},
          {value: "keyword_name", text: "Keyword", indexType: "text", operators: ['eq', 'in', 'inText']},
          {value: "cost_amount", text: "Cost", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cost_amount_account_currency", text: "Cost(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cost_impressions", text: "Impression(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_c", text: "ClicksIn(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_r", text: "ClicksIn(Revenue)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_c", text: "ClicksOut(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_r", text: "ClicksOut(Revenue", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "revenue_amount_net", text: "Revenue(Net)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_budget_amount", text: "Budget", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_budget_amount_account_currency", text: "Budget(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_bid_amount", text: "BidAmount", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_bid_amount_account_currency", text: "BidAmount(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "route_hash__livemainentity__status", text: "Status", indexType: "status", operators: ['eq', 'in']},
          {value: "prop_delivery_status", text: "Delivery Status", indexType: "deliveryStatus", operators: ['eq', 'in']},
          {value: "profit", text: "Profit", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "roi", text: "ROI", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "ctr_r", text: "CR(R)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "ctr_c", text: "CR(C)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cpa_r", text: "CPA(R)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cpa_c", text: "CPA(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rpc", text: "RPC", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rpc_c", text: "RPC(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rps", text: "RPS", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_ratio", text: "ClicksOut(RevCostRatio)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_ratio", text: "ClicksIn(RevCostRatio)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},


          {value: "route_hash__livemainentity__last_optimization_on_datetime", text: "Last Optimization", indexType: "datetime", operators: ['lte', 'lt', 'gt', 'gte', 'eq',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "route_hash__livemainentity__created_on_datetime", text: "Created On", indexType: "datetime", operators: ['lte', 'lt', 'gt', 'gte', 'eq',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},

          {value: "log_optimization_group_filter", text: "Optimization", indexType: "optimizationMessage", operators: ['eq']},
          {value: "log_deletion_group_filter", text: "Deletion", indexType: "deletionMessage", operators: ['eq']},

      ],
      indexOperators: {
        'eq': {value: "", text: "="},
        'inText': {value: "__icontains", text: "~="},
        'in': {value: "__in", text: "=,"},
        'gt': {value: "__gt", text: ">"},
        'lt': {value: "__lt", text: "<"},
        'lte': {value: "__lte", text: "<="},
        'gte': {value: "__gte", text: ">="},

        'eqField': {value: "exactField", text: "=(Field)"},
        'gtField': {value: "gtField", text: ">(Field)"},
        'ltField': {value: "ltField", text: "<(Field)"},
        'lteField': {value: "lteField", text: "<=(Field)"},
        'gteField': {value: "gteField", text: ">=(Field)"},
      },

      groupBy: [],
      defaultOveralls: {
            "cost_amount": 0,
            "cost_amount_account_currency": 0,
            "cost_impressions": 0,
            "cost_clicks_in": 0,
            "cost_clicks_out": 0,
            "revenue_amount_net": 0,
            "revenue_amount_gross": 0,
            "revenue_amount_partner_currency": 0,
            "revenue_clicks_in": 0,
            "revenue_clicks_out": 0,
            "prop_budget_amount": 0,
            "prop_budget_amount_account_currency": 0,
            "prop_bid_amount": 0,
            "prop_bid_amount_account_currency": 0,
            "profit": 0,
            "roi": 0,
            "ctr_c": 0,
            "ctr_r": 0,
            "cpa_c": 0,
            "cpa_r": 0,
            "rpc": 0,
            "rps": 0,
            "revenue_cost_ratio_clicks_out": 0,
            "revenue_cost_ratio_clicks_in": 0,
      },
      defaultOverallsKpi: {
          "keywords_in_profit": 0,
          "keywords_no_traffic": 0,
          "keywords_in_loss": 0,
          "percentage_in_profit": 0,
          "percentage_in_no_traffic": 0,
          "percentage_in_loss": 0,
          "ratio_in_profit": 0,
          "ratio_in_no_traffic": 0,
          "ratio_in_loss": 0,
          "keywords_in_profit_roi": 0,
          "keywords_in_loss_roi": 0,
      },
      dailyPerformances: {
        all: [],
        loading: false,
        overalls: {},
        overallsKpi: {},
        paging: {
            page_size: 0,
            count: 0,
            pages: 1,
            page_number: 1,
            page_count: 1
        }
      },

      dailyPerformancesPeriodOne: {
        all: [],
        loading: false,
        selectedRangeDate: [],
      },
      dailyPerformancesPeriodTwo: {
        all: [],
        loading: false,
        selectedRangeDate: [],
      },

      showEmptyResultMessage: false,

      selectedRangeDate_period_1: [],
      selectedRangeDate_period_2: [],
    }
  },
  computed: {

    titleStack () {
      return ['Admin', 'Optimization']
    },
    ...mapState([
      'theme',
    ]),
    rulesParsed () {
      let d = this.rules,
          r = [];

      for(let i in d){
        if(d[i] === null || !('indexName' in d[i]) || d[i]['indexName'] === null){
          continue;
        }

        if(!('indexOperator' in d[i]) || d[i]['indexOperator']['value'] === null){
          continue;
        }

        if(!('indexValue' in d[i]) || d[i]['indexValue'] === null){
          continue;
        }

        if(d[i]['indexOperator']['value'].indexOf('Field') > -1){
          continue;
        }

        let obj = {};

        if(d[i]['indexName']['value'].indexOf('custom') > -1){

          if(d[i]['indexName']['value'].indexOf('custom__logoptimizationgroup') > -1) {

            obj['log_optimization_group_filter'] = d[i]['indexValue'];

          }else if(d[i]['indexName']['value'].indexOf('custom__logdeletiongroup') > -1){

            obj['log_deletion_group_filter'] = d[i]['indexValue'];

          }else{

            alert('Unable to find custom filter name.');

          }

        }else{

          let kObj = d[i]['indexName']['value'] + d[i]['indexOperator']['value'];
          if(d[i]['indexName']['indexType'] === 'datetime'){
            obj[kObj] = this.formatDatetime(d[i]['indexValue']);
          }else{
            obj[kObj] = d[i]['indexValue'];
          }

        }

        r.push(obj);
      }

      return r;
    },

    rulesFieldParsed () {
      let d = this.rules,
          r = [];

      for(let i in d) {
        if (d[i] === null || !('indexName' in d[i]) || d[i]['indexName'] === null) {
          continue;
        }

        if (!('indexOperator' in d[i]) || d[i]['indexOperator']['value'] === null || typeof d[i]['indexOperator']['value'] === 'undefined') {
          continue;
        }

        if (d[i]['indexOperator']['value'].indexOf('Field') === -1) {
          continue;
        }

        if (!('indexValueField' in d[i]) || d[i]['indexValueField'] === null) {
          continue;
        }

        r.push({
          'field1': d[i]['indexName']['value'],
          'operator': d[i]['indexOperator']['value'].replace('Field', ''),
          'field2': d[i]['indexValueField']['value'],
          'factor': d[i]['indexFactor'],
          'addendum': 0,
        });
      }

      return r;
    },

    tableData() {

      //merge main table, period 1, and period 2 data in one array and differentiate between fields by _ for period1 and __ for period 2.

      //add _ for period 1 props
      const period_one = this.dailyPerformancesPeriodOne.all.map(p1 => {
        let row = {}
        for(let prop in p1) {row['_'+prop] = p1[prop]}
        return row
      })
      
      //and __ for period 2 props
      const period_two = this.dailyPerformancesPeriodTwo.all.map(p2 => {
        let row = {}
        for(let prop in p2) {row['__'+prop] = p2[prop]}
        return row
      })
      
      //pick the array with max length and add the other 2 arrays to it.
      let max_length_array = [], arr_1 = [], arr_2 = []

      if(period_one.length >= this.dailyPerformances.all.length && period_one.length >= period_two.length) {
        max_length_array = period_one
        arr_1 = period_two
        arr_2 = this.dailyPerformances.all

      } else if(period_two.length >= this.dailyPerformances.all.length && period_two.length >= period_one.length) {
        max_length_array = period_two
        arr_1 = period_one
        arr_2 = this.dailyPerformances.all

      } else {
        max_length_array = this.dailyPerformances.all
        arr_1 = period_one
        arr_2 = period_two
      }

      return {
        all: max_length_array.map((max_arr, index) => {
          return {
            ...max_arr,
            ...arr_1[index],
            ...arr_2[index],
          }
        }),
        count: max_length_array.length,
      }
    },

  },
  methods: {

    addWithTab(r) {
      if(!this.$refs['r' + r][0].value.text) return;
      
      this.addRule()
      const nextRow =  r+1
      this.$nextTick(() => {
        this.$refs['r' + nextRow][0].focus()
      })
    },

    delWithTab(r, rowId, e) {
      if(e.code !== 'Delete' || rowId + 1 === 1) return;

      this.removeRule(r, rowId)
      const previousRow = rowId - 1
      this.$nextTick(() => {
        this.$refs['r' + previousRow][0].focus()
      })
    },

    getMaxBudget(row){
      let networkId = row.route_hash.network.id,
          agencyFeedId = row.route_hash.livemainentity.account.agency_feed.id,
          countryCode = row.route_hash.livemainentity.country.code.toUpperCase();

      return this.makeNumber(this.getUserSetting(networkId, agencyFeedId, countryCode, 'max_budget', 0));
    },

    generateTrafficSourceLink(props) {
      let link;

      if(props.row.route_hash.network.dealer === 2){
        link = `https://business.facebook.com/adsmanager/manage/ads?act=${props.row.route_hash.livemainentity.account.remote_id}&business_id=${props.row.route_hash.livemainentity.account.manager.remote_id}&selected_adset_ids=${this.getMainEntityRemoteId(props.row)}`;
      }else{
        link = null;
      }

      return link;
    },

    tableHeight () {
      let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
          otherHeights = 0,
          newHeight;

      if(typeof this.$refs.filterstop !== 'undefined') {
        otherHeights = (this.$refs.filterstop.clientHeight * 3);
      }

      newHeight = vh - otherHeights;

      if(newHeight < 1500) {
        newHeight = 1500;
      }

      return vh - 175
    },

    changePage(pageId){
      this.filterList(pageId);
    },
    setSelectedDate (code) {
      let d;
      if (code === 'TODAY') {
        d = new Date();
        this.selectedDate = d;
      }else if (code === 'YESTERDAY') {
        d = new Date() - 1000 * 60 * 60 * 24;
        this.selectedDate = new Date(d)
      }
    },
    filterList (pageId) {
      let parsedDateFrom = moment(this.selectedMultiFilter['rangeDate'][0]).format('YYYY-MM-DD'),
          parsedDateTo = moment(this.selectedMultiFilter['rangeDate'][1]).format('YYYY-MM-DD'),
      that = this,
      sorting;

      if(this.groupBy.length === 0){
        return alert('You must group by something in order to proceed.');
      }

      pageId = pageId || null;

      if(this.sortingPriority && this.sortingPriority.length > 0){
        sorting = this.sortingPriority;
      }else{
        sorting = this.singleSorting;
      }

      sorting = sorting ? sorting : {};

      this.dailyPerformances.loading = true;
      this.showEmptyResultMessage = false;

      getAllDailyPerformanceAnalysis(
        pageId,
        sorting,
        this.pageSize,
        parsedDateFrom,
        parsedDateTo,
        this.groupBy.join(','),
        this.selectedMultiFilter['networks'].map((element) => { return element.id }),
        this.selectedMultiFilter['products'].map((element) => { return element.id }),
        this.selectedMultiFilter['countries'].map((element) => { return element.id }),
        this.selectedMultiFilter['accounts'].map((element) => { return element.id }),
        this.selectedMultiFilter['biddingStrategies'].map((element) => { return element.id }),
        this.selectedMultiFilter['agencyFeeds'].map((element) => { return element.id }),
        this.selectedMultiFilter['marketingAgency']?.map((element) => { return element.id }) || [],
        this.selectedMultiFilter['currencies'].map((element) => { return element.code }),
        this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }),
        this.rulesParsed,
        this.rulesFieldParsed,
        this.showRemoved,
        this.showPaused,
        this.showActive
      ).then((e) => {
        Vue.set(that.dailyPerformances, 'all', e.data.results);
        Vue.set(that.dailyPerformances, 'overalls', e.data.overall_performance);
        Vue.set(that.dailyPerformances, 'overallsKpi', e.data.overall_kpi);
        that.dailyPerformances.paging.page_size = e.data.page_size;
        that.dailyPerformances.paging.count = e.data.count;
        that.dailyPerformances.paging.pages = e.data.pages;
        that.dailyPerformances.paging.page_number = e.data.page_number;
        that.dailyPerformances.paging.page_count = e.data.page_count;
        that.dailyPerformances.loading = false;

        this.showEmptyResultMessage = true;



      }).catch((e) => {
        alert('An error occurred, try again if this persists, please notify the technical team.');
        console.dir(e);
      })

      //period 1
      if(this.selectedRangeDate_period_1.length) {
        console.log(`+++++++++++PERIOD 1++++++`)
        let parsedDateFrom = moment(this.selectedRangeDate_period_1[0]).format('YYYY-MM-DD'),
          parsedDateTo = moment(this.selectedRangeDate_period_1[1]).format('YYYY-MM-DD')

        that.updateTable += 1
        
        getAllDailyPerformanceAnalysis(
          pageId,
          sorting,
          this.pageSize,
          parsedDateFrom,
          parsedDateTo,
          this.groupBy.join(','),
          this.selectedMultiFilter['networks'].map((element) => { return element.id }),
          this.selectedMultiFilter['products'].map((element) => { return element.id }),
          this.selectedMultiFilter['countries'].map((element) => { return element.id }),
          this.selectedMultiFilter['accounts'].map((element) => { return element.id }),
          this.selectedMultiFilter['biddingStrategies'].map((element) => { return element.id }),
          this.selectedMultiFilter['agencyFeeds'].map((element) => { return element.id }),
          this.selectedMultiFilter['marketingAgency']?.map((element) => { return element.id }) || [],
          this.selectedMultiFilter['currencies'].map((element) => { return element.code }),
          this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }),
          this.rulesParsed,
          this.rulesFieldParsed,
          this.showRemoved,
          this.showPaused,
          this.showActive,
        ).then((e) => {
          that.$nextTick(() => {
            Vue.set(that.dailyPerformancesPeriodOne, 'all', e.data.results);
            Vue.set(that.dailyPerformancesPeriodOne, 'overalls', e.data.overall_performance);
            Vue.set(that.dailyPerformancesPeriodOne, 'overallsKpi', e.data.overall_kpi);
            that.dailyPerformancesPeriodOne.loading = false;
            that.updateTable += 1
          })

          // this.showEmptyResultMessage = true;
        }).catch((e) => {
          alert('An error occurred, try again if this persists, please notify the technical team.');
          console.dir(e);
        })
      }

      //period 2
      if(this.selectedRangeDate_period_2.length) {
        let parsedDateFrom = moment(this.selectedRangeDate_period_2[0]).format('YYYY-MM-DD'),
          parsedDateTo = moment(this.selectedRangeDate_period_2[1]).format('YYYY-MM-DD')

        that.updateTable += 1
        
        getAllDailyPerformanceAnalysis(
          pageId,
          sorting,
          this.pageSize,
          parsedDateFrom,
          parsedDateTo,
          this.groupBy.join(','),
          this.selectedMultiFilter['networks'].map((element) => { return element.id }),
          this.selectedMultiFilter['products'].map((element) => { return element.id }),
          this.selectedMultiFilter['countries'].map((element) => { return element.id }),
          this.selectedMultiFilter['accounts'].map((element) => { return element.id }),
          this.selectedMultiFilter['biddingStrategies'].map((element) => { return element.id }),
          this.selectedMultiFilter['agencyFeeds'].map((element) => { return element.id }),
          this.selectedMultiFilter['marketingAgency']?.map((element) => { return element.id }) || [],
          this.selectedMultiFilter['currencies'].map((element) => { return element.code }),
          this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }),
          this.rulesParsed,
          this.rulesFieldParsed,
          this.showRemoved,
          this.showPaused,
          this.showActive,
        ).then((e) => {
          that.$nextTick(() => {
            Vue.set(that.dailyPerformancesPeriodTwo, 'all', e.data.results);
            Vue.set(that.dailyPerformancesPeriodTwo, 'overalls', e.data.overall_performance);
            Vue.set(that.dailyPerformancesPeriodTwo, 'overallsKpi', e.data.overall_kpi);
            that.dailyPerformancesPeriodTwo.loading = false;
            that.updateTable += 1
          })

          // this.showEmptyResultMessage = true;
        }).catch((e) => {
          alert('An error occurred, try again if this persists, please notify the technical team.');
          console.dir(e);
        })
      }

    },

    formatDatetime: function (time){
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },

    makeNumber: function (str){
      let num = numeral(str).value()

      if(num < 0 || num > 0){
        return num
      }

      return 0;
    },

    sortPressed: function (field, order){

        //remove _ from periods
        if(field[1] === '_') {
          field = field.substr(2)
        } else if(field[0] === '_') {
          field = field.substr(1)
        }

        //use the field without annotation, ex. country instead of country.name
        field = field.includes('.') ? field.split('.')[0] : field

        if((this.customKey && event[this.customKey]) || !this.customKey) {
          let existingPriority = this.sortingPriority.filter(i => i.field === field)[0]
          if(existingPriority) {
            existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
          } else {
            // request sorted data from backend
            this.sortingPriority.push({field: field, order: order})
          }
          this.singleSorting = null;
          this.filterList()
        } else {
          // request regular sorted data from backend
          this.sortingPriority = []
          this.singleSorting = [{field: field, order: order}];
          this.filterList()
        }
    },

    sortingPriorityRemoved: function (field){
        this.sortingPriority = this.sortingPriority.filter((priority) => priority.field !== field)
    },

    removeRule: function (rule, rowId) {
      this.$delete(this.rules, rowId);
    },

    addRule: function (){
      this.rules.push(Object.assign({}, this.emptyRule))
    },

    getOperators: function (operatorsArray) {
      let r = [];

      for(let i in operatorsArray){
        r.push(this.indexOperators[operatorsArray[i]]);
      }

      return r;
    },

    setFirstOperator: function (rowId){
      let r = this.getOperators(this.rules[rowId].indexName.operators, rowId);
      if(this.rules[rowId] && this.rules[rowId].indexOperator){
        if(r.length > 0){
          if(JSON.stringify(this.rules[rowId].indexOperator) === '{}'){
            this.rules[rowId].indexOperator = r[0];
          }else if(this.rules[rowId].indexName.operators.indexOf(this.rules[rowId].indexOperator.value) === -1) {
            this.rules[rowId].indexOperator = r[0];
          }
        }
      }
    },

    isEmpty(val) {
      return !val || val === '' || val === 0 || val === '0';
    },

    hideFilters(){

      let that = this;

      this.executeWhenPermissionsAreLoaded(() => {
        if(!that.isSuperUser()){

          let fieldsToDelete = [
            'log_optimization_group_filter',
            'log_deletion_group_filter'
          ];

          for(let ii in fieldsToDelete) {
            let fieldName = fieldsToDelete[ii];

            for (let i in that.indexNames) {
              let r = that.indexNames[i];
              if (fieldName === r.value)
              {
                Vue.delete(that.indexNames, i);
              }
            }
          }
        }
      })
    },

    getName(obj, field) {
      //safely access the field
      return obj?.[field] || '-'
    },

    togglePeriodDisplay() {

      //if you wanna hide it, clear the data.
      if(this.showPeriodDatePicker) {
        this.showPeriodDatePicker = false

        this.clearPeriod('dailyPerformancesPeriodOne', 1)
        this.clearPeriod('dailyPerformancesPeriodTwo', 2)
        return;
      }
      this.showPeriodDatePicker = true
    },

    clearPeriod(period_object_name, period_number) {
      this[period_object_name].all = []
      this[period_object_name].overalls = this.defaultOveralls
      this[period_object_name].overall_kpi = this.defaultOverallsKpi
      const period_date = 'selectedRangeDate_period_'+period_number
      this[period_date] = []
      this.updateTable += 1
    },

  },

  watch: {
    groupBy() {
      //rerender the table to update groupBy selections
      this.updateTable += 1
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
