<template>
  <div :class="{'ml-6': marginLeft}">
    <b-loading v-model="isLoading"></b-loading>

      <b-field class="file">
        <b-upload v-model="files" accept="image/png, image/jpeg" @input="upload" :multiple="true">
          <a class="button is-primary" @click="clearFiles">
            <b-icon icon="upload" custom-size="default"></b-icon>
            <span>Upload Images</span>
          </a>
        </b-upload>

        <span v-for="(file, i) in files" class="file-name" v-bind:key="`file-${i}-${file.name}`">{{ file.name }}</span>

      </b-field>

  </div>
</template>

<script>
import {permissionsUtils} from "@/helpers/permissions-utils";
import {uploadByBase64} from "@/services";

export default {
  name: 'ImageUploader',
  mixins: [
      permissionsUtils
  ],
  props: {
    keywordName: {
      type: String,
    },
    marginLeft: {
      type: Boolean,
      default: true
    }
  },

  mounted() {

  },

  data() {
    return {

      files: [],
      loading: 0,
      filesUploaded: []

    }
  },
  computed: {

    isLoading(){
      return this.loading > 0;
    }

  },
  watch: {},
  methods: {
    upload(files){

      let that = this,
          file, fileReader = {};

      for(let i in files){

        file = files[i];

        fileReader['file_' + i] = {
          fileReader: new FileReader(),
        }

        fileReader['file_' + i].fileReader.readAsDataURL(file);

        fileReader['file_' + i].fileReader.onload = () => {
          that.sendUploadRequest(fileReader['file_' + i].fileReader.result, file.name);
        };

        fileReader['file_' + i].fileReader.onerror = (error) => {
          that.$buefy.toast.open({
            message: `An error occurred, try again: ${error}`,
            type: 'is-danger'
          })
        };

      }
    },

    sendUploadRequest(fileContentBase64, fileName){
      let that = this;

      that.loading += 1;

      uploadByBase64(
        this.keywordName,
        fileContentBase64.split(",")[1],
        fileName
      ).then(response => {

        that.loading -= 1;
        this.filesUploaded.push(response.data);

        if(that.loading === 0){
          this.$emit('input', this.filesUploaded);
          this.$emit('finished', {});
        }

      },
      error => {

        that.loading -= 1;
        that.$buefy.toast.open({
          message: `An error occurred, try again: ${error}`,
          type: 'is-danger'
        })

      });

    },

    clearFiles(){
      this.files = [];
      this.filesUploaded = [];
    }
  },
}
</script>
