<template>
    <b-tooltip multilined class="toolip" :label="label" :append-to-body="appendToBody">
        <b-icon icon="help-circle-outline" size="is-small" />
    </b-tooltip>
</template>

<script>
export default {
    name: 'Tooltip',

    props: {
        label: {
            type: String,
            required: true,
        },
        appendToBody: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped>
.toolip {
    cursor: pointer;
}
</style>