import { config } from './config';
import axios from "axios";

export const naplesEnableCountriesService = {
    getAll,
    config
};

function getAll(){

    let endpoint;

    endpoint = '/api/proxy/naples/domain/enable-countries/';

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}




