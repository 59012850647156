import { config } from './config';
import axios from "axios";

export const performanceMainEntityService = {
    get,
    config
};

function get(mainEntityId){
    let endpoint;

    if(!mainEntityId){
        return;
    }

    endpoint = '/api/performance/main-entities/' + mainEntityId + '/';

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

