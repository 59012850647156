import {userService} from '@/services';

export const users = {
    namespaced: true,
    state: {
        all: [],
        loading: false,

        allMarketing: [],
        allMarketingAgencyUsers: [],
        loadingMarketing: false,
        loadingAllMarketingAgencyUsers: false,
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            userService.getUsers({
                'is_active': true,
                'ordering': 'username',
            })
                .then(
                    users => commit('getAllSuccess', users),
                    error => commit('getAllFailure', error)
                );
        },
        getAllMarketing({ commit }) {
            commit('getAllMarketingRequest');

            userService.getUsers({
                'is_active': true,
                'ordering': 'username',
                'groups__name': 'isMarketing'
            })
                .then(
                    users => commit('getAllMarketingSuccess', users),
                    error => commit('getAllMarketingFailure', error)
                );
        },

        getMarketingAgencyUsers({ commit }, no_loading) {
            if(!no_loading) commit('getAllMarketingAgencyUsersRequest');

            userService.getMarketingAgencyUsers({})
                .then(
                    users => commit('getAllMarketingAgencyUsersSuccess', users),
                    error => commit('getAllMarketingAgencyUsersFailure', error)
                );
        },
        saveUserMarketingAgent({ commit }, payload) {
            commit('saveUserMarketingAgentRequest');

            return new Promise((resolve, reject) => {
                userService.saveUserMarketingAgent(payload)
                    .then(
                        data => {commit('saveUserMarketingAgentSuccess', data); resolve(data)},
                        error => {commit('saveUserMarketingAgentFailure', error); reject(error)}
                    );
            })
        },
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, users) {
            state.all = users.data['results'];
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.all = [];
            state.loading = false;
            console.dir(error);
        },
        getAllMarketingRequest(state) {
            state.allMarketing = [];
            state.loadingMarketing = true;
        },
        getAllMarketingSuccess(state, users) {
            state.allMarketing = users.data['results'];
            state.loadingMarketing = false;
        },
        getAllMarketingFailure(state, error) {
            state.allMarketing = [];
            state.loadingMarketing = false;
            console.dir(error);
        },

        getAllMarketingAgencyUsersRequest(state) {
            state.allMarketingAgencyUsers = [];
            state.loadingAllMarketingAgencyUsers = true;
        },
        getAllMarketingAgencyUsersSuccess(state, users) {
            state.allMarketingAgencyUsers = users.data['results'];
            state.loadingAllMarketingAgencyUsers = false;
        },
        getAllMarketingAgencyUsersFailure(state, error) {
            state.allMarketingAgencyUsers = [];
            state.loadingAllMarketingAgencyUsers = false;
            console.dir(error);
        },
        saveUserMarketingAgentRequest(state) {
            console.log('req')
            //state.all = response.data.results;
            state.loading = true;
        },
        saveUserMarketingAgentSuccess(state, data) {
            console.log('res', data)
            state.loadingMarketing = false;
        },
        saveUserMarketingAgentFailure(state, error) {
            state.loadingMarketing = false;
            console.dir(error);
        },
    }
}
