<template>
  <div>
    <section class="section is-main-section" v-if="visibleTo('IsSuperUser') ||
    visibleTo('IsManagement') || visibleTo('IsMarketing')">

      <dashboard-tiles />

      <card-component
        title="Daily Performance"
        icon="finance"
        customHeaderClass="px-1"
        class="mb-5"
      >
        <template v-slot:toolbar-right>
          <b-field class="mt-3">
            <b-datepicker
              size="is-small"
              placeholder="Date..."
              v-model="dailyChartSelectedDate"
              range>
              <b-button
              label="This Month"
              type="is-primary"
              icon-left="calendar-today"
              @click="setSelectedDate('THIS_MONTH', 'dailyChartSelectedDate')" />
              <b-button
              label="Previous Month"
              type="is-primary"
              icon-left="calendar"
              @click="setSelectedDate('PREVIOUS_MONTH', 'dailyChartSelectedDate')" />
            </b-datepicker>
            <p class="control">
                <b-button size="is-small" :focused="dailyChartType === 'BY_AGENCY_FEED'" @click="dailyChartType='BY_AGENCY_FEED'">By Agency Feed</b-button>
            </p>
            <p class="control">
                <b-button size="is-small" :focused="dailyChartType === 'BY_DEALER'" @click="dailyChartType='BY_DEALER'">By Dealer</b-button>
            </p>
            <p class="control">
                <b-button size="is-small" :focused="dailyChartType === 'BY_PRODUCT'" @click="dailyChartType='BY_PRODUCT'">By Product</b-button>
            </p>
            <p class="control">
                <b-button size="is-small" :focused="dailyChartType === 'BY_COUNTRY'" @click="dailyChartType='BY_COUNTRY'">By Country</b-button>
            </p>
          </b-field>
          <a
            href="#"
            class="card-header-icon"
            aria-label="more options"
            @click.prevent="fillChartData"
          >
            <b-icon icon="reload" custom-size="default" />
          </a>
        </template>
        <div v-if="chartData" class="chart-area">
          <b-loading :is-full-page="false" v-model="dashboardsChartPerformances.loading"></b-loading>
          <line-chart
            ref="bigChart"
            style="height: 20rem;"
            chart-id="big-line-chart"
            :chart-data="chartData"
            :extra-options="extraOptions"
          >
          </line-chart>
        </div>
      </card-component>


      <card-component
        title="Keywords"
        icon="finance"
        customHeaderClass="px-1"
        class="p-1 mb-5"
      >
        <div v-if="chartKeywordsData" class="chart-area">
          <b-loading :is-full-page="false" v-model="dashboardsChartKeywords.loading"></b-loading>

          <dependant-multi-filter
              v-model="selectedMultiFilter"
              range-date-enabled
              :default-range-date="defaultKeywordsRageDate"
              products-enabled
              networks-enabled
              agency-feeds-enabled
              countries-enabled
              @filter="fillChartKeywordsData" />

          <line-chart
            ref="bigChart2"
            style="height: 20rem;"
            chart-id="big-line-chart"
            :chart-data="chartKeywordsData"
            :extra-options="extraOptions"
          >
          </line-chart>
        </div>
      </card-component>

      <dashboard-kpi></dashboard-kpi>

      <card-component
        title="Facebook"
        icon="finance"
        v-if="isSuperUser()"
      >
        <div>
          <strong>Pages:</strong> <br />
          Unpublished: <a href="#" @click="viewFacebookPages('Unpublished Pages', facebookPages.unpublished)">{{ facebookPages.unpublished.length }}</a> |
          Blocked: <a href="#" @click="viewFacebookPages('Blocked Pages', facebookPages.blocked)">{{ facebookPages.blocked.length }}</a> |
          Empty: <a href="#" @click="viewFacebookPages('Empty Pages', facebookPages.empty)">{{ facebookPages.empty.length }}</a> |
          Full: <a href="#" @click="viewFacebookPages('Full Pages', facebookPages.full)">{{ facebookPages.full.length }}</a> |
          Exceeded: <a href="#" @click="viewFacebookPages('Exceeded', facebookPages.exceeded)">{{ facebookPages.exceeded.length }}</a> |
          Almost Full: <a href="#" @click="viewFacebookPages('Almost Full Pages', facebookPages.almostFull)">{{ facebookPages.almostFull.length }}</a>

          | Launching Capacity:
          <span v-for="(v, i) in facebookPages.pagesCapacity" :key="`facebook-pages-${i}`">
            {{ i }}: {{ v }} |
          </span>

          <br />
          <strong>Accounts:</strong> <br />
          CBSi Ads Available: <a href="#" @click="viewAccounts('CBSi Ads Available', cbsiBingAdsAvailable.accounts)">{{ cbsiBingAdsAvailable.count }}</a>
          IAC Ads Available: <a href="#" @click="viewAccounts('IAC Ads Available', iacBingAdsAvailable.accounts)">{{ iacBingAdsAvailable.count }}</a>
          Blocked: <a href="#" @click="viewAccounts('Blocked Accounts', accounts.blocked)">{{ accounts.blocked.length }}</a>
        </div>

      </card-component>

      <clients-table />

      <card-component title="Daily Tq Scores" class="has-table has-mobile-sort-spaced" v-if="visibleTo('IsSuperUser')">
        <dependant-multi-filter
          v-model="selectedFiltersTqScoresData"
          range-date-enabled
          agency-feeds-enabled
          countries-enabled
          :default-range-date="defaultTQRangeDate"
          @filter="fillTqScoresData"
        >
        </dependant-multi-filter>
        <tq-scores-table
          :is-loading="tqScores.loading"
          :data="tqScores.all"
          :paginated="true"
          :per-page="tqScores.paging.page_size"
          :total="tqScores.paging.count"
          :current-page="tqScores.paging.page_number"
          :overalls="tqScores.paging.overalls"
          @page-change="tqScoresChangePage"
          @sort-pressed="tqScoresSortPressed"
        />
      </card-component>


      <card-component title="Keywords Performance" icon="monitor-dashboard" class="has-table has-mobile-sort-spaced px-1 pb-1" customHeaderClass="px-1">
        <template v-slot:toolbar-right>
          <b-field class="mt-3">
            <b-datepicker
              size="is-small"
              placeholder="Date..."
              v-model="dailyReportKeywordsSelectedDate"
              class="move-dropdown-to-right"
              range>
              <b-button
              label="Yesterday"
              type="is-primary"
              icon-left="calendar"
              @click="setSelectedDate('YESTERDAY', 'dailyReportKeywordsSelectedDate')" />
              <b-button
              label="Last 7 days"
              type="is-primary"
              icon-left="calendar"
              @click="setSelectedDate('LAST_7_DAYS', 'dailyReportKeywordsSelectedDate')" />
              <b-button
              label="This Month"
              type="is-primary"
              icon-left="calendar-today"
              @click="setSelectedDate('THIS_MONTH', 'dailyReportKeywordsSelectedDate')" />
              <b-button
              label="Previous Month"
              type="is-primary"
              icon-left="calendar"
              @click="setSelectedDate('PREVIOUS_MONTH', 'dailyReportKeywordsSelectedDate')" />
            </b-datepicker>

          </b-field>
          <a
            href="#"
            class="card-header-icon"
            aria-label="more options"
            @click.prevent="fillReportKeywordData"
          >
            <b-icon icon="reload" custom-size="default" />
          </a>
        </template>
        <keywords-performance-table
          :is-loading="dashboardsReportKeywordsPerformances.loading"
          :data="dashboardsReportKeywordsPerformances.all"
          :paginated="true"
          :per-page="dashboardsReportKeywordsPerformances.paging.page_size"
          :total="dashboardsReportKeywordsPerformances.paging.count"
          :current-page="dashboardsReportKeywordsPerformances.paging.page_number"
          @page-change="fillReportKeywordDataChangePage"
          @sort-pressed="fillReportKeywordDataSortPressed"
        /><!--            ${$router.options.base}-->
      </card-component>

    </section>
    <section class="section is-main-section" v-if="visibleTo('IsImagePicker')">
      <dashboard-image-picker></dashboard-image-picker>
    </section>
    <b-modal
      v-model="showAccountsModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      :aria-label="accountModalTitle"
      @close="showAccountsModal=false"
      aria-modal>
      <template #default="props">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ accountModalTitle }}</p>
              <button
                  type="button"
                  class="delete"
                  @click="props.close" />
           </header>
            <section class="modal-card-body">
              <accounts-viewer :accounts="accountModalAccounts"></accounts-viewer>
            </section>
          </div>
      </template>
    </b-modal>

    <b-modal
      v-model="showFacebookPagesModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      :aria-label="facebookPagesModalTitle"
      @close="showFacebookPagesModal=false"
      aria-modal>
      <template #default="props">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ facebookPagesModalTitle }}</p>
              <button
                  type="button"
                  class="delete"
                  @click="props.close" />
           </header>
            <section class="modal-card-body">
              <facebook-pages-viewer :pages="facebookPagesModalPages"></facebook-pages-viewer>
            </section>
          </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
// @ is an alias to /src
import CardComponent from '@/components/CardComponent'
import LineChart from '@/components/Charts/LineChart'
import ClientsTable from '@/components/ClientsTable'
import TqScoresTable from '@/components/TqScoresTable'
import {mapState} from "vuex";
import moment from "moment-timezone";
import KeywordsPerformanceTable from "@/components/KeywordsPerformanceTable";
import AccountsViewer from "@/components/AccountsViewer";
import FacebookPagesViewer from "@/components/FacebookPagesViewer";
import numeral from "numeraljs";
import DashboardKpi from "@/components/Dashboard/DashboardKpi";
import DashboardTiles from "@/components/Dashboard/DashboardTiles";
import {numberUtils} from "@/helpers/number-utils";
import DashboardImagePicker from "@/components/Dashboard/DashboardImagePicker";
import {osUtils} from "@/helpers/os-utils";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {permissionsUtils} from "@/helpers/permissions-utils";

export default {
  name: 'Home',
  components: {
    DependantMultiFilter,
    DashboardImagePicker,
    DashboardKpi,
    FacebookPagesViewer,
    AccountsViewer,
    KeywordsPerformanceTable,
    ClientsTable,
    TqScoresTable,
    LineChart,
    CardComponent,
    DashboardTiles,
  },

  data () {
    return {

      selectedNetworks: [],
      selectedAgencyFeeds: [],
      selectedProducts: [],
      selectedCountries: [],
      selectedAccounts: [],

      defaultTQRangeDate: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate()
      ],

      defaultKeywordsRageDate: [
        moment().clone().startOf('month').toDate(),
        moment().toDate()
      ],


      showAccountsModal: false,
      accountModalTitle: '',
      accountModalAccounts: [],

      showFacebookPagesModal: false,
      facebookPagesModalTitle: '',
      facebookPagesModalPages: [],

      dailyChartSelectedDate: [],
      dailyChartKeywordSelectedDate: [],

      dailyReportKeywordsSelectedDate: [],

      selectedFiltersTqScoresData: {
        rangeDate: [
            moment().subtract(1, 'days'),
            moment().subtract(1, 'days')
        ],
        agencyFeeds: [],
        countries: []
      },

      tqScoresSelectedDate: moment().subtract(1, 'days').toDate(),

      dailyChartType: 'BY_AGENCY_FEED',

      chartColors: {
        default: {
          primary: '#00D1B2',
          info: '#209CEE',
          danger: '#FF3860',
          warning: '#FF5900'
        }
      },
       extraOptions: {
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          responsive: true,
          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: 'nearest',
            intersect: 0,
            position: 'nearest'
          },
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.0)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9a9a9a'
                }
              }
            ],

            xAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(225,78,202,0.1)',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  padding: 20,
                  fontColor: '#9a9a9a'
                }
              }
            ]
          }
        },
      defaultChart: {
        chartData: null,
      },

      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'countries': [],
        'products': [],
        'rangeDate': [],
      },

    }
  },
  computed: {
    titleStack () {
      return ['Admin', 'Dashboard']
    },
    ...mapState([
      'dashboardsChartPerformances',
      'dashboardsChartKeywords',
      'dashboardsReportKeywordsPerformances',
      'facebookPages',
      'accounts',
      'tqScores',
      'theme'
    ]),

    ...mapState([
      'networks',
      'agencyFeeds',
      'products',
      'accounts',
      'countries',
    ]),

    cbsiBingAdsAvailable (){
      return this.getAdsAvailable(this.accounts.cbsiBingAll);
    },

    iacBingAdsAvailable (){
      return this.getAdsAvailable(this.accounts.iacBingAll);
    },

    chartData () {
      let data = this.dataToChart(this.dashboardsChartPerformances.all, 'date_val', 'name', 'profit');
      let chartData = {
        datasets: [],
        labels: []
      },
          borderColor,
      colors = [
        '#00D1B2',
        '#209CEE',
        '#FF3860',
        '#FF5900',
        '#5369F8',
        '#00ffff',
        '#7fffd4',
        '#0000ff',
        '#8a2be2',
        '#a52a2a',
        '#deb887',
        '#5f9ea0',
        '#7fff00',
        '#d2691e',
        '#6495ed',
        '#fff8dc',
        '#dc143c',
        '#00ffff',
        '#00008b',
        '#008b8b',
        '#b8860b',
        '#a9a9a9',
        '#006400',
        '#a9a9a9',
        '#bdb76b',
        '#8b008b',
        '#556b2f',
        '#ff8c00',
        '#9932cc',
        '#8b0000',
        '#e9967a',
        '#8fbc8f',
        '#483d8b',
        '#2f4f4f',
        '#00ced1',
        '#9400d3',
        '#ff1493',
        '#00bfff',
        '#1e90ff',
        '#b22222',
        '#228b22',
        '#ff00ff',
        '#ffd700',
        '#daa520',
        '#808080',
        '#008000',
        '#adff2f',
        '#ff69b4',
        '#cd5c5c',
        '#4b0082',
        '#f0e68c',
        '#7cfc00',
        '#add8e6',
        '#f08080',
        '#90ee90',
        '#ffb6c1',
        '#ffa07a',
        '#20b2aa',
        '#32cd32',
      ],
      i = 0;

      for(let name in data['dataset']){

        if(i >= colors.length){
          borderColor = '#000'
        }else{
          borderColor = colors[i];
        }

        let total = this.renderCurrency(data['dataset'][name].reduce((a, b) => a + b, 0), 2);
        chartData.datasets.push({
          fill: false,
          borderColor: borderColor,
          borderWidth: 2,
          borderDash: [],
          label: name + ' (' + total + '$)',
          borderDashOffset: 0.0,
          pointBackgroundColor: '#000',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: this.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: data['dataset'][name]
        });

        i++;
      }


      chartData.labels = data['labels'];

      return chartData;
    },
    chartKeywordsData () {
      let data = this.dataToChartPivot(this.dashboardsChartKeywords.all, 'date_val', {
        'with_traffic': 'With Traffic',
        'without_traffic': 'Without Traffic',
        'in_review': 'In Review',
        'not_delivering': 'Not Delivering',
        'launched_today': 'Launched Today',
      });
      let chartData = {
        datasets: [],
        labels: []
      },
          color;

      for(let name in data['dataset']){

        if(name === 'With Traffic'){
          color =   this.chartColors.default.info
        }else if(name === 'Without Traffic'){
          color =   this.chartColors.default.danger
        }else if(name === 'Not Delivering'){
          color =   this.chartColors.default.warning
        }else if(name === 'In Review'){
          color =   '#9400d3'
        }else{
          color =   this.chartColors.default.primary
        }

        chartData.datasets.push({
          fill: false,
          borderColor: color,
          borderWidth: 2,
          borderDash: [],
          label: name,
          borderDashOffset: 0.0,
          pointBackgroundColor: '#000',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: this.chartColors.default.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: data['dataset'][name]
        });
      }

      chartData.labels = data['labels'];

      return chartData;
    }
  },

  created () {

    this.setSelectedDate('THIS_MONTH', 'dailyChartSelectedDate');
    this.setSelectedDate('THIS_MONTH', 'dailyChartKeywordSelectedDate');
    this.setSelectedDate('YESTERDAY', 'dailyReportKeywordsSelectedDate');
    this.selectedMultiFilter['rangeDate'] = this.defaultKeywordsRageDate;

    if(!this.networks.all.length) this.$store.dispatch('networks/getAll');
    if(!this.products.all.length) this.$store.dispatch('products/getAll');
    if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll');
    if(!this.countries.all.length) this.$store.dispatch('countries/getAll');

    if(this.isSuperUser()){
      if(!this.accounts.all.length) this.$store.dispatch('accounts/getAll');
    }

    if(this.isSuperUser()) {
      this.$store.dispatch(
          'tqScores/getAll', {
            rangeDate: [
              moment().subtract(1, 'days'),
              moment().subtract(1, 'days')
            ],
            ordering: 'score'
          }
      );
    }

    this.$store.dispatch(
        'dashboardsReportKeywordsPerformances/getByDateRange', {
          code: 'YESTERDAY',
          fromDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          toDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          ordering: '-revenue'
        }
    );

    if(this.visibleTo('IsSuperUser')){
      if(!this.facebookPages.unpublished.length)
        this.$store.dispatch(
          'facebookPages/getUnpublishedPagesList'
        );

      if(!this.facebookPages.blocked.length)
        this.$store.dispatch(
          'facebookPages/getBlockedPagesList'
        );

      if(!this.facebookPages.empty.length)
        this.$store.dispatch(
          'facebookPages/getEmptyPagesList'
        );

      if(!this.facebookPages.full.length)
        this.$store.dispatch(
          'facebookPages/getFullPagesList'
        );

      if(!this.facebookPages.almostFull.length)
        this.$store.dispatch(
          'facebookPages/getAlmostFullPagesList'
        );

      if(!this.facebookPages.exceeded.length)
        this.$store.dispatch(
          'facebookPages/getExceededPagesList'
        );

      if(!this.facebookPages.pagesCapacity.length)
        this.$store.dispatch(
          'facebookPages/getPagesCapacityList'
        );
    }

    if(this.isSuperUser()) {

      if(!this.accounts.cbsiBingAll.length)
        this.$store.dispatch(
            'accounts/getCbsiBingAll'
        );

      if(!this.accounts.iacBingAll.length)
        this.$store.dispatch(
            'accounts/getIacBingAll'
        );

      if(!this.accounts.blocked.length)
        this.$store.dispatch(
            'accounts/getBlockedList'
        );

    }

    this.$buefy.snackbar.open({
      message: 'Welcome back',
      queue: false
    })

    this.fillChartData()
    this.fillChartKeywordsData()

  },
  mixins: [
      numberUtils,
      osUtils,
      permissionsUtils
  ],
  methods: {
    renderCurrency(amount, decimals) {
      let num = numeral(amount).format('0.' + ('0'.repeat(decimals)));
      if (!(num !== 0)) {
        return '';
      }

      return '$' + num;
    },
    visibleTo (groupName){
      return this.theme.userGroups.indexOf(groupName) > -1;
    },

    setSelectedDate(dateCode, propertyName) {
      if(dateCode==='THIS_MONTH'){
        this[propertyName] = [
          moment().clone().startOf('month').toDate(),
          moment().clone().endOf('month').toDate()
        ];
      }else if(dateCode==='PREVIOUS_MONTH'){
        this[propertyName] = [
          moment().clone().subtract(1, 'months').startOf('month').toDate(),
          moment().clone().subtract(1, 'months').endOf('month').toDate()
        ];
      }else if(dateCode==='LAST_7_DAYS'){
        this[propertyName] = [
          moment().clone().subtract(8, 'days').toDate(),
          moment().clone().toDate()
        ];
      }else if(dateCode==='YESTERDAY'){
        this[propertyName] = [
          moment().clone().subtract(1, 'days').toDate(),
          moment().clone().subtract(1, 'days').toDate(),
        ];
      }else{
        this.$buefy.snackbar.open(dateCode + ' is not within the valid options.');
      }
    },

    randomChartData (n) {
      const data = []
      for (let i = 0; i < n; i++) {
        data.push(Math.round(Math.random() * 200))
      }
      return data
    },

    dataToChart (data, dateKey, labelKey, axisYKey) {

      if(data.length === 0){
        return [];
      }

      // Create all Labels (For example: US, NL, etc.)
      let labels = {};
      for(let i in data){
        labels[data[i][labelKey]] = 0;
      }

      // Get all dates
      let minDate, maxDate, currentDate, momentDate;
      for(let i in data){
        currentDate = data[i][dateKey];
        momentDate = moment(currentDate);
        if(!minDate){
          minDate = maxDate = currentDate;
        }

        if(momentDate < moment(minDate)){
          minDate = currentDate;
        }

        if(momentDate > moment(maxDate)){
          maxDate = currentDate;
        }
      }

      let allDates = {};
      let currentMoment = moment(minDate).subtract(1, 'days');
      let endMoment = moment(maxDate);
      while (currentMoment.isBefore(endMoment, 'day')) {
        currentMoment.add(1, 'days');
        allDates[currentMoment.clone().format('YYYY-MM-DD')] = 0;
      }

      //reorganize data.
      let reorganizedData = {};
      for(let i in data){

        if( typeof reorganizedData[data[i][labelKey]] === 'undefined' ){
          reorganizedData[data[i][labelKey]] = {};
        }
        if( typeof reorganizedData[  data[i][labelKey]  ][  data[i][dateKey]  ] === 'undefined' ){

          let amount = 0;

          if(data[i][axisYKey]){
            amount = data[i][axisYKey];
          }

          reorganizedData[  data[i][labelKey]  ][   data[i][dateKey]   ] = amount;
        }
      }

      allDates = Object.fromEntries(Object.entries(allDates).sort());

      // Add missing dates.
      for(let i in reorganizedData){
        reorganizedData[i] = {...allDates, ...reorganizedData[i] };
      }

      // convert objects to arrays.
      for(let i in reorganizedData){
        reorganizedData[i] = Object.fromEntries(Object.entries(reorganizedData[i]).sort());
        reorganizedData[i] = Object.values(reorganizedData[i]);
      }

      return {
        'dataset': reorganizedData,
        'labels': Object.keys(allDates)
      }
    },

    dataToChartPivot (data, dateKey, labelKeys) {

      if(data.length === 0){
        return [];
      }

      // Create all Labels (For example: US, NL, etc.)
      let labels = {};
      for(let i in labelKeys){
        labels[i] = 0;
      }

      // Get all dates
      let minDate, maxDate, currentDate, momentDate;
      for(let i in data){
        currentDate = data[i][dateKey];
        momentDate = moment(currentDate);
        if(!minDate){
          minDate = maxDate = currentDate;
        }

        if(momentDate < moment(minDate)){
          minDate = currentDate;
        }

        if(momentDate > moment(maxDate)){
          maxDate = currentDate;
        }
      }

      let allDates = {};
      let currentMoment = moment(minDate).subtract(1, 'days');
      let endMoment = moment(maxDate);
      while (currentMoment.isBefore(endMoment, 'day')) {
        currentMoment.add(1, 'days');
        allDates[currentMoment.clone().format('YYYY-MM-DD')] = 0;
      }

      //reorganize data.
      let reorganizedData = {};
      for(let i in data){
        for(let labelKey in labelKeys) {
          // labelKeys[labelKey] = data[i][labelKey]
          if (typeof reorganizedData[labelKeys[labelKey]] === 'undefined') {
            reorganizedData[labelKeys[labelKey]] = {};
          }
          if (typeof reorganizedData[labelKeys[labelKey]][data[i][dateKey]] === 'undefined') {

            let amount = 0;

            if (data[i][labelKey]) {
              amount = data[i][labelKey];
            }

            reorganizedData[labelKeys[labelKey]][data[i][dateKey]] = amount;
          }
        }
      }

      allDates = Object.fromEntries(Object.entries(allDates).sort());

      // Add missing dates.
      for(let i in reorganizedData){
        reorganizedData[i] = {...allDates, ...reorganizedData[i] };
      }

      // convert objects to arrays.
      for(let i in reorganizedData){
        reorganizedData[i] = Object.fromEntries(Object.entries(reorganizedData[i]).sort());
        reorganizedData[i] = Object.values(reorganizedData[i]);
      }

      return {
        'dataset': reorganizedData,
        'labels': Object.keys(allDates)
      }
    },

    fillReportKeywordData (pageNumber, ordering) {
      let fromDate,
          toDate;

      pageNumber = pageNumber || this.dashboardsReportKeywordsPerformances.paging.page_number || 1;
      ordering = ordering || this.dashboardsReportKeywordsPerformances.ordering || 'revenue';

      if(this.dailyReportKeywordsSelectedDate.length > 1){
        fromDate = moment(this.dailyReportKeywordsSelectedDate[0]).format('YYYY-MM-DD');
        toDate = moment(this.dailyReportKeywordsSelectedDate[1]).format('YYYY-MM-DD');
      }

      this.$store.dispatch(
        'dashboardsReportKeywordsPerformances/getByDateRange', {
          fromDate: fromDate,
          toDate: toDate,
          pageNumber: pageNumber,
          ordering: ordering
        }
      );

    },

    fillReportKeywordDataChangePage (newPage) {
      this.fillReportKeywordData(newPage);
    },

    fillReportKeywordDataSortPressed (field, order) {
      if(order === 'desc'){
        field = '-' + field;
      }

      this.fillReportKeywordData(null, field);
    },

    fillTqScoresData (pageNumber, ordering) {

      pageNumber = pageNumber || this.tqScores.paging.page_number || 1;
      ordering = ordering || this.tqScores.ordering || 'score';

      let countries = this.selectedFiltersTqScoresData.countries,
          agencyFeeds = this.selectedFiltersTqScoresData.agencyFeeds,
          rangeDate = this.selectedFiltersTqScoresData.rangeDate;

      if(countries.length > 0){
        countries = countries.map((e) => { return e.id })
      }
      if(agencyFeeds.length > 0){
        agencyFeeds = agencyFeeds.map((e) => { return e.id })
      }

      this.$store.dispatch(
          'tqScores/getAll', {
            pageNumber: pageNumber,
            ordering: ordering,
            countries: countries,
            agencyFeeds: agencyFeeds,
            rangeDate: rangeDate && rangeDate.length > 0 ? [ moment(rangeDate[0]), moment(rangeDate[1])] : [],
          }
      );
    },

    tqScoresChangePage (newPage) {
      this.fillTqScoresData(newPage);
    },

    tqScoresSortPressed (field, order) {
      if(order === 'desc'){
        field = '-' + field;
      }

      this.fillTqScoresData(null, field);
    },
    fillChartData () {
      let fromDate,
          toDate,
          dailyChartType = this.dailyChartType;

      if(this.dailyChartSelectedDate.length > 1){
        fromDate = moment(this.dailyChartSelectedDate[0]).format('YYYY-MM-DD');
        toDate = moment(this.dailyChartSelectedDate[1]).format('YYYY-MM-DD');
      }

      this.$store.dispatch(
          'dashboardsChartPerformances/getByDateRange', {
            fromDate: fromDate,
            toDate: toDate,
            dailyChartType: dailyChartType
          }
      );
    },
    fillChartKeywordsData () {
      this.$store.dispatch(
          'dashboardsChartKeywords/getByDateRange', {
            fromDate: moment(this.selectedMultiFilter['rangeDate'][0]).format('YYYY-MM-DD'),
            toDate: moment(this.selectedMultiFilter['rangeDate'][1]).format('YYYY-MM-DD'),
            networks: this.selectedMultiFilter['networks'],
            agencyFeeds: this.selectedMultiFilter['agencyFeeds'],
            products: this.selectedMultiFilter['products'],
            countries: this.selectedMultiFilter['countries'],
          }
      );
    },

    getAdsAvailable (accountList){
      let accounts = accountList,
        spaceAvailable = 0,
        accountsWithAds = [],
        adsAvailableForAccount = 0;

      if(accounts.length === 0){
        return {
          count: spaceAvailable,
          accounts: accountsWithAds
        }
      }

      for(let i in accounts){
        adsAvailableForAccount = accounts[i].ads_limit - accounts[i].ads_live_count;
        spaceAvailable += adsAvailableForAccount;

        if(adsAvailableForAccount > 0){
          accountsWithAds.push(accounts[i]);
        }
      }

      return {
          count: spaceAvailable,
          accounts: accountsWithAds
      };
    },

    viewAccounts (title, accountList){
      this.showAccountsModal = true;
      this.accountModalTitle = title;
      this.accountModalAccounts = accountList;
    },

    viewFacebookPages (title, pagesList){
      this.showFacebookPagesModal = true;
      this.facebookPagesModalTitle = title;
      this.facebookPagesModalPages = pagesList;
    },
    selectedAgencyFeedsChanged: function (){
      this.$store.dispatch('products/getAll', {
        'agencyFeeds': this.selectedAgencyFeeds.map((element) => { return element.id })
      });
    },
  }
}
</script>
