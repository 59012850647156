<style scoped>
.is-selected {
  border: 1px solid red;
}

.bordered {
  border: 1px solid #696969;
  border-radius: 1rem;
  padding: 1rem;
}
</style>
<template>
  <div>
    <b-button type="is-success" @click="isCreateNewWaterMark = true;" v-if="!isCreateNewWaterMark && !isCreateNewFrame">
      Create new watermark
    </b-button>
    <b-button type="is-success" @click="isCreateNewFrame = true;" v-if="!isCreateNewFrame && !isCreateNewWaterMark"
              class="ml-3">Create new frame
    </b-button>
    <div v-if="isCreateNewWaterMark" class="bordered">
      <b-field
          label="Watermarks:"
      >
        <div class="row">
          <div
              class="column"
              v-for="(w, wRowId) in waterMarksList"
              v-bind:key="`watermarks-list-${wRowId}`"
              :data-url="w.image"
              @click="selectWaterMark(w['watermark_code']);"
              style="max-width: 200px; cursor: pointer;"
          >
            <b-image
                :src="w.image"
                :class="{'is-selected': watermarkItem.code === w['watermark_code']}"
            ></b-image>
          </div>
        </div>

      </b-field>

      <b-field
          label="Positions:"
      >

        <b-select v-model="watermarkItem['position']">
          <option v-for="s in waterMarkPositions" :value="s.code"
                  v-bind:key="`watermarks-positions-${s.code}`">
            {{ s.name }}
          </option>
        </b-select>
      </b-field>
      <b-field
          label="Country:"
      >
        <b-checkbox v-model="watermarkItem['applyToAllCountries']">Apply to all countries</b-checkbox>
      </b-field>
      <div v-if="!watermarkItem['applyToAllCountries']">
        List of countries
        <multiselect
            v-model="watermarkItem['countries']"
            :options="countries"
            placeholder="Appy only to.."
            label="name"
            multiple
            :close-on-select="false"
            :searchable="true"
            track-by="code">
        </multiselect>
      </div>

      <b-field
          label="Date range:"
      >
        <b-checkbox v-model="watermarkItem['applyAlways']">Apply always</b-checkbox>
        <br/>
      </b-field>
      <div v-if="!watermarkItem['applyAlways']">
        <div>
          <b-field grouped>
            <b-field label="From day:">
              <b-input type="number" step="1" max="31" min="1" v-model="watermarkItem['fromDay']"></b-input>
            </b-field>
            <b-field label="From Month:">
              <b-input type="number" step="1" max="12" min="1" v-model="watermarkItem['fromMonth']"></b-input>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="To day:">
              <b-input type="number" step="1" max="31" min="1" v-model="watermarkItem['toDay']"></b-input>
            </b-field>
            <b-field label="To Month:">
              <b-input type="number" step="1" max="12" min="1" v-model="watermarkItem['toMonth']"></b-input>
            </b-field>
          </b-field>
          <span class="is-small"><b>Note:</b> if days are greater that the last date of that month, for example 31 of February, we will use the latest day of that month.</span>
        </div>
      </div>

      <b-button type="is-success" size="is-small" class="mt-6" @click="addWaterMark()">Add</b-button>

      <b-button type="is-danger" size="is-small" class="ml-6 mt-6" @click="isCreateNewWaterMark=false">Cancel</b-button>
    </div>
    <div v-if="isCreateNewFrame" class="bordered">
      <b-field
          label="Frames:"
      >
      </b-field>

      <div class="row">
        <div
            class="column"
            v-for="(f, wRowId) in framesList"
            v-bind:key="`frames-list-${wRowId}`"
            @click="selectFrame(f['frame_code']);"
            style="max-width: 200px; cursor: pointer;"
        >
          <div
              style="height: 180px; width: 180px; margin-left: 10px; align-content: center; text-align: center;"
              :style="{
                  'border-color': f['color_html'],
                  'border-width': getFrameSizeInPx(f['frame_size_type']),
                  'border-style': 'solid'
                }"
          >
            <b>{{ frameItem.code === f['frame_code'] ? 'selected' : '' }}</b>
          </div>
        </div>

      </div>

      <b-field
          label="Date range:"
      >
        <b-checkbox v-model="frameItem['applyAlways']">Apply always</b-checkbox>
        <br/>
      </b-field>
      <div v-if="!frameItem['applyAlways']">
        <div>
          <b-field grouped>
            <b-field label="From day:">
              <b-input type="number" step="1" max="31" min="1" v-model="frameItem['fromDay']"></b-input>
            </b-field>
            <b-field label="From Month:">
              <b-input type="number" step="1" max="12" min="1" v-model="frameItem['fromMonth']"></b-input>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="To day:">
              <b-input type="number" step="1" max="31" min="1" v-model="frameItem['toDay']"></b-input>
            </b-field>
            <b-field label="To Month:">
              <b-input type="number" step="1" max="12" min="1" v-model="frameItem['toMonth']"></b-input>
            </b-field>
          </b-field>
          <span class="is-small"><b>Note:</b> if days are greater that the last date of that month, for example 31 of February, we will use the latest day of that month.</span>
        </div>
      </div>


      <b-button type="is-success" size="is-small" class="mt-6" @click="addFrame()">Add</b-button>

      <b-button type="is-danger" size="is-small" class="ml-6 mt-6" @click="isCreateNewFrame=false">Cancel</b-button>

    </div>

    <hr v-if="watermarks.length > 0"/>
    <b-field label="Selected Watermarks:" v-if="watermarks.length > 0"></b-field>
    <div class="b-table" v-if="watermarks.length > 0">
      <span><b>Note:</b> in case of overlap configuration a random one from them will be applied. </span>
      <div class="table-wrapper">
        <table class="table is-striped">
          <thead>
          <tr>
            <th>Image</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(watermark, rowId) in watermarks" v-bind:key="`selected-watermarks-${rowId}`">
            <td>
              <div style="width: 200px; height: 200px;">
                <b-image
                    :src="getWaterMarkByCode(watermark['code']).image"
                    responsive
                    style="width: 200px;"
                ></b-image>
              </div>
            </td>
            <td>
              <span><b>Position:</b> {{ watermark.position }}</span><br/>
              <span><b>Countries:</b> {{
                  watermark.countriesCode.length === 0 ? 'All' : watermark.countriesCode.join(", ")
                }}</span><br/>

              <span v-if="watermark.applyAlways"><b>Applies always.</b></span>
              <span v-else><b>From:</b> {{ watermark.fromDay }}/{{ watermark.fromMonth }}, <b>To:</b> {{
                  watermark.toDay
                }}/{{ watermark.toMonth }}</span>
            </td>
            <td style="text-align: right;">
              <b-button @click="removeWaterMark(rowId);" type="is-danger" size="is-small">Remove</b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr v-if="frames.length > 0"/>
    <b-field label="Selected Frames:" v-if="frames.length > 0"></b-field>

    <div class="b-table" v-if="frames.length > 0">
      <span><b>Note:</b> in case of overlap configuration a random one from them will be applied. </span>
      <div class="table-wrapper">
        <table class="table is-striped">
          <thead>
          <tr>
            <th>Image</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(frame, rowId) in frames" v-bind:key="`selected-frames-${rowId}`">
            <td>
              <div
                  style="height: 180px; width: 180px; margin-left: 10px; align-content: center; text-align: center;"
                  :style="{
                'border-color': getFrameByCode(frame['code'])['color_html'],
                'border-width': getFrameSizeInPx(getFrameByCode(frame['code'])['frame_size_type']),
                'border-style': 'solid'
              }"
              >

              </div>
            </td>
            <td>
              <span v-if="frame.applyAlways"><b>Applies always.</b></span>
              <span v-else><b>From:</b> {{ frame.fromDay }}/{{ frame.fromMonth }}, <b>To:</b> {{
                  frame.toDay
                }}/{{ frame.toMonth }}</span>
            </td>
            <td style="text-align: right;">
              <b-button @click="removeFrame(rowId);" type="is-danger" size="is-small">Remove</b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import vue from "vue";
import {picassoWatermarksService} from "@/services/picasso-watermarks.service";
import {picassoFramesService} from "@/services/picasso-frames.service";
import {devCountriesService} from "@/services";
import Multiselect from "vue-multiselect";

export default {
  name: 'WaterMarksFramesSelector',
  components: {Multiselect},
  props: {
    agencyFeed: {
      type: String,
      default: null
    },
    initialData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  created() {
    let that = this;

    picassoWatermarksService.get().then(response => {
      that.waterMarksList = response.data;
      that.loadedDependencies = that.loadedDependencies + 1;
      if (this.initialData && that.loadedDependencies === 3) {
        this.normalizeInitialData(this.initialData);
      }
    }).catch(r => {
      alert('An Error Occurred.')
      console.dir(r);

    })

    picassoFramesService.get().then(response => {
      that.framesList = response.data;
      that.loadedDependencies = that.loadedDependencies + 1;
      if (this.initialData && that.loadedDependencies === 3) {
        this.normalizeInitialData(this.initialData);
      }
    }).catch(r => {
      alert('An Error Occurred.')
      console.dir(r);
    })

    devCountriesService.getAll({
      'agency_feed': this.agencyFeed
    }).then(response => {
      that.countries = response.data['results'];
      that.loadedDependencies = that.loadedDependencies + 1;
      if (this.initialData && that.loadedDependencies === 3) {
        this.normalizeInitialData(this.initialData);
      }
    });
  },

  data() {
    return {
      isLoading: false,

      loadedDependencies: 0,

      isCreateNewWaterMark: false,
      isCreateNewFrame: false,

      waterMarksList: [],
      framesList: [],

      countries: [],

      watermarkItem: {
        position: 'MIDDLE_CENTER',
        applyToAllCountries: true,
        code: null,
        countries: [],
        countriesCode: [],
        applyAlways: true,
        fromDay: 1,
        fromMonth: 1,
        toDay: 31,
        toMonth: 12,
      },

      watermarks: [],

      frames: [],

      frameItem: {
        code: null,
        applyAlways: true,
        fromDay: 1,
        fromMonth: 1,
        toDay: 31,
        toMonth: 12,
      },

      waterMarkPositions: [
        {name: 'Top-Right', code: 'TOP_RIGHT'},
        {name: 'Top-Center', code: 'TOP_CENTER'},
        {name: 'Top-Left', code: 'TOP_LEFT'},
        {name: 'Middle-Right', code: 'MIDDLE_RIGHT'},
        {name: 'Middle-Center', code: 'MIDDLE_CENTER'},
        {name: 'Middle-Left', code: 'MIDDLE_LEFT'},
        {name: 'Bottom-Right', code: 'BOTTOM_RIGHT'},
        {name: 'Bottom-Center', code: 'BOTTOM_CENTER'},
        {name: 'Bottom-Left', code: 'BOTTOM_LEFT'},
      ]

    }
  },

  methods: {


    isValidWaterMark() {

      if (!this.watermarkItem.applyToAllCountries && (!this.watermarkItem.countries || this.watermarkItem.countries.length === 0)) {
        this.$buefy.snackbar.open({
          'message': 'You must select 1 or more countries when filtering by country.',
          'position': 'is-top'
        });
        return false;
      }

      if (!this.watermarkItem.applyAlways) {

        if (!(this.watermarkItem.fromDay >= 1 && this.watermarkItem.fromDay <= 31)) {
          this.$buefy.snackbar.open({'message': 'From Day must be from 1-31.', 'position': 'is-top'});
          return false;
        }

        if (!(this.watermarkItem.fromMonth >= 1 && this.watermarkItem.fromMonth <= 12)) {
          this.$buefy.snackbar.open({'message': 'From Month must be from 1-12.', 'position': 'is-top'});
          return false;
        }

        if (!(this.watermarkItem.toDay >= 1 && this.watermarkItem.toDay <= 31)) {
          this.$buefy.snackbar.open({'message': 'To Day must be from 1-31.', 'position': 'is-top'});
          return false;
        }

        if (!(this.watermarkItem.toMonth >= 1 && this.watermarkItem.toMonth <= 12)) {
          this.$buefy.snackbar.open({'message': 'To Month must be from 1-12.', 'position': 'is-top'});
          return false;
        }

        if (this.watermarkItem.fromMonth === this.watermarkItem.toMonth) {
          if (this.watermarkItem.fromDay > this.watermarkItem.toDay) {
            this.$buefy.snackbar.open({'message': 'From Day cannot be bigger than To Day.', 'position': 'is-top'});
            return false;
          }
        }

        if (this.watermarkItem.fromMonth > this.watermarkItem.toMonth) {
          this.$buefy.snackbar.open({'message': 'From Month cannot be bigger than To Month.', 'position': 'is-top'});
          return false;
        }

      }

      if (!this.watermarkItem.position || this.watermarkItem.position === '') {
        this.$buefy.snackbar.open({'message': 'You must select a position', 'position': 'is-top'});
        return false;
      }

      if (!this.watermarkItem.code || this.watermarkItem.code === '') {
        this.$buefy.snackbar.open({'message': 'You must select a watermark image', 'position': 'is-top'});
        return false;
      }

      return true;
    },
    isValidFrame() {


      if (!this.frameItem.applyAlways) {

        if (!(this.frameItem.fromDay >= 1 && this.frameItem.fromDay <= 31)) {
          this.$buefy.snackbar.open({'message': 'From Day must be from 1-31.', 'position': 'is-top'});
          return false;
        }

        if (!(this.frameItem.fromMonth >= 1 && this.frameItem.fromMonth <= 12)) {
          this.$buefy.snackbar.open({'message': 'From Month must be from 1-12.', 'position': 'is-top'});
          return false;
        }

        if (!(this.frameItem.toDay >= 1 && this.frameItem.toDay <= 31)) {
          this.$buefy.snackbar.open({'message': 'To Day must be from 1-31.', 'position': 'is-top'});
          return false;
        }

        if (!(this.frameItem.toMonth >= 1 && this.frameItem.toMonth <= 12)) {
          this.$buefy.snackbar.open({'message': 'To Month must be from 1-12.', 'position': 'is-top'});
          return false;
        }

        if (this.frameItem.fromMonth === this.frameItem.toMonth) {
          if (this.frameItem.fromDay > this.frameItem.toDay) {
            this.$buefy.snackbar.open({'message': 'From Day cannot be bigger than To Day.', 'position': 'is-top'});
            return false;
          }
        }

        if (this.frameItem.fromMonth > this.frameItem.toMonth) {
          this.$buefy.snackbar.open({'message': 'From Month cannot be bigger than To Month.', 'position': 'is-top'});
          return false;
        }

      }

      if (!this.frameItem.code || this.frameItem.code === '') {
        this.$buefy.snackbar.open({'message': 'You must select a frame.', 'position': 'is-top'});
        return false;
      }

      return true;
    },

    addWaterMark() {

      if (!this.isValidWaterMark()) {
        return;
      }
      this.watermarkItem.countriesCode = this.watermarkItem.countries.map((e) => {
        return e.code
      });
      vue.set(this.watermarks, this.watermarks.length, Object.assign({}, this.watermarkItem));
      this.isCreateNewWaterMark = false;

      this.updateModel();
    },

    addFrame() {

      if (!this.isValidFrame()) {
        return;
      }
      vue.set(this.frames, this.frames.length, Object.assign({}, this.frameItem));
      this.isCreateNewFrame = false;

      this.updateModel();
    },

    removeWaterMark(rowId) {
      vue.delete(this.watermarks, rowId);
      this.updateModel();
    },

    removeFrame(rowId) {
      vue.delete(this.frames, rowId);
      this.updateModel();
    },

    selectWaterMark(code) {
      vue.set(this.watermarkItem, 'code', code);
    },

    selectFrame(code) {
      vue.set(this.frameItem, 'code', code);
    },

    getFrameSizeInPx(sizeCode) {

      if (sizeCode === 'MINOR') {
        return '1px';
      } else if (sizeCode === 'SMALL') {
        return '5px';
      } else if (sizeCode === 'MEDIUM') {
        return '10px';
      } else if (sizeCode === 'BIG') {
        return '15px';
      } else {
        alert('Size code: ' + sizeCode + ' is not valid. Please contact IT.')
      }

    },

    getWaterMarkByCode(code) {
      let items = this.waterMarksList.filter((e) => {
        return e.watermark_code === code
      });

      if (items) {
        return items[0];
      }
      return {};
    },

    getFrameByCode(code) {
      let items = this.framesList.filter((e) => {
        return e.frame_code === code
      });

      if (items) {
        return items[0];
      }

      return {};
    },

    normalizeInitialData(data) {
      let frames, watermarks, frameCode, frameDateRange, watermarkCode, watermarkDateRange,
          watermarkPosition, watermarkRowId, newWatermark, frameRowId, newFrame, watermarksCountry;

      frames = data['frames'];
      watermarksCountry = data['watermarks'];

      if (this.framesList.length > 0) {
        for (let iF in frames) {
          frameCode = frames[iF]['code'];
          frameDateRange = frames[iF]['date_range'];

          frameRowId = this.getFrameRowId(frameCode, frameDateRange);

          if (!frameRowId) {

            newFrame = {
              'code': frameCode,
            }

            if (frameDateRange) {
              newFrame['fromDay'] = frameDateRange['from']['day'];
              newFrame['fromMonth'] = frameDateRange['from']['month'];
              newFrame['toDay'] = frameDateRange['to']['day'];
              newFrame['toMonth'] = frameDateRange['to']['month'];
              newFrame['applyAlways'] = false;
            }else{
              newFrame['applyAlways'] = true;
            }

            this.frames.push(newFrame);
          }
        }
      }


      for (let countryCode in watermarksCountry) {

        if (this.waterMarksList.length > 0) {
          watermarks = watermarksCountry[countryCode];
          for (let iW in watermarks) {
            watermarkCode = watermarks[iW]['code'];
            watermarkDateRange = watermarks[iW]['date_range'];
            watermarkPosition = watermarks[iW]['position'];

            watermarkRowId = this.getWatermarkRowId(watermarkCode, watermarkPosition, watermarkDateRange);

            if (!watermarkRowId) {

              newWatermark = {
                'code': watermarkCode,
                'position': watermarkPosition
              }

              if (watermarkDateRange) {
                newWatermark['fromDay'] = watermarkDateRange['from']['day'];
                newWatermark['fromMonth'] = watermarkDateRange['from']['month'];
                newWatermark['toDay'] = watermarkDateRange['to']['day'];
                newWatermark['toMonth'] = watermarkDateRange['to']['month'];
                newWatermark['applyAlways'] = false;
              } else {
                newWatermark['applyAlways'] = true;
              }

              if (countryCode !== 'default') {
                newWatermark['countriesCode'] = [countryCode];
                newWatermark['applyToAllCountries'] = false;
              } else {
                newWatermark['countriesCode'] = [];
                newWatermark['applyToAllCountries'] = true;
              }

              this.watermarks.push(newWatermark);
            } else {
              if (this.watermarks[watermarkRowId]['countriesCode'].indexOf(countryCode) === -1) {
                this.watermarks[watermarkRowId]['countriesCode'].push(countryCode);
              }
            }
          }
        }
      }

      this.updateModel();
    },

    denormalizeInitialData() {
      let watermarks = {}, frames = [];

      for(let iW in this.watermarks){
        let watermark = this.watermarks[iW];
        let newWatermark = {
          'code': watermark['code'],
          'position': watermark['position']
        }

        if(!watermark['applyAlways']){
          newWatermark['date_range'] = {
            "from": {
              "month": watermark['fromMonth'],
              "day": watermark['fromDay']
            },
            "to": {
              "month": watermark['toMonth'],
              "day": watermark['toDay']
            }
          };
        }

        if(watermark['applyToAllCountries']){

          if(!('default' in watermarks)){
            watermarks['default'] = [];
          }

          watermarks['default'].push(newWatermark);
        }else{
          for(let i in watermark['countriesCode']){
            let countryCode = watermark['countriesCode'][i];
            if(!(countryCode in watermarks)){
              watermarks[countryCode] = [];
            }
            watermarks[countryCode].push(newWatermark);
          }
        }
      }

      for(let iF in this.frames){
        let frame = this.frames[iF];
        let newFrame = {
          'code': frame['code'],
        }

        if(!frame['applyAlways']){
          newFrame['date_range'] = {
            "from": {
              "month": frame['fromMonth'],
              "day": frame['fromDay']
            },
            "to": {
              "month": frame['toMonth'],
              "day": frame['toDay']
            }
          };
        }

        frames.push(newFrame);
      }

      return {
        'watermarks': watermarks,
        'frames': frames
      }

    },

    getWatermarkRowId(watermarkCode, watermarkPosition, watermarkDateRange) {

      watermarkDateRange = watermarkDateRange || false;

      for (let iW in this.watermarks) {

        let watermark = this.watermarks[iW];

        if (watermark['code'] === watermarkCode && watermark['position'] === watermarkPosition) {

          if (!watermarkDateRange && watermark['applyAlways']) {
            return iW;
          } else {
            if (
                watermarkDateRange['from']['month'] === watermark['fromMonth'] &&
                watermarkDateRange['from']['day'] === watermark['fromDay'] &&
                watermarkDateRange['to']['month'] === watermark['toMonth'] &&
                watermarkDateRange['to']['day'] === watermark['toDay']
            ) {
              return iW;
            }
          }
        }
      }

      return null;
    },

    getFrameRowId(frameCode, frameDateRange) {

      frameDateRange = frameDateRange || false;

      for (let iF in this.frames) {

        let frame = this.frames[iF];

        if (!frameDateRange) {
          if (frame['code'] === frameCode && frame['applyAlways']) {
            return iF;
          }
        } else {
          if (
            frame['code'] === frameCode &&
            frameDateRange['from']['month'] === frame['fromMonth'] &&
            frameDateRange['from']['day'] === frame['fromDay'] &&
            frameDateRange['to']['month'] === frame['toMonth'] &&
            frameDateRange['to']['day'] === frame['toDay']
          ) {
            return iF;
          }
        }
      }

      return null;
    },

    updateModel(){
      this.$emit('input', this.denormalizeInitialData());
    }

  },

}
</script>
