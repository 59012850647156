import { config } from './config';
import axios from "axios";


export const toolsImageSearcherService = {
    search,
    searchFreeImages,
    uploadByUrl,
    uploadByBase64,
    config
};


export function search(keywordName, networkCode, limit) {

    limit = limit || 20;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/proxy/picasso/image/image-searcher/?network_code=${networkCode}&keyword_name=${keywordName}&limit=${limit}`)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function searchFreeImages(keywordName) {

    let endpoint = `${config.apiUrl}/api/proxy/picasso/image/free-image-searcher/?keyword_name=${keywordName}`;
    endpoint += '&nocache=' + Date.now();

    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function uploadByUrl(keywordName, url) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/proxy/picasso/image/base-images/`,{
            'keyword_name': keywordName,
            'image_url': url
        })
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}
export function uploadByBase64(keywordName, base64Content, fileName) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/proxy/picasso/image/base-images/`,{
            'keyword_name': keywordName,
            'image_content_base64': base64Content,
            'image_content_name': fileName
        })
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}