<template>
  <div>
    <section class="section is-main-section">

      <b-tabs v-model="activeTab" type="is-boxed">

        <b-tab-item label="Not Launched Keywords" value="NOT_LAUNCHED_KEYWORDS">

           <dependant-multi-filter
              v-model="selectedMultiFilter"
              networks-enabled
              partners-enabled
              countries-enabled
              marketing-users-enabled
              languages-enabled
              subdomain-locales-enabled
              @filter="filterList"
          ></dependant-multi-filter>
          <b-field class="mt-1 is-inline-block">
            <b-input
              class="mr-1"
              style="{border-radius: 0}"
              v-model="keyword"
              placeholder="Keyword"
              name="keyword"
              type="text" />
            <b-checkbox v-model="has_media">Has Media</b-checkbox>
            <b-checkbox v-model="is_active">Is Active</b-checkbox>
            <b-checkbox v-model="is_approved">Is Approved</b-checkbox>
          </b-field>

          <b-field class="is-inline-block mx-1" label="Uploaded on" label-position="on-border">
            <b-datepicker
            style="min-width: 16rem;"
            class="form-control"
            placeholder="Uploaded on"
            v-model="last_upload_on_datetime__range"
            range>
            <b-button type="is-success" :disabled="!last_upload_on_datetime__range.length" @click="last_upload_on_datetime__range = []">Clear</b-button>
          </b-datepicker>
        </b-field>

        <b-field class="is-inline-block" label="First seen on" label-position="on-border">
          <b-datepicker
            style="min-width: 16rem;"
            class="form-control"
            placeholder="First seen on"
            v-model="first_seen_on_datetime__range"
            range>
            <b-button type="is-success" :disabled="!first_seen_on_datetime__range.length" @click="first_seen_on_datetime__range = []">Clear</b-button>
          </b-datepicker>
        </b-field>
        
        <b-field>
          <b-button type="is-danger" class="mr-1" @click="removeAll(results.all)" :disabled="removingIsLoading > 0 || relaunchingIsLoading > 0">Remove All</b-button>
          <b-button type="is-success" @click="relaunchAll(results.all)" :disabled="removingIsLoading > 0 || relaunchingIsLoading > 0">Relaunch All</b-button><br />
        </b-field>

          <b-table
              :data="results.all"

              :striped="true"
              :hoverable="true"
              :loading="results.loading"
              :sticky-header="true"

              pagination-position="top"
              default-sort-direction="DESC"
              :pagination-rounded="false"
              sort-icon="chevron-up"
              sort-icon-size="is-medium"
              default-sort="_profit"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"

              :per-page="results.paging.page_size"
              :total="results.paging.count"
              :current-page="results.paging.page_number"

              @page-change="changePage"
              backend-pagination
              paginated

              backend-sorting
              @sort="sortPressed"
              @sorting-priority-removed="sortingPriorityRemoved"

              :sort-multiple="true"
              :sort-multiple-data="sortingPriority"
              :sort-multiple-key="customKey"

              :scrollable="true"
              narrowed
              height="900"
          >
            <div v-if="showEmptyResultMessageNotLaunched" slot="empty" class="notification is-warning is-light has-text-centered">
              The selected query didn't return any records, Try a different date or reduce the amount of filters.
            </div>
            <b-table-column label="Actions" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">
                <b-button size="is-small" type="is-danger" class="mr-1" @click="remove(props.row.id, props.row)" v-if="showRemove(props.row)">Remove</b-button>
                <b-button size="is-small" type="is-success" @click="relaunch(props.row.id, props.row)" v-if="showRelaunch(props.row)">Relaunch</b-button>
              </template>
            </b-table-column>

            <b-table-column field="keyword" label="Keyword" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.keyword.name  }}</template>
            </b-table-column>

            <b-table-column field="network.code" label="Network" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.network.code }}</template>
            </b-table-column>

            <b-table-column field="country" label="Country" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.country.name }}</template>
            </b-table-column>

            <b-table-column field="agency_feeds" label="Agency Feeds" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.agency_feeds.map(e => { return e[getFieldCodeLabel()] }).join(', ') }}</template>
            </b-table-column>

            <b-table-column field="subdomain_locales" label="Subdomain Locales" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">
                <b-button icon-right="pencil" size="is-small" class="mr-2" type="is-success" @click="showSubdomainModal = true; clickedRowId = props.row.id; row = props.row" />
                <span>{{ selectedSubdomains(props.row) }}</span>
              </template>
            </b-table-column>

            <b-table-column field="medias" label="Medias" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">

                <b-tag v-if="props.row.videos.length > 0">
                  <b-icon icon="file-video-outline" />
                  {{ props.row.videos.length  }}
                </b-tag>

                <b-tag v-if="props.row.images.length > 0">
                  <b-icon icon="file-image-outline" />
                  {{ props.row.images.length  }}
                </b-tag>
              </template>
            </b-table-column>

            <b-table-column label="User" field="user" sortable v-slot="props">
                {{ getUsername(props.row.user) }}
            </b-table-column>

            <b-table-column field="is_approved" label="Is approved" sortable v-slot="props">
                <b-icon v-if="props.row.is_approved" icon="check-circle" size="is_medium" type="is-success"  />
                <b-icon v-else icon="close-circle" size="is_medium" type="is-danger" />
            </b-table-column>

            <b-table-column field="is_active" label="Is active" sortable v-slot="props">
                <b-icon v-if="props.row.is_active" icon="check-circle" size="is_medium" type="is-success" />
                <b-icon v-else icon="close-circle" size="is_medium" type="is-danger" />
            </b-table-column>

            <b-table-column field="created_on_datetime" label="First seen on" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ datetimeToSqlString(props.row.created_on_datetime) }}</template>
            </b-table-column>

            <b-table-column field="last_upload_on_datetime" label="Uploaded on" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ datetimeToSqlString(props.row.last_upload_on_datetime) }}</template>
            </b-table-column>

            <template slot="top-left">
                <b-field class="mt-2">
                  <b-field label="Rows" label-position="on-border" style="width: 8rem;">
                    <b-input v-model="results.paging.count" size="is-small" disabled />
                  </b-field>
                  <b-field label="Page Size" label-position="on-border">
                    <b-select v-model="pageSize" size="is-small">
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                      <option>5000</option>
                      <option>10000</option>
                    </b-select>
                    </b-field>
                </b-field>
            </template>

          </b-table>
        </b-tab-item>

        <b-tab-item label="Launched Keywords">
          <dependant-multi-filter
            v-model="launchedSelectedMultiFilter"
            range-date-enabled
            networks-enabled
            agency-feeds-enabled
            products-enabled
            countries-enabled
            marketing-users-enabled
            @filter="filterLaunchedList"
          ></dependant-multi-filter>

          <b-table
            :data="launchedResults.all"

            :striped="true"
            :hoverable="true"
            :loading="launchedResults.loading"
            :sticky-header="true"

            pagination-position="top"
            default-sort-direction="DESC"
            :pagination-rounded="false"
            sort-icon="chevron-up"
            sort-icon-size="is-medium"
            default-sort="_profit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"

            :per-page="launchedResults.paging.page_size"
            :total="launchedResults.paging.count"
            :current-page="launchedResults.paging.page_number"

            @page-change="launchedChangePage"
            backend-pagination
            paginated

            backend-sorting
            @sort="launchedSortPressed"
            @sorting-priority-removed="launchedSortingPriorityRemoved"

            :sort-multiple="true"
            :sort-multiple-data="launchedSortingPriority"
            :sort-multiple-key="customKey"

            :scrollable="true"
            narrowed
            height="900"
          >

            <div v-if="showEmptyResultMessageLaunched" slot="empty" class="notification is-warning is-light has-text-centered">
              The selected query didn't return any records, Try a different date or reduce the amount of filters.
            </div>

            <b-table-column field="cnt" label="Count" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.cnt  }}</template>
            </b-table-column>

            <b-table-column field="network" label="Network" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.network.code  }}</template>
            </b-table-column>

            <b-table-column field="user" label="User" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.user.username  }}</template>
            </b-table-column>

            <b-table-column field="subdomain_locale__country" label="Country" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.country.name  }}</template>
            </b-table-column>

            <b-table-column field="subdomain_locale__subdomain__product" label="Product" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span>
              </template>
              <template v-slot="props">{{ props.row.product[getFieldCodeLabel()]  }}</template>
            </b-table-column>

          </b-table>
        </b-tab-item>
      </b-tabs>
      <CustomModalWrapper
        title="Subdomain Locales"
        :show="showSubdomainModal"
      >
        <LaunchingQueuePageSubdomainLocalesModal
          :row="row"
          :id="clickedRowId"
          @close="showSubdomainModal = false; filterList('noLoading')">
        </LaunchingQueuePageSubdomainLocalesModal>
      </CustomModalWrapper>
    </section>
  </div>
</template>

<script>
import CustomModalWrapper from "@/components/CustomModalWrapper";
import LaunchingQueuePageSubdomainLocalesModal from "@/pages/found_keywords/LaunchingQueuePageSubdomainLocalesModal"
//import Multiselect from 'vue-multiselect'
import {creationNotLaunchedFoundKeywordsService} from "@/services/creation-not-launched-found-keywords.service";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {creationLaunchedKeywordCounterService} from "@/services/creation-launched-keywords-counter.service";
import moment from "moment-timezone/moment-timezone-utils";
import {creationFoundKeywordNetworkUserService} from "@/services/creation-found-keyword-network-user.service";
import {permissionsUtils} from "@/helpers/permissions-utils";
import Vue from "vue";
import {timeUtils} from "@/helpers/time-utils";
import { mapState } from "vuex";

export default {
  name: 'LaunchingQueuePage',

  mounted() {
    if(!this.$store.state.networks.all.length) this.$store.dispatch('networks/getAll');
    if(!this.$store.state.countries.all.length) this.$store.dispatch('countries/getAll');
    if(!this.$store.state.users.all.length) this.$store.dispatch('users/getAll');

    //uploaded_date default as last 3 days
    this.last_upload_on_datetime__range = [
        moment().subtract(3, 'days').toDate(),
        new Date()
    ]

  },

  mixins: [
    permissionsUtils,
    timeUtils,
  ],
  components: {
    DependantMultiFilter,
   // Multiselect,
    LaunchingQueuePageSubdomainLocalesModal,
    CustomModalWrapper,
  },

  data() {
    return {

      activeTab: 'NOT_LAUNCHED_KEYWORDS',

      results: {
        all: [],
        loading: false,
        paging: {
          page_size: 25,
          count: 0,
          page_number: 1
        },
      },

      pageNumber: 1,

      singleSorting: null,
      customKey: 'ctrlKey',
      sortingPriority: [],

      pageSize: 25,

      selectedMultiFilter: {},
      keyword: '',
      has_media: false,
      is_active: true,
      is_approved: false,
      last_upload_on_datetime__range: [],
      first_seen_on_datetime__range: [],

      //

      launchedResults: {
        all: [],
        loading: false,
        paging: {
          page_size: 25,
          count: 0,
          page_number: 1
        },
      },

      launchedPageNumber: 1,

      launchedSingleSorting: null,
      launchedSortingPriority: [],

      launchedPageSize: 25,

      launchedSelectedMultiFilter: {},

      removingIsLoading: 0,
      relaunchingIsLoading: 0,

      showSubdomainModal: false,
      clickedRowId: null,
      row: {},
      showEmptyResultMessageNotLaunched: false,
      showEmptyResultMessageLaunched: false,
    }
  },

  computed: {
    ...mapState([
      'users',
    ])
  },



  methods: {

    filterList (noLoading) {
      let pageId = this.pageNumber,
          sorting,
          ordering,
          params;

      if(this.sortingPriority && this.sortingPriority.length > 0){
        sorting = this.sortingPriority;
      }else{
        sorting = this.singleSorting;
      }

      sorting = sorting ? sorting : {};

      if(sorting.length > 0) {

        let sortingGroup = [];

        for(let i in sorting) {
            let sort = sorting[i];
            if(sort['order'].toLowerCase() === 'desc'){
                if(!(sort['field'].indexOf('-') === 0) ){
                    sort['field'] = '-' + sort['field'];
                }
            }

            if(sortingGroup.indexOf(sort['field']) > -1){
                continue;
            }

            sortingGroup.push(sort['field']);
        }

        ordering = sortingGroup.join(',');
      }else{
        ordering = null;
      }

      if(!noLoading) this.results.loading = true;
      this.showEmptyResultMessageNotLaunched = false;

      params = {
        'ordering': ordering,
        'page': pageId,
        'page_size': this.pageSize,
        'network__in': this.selectedMultiFilter['networks'].map((element) => { return element.id }).join(','),
        'partner__in': this.selectedMultiFilter['partners'].map((element) => { return element.id }).join(','),
        'country__in': this.selectedMultiFilter['countries'].map((element) => { return element.id }).join(','),
        'language__in': this.selectedMultiFilter['languages'].map((element) => { return element.id }).join(','),
        'subdomain_locales__in': this.selectedMultiFilter['subdomainLocales'].map((element) => { return element.id }).join(','),
        'created_by_user__in': this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }).join(','),
        'keyword__name__icontains': this.keyword,
        'last_upload_on_datetime__range': this.parseDate(this.last_upload_on_datetime__range),
        'found_keyword__created_on_datetime__range': this.parseDate(this.first_seen_on_datetime__range),
      };

      if(this.has_media){
        params['active_images_count__gt'] = 0;
      }

      if(this.is_active){
        params['is_active'] = true;
        params['found_keyword__is_active'] = true;
        params['found_keyword_network__is_active'] = true;
      }

      if(this.is_approved){
        params['found_keyword_network__is_approved'] = true;
        params['found_keyword_network__is_strictly_forbidden'] = false;
        params['found_keyword_network__are_categories_allowed'] = true;
      }

      creationNotLaunchedFoundKeywordsService.get(params).then((r) => {
        this.results.all = r.data['results']
        this.results.paging.page_size = r.data['page_size']
        this.results.paging.count = r.data['count']
        this.results.paging.page_number = r.data['page_number']
        this.results.loading = false;
        this.showEmptyResultMessageNotLaunched = true;

      }).catch((r) => {
        alert('An error occurred while trying to load NonLaunchedKeywords');
        console.dir(r);
      })
    },
    changePage(pageId){
      this.pageNumber = pageId;
      this.filterList();
    },
    sortingPriorityRemoved: function (field){
        this.sortingPriority = this.sortingPriority.filter((priority) => priority.field !== field)
    },
    sortPressed: function (field, order){
        if((this.customKey && event[this.customKey]) || !this.customKey) {
          let existingPriority = this.sortingPriority.filter(i => i.field === field)[0]
          if(existingPriority) {
            existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
          } else {
            // request sorted data from backend
            this.sortingPriority.push({field: field, order: order})
          }
          this.singleSorting = null;
          this.filterList()
        } else {
          // request regular sorted data from backend
          this.sortingPriority = []
          this.singleSorting = [{field: field, order: order}];
          this.filterList()
        }
    },

    parseDate(dateRange) {
      if(dateRange.length !== 2){
        return;
      }
      return moment(dateRange[0]).format('YYYY-MM-DD 00:00:00') + ',' + moment(dateRange[1]).format('YYYY-MM-DD 23:59:59')
    },
    //

    filterLaunchedList () {
      let pageId = this.launchedPageNumber,
          sorting,
          ordering,
          datetimeRange;

      if(this.launchedSortingPriority && this.launchedSortingPriority.length > 0){
        sorting = this.launchedSortingPriority;
      }else{
        sorting = this.launchedSingleSorting;
      }

      sorting = sorting ? sorting : {};

      if(sorting.length > 0) {

        let sortingGroup = [];

        for(let i in sorting) {
          let sort = sorting[i];
          if(sort['order'].toLowerCase() === 'desc'){
            if(!(sort['field'].indexOf('-') === 0) ){
              sort['field'] = '-' + sort['field'];
            }
          }

          if(sortingGroup.indexOf(sort['field']) > -1){
            continue;
          }

          sortingGroup.push(sort['field']);
        }

        ordering = sortingGroup.join(',');
      }else{
        ordering = null;
      }

      this.launchedResults.loading = true;


      datetimeRange  = moment(this.launchedSelectedMultiFilter['rangeDate'][0]).format('YYYY-MM-DD 00:00:00');
      datetimeRange += ',';
      datetimeRange += moment(this.launchedSelectedMultiFilter['rangeDate'][1]).format('YYYY-MM-DD 23:23:59');

      this.showEmptyResultMessageLaunched = false;

      creationLaunchedKeywordCounterService.get({
        'ordering': ordering,
        'page': pageId,
        'page_size': this.pageSize,
        'network__in': this.launchedSelectedMultiFilter['networks'].map((element) => { return element.id }).join(','),
        'agency_feed__id__in': this.launchedSelectedMultiFilter['agencyFeeds'].map((element) => { return element.id }).join(','),
        'product__in': this.launchedSelectedMultiFilter['products'].map((element) => { return element.id }).join(','),
        'country__in': this.launchedSelectedMultiFilter['countries'].map((element) => { return element.id }).join(','),
        'user__in': this.launchedSelectedMultiFilter['marketingUsers'].map((element) => { return element.id }).join(','),
        'created_on_datetime__range': datetimeRange
      }).then((r) => {
        this.launchedResults.all = r.data['results']
        this.launchedResults.paging.page_size = r.data['page_size']
        this.launchedResults.paging.count = r.data['count']
        this.launchedResults.paging.page_number = r.data['page_number']
        this.launchedResults.loading = false;
        this.showEmptyResultMessageLaunched = true;

      }).catch((r) => {
        alert('An error occurred while trying to load LaunchedKeywords');
        console.dir(r);
      })
    },

    launchedChangePage(pageId){
      this.launchedPageNumber = pageId;
      this.filterLaunchedList();
    },

    launchedSortingPriorityRemoved: function (field){
        this.launchedSortingPriority = this.launchedSortingPriority.filter((priority) => priority.field !== field)
    },
    launchedSortPressed: function (field, order){
        if((this.customKey && event[this.customKey]) || !this.customKey) {
          let existingPriority = this.launchedSortingPriority.filter(i => i.field === field)[0]
          if(existingPriority) {
            existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
          } else {
            // request sorted data from backend
            this.launchedSortingPriority.push({field: field, order: order})
          }
          this.launchedSingleSorting = null;
          this.filterLaunchedList()
        } else {
          // request regular sorted data from backend
          this.launchedSortingPriority = []
          this.launchedSingleSorting = [{field: field, order: order}];
          this.filterLaunchedList()
        }
    },

    remove(id, row){
      let that = this;
      this.removingIsLoading += 1;
      creationFoundKeywordNetworkUserService.remove(id).then(() => {
        Vue.delete(row);
        that.removingIsLoading -= 1;
      })
    },

    relaunch(id, row){
      let that = this;
      this.relaunchingIsLoading += 1;
      creationFoundKeywordNetworkUserService.patch(id, {
        'last_upload_on_datetime': moment().utc()
      }).then(response => {
        Vue.set(row, 'last_upload_on_datetime', response.data['last_upload_on_datetime']);
        that.relaunchingIsLoading += 1;
      })
    },

    showRelaunch(row){

      // only allow it after 3 days.
      if(!row['last_upload_on_datetime'] || moment(row['last_upload_on_datetime']) < moment().utc().subtract(3, 'days')) {
        // user must be the logged user or a superuser
        return row['user'] + '' === this.getUserId() + '' || this.isSuperUser();
      }

      return false;
    },

    showRemove(row){
       // user must be the logged user or a superuser
       return row['user'] + '' === this.getUserId() + '' || this.isSuperUser();
    },

    removeAll(rows){
      for(let r in rows){
        let row = rows[r];

        if(this.showRemove(row)){
          this.remove(row.id);
        }
      }
    },

    relaunchAll(rows){

      for(let r in rows){

        let row = rows[r];

        if(this.showRelaunch(row)){
          this.relaunch(row.id);
        }

      }

    },

    selectedSubdomains(row) {
      let self = this;
      const subdomains = row.subdomain_locales.map(e => {
       return `${e.country.code} - ${e.language.code} - ${e.product[self.getFieldCodeLabel()]}`
      })
      /*const ag = row.agency_feeds.map(ag => {
        return ag[self.getFieldCodeLabel()]
      })

      if(ag.length && subdomains.length) return ag.join() + ' <> ' + subdomains.join(' | ')*/

      return  subdomains.join(' | ')
    },

    getUsername(id) {
      return this.users.all.find(u => u.id === id)?.username
    },
    
  },
  watch: {
    removingIsLoading:  function (newValue, oldValue){
      if(newValue === oldValue){
        return;
      }
      if(newValue > 0){
        return;
      }
      this.filterList()
    },
    relaunchingIsLoading:  function (newValue, oldValue){
      if(newValue === oldValue){
        return;
      }
      if(newValue > 0){
        return;
      }
      this.filterList()
    },
  }
}
</script>