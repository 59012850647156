import { config } from './config';
import axios from "axios";

export const optimizationAutomationService = {
    getAll,
    create,
    update,
    remove,
};

function getAll(params) {
    params = params || {};

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/proxy/bender/automations/`, {params})
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function create(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/proxy/bender/automations/`, payload)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function update(payload) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${config.apiUrl}/api/proxy/bender/automations/${payload.id}/`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function remove(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${config.apiUrl}/api/proxy/bender/automations/${id}/`)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}