<template>
  <div>
    <b-button @click="clone" :disabled="isLoading" icon-left="content-duplicate" class="is-small is-warning">Clone</b-button>
    <b-modal :active.sync="isModalActive" has-modal-card can-cancel>
      <div class="modal-card" style="max-width: 12rem;">
        <header class="modal-card-head">
          <p class="modal-card-title">Clone Options</p>
        </header>
        <section class="modal-card-body p-5">

          <b-field label="Marketing Users">
            <b-select v-model="cloneFromUser" :loading="users['loadingMarketing']">
              <option v-for="s in users['allMarketing']" :value="s.id" v-bind:key="`clone-${s.id}`">
                {{ s.username }}
              </option>
            </b-select>
          </b-field>

          <b-button @click="save" :disabled="isLoading || cloneFromUser === null" icon-left="content-duplicate" class="mt-4 is-small is-success">Clone</b-button>

        </section>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {creationLaunchClonedKeyword} from "@/services/creation-launch-cloned-keyword.service";
import {SnackbarProgrammatic} from "buefy";
import {mapState} from "vuex";
import {permissionsUtils} from "@/helpers/permissions-utils";

export default {

  name: 'CloneMainEntity',

  mixins: [
    permissionsUtils
  ],

  props: {
    mainEntityId: {
      type: String,
      required: true,
    },
    mainEntitySubdomainLocale: {
      type: String,
      required: true,
    },
  },

  mounted(){
    if(this.users['allMarketing'].length === 0)
      this.$store.dispatch('users/getAllMarketing');

    if(this.isSuperUser()){
      this.cloneFromUser = this.users['allMarketing'].filter((e) => { return e.username.toUpperCase() === 'AUTOMATION'})[0]['id'];
    }
  },
  data () {
    return {
      isLoading: false,
      isModalActive: false,
      cloneFromUser: null,
    }
  },

  methods: {

    clone(){
      if(this.isSuperUser()){
        this.isModalActive = true;
      }else{
        this.save();
      }
    },

    save(){
      let x,
          params = {
            'resourcename': this.mainEntityId,
            subdomain_locales: [this.mainEntitySubdomainLocale],
          };

      if(!this.isSuperUser()){
        x = confirm('Are you sure you want to duplicate this keyword?');

        if(!x){
          return;
        }
      }

      if(this.isSuperUser()){
        params['user'] = this.cloneFromUser;
      }

      this.isLoading = true;

      creationLaunchClonedKeyword.post(params).then(
            () => {
              SnackbarProgrammatic.open('Saved! Added to the launching queue.')
              this.isLoading = false;
              if(this.isSuperUser()) {
                this.isModalActive = false;
              }
            },
            error =>
            {
              alert('An error occurred. Error: ' + error.response.data['message']);
              console.dir(error);
              this.isLoading = false;

            })

    }

  },

  computed: {
    ...mapState([
      'theme',
      'users',
    ])
  }

}
</script>