import { config } from './config';
import axios from "axios";

export const creationSettingsService = {
    get,
    getById,
    save,
    config
};


function get(params){

    let axiosConfig = {}

    let endpoint;

    // Append to request.
    axiosConfig['params'] = params;

    endpoint = '/api/creation/creation-settings/';

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function getById(id){

    let endpoint;

    endpoint = '/api/creation/creation-settings/' + id + '/';

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function save(params){

    let endpoint;

    endpoint = '/api/creation/creation-settings/';

    if(params['id']){
        return new Promise((resolve, reject) => {
            axios
                .put(`${config.apiUrl}${endpoint}` + params['id'] + '/', params)
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
        });
    }else{
        return new Promise((resolve, reject) => {
          axios
            .post(`${config.apiUrl}${endpoint}`, params)
            .then(response => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
    }
}
