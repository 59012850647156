import { optimizationDashboardsChartKeywordsService } from '@/services';


export const dashboardsChartKeywords = {
    namespaced: true,
    state: {
        all: [],
        loading: false
    },
    actions: {
        getByDateRange({ commit }, payload) {
            commit('getAllRequest');

            let fromDate = payload['fromDate'],
                toDate = payload['toDate'],
                networks = payload['networks'],
                agencyFeeds = payload['agencyFeeds'],
                products = payload['products'],
                countries = payload['countries'],
                accounts = payload['accounts'] || [];

            optimizationDashboardsChartKeywordsService.getByDateRange(
                fromDate,
                toDate,
                networks,
                agencyFeeds,
                products,
                countries,
                accounts
            )
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },
    },
    mutations: {
        getAllRequest(state) {
            state.all = []
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        getAllFailure(state) {
            state.loading = false;
        },
    }
}
