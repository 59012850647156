import { userService } from '@/services';
import { router } from '@/helpers';

let localStorageUser = localStorage.getItem('user');

if(localStorageUser === 'undefined' || !localStorageUser){
    localStorageUser = '{}'
}
const user = JSON.parse(localStorageUser);
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

    export const authentication = {
    namespaced: true,
    state: {
        ...initialState,
        loading: false,
    },
    actions: {
        login({ commit }, payload) {
            commit('loginRequest', { username: payload.username });

            return new Promise((resolve, reject) => {
                userService.login(payload)
                    .then(
                        user => {
                            commit('loginSuccess', user);
                            router.push({ path: '/' }).catch(function (e){
                                console.dir(e);
                            });
                            resolve(user)
                        },
                        error => {
                            commit('loginFailure', error);
                            //dispatch('alert/error', error, { root: true });
                            console.dir(error);
                            reject(error)
                        }
                    );
            })
        },
        logout({ commit }) {
            userService.logout();
            commit('logout');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.loading = true
            state.status = { loggingIn: true, loggedIn: false };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
            state.loading = false
        },
        loginFailure(state) {
            state.status = { loggedIn: false};
            state.user = null;
            state.loading = false
        },
        logout(state) {
            state.status = { loggedIn: false};
            state.user = null;
        }
    }
}
