import { administrationAvailableAdUnitService } from '@/services';

export const adunits = {
    namespaced: true,

    state: {
        all: [],
        loading: false,
    },

    actions: {
        getAll({ commit }, payload) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                administrationAvailableAdUnitService.getAll(payload)
                    .then(
                        data => {commit('getAllSuccess', data); resolve(data)},
                        error => {commit('getAllFailure', error); reject(error)}
                    ); 
            })
        },
    },

    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
        },
    }
}
