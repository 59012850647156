import { optimizationDashboardsOverviewPerformancesService } from '@/services';


export const dashboardsOverviewPerformances = {
    namespaced: true,
    state: {
        all: {
            'TODAY': {
                'revenue': 0,
                'cost': 0,
                'roi': 0,
                'previous_period_revenue': 0,
                'previous_period_cost': 0,
                'previous_period_profit': 0,
                'previous_period_roi': 0,
                'isLoading': false
            },
            'YESTERDAY': {
                'revenue': 0,
                'cost': 0,
                'roi': 0,
                'previous_period_revenue': 0,
                'previous_period_cost': 0,
                'previous_period_profit': 0,
                'previous_period_roi': 0,
                'isLoading': false
            },
            'LAST_7_DAYS': {
                'revenue': 0,
                'cost': 0,
                'roi': 0,
                'previous_period_revenue': 0,
                'previous_period_cost': 0,
                'previous_period_profit': 0,
                'previous_period_roi': 0,
                'isLoading': false
            },
            'THIS_MONTH': {
                'revenue': 0,
                'cost': 0,
                'roi': 0,
                'previous_period_revenue': 0,
                'previous_period_cost': 0,
                'previous_period_profit': 0,
                'previous_period_roi': 0,
                'isLoading': false
            }
        },
        loading: false
    },
    actions: {
        getByDateRange({ commit }, payload) {
            let code = payload['code'],
                fromDate = payload['fromDate'],
                toDate = payload['toDate'],
                timezone = payload['timezone'];

            commit('getAllRequest', {code: code});

            if(timezone === 'ALL'){
                timezone = null;
            }else if(timezone === 'PST'){
                timezone = 'America/Los_Angeles'
            }

            optimizationDashboardsOverviewPerformancesService.getByDateRange(
                fromDate,
                toDate,
                timezone
            )
                .then(
                    data => commit('getAllSuccess', {
                        data: data,
                        code: code
                    }),
                    error => commit('getAllFailure', {code: code, error: error})
                );
        },
    },
    mutations: {
        getAllRequest(state, payload) {
            state.all[payload['code']].isLoading = true;
            state.loading = true;
        },
        getAllSuccess(state, response) {
            if(response.data.data.results.length > 0){
                state.all[response['code']] = {...response['data'].data.results[0], ...{isLoading: false}};
            }
            state.all[response['code']].isLoading = false;
            state.loading = false;
        },
        getAllFailure(state, payload) {
            state.all[payload['code']].isLoading = false;
            state.loading = false;
        },
    }
}
