import { config } from './config';
import axios from "axios";

export const partnersService = {
    getAll,
    getAllAvailablePartners,
};

export function getAll(params) {
    params = params || {}
    params['is_available'] = true
    params['is_active'] = true
    params['agency_feed__is_active'] = true
    return new Promise((resolve, reject) => {
        axios
        .get(`${config.apiUrl}/api/administration/partners/`, {params})
        .then(response => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function getAllAvailablePartners(params) {
    params = params || {}
    params['is_available'] = true
    params['is_active'] = true
    params['agency_feed__is_active'] = true
    return new Promise((resolve, reject) => {
        axios
        .get(`${config.apiUrl}/api/administration/available-partners/`, {params})
        .then(response => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });
}