import { creationFoundKeywordImagesService } from '@/services';

export const foundKeywordImages = {
    namespaced: true,
    state: {
        all: [],
        loading: false
    },
    actions: {
        save({ commit }, payload) {
            commit('saveRequest');

            let imageRequestId = payload['imageRequestId'],
                foundKeywordId = payload['foundKeywordId'],
                imageHashUnique = payload['imageHashUnique']
            ;

            creationFoundKeywordImagesService.post({
                image_request_id: imageRequestId,
                found_keyword: foundKeywordId,
                image_hash_unique: imageHashUnique
                })
                .then(
                    data => commit('saveSuccess', data),
                    error => commit('saveFailure', error)
                );
        },
    },
    mutations: {
        saveRequest(state) {
            state.loading = true;
        },
        saveSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        saveFailure(state) {
            alert('An error occurred your images are not saving, please stop now, and contact IT Support.');
            state.loading = false;
        },
    }
}
