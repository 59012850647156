import { config } from './config';
import axios from "axios";

export const internalAgencyManagerService = {
    getAll,
    save,
    update,
    saveFile,
    getFiles,
};

function getAll(filters) {

    let axiosConfig = {}

    axiosConfig['params'] = filters || {};

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/list-marketing-agencies`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function save(payload){

  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api/administration/create-marketing-agencies`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function update(payload) {

  return new Promise((resolve, reject) => {
    axios
      .patch(`${config.apiUrl}/api/administration/update-marketing-agency/${payload.id}`, payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

function saveFile(payload) {
  
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api/administration/marketing-agency-files/`, payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

function getFiles(params) {
  
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/api/administration/marketing-agency-files/`, {params})
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}
