import { config } from './config';
import axios from "axios";


export const administrationProductsService = {
    getAll,
    getAllProductsWithAvailableUrlCodes,
    config
};


export function getAll(filters) {

    let axiosConfig = {}
    let params = {
        'partner__is_active': true,
        'partner__agency_feed__is_active': true,
    };

    if(filters['agency_feed__in']?.length > 0){
      params['agency_feed__in'] = filters['agency_feed__in'].join(',');
    }
    if(filters['network__in']?.length > 0){
      params['network__in'] = filters['network__in'].join(',');
    }
    if(filters['partner__in']?.length > 0){
      params['partner__in'] = filters['partner__in'].join(',');
    }
    if(filters['country__in']?.length > 0){
      params['country__in'] = filters['country__in'].join(',');
    }
    if(filters['adunit__in']?.length > 0){
      params['adunit__in'] = filters['adunit__in'].join(',');
    }
    if(filters['language__in']?.length > 0){
      params['language__in'] = filters['language__in'].join(',');
    }

    // Append to request.
    axiosConfig['params'] = params;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/products/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function getAllProductsWithAvailableUrlCodes(filters) {

  let axiosConfig = {};
  let params = { 'is_available' : true }
  
  if(filters['agency_feed__in'].length > 0){
    params['agency_feed__in'] = filters['agency_feed__in'].join(',');
  }
  if(filters['network__in'].length > 0){
    params['network__in'] = filters['network__in'].join(',');
  }
  if(filters['partner__in'].length > 0){
    params['partner__in'] = filters['partner__in'].join(',');
  }
  if(filters['country__in'].length > 0){
    params['country__in'] = filters['country__in'].join(',');
  }
  if(filters['adunit__in']?.length > 0){
    params['adunit__in'] = filters['adunit__in'].join(',');
  }
  if(filters['language__in']?.length > 0){
    params['language__in'] = filters['language__in'].join(',');
  }

  axiosConfig['params'] = params
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/api/administration/available-products/`, axiosConfig)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}