<style>
.table td {
  vertical-align: middle;
}
</style>
<template>
  <div>
    <section v-if="$store.state.theme.viewMode === 'normal'" class="section is-main-section pb-4" ref="filterstop">
            <dependant-multi-filter
              v-model="selectedMultiFilter"
              single-date-enabled
              products-enabled
              networks-enabled
              agency-feeds-enabled
              countries-enabled
              marketing-users-enabled
              accounts-enabled
              :marketing-agency-enabled="getUserGroups().some(r => ['IsSuperUser', 'IsBusinessDeveloper'].includes(r))"
              bidding-strategies-enabled
              currencies-enabled

              @filter="filterList"
              @download="downloadAsCsv()"
          ></dependant-multi-filter>
<!--:download-enabled="isSuperUser()"-->
        <div class="is-pulled-right is-hidden-mobile">
          <b-tag rounded type="is-success">
            <b-tooltip label="Keywords in Profit" dashed>
              K+: {{  dailyPerformances.overallsKpi.keywords_in_profit }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-warning">
            <b-tooltip label="Keywords No Traffic" dashed>
              K0: {{  dailyPerformances.overallsKpi.keywords_no_traffic }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Keywords in Loss" dashed>
              K-: {{  dailyPerformances.overallsKpi.keywords_in_loss }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="Percentage in Profit" dashed>
              %+: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_profit) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-warning">
            <b-tooltip label="Percentage in No Traffic" dashed>
              %0: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_no_traffic) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Percentage in Loss" dashed>
              %-: {{  renderPercentage(dailyPerformances.overallsKpi.percentage_in_loss) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="Ratio in Profit" dashed>
              R+: {{  renderNumber(dailyPerformances.overallsKpi.ratio_in_profit, 2) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="Ratio in Loss" dashed>
              R-: {{  renderNumber(dailyPerformances.overallsKpi.ratio_in_loss, 2) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-success" class="ml-4">
            <b-tooltip label="ROI in Profit" dashed>
              ROI+: {{  renderPercentage(dailyPerformances.overallsKpi.keywords_in_profit_roi, 2) }}
            </b-tooltip>
          </b-tag>
          <b-tag rounded type="is-danger">
            <b-tooltip label="ROI in Loss" dashed>
              ROI-: {{  renderPercentage(dailyPerformances.overallsKpi.keywords_in_loss_roi, 2) }}
            </b-tooltip>
          </b-tag>
        </div>

        <b-field grouped v-for="(r, rowId) in rules" v-bind:key="`performance-filter-${rowId}`">
                <b-field @keyup.native.delete="e => delWithTab(r, rowId, e)">
                    <b-button
                    type="is-danger"
                    @click="removeRule(r, rowId);"
                    :disabled="rules.length === 1"
                    icon-left="close" />

                    <b-select
                        :ref="'r' + rowId"
                        placeholder="Index"
                        v-model="rules[rowId].indexName"
                        @change.native="setFirstOperator(rowId)"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-name-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Operator"
                        v-model="rules[rowId].indexOperator"
                        :disabled="Object.keys(rules[rowId].indexName).length === 0">
                        <option
                            v-for="(indexOperator, indexOperatorI) in getOperators(rules[rowId].indexName.operators)"
                            :key="`performance-index-operator-${rowId}-${indexOperatorI}`"
                            :value="indexOperator"
                        >
                          {{ indexOperator.text }}
                        </option>
                    </b-select>

                    <b-select
                        placeholder="Index"
                        v-model="rules[rowId].indexValueField"
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                    >
                        <option
                            v-for="(indexName, indexNameI) in indexNames"
                            :value="indexName"
                            :key="`performance-index-value-${rowId}-${indexNameI}`">
                          {{ indexName.text }}
                        </option>
                    </b-select>
                    <span
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                        style="padding: 1rem;"
                    >*</span>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexFactor"
                        v-if="(rules[rowId].indexOperator.value || '').indexOf('Field') > -1"
                        style="width: 50px;"
                    ></b-input>

                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="rules[rowId].indexName.indexType === 'number'"
                    ></b-input>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        v-else-if="rules[rowId].indexName.indexType === 'money'"
                    ></b-input>
                    <b-input
                        type="number"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        prefix="%"
                        v-else-if="rules[rowId].indexName.indexType === 'percentage'"
                    ></b-input>
                    <b-input
                        type="text"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        v-else-if="rules[rowId].indexName.indexType === 'text'"
                    ></b-input>

                    <b-datetimepicker
                        v-model="rules[rowId].indexValue"
                        v-else-if="rules[rowId].indexName.indexType === 'datetime'"
                        :datetime-formatter="formatDatetime"
                        :timepicker="{ enableSeconds: true }"
                        placeholder="Click to select...">

                        <template #left>
                            <b-button
                                label="Now"
                                type="is-primary"
                                icon-left="clock"
                                @click="datetime = new Date()" />
                        </template>

                        <template #right>
                            <b-button
                                label="Clear"
                                type="is-danger"
                                icon-left="close"
                                outlined
                                @click="datetime = null" />
                        </template>
                    </b-datetimepicker>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="rules[rowId].indexName.indexType === 'deliveryStatus'"
                    >
                      <option value="DELIVERING">
                        Delivering
                      </option>
                      <option value="NOT_DELIVERING">
                        Not Delivering
                      </option>
                    </b-select>

                    <b-select
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        v-else-if="rules[rowId].indexName.indexType === 'status'"
                    >
                      <option value="ACTIVE">
                        Active
                      </option>
                    </b-select>
                    <log-optimization-group-filter
                      v-model="rules[rowId].indexValue"
                      @keyup.native.enter="filterList"
                      v-else-if="rules[rowId].indexName.indexType === 'optimizationMessage'"
                    ></log-optimization-group-filter>

                    <log-deletion-group-filter
                      v-model="rules[rowId].indexValue"
                      @keyup.native.enter="filterList"
                      v-else-if="rules[rowId].indexName.indexType === 'deletionMessage'"
                    ></log-deletion-group-filter>

                    <b-input
                        type="text"
                        v-model="rules[rowId].indexValue"
                        @keyup.native.enter="filterList"
                        indexValue
                        disabled
                        v-else
                    ></b-input>

                  <b-button
                    type="is-success"
                    @click="addRule();"
                    @keyup.tab="addWithTab(rowId)"
                    icon-left="check" />
                  </b-field>
        </b-field>
        <b-field>
            <strong>
              {{ !showActive && !showPaused && !showRemoved ? '(showing all)': '' }}
            </strong>
            <b-switch v-model="showActive">
                {{ showActive ? 'Showing Active' : 'Hiding Active' }}
            </b-switch>
            <b-switch v-model="showPaused">
                {{ showPaused ? 'Showing Paused' : 'Hiding Paused' }}
            </b-switch>
            <b-switch v-model="showRemoved">
                {{ showRemoved ? 'Showing Removed' : 'Hiding Removed' }}
            </b-switch>
            <b-checkbox v-model="accountIsBlocked" :indeterminate="true" v-if="isSuperUser()">
                Account is blocked
            </b-checkbox>

            <b-button @click="displayBulkActionDialog=true" :disabled="dailyPerformances.all.length === 0">Bulk Action</b-button>

        </b-field>
      </section>

      <section class="section is-main-section" style="padding-top: 0;">
        <span class="is-hidden-desktop">
            Rev: {{ renderCurrency(dailyPerformances.overalls.sum_revenue_amount_net, 2) }}
            Costs: {{ renderCurrency(dailyPerformances.overalls.sum_cost_amount, 2) }}
            Profit: {{ renderCurrency(dailyPerformances.overalls.sum_profit, 2) }}
            ({{ renderPercentage(dailyPerformances.overalls.avg_roi, 2) }})
        </span>

        <b-table
             ref="tbl"
            :data="dailyPerformances.all"

            :striped="true"
            :hoverable="true"
            :loading="dailyPerformances.loading"
            :height="tableHeight()"
            :sticky-header="true"

            pagination-position="top"
            default-sort-direction="DESC"
            :pagination-rounded="false"
            sort-icon="chevron-up"
            sort-icon-size="is-medium"
            default-sort="_profit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"

            :per-page="dailyPerformances.paging.page_size"
            :total="dailyPerformances.paging.count"
            :current-page="dailyPerformances.paging.page_number"

            @page-change="changePage"
            backend-pagination
            paginated

            backend-sorting
            @sort="sortPressed"
            @sorting-priority-removed="sortingPriorityRemoved"

            :sort-multiple="true"
            :sort-multiple-data="sortingPriority"
            :sort-multiple-key="customKey"

            detailed
            :show-detail-icon="showDetailIcon"
            detail-key="id"
            @details-open="(row) => onDetailsOpened(row)"
            :opened-detailed="openedDetailed"
            :scrollable="true"
            narrowed
            header-class="alex"
        >
            <div v-if="showEmptyResultMessage" slot="empty" class="notification is-warning is-light has-text-centered">
              The selected query didn't return any records, Try a different date or reduce the amount of filters.
            </div>

            <b-table-column field="route_hash__created_on_datetime" label="Edit" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <b-button
                class="is-small is-dark"
                icon-left="history"
                @click="openModal(props.row)" />
              </template>
            </b-table-column>

            <b-table-column field="route_hash.livemainentity.country.name" label="Country" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                {{ props.row.route_hash.livemainentity.country.name }}
                  <br>
                <span style="font-size: 10px;word-wrap:break-word; display: inline-block; width: 100%;">
                  {{ renderDate(props.row.route_hash.livemainentity.created_on_datetime, 'MM-DD HH:mm') }} - Eta: {{ countDays(props.row.route_hash.livemainentity.created_on_datetime) }}D
                </span>
                  </div>
              </template>
            </b-table-column>

           <b-table-column field="route_hash_value" label="Info" class="is-small" >
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div style="min-width: 70px;">

                <b-icon icon="facebook" size="is-small" v-if="props.row.route_hash.network.code === 'FBA'" title="Facebook"></b-icon>
                <b-icon icon="google" size="is-small"  v-else-if="props.row.route_hash.network.code === 'GDN'"  title="Google"></b-icon>
                <b-icon icon="google-plus" size="is-small" v-else title="Google Plus"></b-icon>
                <b-icon size="is-small"
                        :type="checkDataConsistency(props.row)"
                        :title="checkDataConsistency(props.row, 'title')"
                        icon="autorenew" style="display: inline;"></b-icon>
                <b-icon size="is-small"
                        type="is-success"
                        v-if="checkOptimizedHoursAgo(props.row.route_hash.livemainentity.last_optimization_on_datetime, props.row.route_hash.livemainentity.account.timezone_code) == 1"
                        :title="props.row.route_hash.livemainentity.last_optimization_on_datetime"
                        icon="calendar" style="display: inline;"></b-icon>
                <b-icon size="is-small"
                        type="is-success"
                        v-if="checkOptimizedHoursAgo(props.row.route_hash.livemainentity.last_optimization_on_datetime, props.row.route_hash.livemainentity.account.timezone_code) == 0"
                        :title="props.row.route_hash.livemainentity.last_optimization_on_datetime"
                        icon="clock" style="display: inline;"></b-icon>
                <b-icon size="is-small"
                        type="is-danger"
                        v-if="props.row.route_hash.livemainentity.delivery_status==='NOT_DELIVERING' &&
                              ['PAUSED', 'REMOVED'].indexOf(props.row.route_hash.livemainentity.status) < 0"
                        :title="props.row.route_hash.livemainentity.delivery_status + ': ' + props.row.route_hash.livemainentity.approval_statuses_list.join(', ')"
                        icon="alert" style="display: inline;"></b-icon>
                <a :href="`/performance-analysis?itemType=ROUTE_HASH&&itemValue=${props.row.route_hash.id}`" target="_blank">
                  <b-icon icon="brain" size="is-small"></b-icon>
                </a>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="products" label="Product" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span>{{ displayProduct(props.row) }}</span><br />
                <span class="is-size-7">{{ props.row.route_hash.livemainentity.account.agency_feed[getFieldCodeLabel()] }}</span>
              </template>
            </b-table-column>

            <b-table-column field="status" label="Status" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <b-switch
                    v-if="props.row.route_hash.livemainentity.status!=='REMOVED'"
                    :disabled="props.row.route_hash.livemainentity.state_is_status_dirty"
                    :value="true"
                    v-model="props.row.newStatus"
                    @input="saveOptimization(props.row)"
                    type="is-success">
                </b-switch>
                <b-switch
                    v-if="props.row.route_hash.livemainentity.status==='REMOVED'"
                    disabled
                    :value="true"
                    type="is-danger">
                </b-switch>
              </template>
            </b-table-column>

            <b-table-column field="route_hash__livemainentity__keyword_name" label="Keyword" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <b-tag rounded type="is-danger" v-if="isDelayed(props.row)">{{ isDelayed(props.row) }}h</b-tag>
                <span>{{ props.row.route_hash.livemainentity.keyword_name }}</span>
              </template>
            </b-table-column>

            <b-table-column field="revenue_amount_net" label="Revenue" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_revenue_amount_net, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.revenue_amount_net, 2) }}</template>
            </b-table-column>

            <b-table-column field="cost_amount" label="Cost" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_cost_amount, 2) }}
              </template>
              <template v-slot="props">

                <span :title="props.row.route_hash.livemainentity.account.currency_code">
                  {{ renderCurrency(props.row.cost_amount, 2) }}
                  <b-icon :title="props.row.route_hash.livemainentity.account.currency_code + ' ' + props.row.cost_amount_account_currency" v-if="props.row.route_hash.livemainentity.account.currency_code !== 'USD'" icon="account-cash" type="is-warning" class="is-inline-block"></b-icon>
                </span>

              </template>
            </b-table-column>

            <b-table-column field="profit" label="Profit" sortable numeric>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.sum_profit, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row.profit) >= 0,
                  'has-text-danger': makeNumber(props.row.profit) < 0,
                }">
                  {{ renderCurrency(props.row.profit, 2) }}
                </span>

              </template>
            </b-table-column>

            <b-table-column field="roi" label="ROI" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformances.overalls.avg_roi, 2) }}
              </template>
              <template v-slot="props">
                <span :class="{
                  'has-text-success': makeNumber(props.row.roi) > 0,
                  'has-text-danger': makeNumber(props.row.roi) < 0,
                }">
                  {{ renderPercentage(props.row.roi, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="selectedRowNewBiddingStrategyId" label="Bidding" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <b-field
                    label="Bidding Strategy"
                    :type="{
                      'is-info': !props.row.route_hash.livemainentity.state_is_bidding_dirty && canSaveBiddingStrategy(props.row) && makeNumber(props.row.selectedRowNewBiddingStrategyId) !== makeNumber(props.row.selectedRowOldBiddingStrategyId),
                      'is-danger': !props.row.route_hash.livemainentity.state_is_bidding_dirty && !canSaveBiddingStrategy(props.row),
                      'is-success': props.row.route_hash.livemainentity.state_is_bidding_dirty,
                    }"
                    :message="canSaveBiddingStrategy(props.row, true)"
                    label-position="on-border">
                  <b-select
                      :disabled="props.row.route_hash.livemainentity.status==='REMOVED' || props.row.route_hash.livemainentity.state_is_bidding_dirty"
                      size="is-small"
                      v-model.number="props.row.selectedRowNewBiddingStrategyId"
                      @keyup.native.enter="saveOptimization(props.row)"
                      @input="biddingStrategyChanged(props.row, props.row.id)">
                    <option
                      v-for="(biddingStrategy, biddingStrategyI) in biddingStrategies.all.filter(row => { return row.dealer === props.row.route_hash.network.dealer})"
                      :value="biddingStrategy.id"
                      :label="biddingStrategy.code"
                      :key="`performance-index-bidding-${props.row.id}-${biddingStrategyI}`"></option>
                  </b-select>
                </b-field>
              </template>
            </b-table-column>

          <b-table-column field="newBid" label="Bid" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span
                        v-if="makeNumber(props.row.exchangeRate) !== 1"
                        class="is-size-7"
                    >
                      {{ renderCurrency(makeNumber(props.row.newBid) / props.row.exchangeRate, 2, 'HKD') }}
                </span>
                <b-field label-position="on-border"
                         style="min-width: 4rem;"
                :type="{
                  'is-danger': getTypeBid(props.row, 'is-danger'),
                  'is-info': getTypeBid(props.row, 'is-info'),
                  'is-success': getTypeBid(props.row, 'is-success'),
                }"
                         :message="canSaveBid(props.row, true)"
                         v-if="['MONEY', null].indexOf(biddingStrategyDetails(props.row.selectedRowNewBiddingStrategyId).bid_type) > -1">
                    <b-input
                        v-if="props.row.route_hash.livemainentity.status!=='REMOVED'"
                        prefix="$"
                        controls-alignment="right"
                        size="is-small"
                        :tabindex="1 + props.row.index * 2"
                        v-model="props.row.newBid"
                        :ref="`new_bid_${props.row.id}`"
                        @focus="selectedFieldCharts('new_bid_' + props.row.id)"
                        @keyup.native.enter="saveOptimization(props.row)"
                        :disabled="!biddingStrategyDetails(props.row.selectedRowNewBiddingStrategyId).is_bid_handled  || props.row.route_hash.livemainentity.state_is_bidding_dirty"
                    ></b-input>

                  <span v-if="props.row.route_hash.livemainentity.status==='REMOVED' && selectedRow.newBid">
                    Bid: ${{ selectedRow.newBid }}
                  </span>
                </b-field>
                <b-field label="New Bid" label-position="on-border"
                         :type="{
                  'is-danger': getTypeBid(props.row, 'is-danger'),
                  'is-info': getTypeBid(props.row, 'is-info'),
                  'is-success': getTypeBid(props.row, 'is-success'),
                }"
                         style="min-width: 4rem;"
                         v-else-if="biddingStrategyDetails(props.row.selectedRowNewBiddingStrategyId).bid_type === 'PERCENTAGE'">

                    <b-input
                        v-if="props.row.route_hash.livemainentity.status!=='REMOVED'"
                        prefix="%"
                        controls-alignment="right"
                        size="is-small"
                        :tabindex="1 + props.row.index * 2"
                        v-model="props.row.newBid"
                        :ref="`new_bid_${props.row.id}`"
                        @focus="selectedFieldCharts('new_bid_' + props.row.id)"
                        @keyup.native.enter="saveOptimization(props.row)"
                        :disabled="!biddingStrategyDetails(props.row.selectedRowNewBiddingStrategyId).is_bid_handled || props.row.route_hash.livemainentity.state_is_bidding_dirty"
                    ></b-input>

                  <span v-if="props.row.route_hash.livemainentity.status==='REMOVED' && selectedRow.newBid">
                    Bid: %{{ selectedRow.newBid }}
                  </span>
                </b-field>

              </template>
            </b-table-column>

          <b-table-column field="newBudget" label="Budget" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <div>
                  <span
                        v-if="makeNumber(props.row.exchangeRate) !== 1"
                        class="is-size-7"
                    >
                      {{ renderCurrency(makeNumber(props.row.newBudget) / props.row.exchangeRate, 2, 'HKD') }}
                  </span>
                  <b-field label-position="on-border" :type="{
                    'is-success': props.row.route_hash.livemainentity.state_is_budget_dirty,
                    'is-danger': !props.row.route_hash.livemainentity.state_is_budget_dirty && !canSaveBudget(props.row),
                    'is-info':  !props.row.route_hash.livemainentity.state_is_budget_dirty && canSaveBudget(props.row) && makeNumber(props.row.newBudget) !== makeNumber(props.row.oldBudget)
                  }"
                  style="min-width: 4rem;"
                  :message="canSaveBudget(props.row, true)">
                    <b-input
                        v-if="props.row.route_hash.livemainentity.status!=='REMOVED'"
                        controls-alignment="right"
                        v-model="props.row.newBudget"
                        @keyup.native.enter="saveOptimization(props.row)"
                        @keyup.enter="saveOptimization(props.row)"
                        size="is-small"
                        :tabindex="2 + props.row.index * 2"
                        :disabled="props.row.route_hash.livemainentity.state_is_budget_dirty"
                    />

                    <span v-if="props.row.route_hash.livemainentity.status==='REMOVED'">
                      Budget: {{ props.row.newBudget }}
                    </span>

                  </b-field>

                </div>
              </template>
            </b-table-column>

            <b-table-column field="prop_bidding_strategy" label="Bidding(H)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                -
              </template>
              <template v-slot="props">
                <span style="font-size: 12px;word-wrap:break-word; display: inline-block; width: 85px;">
                    {{ (biddingStrategies.all.filter(row => { return row.id == props.row.prop_bidding_strategy })[0] || {}).code }}
                  </span>
              </template>
            </b-table-column>

            <b-table-column field="prop_bid_amount" label="Bid(H)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_prop_bid_amount, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.prop_bid_amount, 3) }}</template>
            </b-table-column>

            <b-table-column field="prop_budget_amount" label="Budget(H)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_prop_budget_amount, 2) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.prop_budget_amount, 2) }}</template>
            </b-table-column>

            <b-table-column field="clicks_in_r" label="ClicksIn(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_in_r }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.clicks_in_r, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="clicks_in_c" label="ClicksIn(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_in_c }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.clicks_in_c, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="clicks_out_r" label="ClicksOut(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_out_r }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.clicks_out_r, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="clicks_out_c" label="ClicksOut(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ dailyPerformances.overalls.sum_clicks_out_c }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.clicks_out_c, 0) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="clicks_in_ratio" label="CiR" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderNumber(dailyPerformances.overalls.avg_clicks_in_ratio, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.clicks_in_ratio, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="clicks_out_ratio" label="CoR" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderNumber(dailyPerformances.overalls.avg_clicks_out_ratio, 2) }}
              </template>
              <template v-slot="props">
                <span>
                  {{ renderNumber(props.row.clicks_out_ratio, 2) }}
                </span>
              </template>
            </b-table-column>

            <b-table-column field="ctr_r" label="CR(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformances.overalls.avg_ctr_r, 2) }}
              </template>
              <template v-slot="props">{{ renderPercentage(props.row.ctr_r, 2) }}</template>
            </b-table-column>

            <b-table-column field="ctr_c" label="CR(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderPercentage(dailyPerformances.overalls.avg_ctr_c, 2) }}
              </template>
              <template v-slot="props">{{ renderPercentage(props.row.ctr_c, 2) }}</template>
            </b-table-column>

            <b-table-column field="cpc_r" label="CPC(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_cpc_r, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.cpc_r, 3) }}</template>
            </b-table-column>

            <b-table-column field="cpc_c" label="CPC(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_cpc_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.cpc_c, 3) }}</template>
            </b-table-column>

            <b-table-column field="cpa_r" label="CPA(R)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_cpa_r, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.cpa_r, 3) }}</template>
            </b-table-column>

            <b-table-column field="cpa_c" label="CPA(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_cpa_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency(props.row.cpa_c, 3) }}</template>
            </b-table-column>

            <b-table-column field="rpc" label="RPC" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_rpc, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.rpc, 3) }}</template>
            </b-table-column>
            <b-table-column field="rpc_c" label="RPC(C)" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency(dailyPerformances.overalls.avg_rpc_c, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.rpc_c, 3) }}</template>
            </b-table-column>

            <b-table-column field="rps" label="RPS" sortable>
              <template v-slot:header="{ column }">
                <span>{{ column.label }}</span><br />
                {{ renderCurrency( dailyPerformances.overalls.avg_rps, 3) }}
              </template>
              <template v-slot="props">{{ renderCurrency( props.row.rps, 3) }}</template>
            </b-table-column>

              <template slot="top-left">
                <b-field>
                  <b-field label="Rows" label-position="on-border" style="width: 8rem;">
                    <b-input v-model="dailyPerformances.paging.count" size="is-small" disabled />
                  </b-field>
                  <b-field label="Page Size" label-position="on-border">
                    <b-select v-model="pageSize" size="is-small">
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                      <option>5000</option>
                      <option>10000</option>
                    </b-select>
                    </b-field>
                    <ViewModes />
                  </b-field>
              </template>

              <template #detail="props">
                <article class="media">
                    <div class="media-content">
                        <div class="content">
                              <b-button
                                @click="deleteOptimization"
                                class="is-danger is-small mr-2 mb-2"
                                icon-left="delete"
                                :disabled="props.row.route_hash.livemainentity.status==='REMOVED'">Delete</b-button>
                              <div style="width: 120px;" class="is-inline-block">
                                <clone-main-entity
                                  :main-entity-id="`${props.row.route_hash.livemainentity.resourcename}`"
                                  :main-entity-subdomain-locale="`${props.row.route_hash.livemainentity.subdomain_locale.id}`"
                                ></clone-main-entity>
                              </div>

                          <table class="table">
                            <thead>
                              <tr>
                                <th>User:</th>
                                <th v-if="!isMarketingUntrusted()">Account:</th>
                                <th v-if="!isMarketingUntrusted()">Production/Active/Blocked:</th>
                                <th>Hash</th>
                              </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                {{ props.row.route_hash.livemainentity.assigned_user.username }}
                              </td>
                              <td v-if="!isMarketingUntrusted()">
                                {{ props.row.route_hash.livemainentity.account.name }} ({{ props.row.route_hash.livemainentity.account.timezone_code }}) ||
                                <a
                                    :href="generateTrafficSourceLink(props)"
                                    :disabled="generateTrafficSourceLink(props) === null"
                                    target="_traffic_source">Open on traffic source</a>
                              </td>
                              <td v-if="!isMarketingUntrusted()">
                                {{ props.row.route_hash.livemainentity.account.is_production ? 'Y':'N'  }}
                                {{ props.row.route_hash.livemainentity.account.is_active ? 'Y':'N' }}
                                {{ props.row.route_hash.livemainentity.account.blocked_on_datetime ? props.row.route_hash.livemainentity.account.blocked_on_datetime : 'Ok' }}
                              </td>
                              <td>
                                {{ props.row.route_hash.id }}
                              </td>
                            </tr>
                            </tbody>
                            <thead v-if="!isMarketingUntrusted()">
                              <tr>
                                <th>Ads Review:</th>
                                <th>Ads Live:</th>
                                <th>Ads Limit:</th>
                                <th>Ads Error:</th>
                              </tr>
                            </thead>
                            <tbody v-if="!isMarketingUntrusted()">
                            <tr>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.ads_in_review_count }}
                              </td>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.ads_running_count }}
                              </td>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.ads_limit }}
                              </td>
                              <td>
                                {{ props.row.route_hash.livemainentity.account.ads_with_error_count }}
                              </td>
                            </tr>
                            </tbody>
                            <thead>
                              <tr>
                                <th>Approval Statuses:</th>
                                <th></th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                {{ props.row.route_hash.livemainentity.approval_statuses_list.join(', ') }}
                              </td>
                              <td>

                              </td>
                              <td>

                              </td>
                              <td>

                              </td>
                            </tr>
                            </tbody>
                            <thead v-if="!isMarketingUntrusted()">
                              <tr>
                                <th colspan="4">Url:</th>
                              </tr>
                            </thead>
                            <tbody v-if="!isMarketingUntrusted()">
                            <tr>
                              <td colspan="4">
                                <a :href="props.row.route_hash.livemainentity.url_value" target="_blank">
                                  {{ props.row.route_hash.livemainentity.url_value }}
                                </a>
                              </td>
                            </tr>
                            </tbody>
                          </table>

                        </div>
                    </div>
                </article>
            </template>

        </b-table>

      </section>
    

          <b-modal
            v-model="isModalActive"
            destroy-on-hide
            aria-role="dialog"
            aria-label="Details"
            :width="2000"
            height="1600"
            aria-modal>
            <template #default>
                <div class="modal-card" style="width: 100%; height: 98vh">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Details</p>
                    </header>
                    <section class="modal-card-body p-3">

                      <div>
                        <b-icon icon="facebook" size="is-small" v-if="selectedHistoricalRow.route_hash.network.code === 'FBA'" title="Facebook" />
                        <b-icon icon="google" size="is-small"  v-else-if="selectedHistoricalRow.route_hash.network.code === 'GDN'"  title="Google" />
                        <b-icon icon="google-plus" size="is-small" v-else title="Google Plus" />

                        <b> &lt;&gt; </b>
                        <b-tooltip label="Route Hash" position="is-bottom">
                          <span ref="routeHash">
                            {{ selectedHistoricalRow.route_hash.id }}
                          </span>
                        </b-tooltip>
                        <span class="is-inline-flex ml-1">
                          <b-button icon-right="content-copy" size="is-small" @click="copy('routeHash')" />
                        </span>
                        
                        <b> &lt;&gt; </b>
                        <b-tooltip label="Agency Feed" position="is-bottom">
                          <span>
                            {{ selectedHistoricalRow.route_hash.livemainentity.account.agency_feed[getFieldCodeLabel()] }}
                          </span>
                        </b-tooltip>

                        <b> &lt;&gt; </b>
                        <b-tooltip label="Product" position="is-bottom">
                          <span>
                            {{ displayProduct(selectedHistoricalRow) }}
                          </span>
                        </b-tooltip>

                        <b> &lt;&gt; </b>
                        <b-tooltip label="Country" position="is-bottom">
                          <span>
                            {{ selectedHistoricalRow.route_hash.livemainentity.country.name  }}
                          </span>
                        </b-tooltip>
                        
                        <b> &lt;&gt; </b>
                        <b-tooltip label="Keyword" position="is-bottom">
                          <span ref="keyword">
                            {{ selectedHistoricalRow.route_hash.livemainentity.keyword_name }}
                          </span>
                        </b-tooltip>
                        <span class="is-inline-flex ml-1">
                            <b-button icon-right="content-copy" size="is-small" @click="copy('keyword')" />
                        </span>

                        <b> &lt;&gt;</b>
                        <span>
                            {{ selectedHistoricalRow.route_hash.livemainentity.assigned_user.username  }}
                        </span>

                        <b v-if="!isMarketingUntrusted()"> &lt;&gt;</b>
                        <span v-if="!isMarketingUntrusted()">
                          <a ref="link" :href="selectedHistoricalRow.route_hash.livemainentity.url_value" target="_blank" class="mx-1 is-inline">Open link</a>
                        </span>
                        <span class="is-inline-flex" v-if="!isMarketingUntrusted()">
                            <b-button icon-right="content-copy" size="is-small" @click="copy('link')" />
                        </span>
                      </div>

                      <hr class="my-3" />

                      <form @submit.prevent class="is-inline-flex mb-1 mr-4" >

                      <b-field label="Status" horizontal>
                        <b-switch
                          v-if="selectedHistoricalRow.route_hash.livemainentity.status!=='REMOVED'"
                          :disabled="selectedHistoricalRow.route_hash.livemainentity.state_is_status_dirty"
                          :value="true"
                          size="is-small"
                          v-model="selectedHistoricalRow.newStatus"
                          @input="saveOptimization(selectedHistoricalRow)"
                          type="is-success">
                        </b-switch>
                        <b-switch
                          v-if="selectedHistoricalRow.route_hash.livemainentity.status==='REMOVED'"
                          disabled
                          :value="true"
                          size="is-small"
                          type="is-danger">
                        </b-switch>
                      </b-field>

                      <b-field
                        label="Bidding Strategy"
                        :type="{
                          'is-info': !selectedHistoricalRow.route_hash.livemainentity.state_is_bidding_dirty &&
                          canSaveBiddingStrategy(selectedHistoricalRow) &&
                          makeNumber(selectedHistoricalRow.selectedRowNewBiddingStrategyId) !== makeNumber(selectedHistoricalRow.selectedRowOldBiddingStrategyId),
                          'is-danger': !selectedHistoricalRow.route_hash.livemainentity.state_is_bidding_dirty && !canSaveBiddingStrategy(selectedHistoricalRow),
                          'is-success': selectedHistoricalRow.route_hash.livemainentity.state_is_bidding_dirty,
                        }"
                        :message="canSaveBiddingStrategy(selectedHistoricalRow, true)"
                        label-position="on-border">
                        <b-select
                          :disabled="selectedHistoricalRow.route_hash.livemainentity.status==='REMOVED' || selectedHistoricalRow.route_hash.livemainentity.state_is_bidding_dirty"
                          size="is-small"
                          v-model.number="selectedHistoricalRow.selectedRowNewBiddingStrategyId"
                          @keyup.native.enter="saveOptimization(selectedHistoricalRow)"
                          @input="biddingStrategyChanged(selectedHistoricalRow, +selectedHistoricalRow.id)">
                          <option
                            v-for="(biddingStrategy, biddingStrategyI) in biddingStrategies.all.filter(row => { return +row.dealer === +selectedHistoricalRow.route_hash.network.dealer})"
                            :value="biddingStrategy.id"
                            :label="biddingStrategy.code"
                            :key="`performance-index-bidding-${selectedHistoricalRow.id}-${biddingStrategyI}`">
                          </option>
                        </b-select>
                      </b-field>


                      <div :class="['is-flex is-align-content-flex-start', makeNumber(selectedHistoricalRow.exchangeRate) !== 1 ? 'is-flex-direction-column-reverse' : null]">
                        <span
                            v-if="makeNumber(selectedHistoricalRow.exchangeRate) !== 1"
                            class="is-size-7 is-inline-block mb-2 ml-2">
                            {{ renderCurrency(makeNumber(selectedHistoricalRow.newBid) / selectedHistoricalRow.exchangeRate, 2, 'HKD') }}
                        </span>
                        <b-field
                            label="New Bid"
                            label-position="on-border"
                            style="min-width: 4rem;"
                            class="mx-1"
                            :type="{
                              'is-danger': getTypeBid(selectedHistoricalRow, 'is-danger'),
                              'is-info': getTypeBid(selectedHistoricalRow, 'is-info'),
                              'is-success': getTypeBid(selectedHistoricalRow, 'is-success'),
                            }"
                            :message="canSaveBid(selectedHistoricalRow, true)"
                            v-if="['MONEY', null].indexOf(biddingStrategyDetails(selectedHistoricalRow.selectedRowNewBiddingStrategyId).bid_type) > -1">
                            <b-input
                                v-if="selectedHistoricalRow.route_hash.livemainentity.status!=='REMOVED'"
                                prefix="$"
                                controls-alignment="right"
                                size="is-small"
                                :tabindex="1 + selectedHistoricalRow.index * 2"
                                v-model="selectedHistoricalRow.newBid"
                                :ref="`new_bid_${selectedHistoricalRow.id}`"
                                @focus="selectedFieldCharts('new_bid_' + selectedHistoricalRow.id)"
                                @keyup.native.enter="saveOptimization(selectedHistoricalRow)"
                                :disabled="!biddingStrategyDetails(selectedHistoricalRow.selectedRowNewBiddingStrategyId).is_bid_handled  || selectedHistoricalRow.route_hash.livemainentity.state_is_bidding_dirty"
                            ></b-input>

                          <span v-if="selectedHistoricalRow.route_hash.livemainentity.status==='REMOVED' && selectedRow.newBid">
                            Bid: ${{ selectedRow.newBid }}
                          </span>
                        </b-field>
                        <b-field
                            label="New Bid" label-position="on-border"
                            class="mx-1"
                            :type="{
                              'is-danger': getTypeBid(selectedHistoricalRow, 'is-danger'),
                              'is-info': getTypeBid(selectedHistoricalRow, 'is-info'),
                              'is-success': getTypeBid(selectedHistoricalRow, 'is-success'),
                            }"
                            style="min-width: 4rem;"
                            v-else-if="biddingStrategyDetails(selectedHistoricalRow.selectedRowNewBiddingStrategyId).bid_type === 'PERCENTAGE'">

                            <b-input
                                v-if="selectedHistoricalRow.route_hash.livemainentity.status!=='REMOVED'"
                                prefix="%"
                                controls-alignment="right"
                                size="is-small"
                                :tabindex="1 + selectedHistoricalRow.index * 2"
                                v-model="selectedHistoricalRow.newBid"
                                :ref="`new_bid_${selectedHistoricalRow.id}`"
                                @focus="selectedFieldCharts('new_bid_' + selectedHistoricalRow.id)"
                                @keyup.native.enter="saveOptimization(selectedHistoricalRow)"
                                :disabled="!biddingStrategyDetails(selectedHistoricalRow.selectedRowNewBiddingStrategyId).is_bid_handled || selectedHistoricalRow.route_hash.livemainentity.state_is_bidding_dirty"
                            ></b-input>

                          <span v-if="selectedHistoricalRow.route_hash.livemainentity.status==='REMOVED' && selectedRow.newBid">
                            Bid: %{{ selectedRow.newBid }}
                          </span>
                        </b-field>
                      </div>

                      <div :class="['is-flex is-align-content-flex-start', makeNumber(selectedHistoricalRow.exchangeRate) !== 1 ? 'is-flex-direction-column-reverse' : null]">
                          <span
                              v-if="makeNumber(+selectedHistoricalRow.exchangeRate) !== 1"
                              class="is-size-7 is-inline-block mb-2 ml-2"
                          >
                            {{ renderCurrency(makeNumber(selectedHistoricalRow.newBudget) / selectedHistoricalRow.exchangeRate, 2, 'HKD') }}
                        </span>
                        <b-field label="Budget" label-position="on-border" :type="{
                          'is-success': selectedHistoricalRow.route_hash.livemainentity.state_is_budget_dirty,
                          'is-danger': !selectedHistoricalRow.route_hash.livemainentity.state_is_budget_dirty && !canSaveBudget(selectedHistoricalRow),
                          'is-info':  !selectedHistoricalRow.route_hash.livemainentity.state_is_budget_dirty && canSaveBudget(selectedHistoricalRow) && makeNumber(selectedHistoricalRow.newBudget) !== makeNumber(selectedHistoricalRow.oldBudget)
                          }"
                          style="min-width: 4rem;"
                          :message="canSaveBudget(selectedHistoricalRow, true)">
                          <b-input
                              v-if="selectedHistoricalRow.route_hash.livemainentity.status!=='REMOVED'"
                              controls-alignment="right"
                              v-model="selectedHistoricalRow.newBudget"
                              @keyup.native.enter="saveOptimization(selectedHistoricalRow)"
                              @keyup.enter="saveOptimization(selectedHistoricalRow)"
                              size="is-small"
                              :tabindex="2 + selectedHistoricalRow.index * 2"
                              :disabled="selectedHistoricalRow.route_hash.livemainentity.state_is_budget_dirty"
                          />

                          <span v-if="selectedHistoricalRow.route_hash.livemainentity.status==='REMOVED'">
                            Budget: {{ selectedHistoricalRow.newBudget }}
                          </span>

                        </b-field>

                      </div>
                      </form>

                        <b-button
                          @click="deleteOptimization"
                          class="is-danger is-small mr-2 mb-2"
                          icon-left="delete"
                          :disabled="selectedHistoricalRow.route_hash.livemainentity.status==='REMOVED'">Delete</b-button>
                        <div style="width: 120px;" class="is-inline-block">
                          <clone-main-entity
                            :main-entity-id="`${selectedHistoricalRow.route_hash.livemainentity.resourcename}`"
                            :main-entity-subdomain-locale="`${selectedHistoricalRow.route_hash.livemainentity.subdomain_locale.id}`"
                          ></clone-main-entity>
                        </div>

                      <b-collapse
                      class="card mb-3 has-background-light"
                      animation="slide"
                      v-model="optimizationAccordion.dailyPerformance"
                      aria-id="daily-performance">
                        <template #trigger="props">
                          <div
                            class="card-header"
                            role="button"
                            aria-controls="daily-performance"
                            :aria-expanded="props.open"
                            >
                              <p class="card-header-title p-1 pl-2">Daily Performance <span class="is-size-7 pl-1">- {{ dailyPerformances.historical.length }} item(s)</span></p>
                              <a class="card-header-icon p-1">
                                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
                              </a>
                          </div>
                        </template>
                      <div class="card-content p-1">
                        <div class="content">
                          <b-table
                            :data="dailyPerformances.historical"
                            :striped="true"
                            :hoverable="true"
                            :loading="dailyPerformances.historical.length === 0"
                            :sticky-header="true"

                            default-sort-direction="DESC"
                            sort-icon="chevron-up"
                            sort-icon-size="is-medium"
                            default-sort="date"

                            :scrollable="true"
                            height="260"
                          >
                        <b-table-column field="date" label="Date" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            -
                          </template>
                          <template v-slot="props">{{ props.row.date  }}</template>
                        </b-table-column>

                        <b-table-column field="revenue_amount_net" label="Revenue" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.sum_revenue_amount_net, 2) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency(props.row.revenue_amount_net, 2) }}</template>
                        </b-table-column>

                        <b-table-column field="cost_amount" label="Cost" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.sum_cost_amount, 2) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency(props.row.cost_amount, 2) }}</template>
                        </b-table-column>

                        <b-table-column field="profit" label="Profit" sortable numeric>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.sum_profit, 2) }}
                          </template>
                          <template v-slot="props">
                            <span :class="{
                              'has-text-success': makeNumber(props.row.profit) >= 0,
                              'has-text-danger': makeNumber(props.row.profit) < 0,
                            }">
                              {{ renderCurrency(props.row.profit, 2) }}
                            </span>

                          </template>
                        </b-table-column>

                        <b-table-column field="roi" label="ROI" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderPercentage(dailyPerformances.historicalOveralls.avg_roi, 2) }}
                          </template>
                          <template v-slot="props">
                            <span :class="{
                              'has-text-success': makeNumber(props.row.roi) > 0,
                              'has-text-danger': makeNumber(props.row.roi) < 0,
                            }">
                              {{ renderPercentage(props.row.roi, 2) }}
                            </span>
                          </template>
                        </b-table-column>

                        <b-table-column field="clicks_in_r" label="ClicksIn(R)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderNumber(dailyPerformances.historicalOveralls.sum_clicks_in_r, 0) }}
                          </template>
                          <template v-slot="props">
                            <span>
                              {{ renderNumber(props.row.clicks_in_r, 0) }}
                            </span>
                          </template>
                        </b-table-column>

                        <b-table-column field="clicks_in_c" label="ClicksIn(C)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderNumber(dailyPerformances.historicalOveralls.sum_clicks_in_c, 0) }}
                          </template>
                          <template v-slot="props">
                            <span>
                              {{ renderNumber(props.row.clicks_in_c, 0) }}
                            </span>
                          </template>
                        </b-table-column>

                        <b-table-column field="clicks_out_r" label="ClicksOut(R)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderNumber(dailyPerformances.historicalOveralls.sum_clicks_out_r, 0) }}
                          </template>
                          <template v-slot="props">
                            <span>
                              {{ renderNumber(props.row.clicks_out_r, 0) }}
                            </span>
                          </template>
                        </b-table-column>

                        <b-table-column field="clicks_out_c" label="ClicksOut(C)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderNumber(dailyPerformances.historicalOveralls.sum_clicks_out_c, 0) }}
                          </template>
                          <template v-slot="props">
                            <span>
                              {{ renderNumber(props.row.clicks_out_c, 0) }}
                            </span>
                          </template>
                        </b-table-column>

                        <b-table-column field="ctr_r" label="CR(R)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderPercentage(dailyPerformances.historicalOveralls.avg_ctr_r, 2) }}
                          </template>
                          <template v-slot="props">{{ renderPercentage(props.row.ctr_r, 2) }}</template>
                        </b-table-column>

                        <b-table-column field="ctr_c" label="CR(C)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderPercentage(dailyPerformances.historicalOveralls.avg_ctr_c, 2) }}
                          </template>
                          <template v-slot="props">{{ renderPercentage(props.row.ctr_c, 2) }}</template>
                        </b-table-column>

                        <b-table-column field="cpc_r" label="CPC(R)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_cpc_r, 3) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency(props.row.cpc_r, 3) }}</template>
                        </b-table-column>

                        <b-table-column field="cpc_c" label="CPC(C)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_cpc_c, 3) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency(props.row.cpc_c, 3) }}</template>
                        </b-table-column>

                        <b-table-column field="cpa_r" label="CPA(R)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_cpa_r, 3) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency(props.row.cpa_r, 3) }}</template>
                        </b-table-column>

                        <b-table-column field="cpa_c" label="CPA(C)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_cpa_c, 3) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency(props.row.cpa_c, 3) }}</template>
                        </b-table-column>

                        <b-table-column field="prop_bid_amount" label="Bid" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_prop_bid_amount, 3) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency(props.row.prop_bid_amount, 2) }}</template>
                        </b-table-column>

                        <b-table-column field="prop_budget_amount" label="Budget" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_prop_budget_amount, 2) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency( props.row.prop_budget_amount, 2) }}</template>
                        </b-table-column>

                        <b-table-column field="rpc" label="RPC" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_rpc, 3) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency( props.row.rpc, 3) }}</template>
                        </b-table-column>

                        <b-table-column field="rpc_c" label="RPC(C)" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_rpc_c, 3) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency( props.row.rpc_c, 3) }}</template>
                        </b-table-column>

                        <b-table-column field="rps" label="RPS" sortable>
                          <template v-slot:header="{ column }">
                            <span>{{ column.label }}</span><br />
                            {{ renderCurrency(dailyPerformances.historicalOveralls.avg_rps, 3) }}
                          </template>
                          <template v-slot="props">{{ renderCurrency( props.row.rps, 3) }}</template>
                        </b-table-column>
                            </b-table>

                        </div>
                      </div>
                    </b-collapse>

                    <b-collapse
                      class="card mb-3 has-background-light"
                      animation="slide"
                      v-model="optimizationAccordion.hourlyPerformance"
                      aria-id="hourly-performance">
                        <template #trigger="props">
                          <div
                            class="card-header"
                            role="button"
                            aria-controls="hourly-performance"
                            :aria-expanded="props.open"
                            >
                              <p class="card-header-title p-1 pl-2">Hourly Performance <span class="is-size-7 pl-1">- {{  hourlyPerformanceCount }} item(s)</span></p>
                              <a class="card-header-icon p-1">
                                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
                              </a>
                          </div>
                        </template>
                      <div class="card-content p-1">
                        <div class="content">
                          <hourly-performance-table
                              :route-hash-value="selectedHistoricalRow.route_hash.id"
                              @hourlyPerformanceCount="gethourlyPerformanceCount"
                              height="280" />
                        </div>
                      </div>
                    </b-collapse>

                    <b-collapse
                      class="card mb-3 has-background-light"
                      animation="slide"
                      v-model="optimizationAccordion.optimizationHistory"
                      aria-id="optimization-history">
                        <template #trigger="props">
                          <div
                            class="card-header"
                            role="button"
                            aria-controls="optimization-history"
                            :aria-expanded="props.open"
                            >
                              <p class="card-header-title p-1 pl-2">Optimization History <span class="is-size-7 pl-1">- {{ logOptimizationGroups.all.length }} item(s)</span></p>
                              <a class="card-header-icon p-1">
                                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
                              </a>
                          </div>
                        </template>
                      <div class="card-content p-1">
                        <div class="content">
                          <b-table
                              :data="logOptimizationGroups.all"

                              :striped="true"
                              :hoverable="true"
                              :loading="logOptimizationGroups.loading"
                              :sticky-header="true"

                              default-sort-direction="DESC"
                              sort-icon="chevron-up"
                              sort-icon-size="is-medium"
                              default-sort="created_on_datetime"

                              :scrollable="true"
                              height="280"
                          >
                            <b-table-column field="created_on_datetime" label="Date" sortable>
                              <template v-slot:header="{ column }">
                                <span>{{ column.label }}</span><br />
                                -
                              </template>
                              <template v-slot="props">{{ formatDatetime(props.row.created_on_datetime)  }}</template>
                            </b-table-column>

                            <b-table-column field="optimization_type" label="Type" sortable>
                              <template v-slot:header="{ column }">
                                <span>{{ column.label }}</span><br />
                              </template>
                              <template v-slot="props">{{ props.row.optimization_type }}</template>
                            </b-table-column>

                            <b-table-column field="old_value" label="Old" sortable>
                              <template v-slot:header="{ column }">
                                <span>{{ column.label }}</span><br />
                              </template>
                              <template v-slot="props">{{ props.row.old_value }}</template>
                            </b-table-column>

                            <b-table-column field="new_value" label="New" sortable>
                              <template v-slot:header="{ column }">
                                <span>{{ column.label }}</span><br />
                              </template>
                              <template v-slot="props">{{ props.row.new_value }}</template>
                            </b-table-column>

                            <b-table-column field="message" label="message" v-if="isSuperUser()" sortable>
                              <template v-slot:header="{ column }">
                                <span>{{ column.label }}</span><br />
                              </template>
                              <template v-slot="props">{{ props.row.message }}</template>
                            </b-table-column>

                            <b-table-column field="current_status" label="current_status" sortable>
                              <template v-slot:header="{ column }">
                                <span>{{ column.label }}</span><br />
                              </template>
                              <template v-slot="props">{{ props.row.current_status }}</template>
                            </b-table-column>

                            <b-table-column field="created_by_user.username" label="By" sortable>
                              <template v-slot:header="{ column }">
                                <span>{{ column.label }}</span><br />
                              </template>
                              <template v-slot="props">{{ props.row.created_by_user.username }}</template>
                            </b-table-column>

                            </b-table>
                        </div>
                      </div>
                    </b-collapse>

                    <b-collapse
                      class="card mb-0 has-background-light"
                      animation="slide"
                      v-model="optimizationAccordion.deletionHistory"
                      aria-id="deletion-history">
                        <template #trigger="props">
                          <div
                            class="card-header"
                            role="button"
                            aria-controls="deletion-history"
                            :aria-expanded="props.open"
                            >
                              <p class="card-header-title p-1 pl-2">Deletion History <span class="is-size-7 pl-1">- {{ logDeletionGroups.all.length }} item(s)</span></p>
                              <a class="card-header-icon p-1">
                                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
                              </a>
                          </div>
                        </template>
                      <div class="card-content p-1">
                        <div class="content">

                          <p v-if="!logDeletionGroups.all.length" class="notification is-warning is-light">No deleted data yet.</p>
                            <b-table
                              v-if="logDeletionGroups.all.length"
                              :data="logDeletionGroups.all"
                              :striped="true"
                              :hoverable="true"
                              :loading="logDeletionGroups.loading"
                              :sticky-header="true"

                              default-sort-direction="DESC"
                              sort-icon="chevron-up"
                              sort-icon-size="is-medium"
                              default-sort="created_on_datetime"

                              :scrollable="true"
                              height="auto"
                            >
                              <b-table-column field="created_on_datetime" label="Date" sortable>
                                <template v-slot:header="{ column }">
                                  <span>{{ column.label }}</span><br />
                                  -
                                </template>
                                <template v-slot="props">{{ props.row.created_on_datetime  }}</template>
                              </b-table-column>

                              <b-table-column field="optimization_type" label="Type" sortable>
                                <template v-slot:header="{ column }">
                                  <span>{{ column.label }}</span><br />
                                </template>
                                <!-- <template v-slot="props">{{ props.row.optimization_type.code }}</template> -->
                                <template>DELETED</template>
                              </b-table-column>

                              <b-table-column field="message" label="message" v-if="isSuperUser()" sortable>
                                <template v-slot:header="{ column }">
                                  <span>{{ column.label }}</span><br />
                                </template>
                                <template v-slot="props">{{ props.row.message }}</template>
                              </b-table-column>

                            </b-table>

                        </div>
                      </div>
                    </b-collapse>

                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="isModalActive=false"
                            />
                    </footer>
                </div>
            </template>
        </b-modal>


        <b-modal v-model="displayBulkActionDialog">

          <card-component
            title="Bulk Action"
            icon="format-list-bulleted"
            custom-class="m-0 pb-3 px-5"
            custom-header-class="px-0 py-2"
          >
            <div>
              <bulk-action
                  :items="dailyPerformances.all"
                  v-on:actions="saveActions"
                  :selected-date="selectedDate"
                  v-model="bulkActions"></bulk-action>
            </div>

            <div>
                <b-button type="is-info is-light" text @click="displayBulkActionDialog = false" class="mr-1">Close</b-button>
                <b-button type="is-success" text @click="saveBulkInstructions();" :disabled="bulkActions.length === 0">Do it now!</b-button>
            </div>
          </card-component>

      </b-modal>
  </div>
</template>

<script>
import ViewModes from '@/components/ViewModes';
import numeral from "numeraljs"
import {mapState} from "vuex";
import moment from "moment-timezone";
import CardComponent from '@/components/CardComponent'
import Vue from "vue";
import HourlyPerformanceTable from "@/components/Optimization/HourlyPerformanceTable";
import LogOptimizationGroupFilter from "@/components/Filter/LogOptimizationGroupFilter";
import LogDeletionGroupFilter from "@/components/Filter/LogDeletionGroupFilter";
import BulkAction from "@/components/BulkAction";
import {OptimizationBulkActionsService} from "@/services/optimization-bulk-actions.service";
import {osUtils} from "@/helpers/os-utils";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {numberUtils} from "@/helpers/number-utils";
import CloneMainEntity from "@/components/Element/CloneMainEntity";
import {dailyOptimizationPerformancesService} from "@/services";
import {performanceMainEntityService} from "@/services/performance-main-entity.service";
import {setDefaultsMainEntity} from "@/helpers/optimization/set-defaults-main-entity";
import {userSettings} from "@/helpers/user/user-settings";
import {notificatorUtils} from "@/helpers/notificator-utils";
import {permissionsUtils} from "@/helpers/permissions-utils";


export default {
  name: 'DailyOptimization',

  props: {
    filterKeywordName: {
      type: String,
      default: ''
    },
  },
  created() {
    if(this.filterKeywordName !== ''){
      let rule = {"indexName":{"value":"keyword_name","text":"Keyword","indexType":"text","operators":["eq","in","inText"]},"indexOperator":{"value":"","text":"="},"indexValue": this.filterKeywordName, "indexType":{},"indexValueField":"","indexFactor":1};
      this.rules.push(rule);
      this.filterList();
    }else{
      this.rules.push(Object.assign({}, this.emptyRule))
    }

    if(this.$route.query.productsId){
      let products = [];
      if(Array.isArray(this.$route.query.countriesId)) {
        for (let i in this.$route.query.productsId) {
          products.push({
            id: this.$route.query.productsId[i]
          });
        }
      }else{
        products.push({
          id: this.$route.query.productsId
        });
      }

      this.selectedProducts = products;
    }

    if(this.$route.query.countriesId){
      let countries = [];
      if(Array.isArray(this.$route.query.countriesId)){
        for(let i in this.$route.query.countriesId){
          countries.push({
            id: this.$route.query.countriesId[i]
          });
        }
      }else{
        countries.push({
          id: this.$route.query.countriesId
        });
      }

      this.selectedCountries = countries;
    }

    this.fetchUserSettings();
    this.hideFilters();

    this.initOptimizationAccordion()

    // hide url if the user isMarketingUntrusted
    if (!this.isMarketingUntrusted()) this.indexNames.splice(2, 0, {value: "url_value", text: "Url", indexType: "text", operators: ['inText']})

  },
  components: {
    CloneMainEntity,
    DependantMultiFilter,
    LogDeletionGroupFilter,
    LogOptimizationGroupFilter,
    HourlyPerformanceTable,
    BulkAction,
    CardComponent,
    ViewModes,
  },
  mixins: [
      osUtils,
      numberUtils,
      userSettings,
      notificatorUtils,
      permissionsUtils
  ],
  data () {

    return {

      displayBulkActionDialog: false,
      bulkActions: [],

      selectedRowNewBiddingStrategyId: '',
      selectedRowOldBiddingStrategyId: '',

      showRemoved: true,
      showPaused: true,
      accountIsBlocked: null,
      showActive: true,

      openedDetailed: [],

      isModalActive: false,
      selectedHistoricalRow: {},

      selectedRow: {
        'newBid': null,
        'oldBid': null,
        'newBudget': null,
        'oldBudget': null,
        'newStatus': null,
        'oldStatus': null
      },

      showDetailIcon: true,

      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'products': [],
        'countries': [],
        'accounts': [],
        'biddingStrategies': [],
        'marketingUsers': [],
        'currencies': [],
        'date': new Date(),
      },

      // Sorting:
      singleSorting: null,
      sortingPriority: [],
      customKey: 'ctrlKey',

      selectedDate: new Date(),

      pageSize: 25,
      pageNumber: 1,

      selectedCurrencies: [],

      rules: [],
      displayNewRule: false,
      emptyRule: {indexName: {}, indexOperator: {}, indexValue: null, indexType: {}, indexValueField: '', indexFactor: 1},
      indexNamesObject: {},
      fieldFactor: 1,
      selectedIndexName: '',
      selectedIndexOperator: '',
      selectedIndexValue: '',
      indexNames: [
          {value: "route_hash", text: "Route Hash", indexType: "text", operators: ['in']},
          {value: "keyword_name", text: "Keyword", indexType: "text", operators: ['eq', 'in', 'inText']},
          {value: "cost_amount", text: "Cost", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cost_amount_account_currency", text: "Cost(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cost_impressions", text: "Impression(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_c", text: "ClicksIn(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_r", text: "ClicksIn(Revenue)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_c", text: "ClicksOut(Costs)", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_r", text: "ClicksOut(Revenue", indexType: "number", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "revenue_amount_net", text: "Revenue(Net)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_budget_amount", text: "Budget", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_budget_amount_account_currency", text: "Budget(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_bid_amount", text: "BidAmount", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "prop_bid_amount_account_currency", text: "BidAmount(AccountCurrency)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "route_hash__livemainentity__status", text: "Status", indexType: "status", operators: ['eq', 'in']},
          {value: "prop_delivery_status", text: "Delivery Status", indexType: "deliveryStatus", operators: ['eq', 'in']},
          {value: "profit", text: "Profit", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "roi", text: "ROI", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "ctr_r", text: "CR(R)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "ctr_c", text: "CR(C)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cpa_r", text: "CPA(R)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "cpa_c", text: "CPA(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rpc", text: "RPC", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rpc_c", text: "RPC(C)", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "rps", text: "RPS", indexType: "money", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_out_ratio", text: "ClicksOut(RevCostRatio)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "clicks_in_ratio", text: "ClicksIn(RevCostRatio)", indexType: "percentage", operators: ['gt', 'gte', 'eq', 'lte', 'lt',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},


          {value: "route_hash__livemainentity__last_optimization_on_datetime", text: "Last Optimization", indexType: "datetime", operators: ['lte', 'lt', 'gt', 'gte', 'eq',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},
          {value: "route_hash__livemainentity__created_on_datetime", text: "Created On", indexType: "datetime", operators: ['lte', 'lt', 'gt', 'gte', 'eq',
              'eqField', 'gtField', 'ltField', 'lteField', 'gteField']},

          {value: "log_optimization_group_filter", text: "Optimization", indexType: "optimizationMessage", operators: ['eq']},
          {value: "log_deletion_group_filter", text: "Deletion", indexType: "deletionMessage", operators: ['eq']},

      ],
      indexOperators: {
        'eq': {value: "", text: "="},
        'inText': {value: "__icontains", text: "~="},
        'in': {value: "__in", text: "=,"},
        'gt': {value: "__gt", text: ">"},
        'lt': {value: "__lt", text: "<"},
        'lte': {value: "__lte", text: "<="},
        'gte': {value: "__gte", text: ">="},

        'eqField': {value: "exactField", text: "=(Field)"},
        'gtField': {value: "gtField", text: ">(Field)"},
        'ltField': {value: "ltField", text: "<(Field)"},
        'lteField': {value: "lteField", text: "<=(Field)"},
        'gteField': {value: "gteField", text: ">=(Field)"},
      },

      userSettingsIndexed: {},
      userSettings: [],

      hourlyPerformanceCount: 0,
      optimizationAccordion: {
        dailyPerformance: '',
        hourlyPerformance: '',
        optimizationHistory: '',
        deletionHistory: '',
      },

      showEmptyResultMessage: false,
    }
  },

  watch: {
    'optimizationAccordion.dailyPerformance': function() {
      this.updateOptimizationAccordion('dailyPerformance')
    },
    'optimizationAccordion.hourlyPerformance': function() {
      this.updateOptimizationAccordion('hourlyPerformance')
    },
    'optimizationAccordion.optimizationHistory': function() {
      this.updateOptimizationAccordion('optimizationHistory')
    },
    'optimizationAccordion.deletionHistory': function() {
      this.updateOptimizationAccordion('deletionHistory')
    },
  },
  computed: {

    titleStack () {
      return ['Admin', 'Optimization']
    },
    ...mapState([
      'theme',
      'dailyPerformances',
      'countries',
      'biddingStrategies',
      'logOptimizationGroups',
      'logDeletionGroups',
    ]),
    rulesParsed () {
      let d = this.rules,
          r = [];

      for(let i in d){
        if(d[i] === null || !('indexName' in d[i]) || d[i]['indexName'] === null){
          continue;
        }

        if(!('indexOperator' in d[i]) || d[i]['indexOperator']['value'] === null){
          continue;
        }

        if(!('indexValue' in d[i]) || d[i]['indexValue'] === null){
          continue;
        }

        if(d[i]['indexOperator']['value'].indexOf('Field') > -1){
          continue;
        }

        let obj = {};

        if(d[i]['indexName']['value'].indexOf('custom') > -1){

          if(d[i]['indexName']['value'].indexOf('custom__logoptimizationgroup') > -1) {

            obj['log_optimization_group_filter'] = d[i]['indexValue'];

          }else if(d[i]['indexName']['value'].indexOf('custom__logdeletiongroup') > -1){

            obj['log_deletion_group_filter'] = d[i]['indexValue'];

          }else{

            alert('Unable to find custom filter name.');

          }

        }else{

          let kObj = d[i]['indexName']['value'] + d[i]['indexOperator']['value'];
          if(d[i]['indexName']['indexType'] === 'datetime'){
            obj[kObj] = this.formatDatetime(d[i]['indexValue']);
          }else{
            obj[kObj] = d[i]['indexValue'];
          }

        }

        r.push(obj);
      }

      return r;
    },

    rulesFieldParsed () {
      let d = this.rules,
          r = [];

      for(let i in d) {
        if (d[i] === null || !('indexName' in d[i]) || d[i]['indexName'] === null) {
          continue;
        }

        if (!('indexOperator' in d[i]) || d[i]['indexOperator']['value'] === null || typeof d[i]['indexOperator']['value'] === 'undefined') {
          continue;
        }

        if (d[i]['indexOperator']['value'].indexOf('Field') === -1) {
          continue;
        }

        if (!('indexValueField' in d[i]) || d[i]['indexValueField'] === null) {
          continue;
        }

        r.push({
          'field1': d[i]['indexName']['value'],
          'operator': d[i]['indexOperator']['value'].replace('Field', ''),
          'field2': d[i]['indexValueField']['value'],
          'factor': d[i]['indexFactor'],
          'addendum': 0,
        });
      }

      return r;
    },
  },

  methods: {

    addWithTab(r) {
      if(!this.$refs['r' + r][0].value.text) return;
      
      this.addRule()
      const nextRow =  r+1
      this.$nextTick(() => {
        this.$refs['r' + nextRow][0].focus()
      })
    },

    delWithTab(r, rowId, e) {
      if(e.code !== 'Delete' || rowId + 1 === 1) return;

      this.removeRule(r, rowId)
      const previousRow = rowId - 1
      this.$nextTick(() => {
        this.$refs['r' + previousRow][0].focus()
      })
    },

    getMaxBudget(row){
      let networkId = row.route_hash.network.id,
          agencyFeedId = row.route_hash.livemainentity.account.agency_feed.id,
          countryCode = row.route_hash.livemainentity.country.code.toUpperCase();

      return this.makeNumber(this.getUserSetting(networkId, agencyFeedId, countryCode, 'max_budget', 0));
    },

    generateTrafficSourceLink(props) {
      let link;

      if(props.row.route_hash.network.dealer === '2'){
        link = `https://business.facebook.com/adsmanager/manage/ads?act=${props.row.route_hash.livemainentity.account.remote_id}&business_id=${props.row.route_hash.livemainentity.account.manager.remote_id}&selected_adset_ids=${this.getMainEntityRemoteId(props.row)}`;
      }else{
        link = null;
      }

      return link;
    },

    tableHeight () {
      let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
          otherHeights = 0,
          newHeight;

      if(typeof this.$refs.filterstop !== 'undefined') {
        otherHeights = (this.$refs.filterstop.clientHeight * 3);
      }

      newHeight = vh - otherHeights;

      if(newHeight < 1500) {
        newHeight = 1500;
      }

      return vh - 175
    },

    changePage(pageId){
      this.filterList(pageId);
    },
    setSelectedDate (code) {
      let d;
      if (code === 'TODAY') {
        d = new Date();
        this.selectedDate = d;
      }else if (code === 'YESTERDAY') {
        d = new Date() - 1000 * 60 * 60 * 24;
        this.selectedDate = new Date(d)
      }
    },
    filterList (pageId, asCSV) {
      let parsedDate = moment(this.selectedMultiFilter['date']).format('YYYY-MM-DD'),
          payload,
          sorting;

      pageId = pageId || null;
      asCSV = asCSV || null;

      if(this.sortingPriority && this.sortingPriority.length > 0){
        sorting = this.sortingPriority;
      }else{
        sorting = this.singleSorting;
      }

      sorting = sorting ? sorting : {};

      this.showEmptyResultMessage = false

      payload = {
        'sorting': sorting,
        'pageId': pageId,
        'date': parsedDate,
        'pageSize': this.pageSize,
        'networks': this.selectedMultiFilter['networks'].map((element) => { return element.id }),
        'products': this.selectedMultiFilter['products'].map((element) => { return element.id }),
        'agencyFeeds': this.selectedMultiFilter['agencyFeeds'].map((element) => { return element.id }),
        'marketingAgency': this.selectedMultiFilter['marketingAgency']?.map((element) => { return element.id }) || [],
        'countries': this.selectedMultiFilter['countries'].map((element) => { return element.id }),
        'accounts': this.selectedMultiFilter['accounts'].map((element) => { return element.id }),
        'biddingStrategies': this.selectedMultiFilter['biddingStrategies'].map((element) => { return element.id }),
        'currencies': this.selectedMultiFilter['currencies'].map((element) => { return element }),
        'assignedUsers': this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }),
        'rules': this.rulesParsed,
        'rulesField': this.rulesFieldParsed,
        'showRemoved': this.showRemoved,
        'showPaused': this.showPaused,
        'showActive': this.showActive,
        'asCSV': asCSV
      };

      if(this.accountIsBlocked!==null){
        payload['accountBlockedOnDatetimeIsNull'] = !this.accountIsBlocked;
      }

      this.$store.dispatch('dailyPerformances/getAll', payload)
      .then(() => this.showEmptyResultMessage = true)
      .catch((e) => {
        alert('An error occurred, try again if this persists, please notify the technical team.');
        console.dir(e);
      })
    },

    formatDatetime: function (time){
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },

    makeNumber: function (str){
      let num = numeral(str).value()

      if(num < 0 || num > 0){
        return num
      }

      return 0;
    },

    checkOptimizedHoursAgo(lastOptimizationDatetime, accountTimezone) {

      if(!lastOptimizationDatetime){
        return -1;
      }

      let lastOptimizationDatetimeMoment = moment(lastOptimizationDatetime).clone().tz('UTC'),

          lastAbsoluteHourOptimized = lastOptimizationDatetimeMoment.format('YYYY-MM-DD HH:00:00'),
          lastAbsoluteHourNow = moment().clone().tz('UTC').format('YYYY-MM-DD HH:00:00'),

          lastOptimizationDatetimeMomentAccountTimezone = moment(lastOptimizationDatetime).tz('UTC').clone().tz(accountTimezone),
          lastAbsoluteDayOptimized = lastOptimizationDatetimeMomentAccountTimezone.format('YYYY-MM-DD 00:00:00'),
          lastAbsoluteDayNow = moment().clone().tz(accountTimezone).format('YYYY-MM-DD 00:00:00');

      if( moment(lastAbsoluteHourOptimized).isSame(moment(lastAbsoluteHourNow))){
        return 0;
      }

      if( moment(lastAbsoluteDayOptimized).isSame(moment(lastAbsoluteDayNow))){
        return 1;
      }

      return -1;
    },

    sortPressed: function (field, order){
        if((this.customKey && event[this.customKey]) || !this.customKey) {
          let existingPriority = this.sortingPriority.filter(i => i.field === field)[0]
          if(existingPriority) {
            existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
          } else {
            // request sorted data from backend
            this.sortingPriority.push({field: field, order: order})
          }
          this.singleSorting = null;
          this.filterList()
        } else {
          // request regular sorted data from backend
          this.sortingPriority = []
          this.singleSorting = [{field: field, order: order}];
          this.filterList()
        }
    },

    sortingPriorityRemoved: function (field){
        this.sortingPriority = this.sortingPriority.filter((priority) => priority.field !== field)
    },

    removeRule: function (rule, rowId) {
      this.$delete(this.rules, rowId);
    },

    addRule: function (){
      this.rules.push(Object.assign({}, this.emptyRule))
    },

    getOperators: function (operatorsArray) {
      let r = [];

      for(let i in operatorsArray){
        r.push(this.indexOperators[operatorsArray[i]]);
      }

      return r;
    },

    setFirstOperator: function (rowId){
      let r = this.getOperators(this.rules[rowId].indexName.operators, rowId);
      if(this.rules[rowId] && this.rules[rowId].indexOperator){
        if(r.length > 0){
          if(JSON.stringify(this.rules[rowId].indexOperator) === '{}'){
            this.rules[rowId].indexOperator = r[0];
          }else if(this.rules[rowId].indexName.operators.indexOf(this.rules[rowId].indexOperator.value) === -1) {
            this.rules[rowId].indexOperator = r[0];
          }
        }
      }
    },

    openRoute: function(row){
      let key = row.id;

      if(this.openedDetailed.indexOf(key) === -1){
        this.openedDetailed = [];
        this.openedDetailed.push(key);
        this.onDetailsOpened(row);
      }else{
        this.openedDetailed = [];
      }
    },

    onDetailsOpened: function (row){
      this.selectedRow = row;

      this.selectedRow.newStatus = this.selectedRow.route_hash.livemainentity.status === 'ACTIVE';
      this.selectedRow.oldStatus = this.selectedRow.route_hash.livemainentity.status === 'ACTIVE';

      this.openedDetailed = [this.openedDetailed[this.openedDetailed.length-1]];
    },

    biddingStrategyDetails: function (biddingStrategyId){
       let r = this.$store.state.biddingStrategies.all.filter((row) => row.id+'' === biddingStrategyId+'');
       return r[0] || {};
    },

    biddingStrategyChanged: function (row, rowId){
      let biddingStrategyDetails = this.biddingStrategyDetails(row.selectedRowNewBiddingStrategyId);
      if(!biddingStrategyDetails.is_bid_handled){
        Vue.set(row, 'newBid', null);
      }else{
        Vue.set(row, 'newBid', row.oldBid);
        if(rowId) {
          let fieldName = 'new_bid_' + rowId
          if( typeof this.$refs[fieldName] !== 'undefined'){
            this.$refs[fieldName].focus();

          }
        }
      }
    },

    selectedFieldCharts(fieldName){
      // Dettach workaround so that when you use shift it will still work, otherwise it would select the whole value.
      setTimeout(() =>{
        let val = this.$refs[fieldName].value.split('.');
        if(val.length === 2){
          this.$refs[fieldName].$refs.input.setSelectionRange(val[0].length + 1, val[0].length + 1 + val[1].length);
        }
      }, 20)
    },

    saveOptimization: function (row){

      if(!this.canSaveBiddingStrategy(row, false)){
        return alert(this.canSaveBiddingStrategy(row, true))
      }

      if(!this.canSaveBid(row, false)){
        return alert(this.canSaveBid(row, true))
      }

      if(!this.canSaveBudget(row, false)){
        return alert(this.canSaveBudget(row, true))
      }
      row.oldBiddingStrategyDetails = this.biddingStrategyDetails(row.selectedRowOldBiddingStrategyId);
      row.newBiddingStrategyDetails = this.biddingStrategyDetails(row.selectedRowNewBiddingStrategyId);

      Vue.set(row.route_hash.livemainentity, 'state_is_dirty', true);
      this.selectedRow = row;
      // this.$store.dispatch('dailyPerformances/optimize', row);
      dailyOptimizationPerformancesService.optimize(
                  row,
                  row['index'],
                  this.dailyPerformances.all
      )
      this.openedDetailed = [];

      this.observeMainEntity(row, 1);
    },

    observeMainEntity(row, counter){

      let that = this;

      setTimeout(() => {

        performanceMainEntityService.get(row.route_hash.livemainentity.id).then((e) => {

          // Fix when changing vue page
          if(!that.dailyPerformances.all[row.index]){
            return;
          }

          // Fix when changing result-set page
          if(that.dailyPerformances.all[row.index].route_hash.livemainentity.id !== row.route_hash.livemainentity.id){
            return;
          }

          if(!e.data.state_is_dirty){
            e.data.is_updated = true;
            Vue.set(that.dailyPerformances.all[row.index].route_hash, 'livemainentity', e.data);
            setDefaultsMainEntity(that.dailyPerformances.all, row.index, e.data);
          }else{
            if(counter < 24){
              that.observeMainEntity(row, counter + 1);
            }
          }
        }).catch((e) => {
          that.slackWarning(
              'An error occurred while trying to update main entity. ' +
              'user: ' + that.theme.userId + ', mainentity: ' + row.route_hash.livemainentity.id + ', error json: '
              + JSON.stringify(e) + ', type: ' + typeof e + ', keys: ' + JSON.stringify(e.keys())
          );
          // alert('An error occurred while trying to update main entity. '+ JSON.stringify(e));
        })
      }, 15*1000);

    },

    getTypeBid(row, type){

      if(type==='is-danger'){
        return !row.route_hash.livemainentity.state_is_bidding_dirty && !this.canSaveBid(row);
      }

      if(type==='is-info'){
        return !row.route_hash.livemainentity.state_is_bidding_dirty && this.canSaveBid(row) && this.makeNumber(row.newBid) !== this.makeNumber(row.oldBid);
      }

      if(type==='is-success'){
        return row.route_hash.livemainentity.state_is_bidding_dirty;
      }

      if(type==='is-success-plus'){
        return row.route_hash.livemainentity.is_updated;
      }
    },

    deleteOptimization: function (){
      let row = this.selectedRow, x;

      x = confirm('Are you sure you want to deleted this from the traffic source? It cannot be restored.')

      if(!x){
        return;
      }

      this.$store.dispatch('dailyPerformances/delete', row);
      this.openedDetailed = [];
    },

    checkDataConsistency: function (row, type){
      type = type || '';
      if(type === 'title'){
        return '' +
            'Costs(UTC): ' + row.last_cost_update_datetime_utc +
            '\n\r' +
            'Revenue(UTC): ' + row.last_revenue_update_datetime_utc +
            ''
            ;
      }

      if(row.is_completed === false){
        return 'is-danger';
      }

      return 'is-success';
    },

    getMainEntityRemoteId: function (row){

      let r = row.route_hash.livemainentity.resourcename.split('--');

      return r[1];
    },

    openModal: function (row) {
      let payload = {
        'fromDate': moment().subtract(365, 'days').format('YYYY-MM-DD'),
        'toDate': moment().format('YYYY-MM-DD'),
        'routeHashValue': row.route_hash.id,
        'mainEntityId': row.route_hash.livemainentity.id,
        'ordering': '-date'
      };

      this.selectedRow = row;
      this.selectedRow.newStatus = this.selectedRow.route_hash.livemainentity.status === 'ACTIVE';
      this.selectedRow.oldStatus = this.selectedRow.route_hash.livemainentity.status === 'ACTIVE';

      this.$store.dispatch('dailyPerformances/getByDateRange', payload);
      this.$store.dispatch('logOptimizationGroups/getAll', payload);
      this.$store.dispatch('logDeletionGroups/getAll', payload);
      this.selectedHistoricalRow = row;
      this.isModalActive = true;

    },

    renderDate: function (date, format){
      format = format || 'YYYY-MM-DD';

      return moment(date).format(format);
    },

    countDays: function (date){
      let a = moment()
      return a.diff(moment(date), 'days') + 1
    },

    saveBulkInstructions: function () {
      let self = this,
          dataToBeSaved = this.bulkActions,
          ii = 0,
          x = confirm('Are you sure? ' + this.bulkActions.length + ' actions will be performed.');

      if(!x){
        return;
      }

      this.displayBulkActionDialog = false;

      this.saveBulkActionFilters();

      for (let i in dataToBeSaved) {
          self.savingInProgress += 1;
          ii = ii + 1;

          setTimeout(function (){

            self.$store.dispatch('dailyPerformances/setIsDirty', {
              'rowId': dataToBeSaved[i]['rowId'],
              'value': true
            })

            if(dataToBeSaved[i]['newStatus'] === 'REMOVED'){
              dailyOptimizationPerformancesService.deleteRequest(
                  dataToBeSaved[i],
                  dataToBeSaved[i]['rowId'],
                  self.dailyPerformances.all)
            }else{
              dailyOptimizationPerformancesService.optimize(
                  dataToBeSaved[i],
                  dataToBeSaved[i]['rowId'],
                  self.dailyPerformances.all)
            }

            self.observeMainEntity(dataToBeSaved[i], 1);

          }, ii * 200);
      }
    },

    saveActions: function (actions){
      this.actions = actions;
    },

    isDelayed (performance) {

      let nowUTC = moment().tz('UTC'),
          performanceUTC = moment(performance.datetime_utc).tz('UTC', true),
          delay,
          performanceTZ = moment(performance.datetime_tz).tz(performance.route_hash.livemainentity.account.timezone_code, true),
          selectedTZ = moment(moment(this.selectedMultiFilter['date']).format('YYYY-MM-DD 00:00:00')).tz(performance.route_hash.livemainentity.account.timezone_code, true),
          selectedFollowingDayTZ = selectedTZ.add(1,'day');

      if(nowUTC.minute() < 15){
        delay = nowUTC.subtract(1, 'hour').diff(performanceUTC, "hours");
      }else{
        delay = nowUTC.diff(performanceUTC, "hours");
      }

      if(selectedFollowingDayTZ.format('YYYY-MM-DD 00:00:00') === performanceTZ.format('YYYY-MM-DD 00:00:00')){
        return false;
      }

      return delay;
    },

    saveBulkActionFilters: function () {

      let actions = this.actions,
          rules = this.rulesParsed,
          rulesField = this.rulesFieldParsed;

      OptimizationBulkActionsService.post(
          actions,
          rules,
          rulesField
      ).then(
            () => {
              console.log('saved');
            },
            error =>
            {
              alert('An error occured, please notify IT, CODE: BulkAction');
              console.dir(error);
            })

    },

    downloadAsCsv(){
      this.filterList(1, true);
    },

    canSaveBudget(row, returnErrorMessage){

      if(isNaN(row.newBudget)){
        if(returnErrorMessage)
          return 'Provide a numeric value';
        return false;
      }

      if(row.newBudget <= 0){
        if(returnErrorMessage)
          return 'Provide a positive numeric value';
        return false;
      }

      if(row.route_hash.livemainentity.status==='REMOVED'){
        if(returnErrorMessage)
          return '';
        return false;
      }

      if(this.makeNumber(row.newBudget) === this.makeNumber(row.oldBudget)){
        if(returnErrorMessage)
          return '';
        return true;
      }

      if(
          !this.isSuperUser() &&
          this.getMaxBudget(row) > 0 &&
          this.makeNumber(row.newBudget) > this.getMaxBudget(row)
      ){
        if(returnErrorMessage)
          return 'Budget cannot be over ' + this.renderCurrency(this.getMaxBudget(row));
        return false;
      }

      if(returnErrorMessage)
          return '';
      return true;
    },

    canSaveBiddingStrategy(row, returnErrorMessage){
      let biddingStrategyDetails = this.biddingStrategyDetails(row.selectedRowNewBiddingStrategyId);
      if(!biddingStrategyDetails){
        return false;
      }

      if(!biddingStrategyDetails.is_bid_handled){
        if(returnErrorMessage)
          return '';
        return true;
      }else{
        if(this.isEmpty(row.newBid)){
          if(returnErrorMessage)
            return 'This strategy requires bid amount';
          return false;
        }

        if(returnErrorMessage)
          return '';
        return true;
      }
    },

    canSaveBid(row, returnErrorMessage){

      if(this.biddingStrategyDetails(row.selectedRowNewBiddingStrategyId).is_bid_handled) {
        if (isNaN(row.newBid)) {
          if (returnErrorMessage)
            return 'Provide a numeric value';
          return false;
        }

        if (row.newBid <= 0) {
          if (returnErrorMessage)
            return 'Provide a positive numeric value';
          return false;
        }
      }

      if(
          this.biddingStrategyDetails(row.selectedRowNewBiddingStrategyId).is_bid_handled &&
          this.isEmpty(row.newBid)
        ){

        if(returnErrorMessage){
          return 'Provide a bid value.'
        }
        return false;

      }

      if(returnErrorMessage){
        return ''
      }
      return true;
    },

    isEmpty(val) {
      return !val || val === '' || val === 0 || val === '0';
    },

    getExchangeRate(row){
      let a = this.makeNumber(row.route_hash.livemainentity.budget_amount_account_currency),
          b = this.makeNumber(row.route_hash.livemainentity.budget_amount);
      return b / a;
    },

    getStep(row, asDecimal){
      let exchangeRage = this.getExchangeRate(row),
        step;

      if(exchangeRage < 1){
        step = 0.001;
        if(asDecimal){
          return step + ''.split('.')[1].length;
        }

        return step;
      }

      step = 0.01;
      if(asDecimal){
        return step + ''.split('.')[1].length;
      }
      return step;
    },

    hideFilters(){

      let that = this;

      this.executeWhenPermissionsAreLoaded(() => {
        if(!that.isSuperUser()){

          let fieldsToDelete = [
            'log_optimization_group_filter',
            'log_deletion_group_filter'
          ];

          for(let ii in fieldsToDelete) {
            let fieldName = fieldsToDelete[ii];

            for (let i in that.indexNames) {
              let r = that.indexNames[i];
              if (fieldName === r.value)
              {
                Vue.delete(that.indexNames, i);
              }
            }
          }
        }
      })

    },

    copy(target) {
        navigator.clipboard.writeText(this.$refs[target]['href']?.trim() || this.$refs[target]['innerHTML']?.trim()).then(() => {
          this.$buefy.toast.open({
                    message: 'Copied!',
                    type: 'is-success',
                })
      })
    },

    gethourlyPerformanceCount(c) {this.hourlyPerformanceCount = c},

    updateOptimizationAccordion(id) {
      const status = JSON.parse(localStorage.getItem('optimizationAccordion'))
        status[id] = this.optimizationAccordion[id]
        localStorage.setItem('optimizationAccordion', JSON.stringify(status))
    },

    initOptimizationAccordion() {
      if(!localStorage.getItem('optimizationAccordion')) {
        localStorage.setItem('optimizationAccordion', JSON.stringify({
          dailyPerformance : true,
          hourlyPerformance : true,
          optimizationHistory : true,
          deletionHistory : true,
        }))
      }
      const status = JSON.parse(localStorage.getItem('optimizationAccordion'))
      this.optimizationAccordion.dailyPerformance = status.dailyPerformance
      this.optimizationAccordion.hourlyPerformance = status.hourlyPerformance
      this.optimizationAccordion.optimizationHistory = status.optimizationHistory
      this.optimizationAccordion.deletionHistory = status.deletionHistory
    },

      displayProduct(row){
        if(row.route_hash){
            if(row.route_hash.livemainentity){
                if(row.route_hash.livemainentity.subdomain_locale){
                  if(row.route_hash.livemainentity.subdomain_locale.product) {
                    if(row.route_hash.livemainentity.subdomain_locale.product.name) {
                        return row.route_hash.livemainentity.subdomain_locale.product.name;
                    }else{
                        return row.route_hash.livemainentity.subdomain_locale.product.label;
                    }
                  }
                }
            }
        }
        return '';
      }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>