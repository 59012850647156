<template>
    <div class="is-flex">
        <code>Bing: <b>{{ pst }}</b> | Yahoo: <b>{{ utc }}</b></code>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'DateTimeWidget',

    created() {
        this.timerIdUTC = setInterval(this.updateTimeUTC, 1000)
        this.timerIdPST = setInterval(this.updateTimePST, 1000)
        this.updateTimeUTC()
        this.updateTimePST()

        if(!this.pt) this.pt = moment().tz('America/Los_Angeles').format("z")
    },
    
    data() {
        return {
            pt: null,
            utc: null,
            pst: null,
            timerIdUTC: null,
            timerIdPST: null,
        }
    },

    methods: {
        updateTimeUTC() {
            const cd = moment().utc()
            this.getDateTime(cd, 'utc')
        },

        updateTimePST() {
            const cd = moment().tz('America/Los_Angeles')
            this.getDateTime(cd, 'pst')
        },

        getDateTime(cd, tz) {
            this[tz] = cd.format('YYYY-MMM-DD hh:mm:ssa')
        },
    },
}
</script>

<style lang="scss" scoped>
code {
    background-color: transparent;
}
</style>