import {administrationAgencyFeedsService} from "@/services/administration-agency-feeds.service";

export const agencyFeeds = {
    namespaced: true,
    state: {
        all: []
    },
    actions: {
        getAll({ commit }, filters) {
            commit('getAllRequest');

            administrationAgencyFeedsService.getAll(filters)
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data.data.results;
            state.loading = false;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
        }
    }
}
