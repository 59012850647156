<template>
    <section class="section is-main-section">
        <form @submit.prevent="submit">
            <b-loading v-model="users.loadingMarketing" />
            <p v-if="errors.length" class="notification is-danger content is-light mb-3" style="max-width: 700px">
                <ul class="m-0">
                    <li v-for="(er, index) in errors" :key="index">{{ er }}</li>
                </ul>
            </p>
            
            <div class="card">
                <div class="card-content py-3">
                    <b-field label="Group Name">
                        <b-input
                            name="group_name"
                            type="text"
                            v-model="group_name"
                            placeholder="Group name"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Users">
                        <multiselect
                        v-model="selectedUsers"
                        :options="users.allMarketing"
                        :multiple="true"
                        :close-on-select="true"
                        placeholder="Users"
                        label="username"
                        track-by="id" />
                    </b-field>
                        
                    <b-field v-if="selectedUsers.length" label="Permissions">
                        <table class="table">
                            <tbody>
                                <tr v-for="(user, index) in selectedUsers" :key="index">
                                    <td>{{ user.username }}</td>
                                    <td><b-checkbox v-model="user.is_hidden">Is Hidden <Tooltip label="By activating this, other people in the group will not see this user but this user will see others." /></b-checkbox></td>
                                </tr>
                            </tbody>
                        </table>
                    </b-field>
                </div>
                <div class="card-footer px-5 py-2 is-justify-content-space-between">
                    <b-button type="is-info" @click="$router.back()">Back</b-button>
                    <b-button type="is-success" @click="submit">Save</b-button>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
export default {
    name: 'AccessGroupsNewPage',

    data() {
        return {
            group_name: '',
            selectedUsers: [],
            errors: [],
        }
    },

    components: {
        Multiselect,
        Tooltip,
    },

    created() {
        if (!this.accessGroups.all.length) this.$store.dispatch('accessGroups/getAll')
        if(!this.users.allMarketing.length) this.$store.dispatch('users/getAllMarketing');

        const id = this.accessGroups.openedRowId

        if(id) {
            const group = this.accessGroups.all.find(a => a.id === id)

            //populate data
            this.group_name = group.name
            for(const i in group.users) {
                let getUser = this.users.allMarketing.find(u => u.id === group.users[i].user)
                if(getUser) {
                    getUser.is_hidden = group.users[i].is_hidden
                    this.selectedUsers.push(getUser)
                }
            }
        }
    },

    computed: {
        ...mapState([
            'users',
            'accessGroups',
        ]),
    },

    methods: {
        submit() {
            if(!this.group_name || !this.selectedUsers.length) return;
            
            this.errors = []
            const users = this.selectedUsers.map(u => {
                return {
                    user: u.id,
                    is_hidden: !!u.is_hidden,
                }
            })

            //save
            const payload = {
                name: this.group_name,
                users,
                'is_active': 'True',
                'marketing_agency': 'marketing_agency.id',
            }

            if(this.accessGroups.openedRowId) payload.id = this.accessGroups.openedRowId

            this.$store.dispatch('accessGroups/save', payload)
                        .then(() => {
                            this.$buefy.snackbar.open({message: 'Saved'});
                            this.$store.dispatch('accessGroups/getAll')
                            this.$router.push({ name: 'access groups' })
                        })
                        .catch(err => {
                            const errorsList = err?.response?.data?.details || []
                            if(errorsList) {
                                this.errors = Array.isArray(errorsList) ? errorsList : [errorsList]
                                return;
                            }                 
                            alert('An error occurred while creating a new access group, please stop now, and contact IT Support.');
                        })
        },
    },

    beforeDestroy() {
        this.$store.commit('accessGroups/openedRowId', {id: null})
    },
}
</script>

<style lang="scss" scoped>
.card {
    max-width: 700px;
    .card-header {
        border-top: 1px solid rgba(24, 28, 33, 0.06);
    }
}
</style>