<template>
    <section class="modal-card-body p-3 is-flex is-justify-content-space-between">
        <b-table
            :striped="true"
            :hoverable="true"
            :sticky-header="true"
            :data="countries.all"
            height="600"
            width="500"
            pagination-position="top"
            default-sort-direction="DESC"
            :pagination-rounded="false"
            sort-icon-size="is-medium"
            :scrollable="true"
            focusable
            narrowed>
            <div slot="empty" class="notification is-warning is-light has-text-centered">
                There is no data yet.
            </div>

            <b-table-column label="Country" field="name" sortable v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column label="Code" field="code" sortable v-slot="props">
                {{ props.row.code }}
            </b-table-column>
        </b-table>

        <b-table
            :striped="true"
            :hoverable="true"
            :sticky-header="true"
            :data="languages.all"
            height="600"
            width="500"
            pagination-position="top"
            default-sort-direction="DESC"
            :pagination-rounded="false"
            sort-icon-size="is-medium"
            :scrollable="true"
            focusable
            narrowed>
            <div slot="empty" class="notification is-warning is-light has-text-centered">
                There is no data yet.
            </div>

            <b-table-column label="Language" field="name" sortable v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column label="Code" field="code" sortable v-slot="props">
                {{ props.row.code }}
            </b-table-column>
        </b-table>
    </section>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: 'CountryLanguageList',

    data() {
        return {

        }
    },

    created() {
        if(!this.countries.all.length) this.$store.dispatch('countries/getAll');
        if(!this.languages.all.length) this.$store.dispatch('languages/getAll');
    },

    computed: {
        ...mapState([
            'countries',
            'languages',
        ])
    },

}
</script>