import { config } from './config';
import axios from "axios";


export const administrationFacebookPagesService = {
    getAll,
    config
};


export function getAll(filters) {

    let axiosConfig = {}

    axiosConfig['params'] = filters || {};

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/facebook-pages/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}