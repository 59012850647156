import { optimizationDashboardsReportKeywordsPerformancesService } from '@/services';

let emptyPaging = {
    page_size: 0,
    count: 0,
    pages: 1,
    page_number: 1,
    page_count: 1
};


export const dashboardsReportKeywordsPerformances = {
    namespaced: true,
    state: {
        all: [],
        loading: false,
        paging: emptyPaging,
        ordering: ''
    },
    actions: {
        getByDateRange({ commit }, payload) {
            commit('getAllRequest');

            let fromDate = payload['fromDate'],
                toDate = payload['toDate'],
                pageNumber = payload['pageNumber'],
                ordering = payload['ordering'];

            optimizationDashboardsReportKeywordsPerformancesService.getByDateRange(
                fromDate,
                toDate,
                pageNumber,
                ordering
            )
                .then(
                    data => commit('getAllSuccess', {
                        ...data,
                        'ordering': ordering
                    }),
                    error => commit('getAllFailure', error)
                );
        },
    },
    mutations: {
        getAllRequest(state) {
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
            state.paging = {
                page_size: response.data.page_size,
                count: response.data.count,
                pages: response.data.pages,
                page_number: response.data.page_number,
                page_count: response.data.page_count
            };
            state.ordering = response.ordering;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
            state.paging = emptyPaging;
        },
    }
}
