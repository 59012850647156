<template>
    <form @submit.prevent>
        <section class="modal-card-body">
            <b-field>
                <b-input
                    type="text"
                    v-model.trim="otp"
                    placeholder="Type the OTP"
                    required>
                </b-input>
            </b-field>
            <span v-if="errorMsg" class="is-block button is-danger is-light">{{ errorMsg }}</span>
        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Change"
                @click="closeAndChange" />
            <b-button
                label="Submit"
                type="is-primary"
                @click="handelSubmit" />
        </footer>
    </form>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'ProfilePageTokenModal',

    props: {
        agent: {
            validator(value) {
                return ['phone', 'email'].includes(value)
            },
            required: true
        }
    },

    data() {
        return {
            otp: '',
            errorMsg: ''
        }
    },

    computed: {
        ...mapState(['myProfile']),
    },

    created() {},

    methods: {
        handelSubmit() {
            this.errorMsg = ''
            if(this.otp && /^[0-9]+$/.test(this.otp)) {
                this.$store.dispatch('myProfile/validateOTP', { agent: this.agent, otp: +this.otp})
                    .then(({ data }) => {
                        if(data.results.validOTP) {
                            this.$emit('sccessOTP')
                        } else { 
                            this.errorMsg = 'Invalid Token'
                        }
                    })
            } else {
                this.errorMsg = 'Invalid Token'
            }
        },

        closeAndChange() {
            this.$emit('closeAndChange')
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-card {
    .modal-card-body {
        padding: 15px;
    }
}
</style>