import { config } from './config';
import axios from "axios";

export const creationLaunchClonedKeyword = {
    post,
};

function post(params){
    
    let endpoint;

    endpoint = '/api/creation/clone-keyword/';

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}${endpoint}`, params)
            .then(response => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            })
    });
}
