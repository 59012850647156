import { toolsImageSearcherService } from '@/services';

export const toolsImageSearcher = {
    namespaced: true,
    state: {
        all: [],
        loading: false
    },
    actions: {
        search({ commit }, payload) {
            commit('searchRequest');

            toolsImageSearcherService.search(payload['keywordName'], payload['networkCode'], payload['limit'])
                .then(
                    response => commit('searchSuccess', response),
                    error => commit('searchFailure', error)
                );
        }
    },
    mutations: {
        searchRequest(state) {
            state.loading = true;
        },
        searchSuccess(state, response) {
            state.all = response.data;
            state.loading = false;
        },
        searchFailure(state, error) {
            state.all = [];
            state.loading = false;
            console.dir(error)
        }
    }
}
