import { internalAgencyManagerService } from "@/services/internal-agency-manager.service";

export const internalAgencyManager = {
    namespaced: true,
    state: {
        all: [],
        files: [],
        loading: false,
        error: null,
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            internalAgencyManagerService.getAll()
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },

        save({ commit }, payload) {
            commit('saveRequest');

            return new Promise((resolve, reject) => {
                internalAgencyManagerService.save(payload)
                    .then(
                        data => {commit('saveSuccess', data); resolve(data)},
                        error => {commit('saveFailure', error); reject(error)}
                    );
            })
        },

        update({ commit }, payload) {
            commit('updateRequest');

            internalAgencyManagerService.update(payload)
                .then(
                    // Do nothing with the returned value.
                    data => data,
                    error => commit('updateFailure', error)
                );
        },

        saveFile({ commit }, payload) {
            commit('saveFileRequest');
            
            let fd = new FormData()
            Object.keys(payload).forEach(key => {
                fd.append(key, payload[key])
            })

            internalAgencyManagerService.saveFile(fd)
                .then(
                    data => commit('saveFileSuccess', data),
                    error => commit('saveFileFailure', error)
                );
        },

        getFiles({ commit }, params) {
            commit('getFilesRequest');

            internalAgencyManagerService.getFiles(params)
                .then(
                    data => commit('getFilesSuccess', data),
                    error => commit('getFilesFailure', error)
                );
        }

    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data.data.results;
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.all = [];
            state.loading = false;
            state.error = error;
        },
        saveRequest(state) {
            state.loading = true;
        },
        saveSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        saveFailure(state) {
            //alert('An error occurred while creating a new agency, please stop now, and contact IT Support.');
            state.loading = false;
        },
        updateRequest(state) {
            state.loading = true;
        },
        updateSuccess(state, data) {
            state.all = state.all.map(a => a.id === data.data.id ? data.data : a)
            state.loading = false;
        },
        updateFailure(state) {
            alert('An error occurred while updating, please stop now, and contact IT Support.');
            state.loading = false;
        },
        saveFileRequest(state) {
            state.loading = true;
        },
        saveFileSuccess(state, data) {
            // state.all = state.all.map(a => a.id === data.data.id ? data.data : a)
            console.log(data.data)
            state.loading = false;
        },
        saveFileFailure(state) {
            alert('An error occurred while uploading the file, please stop now, and contact IT Support.');
            state.loading = false;
        },
        getFilesRequest(state) {
            state.loading = true;
        },
        getFilesSuccess(state, data) {
            state.files = data.data.results;
            state.loading = false;
        },
        getFilesFailure(state) {
            alert('An error occurred while get the files, please stop now, and contact IT Support.');
            state.loading = false;
        },
    }
}