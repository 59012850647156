import { config } from './config';
import axios from "axios";
import moment from "moment-timezone";

export const optimizationDashboardsReportPerformancesService = {
    getByDateRange,
    config
};

export function getByDateRange(
    fromDate,
    toDate,
    chartType,
    networks,
    agencyFeeds,
    products,
    countries,
    accounts
){

    let axiosConfig = {}, params = {};

    params['chart_type'] = chartType;
    if(!fromDate || !toDate){
        let yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
        params['date__range'] = yesterday + ',' + yesterday;
    }else{
        params['date__range'] = fromDate + ',' + toDate;
    }

    params['page_size'] = 5000;

    if(networks.length > 0){
        params['network__in'] = networks.map(e => {return e.id}).join(',')
    }

    if(agencyFeeds.length > 0){
        params['agency_feed__in'] = agencyFeeds.map(e => {return e.id}).join(',')
    }

    if(products.length > 0){
        params['product__in'] = products.map(e => {return e.id}).join(',')
    }

    if(countries.length > 0){
        params['country__in'] = countries.map(e => {return e.id}).join(',')
    }

    if(accounts.length > 0){
        params['account__in'] = accounts.map(e => {return e.id}).join(',')
    }

    // Append to request.
    axiosConfig['params'] = params;

    let url = `${config.apiUrl}/api/performance/dashboards-report-performances/`;

    if(chartType === 'BY_AD_UNIT'){
        url = `${config.apiUrl}/api/performance/dashboards-report-performances-adunit-country/`;
    }else if(chartType === 'BY_AD_UNIT_COUNTRY_PRODUCT'){
        url = `${config.apiUrl}/api/performance/dashboards-report-performances-adunit-country/`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}