import {optimizationLogOptimizationGroupsService} from '@/services';

export const logOptimizationGroups = {
    namespaced: true,
    state: {
        all: [],
        loading: false
    },
    actions: {
        getAll({ commit }, payload) {
            commit('getAllRequest');

            let mainEntityId = payload['mainEntityId'];

            optimizationLogOptimizationGroupsService.getAll(
                mainEntityId
            )
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },

    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        getAllFailure(state, error) {
            state.all = [];
            state.loading = false;
            console.dir(error);
        },
    }
}
