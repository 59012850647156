import { config } from './config';
import axios from "axios";

export const contentsService = {
    getAll,
};

export function getAll(params) {
    params = params || {}

    return new Promise((resolve, reject) => {
        axios
        .get(`${config.apiUrl}/api/administration/available-contents/`, {params})
        .then(response => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });
}
