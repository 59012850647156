<template>
  <div>
    <section class="section is-main-section">
      <h4 class="title has-text-centered">
        Url Builder
      </h4>
      <div v-if="Object.keys(urls).length === 0">
        <dependant-multi-filter
            agency-feeds-enabled
            products-with-available-url-codes-enabled
            networks-enabled
            countries-enabled
            filter-disabled
            v-model="selectedMultiFilter"
        >
        </dependant-multi-filter>

        <b-input v-model="keywords" maxlength="9000" type="textarea" placeholder="Paste or type list of keywords here.. One keyword one line.."></b-input>

        <b-button @click="generate" class="is-success">Generate!</b-button>
      </div>
      <div v-else>

        <div v-for="(u, k) in urls" :key="k" class="m-4" style="position: relative;">

          <b-loading v-model="u.isLoading" :is-full-page="false"></b-loading>

          <span>{{ u.keywordName}}</span><br />

          <div v-if="u.urls.length > 0">
            <a v-for="url in u.urls" :key="url" :href="url" target="_blank">{{ url }}</a>
          </div>

          <span v-if="u.error" class="is-danger">{{ u.error }}</span>

        </div>

        <b-button class="is-danger" @click="urls = {}">Reset</b-button>

      </div>

    </section>
  </div>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {administrationSubdomainLocaleService} from "@/services/administration-subdomainlocale.service";
import {tyrionUrlRotationRouteHashesService} from "@/services/tyrion-urlrotation-route-hashes.service";

export default {
  name: 'UrlBuilder',

  mounted() {},

  components: {
    DependantMultiFilter
  },

  data() {
    return {

      selectedMultiFilter: {
        'networks': [],
        'agencyFeeds': [],
        'products': [],
        'countries': [],
      },

      keywords: '',

      urls: {},
    }
  },

  computed: {
    ...mapState([
      'networks',
      'agencyFeeds'
    ]),
  },

  methods: {
    isValid(){

      if(this.selectedMultiFilter['networks'].length === 0){
        alert('Please select at least 1 network.');
        return false;
      }

      if(this.selectedMultiFilter['agencyFeeds'].length === 0){
        alert('Please select at least 1 Agency Feed.');
        return false;
      }

      if(this.selectedMultiFilter['productsWithAvailableUrlCodes'].length === 0){
        alert('Please select at least 1 product.');
        return false;
      }

      if(this.selectedMultiFilter['countries'].length === 0){
        alert('Please select at least 1 country.');
        return false;
      }

      if(!this.keywords || this.keywords === ''){
        alert('Please add at least one keyword.');
        return false;
      }

      return true;
    },
    getKeywordParts() {
      this.keywords = this.keywords.trim();

      let parts = this.keywords.split("\n");

      for(let i in parts){
        parts[i] = parts[i].toLowerCase().trim();
      }

      return parts;
    },
    generate() {
      let that = this;

      if(!this.isValid()){
        return;
      }

      let keywordParts = this.getKeywordParts();

      administrationSubdomainLocaleService.get({
        'product__in': this.selectedMultiFilter['productsWithAvailableUrlCodes'].map((element) => {
          return element.id
        }).join(','),
        'country__in': this.selectedMultiFilter['countries'].map((element) => {
          return element.id
        }).join(','),
      }).then((e) => {
        if(!e.data.results || e.data.results.length === 0){
          return alert('Cannot find valid subdomain locales for the current combination of Product(s) and Country(ies).');
        }
        let rows = e.data.results,
            networks = that.selectedMultiFilter['networks'];

        for(let i in rows){
          let row = rows[i];

          let productId = row['product']['id'];

          for(let ii in keywordParts){
            let keywordName = keywordParts[ii];

            for(let iii in networks){
              let network = networks[iii];
              this.generateUrl(
                  productId,
                  network.id,
                  row.id,
                  keywordName
              );
            }
          }
        }

      })
    },

    generateUrl(productId, networkId, subdomainLocaleId, keywordName){

      let that = this,
          key = productId + '_' + networkId + '_' + subdomainLocaleId + '_' + keywordName;

      if(this.urls[key] === undefined ){
        Vue.set(this.urls, key, {
          isLoading: true,
          error: null,
          urls: [],
          keywordName: keywordName
        })
      }

      tyrionUrlRotationRouteHashesService.post({
        'is_active': false,
        'rotation_type': 'STATIC',
        'product': productId,
        'network': networkId,
        'keywordweight_set': [
            {
                'weight': 100,
                'subdomain_locale': subdomainLocaleId,
                'keyword': keywordName
            }
        ]
      }).then((e) => {
        Vue.set(that.urls[key], 'isLoading', false);

        if(['200', '500'].indexOf(`${e.status}`) > -1){
          Vue.set(that.urls[key], 'error', 'Not found for selected combination.');
        }else{
          Vue.set(that.urls[key].urls, that.urls[key].urls.length, e.data.source_url);
        }
      })
    }
  }
}
</script>