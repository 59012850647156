<template>
    <div class="profile">
        <section class="section is-main-section">
            <div class="card">
                <header class="card-header">
                    <p class="card-header-title">Profile</p>
                </header>
                <div class="card-content">
                    <div class="custom-header">
                        <div class="is-user-avatar has-max-width">
                            <img src="https://avatars.dicebear.com/v2/human/somename.svg?options[mood][]=happy" :alt="theme.firstName + '\'s Profile Picture'" />
                        </div>
                        <div>
                            <p class="is-block box is-default is-light py-1 pl-2 mb-2">
                                <b-icon icon="account" /><span class="phone">{{ theme.firstName + ' ' + theme.lastName }}</span>
                            </p>
                            <p class="is-block box is-default is-light py-1 pl-2 mb-2">
                                <b-icon icon="at" /><span class="phone">{{ theme.userName }}</span>
                            </p>
                            <p class="is-block box is-default is-light py-1 pl-2 mb-2">
                                <b-icon icon="phone" /><span class="phone">{{ theme.userPhone || 'No phone' }}</span>
                            </p>
                            <p class="is-block box is-default is-light is-light py-1 pl-2 mb-2">
                                <b-icon icon="email" /><span class="phone">{{ theme.userEmail || 'No email' }}</span>
                            </p>
                        </div>
                    </div>

                    <hr />

                    <b-tabs :animated="false" :destroy-on-hide="true" size="is-medium" :multiline="true" type="is-boxed">
                        <b-tab-item label="Name">
                            <ProfilePageName @customDestroy="customDestroy++" :key="customDestroy" />
                        </b-tab-item>

                        <b-tab-item label="Password">
                            <ProfilePagePassword @customDestroy="customDestroy++" :key="customDestroy" />
                        </b-tab-item>

                        <!-- <b-tab-item label="Phone">
                            <ProfilePagePhone @customDestroy="customDestroy++" :key="customDestroy" />
                        </b-tab-item> -->

                        <b-tab-item label="Email">
                            <ProfilePageEmail @customDestroy="customDestroy++" :key="customDestroy" />
                        </b-tab-item>

                        <b-tab-item label="Authentication">
                            <ProfilePageAuth @customDestroy="customDestroy++" :key="customDestroy" />
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ProfilePagePassword from '@/pages/profile/ProfilePagePassword';
// import ProfilePagePhone from '@/pages/profile/ProfilePagePhone';
import ProfilePageName from '@/pages/profile/ProfilePageName';
import ProfilePageEmail from '@/pages/profile/ProfilePageEmail';
import ProfilePageAuth from '@/pages/profile/ProfilePageAuth';
import { mapState } from 'vuex'
export default {
    name: 'ProfilePage',

    data() {
        return {
            customDestroy: 1,
        }
    },

    components: {
        ProfilePageName,
        ProfilePagePassword,
        // ProfilePagePhone,
        ProfilePageEmail,
        ProfilePageAuth,
    },

    computed: {
        ...mapState(['theme']),
    }

}
</script>

<style lang="scss">
.profile {
    max-width: 800px;
    .card-content {
        .custom-header {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            p {
                display: flex !important;
                justify-content: flex-start;
                align-items: center;
                width: 300px;
                box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 5%), 0 0px 0 1px rgb(10 10 10 / 1%);
                > span.phone {
                    margin-left: 15px;
                }
            }
            .is-user-avatar {
                img {
                    width: 100px;
                }
                span {
                   text-align: center;
                    display: inherit;
                }
            }
        }     
    }
}
</style>