<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a
        class="navbar-item"
        @click.prevent="menuToggleMobile"
      >
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
      <div class="navbar-item has-control no-left-space-touch">
        <div class="control">
           <DateTimeWidget />
          <!-- <input class="input" placeholder="Search everywhere..." /> -->
        </div>
      </div>
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div class="navbar-end">
        <nav-bar-menu class="has-divider has-user-avatar">
          <user-avatar />
          <div class="is-user-name">
            <span>{{ userName }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <router-link
              to="/profile"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="account" custom-size="default" />
              <span>My Profile</span>
            </router-link>
            <router-link to="/launching-settings" exact-active-class="is-active" class="navbar-item">
              <b-icon icon="rocket-launch" custom-size="default"></b-icon>
              <span>Launching Settings</span>
            </router-link>
            <hr class="navbar-divider" />
            <a class="navbar-item" @click="logout">
              <b-icon icon="logout" custom-size="default"></b-icon>
              <span>Log Out</span>
            </a>
          </div>
        </nav-bar-menu>
        <a
          class="navbar-item has-divider is-desktop-icon-only"
          title="About"
          @click="showAboutUsModal = true"
        >
          <b-icon icon="help-circle-outline" custom-size="default" />
          <span>About</span>
        </a>
        <a
          class="navbar-item is-desktop-icon-only"
          title="Log out"
          @click="logout"
        >
          <b-icon icon="logout" custom-size="default" />
          <span>Log out</span>
        </a>
      </div>
      <b-modal v-model="showAboutUsModal">
        <AboutUsModal />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
      </b-modal>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import UserAvatar from '@/components/UserAvatar'
import AboutUsModal from '@/components/AboutUsModal'
import DateTimeWidget from '@/components/DateTimeWidget'
export default {
  name: 'NavBar',
  components: {
    UserAvatar,
    NavBarMenu,
    AboutUsModal,
    DateTimeWidget,
  },
  data () {
    return {
      isMenuNavBarActive: false,
      showAboutUsModal: false,
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return this.isMenuNavBarActive ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    ...mapState('theme', ['isNavBarVisible', 'isAsideMobileExpanded', 'userName'])
  },
  mounted () {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false
    })
  },
  methods: {
    menuToggleMobile () {
      this.$store.commit('theme/asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = !this.isMenuNavBarActive
    },
    logout () {

      if(!confirm('Are you sure? 99% of the times this was clicked by mistake.')){
        return;
      }

      this.$buefy.snackbar.open({
        message: 'Logged out',
        queue: false
      })
      const { dispatch } = this.$store;
      dispatch('authentication/logout');
      window.location.reload();
    }
  }
}
</script>