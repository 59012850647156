import { devCountriesService } from '@/services';

export const countries = {
    namespaced: true,
    state: {
        all: [],
        loading: false,
        allFullList: [],
        loadingFullList: false
    },
    actions: {
        getAll({ commit }, payload) {
            commit('getAllRequest');

            devCountriesService.getAll(payload)
                .then(
                    performance => commit('getAllSuccess', performance),
                    error => commit('getAllFailure', error)
                );
        },
        getAllFullList({ commit }) {
            commit('getAllFullListRequest');

            devCountriesService.getAllFullList()
                .then(
                    performance => commit('getAllFullListSuccess', performance),
                    error => commit('getAllFullListFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.loading = false;
        },
        getAllFailure(state, error) {
            console.error(error);
            state.loading = false;
        },
        getAllFullListRequest(state) {
            state.allFullList = [];
            state.loadingFullList = true;
        },
        getAllFullListSuccess(state, response) {
            state.allFullList = response.data.results;
            state.loadingFullList = false;
        },
        getAllFullListFailure(state, error) {
            console.error(error);
            state.loadingFullList = false;
        }
    }
}
