import {administrationAccountsService} from "@/services/administration-accounts.service";

export const accounts = {
    namespaced: true,
    state: {
        all: [],
        loading: false,
        error: null,

        cbsiBingAll: [],
        cbsiBingLoading: false,
        cbsiError: null,

        iacBingAll: [],
        iacBingLoading: false,
        iacBingError: null,

        blocked: [],
        blockedLoading: false,
        blockedError: null
    },
    actions: {
        getAll({ commit }) {
            commit('getAllRequest');

            administrationAccountsService.getAll()
                .then(
                    data => commit('getAllSuccess', data),
                    error => commit('getAllFailure', error)
                );
        },

        getCbsiBingAll({ commit }) {
            commit('getCbsiBingAllRequest');

            administrationAccountsService.getAll({
                'name__startswith': 'PY_CB'
            })
                .then(
                    data => commit('getCbsiBingAllSuccess', data),
                    error => commit('getCbsiBingAllFailure', error)
                );
        },

        getIacBingAll({ commit }) {
            commit('getIacBingAllRequest');

            administrationAccountsService.getAll({
                'name__startswith': 'PY_BI'
            })
                .then(
                    data => commit('getIacBingAllSuccess', data),
                    error => commit('getIacBingAllFailure', error)
                );
        },

        getBlockedList({ commit }) {
            commit('getBlockedListRequest');

            administrationAccountsService.getAll({
                'blocked_on_datetime__isnull': 'False'
            })
                .then(
                    data => commit('getBlockedListSuccess', data),
                    error => commit('getBlockedListFailure', error)
                );
        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
        },
        getAllSuccess(state, data) {
            state.all = data.data.results;
            state.loading = false;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
            state.error = null;
        },


        getIacBingAllRequest(state) {
            state.iacBingAll = [];
            state.iacBingLoading = true;
        },
        getIacBingAllSuccess(state, data) {
            state.iacBingAll = data.data.results;
            state.iacBingLoading = false;
        },
        getIacBingAllFailure(state) {
            state.iacBingAll = [];
            state.iacBingLoading = false;
            state.iacBingError = null;
        },


        getCbsiBingAllRequest(state) {
            state.cbsiBingAll = [];
            state.cbsiBingLoading = true;
        },
        getCbsiBingAllSuccess(state, data) {
            state.cbsiBingAll = data.data.results;
            state.cbsiBingLoading = false;
        },
        getCbsiBingAllFailure(state) {
            state.cbsiBingAll = [];
            state.cbsiBingLoading = false;
            state.cbsiBingError = null;
        },


        getBlockedListRequest(state) {
            state.blocked = [];
            state.blockedLoading = true;
        },
        getBlockedListSuccess(state, data) {
            state.blocked = data.data.results;
            state.blockedLoading = false;
        },
        getBlockedListFailure(state) {
            state.blocked = [];
            state.blockedLoading = false;
            state.blockedError = null;
        }
    }
}
