import { config } from './config';
import axios from "axios";

export const hemingwayStemmedKeywordCreatives = {
    generate,
};

export function generate(term, maxLength, numberOfResults) {

    // params['is_active'] = 'True'

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/proxy/hemingway/stemmed-keyword-creatives/`, {
            'keyword_name': term,
            'max_length': maxLength,
            'number_of_results': numberOfResults
        })
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function save(payload) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/administration/access-groups`, payload)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}