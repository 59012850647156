import { partnersService } from '@/services';

let emptyPaging = {
    page_size: 0,
    count: 0,
    pages: 1,
    page_number: 1,
    page_count: 1
};

export const partners = {
    namespaced: true,

    state: {
        all: [],
        allAvailablePartners: [],
        paging: emptyPaging,
        loading: false,
    },

    actions: {
        getAll({ commit }, payload) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                partnersService.getAll(payload)
                    .then(
                        data => {commit('getAllSuccess', data); resolve(data)},
                        error => {commit('getAllFailure', error); reject(error)}
                    ); 
            })
        },

        getAllAvailablePartners({ commit }, payload) {
            commit('getAllAvailableRequest');

            return new Promise((resolve, reject) => {
                partnersService.getAllAvailablePartners(payload)
                    .then(
                        data => {commit('getAllAvailableSuccess', data); resolve(data)},
                        error => {commit('getAllAvailableFailure', error); reject(error)}
                    ); 
            })
        },
    },

    mutations: {
        getAllRequest(state) {
            state.all = [];
            state.loading = true;
            state.paging = emptyPaging;
        },
        getAllSuccess(state, response) {
            state.all = response.data.results;
            state.paging = {
                page_size: response.data.page_size,
                count: response.data.count,
                pages: response.data.pages,
                page_number: response.data.page_number,
                page_count: response.data.page_count
            };
            state.loading = false;
        },
        getAllFailure(state) {
            state.all = [];
            state.loading = false;
            state.paging = emptyPaging;
        },
        getAllAvailableRequest(state) {
            state.allAvailablePartners = [];
            state.loading = true;
            state.paging = emptyPaging;
        },
        getAllAvailableSuccess(state, response) {
            state.allAvailablePartners = response.data.results;
            state.paging = {
                page_size: response.data.page_size,
                count: response.data.count,
                pages: response.data.pages,
                page_number: response.data.page_number,
                page_count: response.data.page_count
            };
            state.loading = false;
        },
        getAllAvailableFailure(state) {
            state.allAvailablePartners = [];
            state.loading = false;
            state.paging = emptyPaging;
        },
    }
}
