import { config } from './config';
import axios from "axios";


export const toolsVideoSearcherService = {
    search,
    searchFreeVideos,
    uploadByUrl,
    config
};


export function search(keywordName, networkCode, limit) {

    limit = limit || 20;

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/proxy/picasso/video/video-searcher/?network_code=${networkCode}&keyword_name=${keywordName}&limit=${limit}`)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function searchFreeVideos(keywordName) {

    let endpoint = `${config.apiUrl}/api/proxy/picasso/video/free-video-searcher/?keyword_name=${keywordName}`;
    endpoint += '&nocache=' + Date.now();

    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function uploadByUrl(keywordName, thumbnailVideo, url) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/proxy/picasso/video/base-videos/`,{
            'keyword_name': keywordName,
            'thumbnail_video': thumbnailVideo,
            'video_url': url
        })
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}