<template>
    <div>
        <section class="section is-main-section">
            <router-link :to="{name: 'internal-agency-new'}" class="button is-success mb-3 mt-1">Create New</router-link>
            <div class="card has-table has-mobile-sort-spaced">
                <div class="card-content">
                    <b-table
                        :loading="internalAgencyManager.loading"
                        :data="internalAgencyManager.all"
                        :striped="true"
                        :hoverable="true"
                        default-sort="total_count"
                        pagination-position="top"
                        :pagination-rounded="false"
                        sort-icon="chevron-up"
                        sort-icon-size="is-medium"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                    >
                        <div slot="empty" class="notification is-warning is-light has-text-centered">
                            There's no organizations yet.
                        </div>
                        <b-table-column label="Organization Name" field="organization_name" sortable v-slot="props">
                            {{ props.row.organization.name }}
                        </b-table-column>

                        <b-table-column label="Users count" field="users_count" sortable v-slot="props">
                            {{ props.row.users_count }}
                        </b-table-column>

                        <b-table-column label="Limit" field="limit" sortable v-slot="props">
                            {{ props.row.limit_amount ? renderCurrency(props.row.limit_amount) : '-' }}
                        </b-table-column>

                        <b-table-column label="Created on" field="created_on" sortable v-slot="props">
                            {{ formatDatetime(props.row.created_on_datetime) }}
                        </b-table-column>

                        <b-table-column label="Contract" field="contract" sortable v-slot="props">
                            {{ props.row.is_contract_attached ? 'Yes' : 'No' }}
                        </b-table-column>

                        <b-table-column label="Actions" sortable v-slot="props">
                        <b-button size="is-small" type="is-success" @click="showLimitsModal = true; clickedRowId = props.row.id">Limits</b-button>
                            <b-button size="is-small" type="is-warning" class="mr-1 ml-1" @click="showAccessModal = true; clickedRowId = props.row.id">Access</b-button>
                            <b-button size="is-small" type="is-info" @click="showFilesModal = true; clickedRowId = props.row.id">Files</b-button>
                        </b-table-column>

                    </b-table>
                </div>
            </div>
        </section>

        <CustomModalWrapper title="Organization Limits" :show="showLimitsModal">
            <InternalAgencyPageLimitsModal :id="clickedRowId" @close="showLimitsModal = false" />
        </CustomModalWrapper>

        <CustomModalWrapper title="Organization Access" :show="showAccessModal">
            <InternalAgencyPageAccessModal :id="clickedRowId" @close="showAccessModal = false" />
        </CustomModalWrapper>

        <CustomModalWrapper title="Organization Files" :show="showFilesModal">
            <InternalAgencyPageFilesModal :id="clickedRowId" @close="showFilesModal = false" />
        </CustomModalWrapper>
    </div>
</template>

<script>
import InternalAgencyPageLimitsModal from "@/pages/internal-agency/InternalAgencyPageLimitsModal"
import InternalAgencyPageAccessModal from "@/pages/internal-agency/InternalAgencyPageAccessModal"
import InternalAgencyPageFilesModal from "@/pages/internal-agency/InternalAgencyPageFilesModal"
import CustomModalWrapper from "@/components/CustomModalWrapper";
import { mapState } from "vuex"
import {numberUtils} from "@/helpers/number-utils";
export default {
    name: 'InternalAgencyPage',

    data() {
        return {
            showLimitsModal: false,
            showAccessModal: false,
            showFilesModal: false,
            clickedRowId: '',
        }
    },

    mixins: [
        numberUtils,
    ],

    components: {
        InternalAgencyPageLimitsModal,
        InternalAgencyPageAccessModal,
        InternalAgencyPageFilesModal,
        CustomModalWrapper
    },

    created() {
        if (!this.internalAgencyManager.all.length) this.$store.dispatch('internalAgencyManager/getAll')
        if (!this.internalAgencyManager.files.length) this.$store.dispatch('internalAgencyManager/getFiles')
    },

    computed: {
        ...mapState([
            'internalAgencyManager',
        ]),
    },
}

</script>