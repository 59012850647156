import axios from "axios";

export const notificatorUtils = {
    methods: {
        slackOutsourcingInfo(message) {
            axios.post('https://hooks.slack.com/services/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',
                {
                    text: message
                }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', } })
        },
        slackWarning(message) {
            axios.post('https://hooks.slack.com/services/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',
                {
                    text: message
                }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', } })
        },
    }
}