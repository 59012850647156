<template>
    <form action="">
        <section class="modal-card-body">
            <b-field label="Amount">
                <b-input
                    type="number"
                    min="0"
                    v-model.number="limit_amount"
                    placeholder="Amount in USD"
                    required>
                </b-input>
            </b-field>
            <span v-if="errorMsg" class="is-block button is-danger is-light">{{ errorMsg }}</span>
        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Close"
                @click="close" />
            <b-button
                label="Set Limit"
                type="is-primary"
                @click="handelSetLimit" />
        </footer>
    </form>
</template>

<script>
export default {
    name: 'InternalAgencyPageLimitsModal',

    props: {
        id: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            limit_amount: '',
            file: {},
            errorMsg: '',
        }
    },

    created() {
        this.limit_amount = this.$store.state.internalAgencyManager.all.find(i => i.id == this.id).limit_amount
    },

    methods: {
        handelSetLimit() {
            const { id, limit_amount } = this

            if(!limit_amount) return
 
            console.log('dispatch update limit...', this.id)
            const payload = { id, limit_amount }

            this.$store.dispatch('internalAgencyManager/update', payload)
                .then(() => {
                    this.clearFieldsAndClose()
                    this.$store.dispatch('internalAgencyManager/getAll')
                    //notify the user
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        message: 'Limits updated Successfully!',
                        actionText: 'Ok',
                        queue: false,
                    }); 
                })

        },

        close() {
            this.clearFieldsAndClose() // TODO: remove is-danger class when existing modal without action
        },

        clearFieldsAndClose() {
            this.limit_amount = ''
            this.file = {}
            this.errorMsg = ''
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-card {
    .modal-card-body {
        padding: 15px;
    }
}
</style>