import { config } from './config';
import axios from "axios";


export const administrationAgencyFeedsService = {
    getAll,
};


export function getAll(params) {

  params = params || {}
  params['is_available'] = true

  return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/agency-feeds/?is_active=True`, {params})
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}