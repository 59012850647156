import { config } from './config';
import axios from "axios";

export const administrationAvailableAdUnitService = {
    getAll,
};


export function getAll(filters) {

    let axiosConfig = {};

    axiosConfig['params'] = filters || {};
    axiosConfig['params']['is_available'] = 'True'

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/available-adunits/`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}