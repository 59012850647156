import { config } from './config';
import axios from "axios";


export const administrationAccountsService = {
    getAll,
    config
};


export function getAll(filters) {

    let axiosConfig = {};

    axiosConfig['params'] = filters || {};
    axiosConfig['params']['is_active'] = 'True'

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/administration/accounts/?fields=id,name,currency_code`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}