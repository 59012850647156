<template>
  <div :class="['card', customClass || '']">
    <header v-if="title" class="card-header">
      <p :class="['card-header-title', customHeaderClass || '']">
        <b-icon v-if="icon" :icon="icon" custom-size="default" />
        {{ title }}
      </p>
      <slot name="toolbar-right"></slot>
    </header>
    <div :class="['card-content', cardContentClass]">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    headerIcon: {
      type: String,
      default: null
    },
    cardContentClass: {
      type: String,
      default: 'p-0'
    },
    customClass: {
      type: String,
    },
    customHeaderClass: {
      type: String,
    },
  },
  methods: {
    headerIconClick () {
      this.$emit('header-icon-click')
    }
  }
}
</script>
