<template>
    <section :class="viewModeClass">
    <b-field class=" is-small">
        <b-radio-button v-model="viewModeBtn"
        native-value="normal" size="is-small"
        type="is-success is-light is-outlined">
        <span>Normal</span>
        </b-radio-button>

        <b-radio-button v-model="viewModeBtn"
        native-value="expanded" size="is-small"
        type="is-success is-light is-outlined">
        <span>Expanded</span>
        </b-radio-button>

        <b-radio-button v-model="viewModeBtn"
        native-value="full" size="is-small"
        type="is-success is-light is-outlined">
        <span>Full screen</span>
        </b-radio-button>
    </b-field>
    </section>
</template>

<script>
export default {
    name: 'ViewModes',

    computed: {
        viewModeClass() {
            if(this.viewModeBtn === 'full') {
                return 'ml-4'
            } else if(this.viewModeBtn === 'expanded') {
                return 'section is-main-section'
            } else {
                return 'pl-5 pb-1'
            }
        },

        viewModeBtn: {
            set(mode) {
                //update the state
                this.$store.commit('theme/set', ['viewMode', mode])

                //full secreen mode setup
                const el = document.querySelector("#app > div");
                if(mode === 'full') {
                    el.requestFullscreen()
                    .then(() => {})
                    .catch(() => {});
                } else {
                    document.exitFullscreen()
                    .then(() => {})
                    .catch(() => {});
                }
            },
            get() {
                return this.$store.state.theme.viewMode
            }
        }
    },

}
</script>