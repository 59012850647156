import { config } from './config';
import axios from "axios";

export const advertisersCheckService = {
    getCheckedKeywords,
    newCheck,
};

export function getCheckedKeywords(params) {

    return new Promise((resolve, reject) => {
        //remove empty filters
        for(let key in params) { if(!params[key]) delete params[key] }

        params['nocache'] = Date.now();

        axios
            .get(`${config.apiUrl}/api/advertiser/checked-keywords/`, {params})
            .then(response => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function newCheck(payload) {

    //remove empty filters
    for(let key in payload) { if(!payload[key]) delete payload[key] }

    return new Promise((resolve, reject) => {
        axios
            .post(`${config.apiUrl}/api/advertiser/checked-advertisers/`, payload)
            .then(response => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}