import { config } from './config';
import axios from "axios";

export const optimizationDashboardsChartLogOptimizationMessageService = {
    get,
    config
};

function get(params){
    let axiosConfig = {}, endpoint;

    // Append to request.
    axiosConfig['params'] = params;

    endpoint = '/api/optimization/dashboards-chart-log-optimization-message/';

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}${endpoint}`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}