import { config } from './config';
import axios from "axios";


export const myProfileService = {
    sendOTPToAgent,
    validateOTP,
    saveAgent,
    changeUserInfo,
    getQRImage,
    verifyCodeFromQR,
    savePassword,
    superLogin,
    config
};

function sendOTPToAgent(payload) {

  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api/profile/sendOTPToAgent`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function validateOTP(payload) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${config.apiUrl}/api/profile/validateOTP`, payload)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

function saveAgent(payload) {

  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api/profile/saveAgent`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function savePassword(payload) {

  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api/profile/savePassword`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function changeUserInfo(payload) {

  return new Promise((resolve, reject) => {
    axios
      .patch(`${config.apiUrl}/api/app_auth/change-user-information`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getQRImage(payload) {

  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/api/app_auth/enable-two-factor-auth`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function verifyCodeFromQR(payload) {

  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api/app_auth/enable-two-factor-auth`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function superLogin(payload) {

  return new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/api/app_auth/super-login`, payload)
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}